import styled from 'styled-components';

export const Container = styled.div`
  @media all and (max-width: 600px) {
    padding: 0 16px;
  }
`;

export const Panel = styled.div`
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;

  span {
    color: #f00;
  }
`;

export const PasswordDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  button {
    position: absolute;
    right: 0;
    margin-right: 10px;
    border: none;
    background: transparent;
  }
`;

export const Div = styled.div`
  margin: 15px 0px;
`;

export const Middle = styled.div`
  text-align: center;
`;
