import IEvent from 'interfaces/IEvent';
import RestService from './RestService';

export default class EventService extends RestService<IEvent> {
  constructor() {
    super('events');
  }

  public async approve(id: number): Promise<void> {
    await this.httpClient.put(`/${id}/approve`);
  }

  public async refuse(id: number): Promise<void> {
    await this.httpClient.put(`/${id}/refuse`);
  }
}
