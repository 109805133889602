import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  @media (max-width: 480px) {
    min-height: fit-content;
  }
`;

export const NavigationButtons = styled.nav`
  width: fit-content;
  max-width: 100%;
  margin: 35px 0;
  padding-left: 50px;
  ul {
    overflow: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    gap: 30px;
  }
  ul::-webkit-scrollbar {
    height: 0;
  }
  @media (max-width: 480px) {
    padding-left: 20px;
    width: 100%;
  }
`;

export const ButtonTab = styled.button`
  width: 125px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px;
  border: 0.5px solid #9a9a9a;
  color: #9a9a9a;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  &:hover {
    border-color: #020c90;
    background-color: #020c90;
    color: #ffffff;
  }
  &[data-active='true'] {
    border-color: #020c90;
    color: #020c90;
    &:hover {
      color: #ffffff;
    }
    @media (max-width: 960px) {
      background-color: #fff;
      &:hover {
        color: #020c90;
      }
    }
  }
`;
