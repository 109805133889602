import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import MainTitle from 'components/MainTitle';
import Heading from 'components/Heading';
import TourSeturForm from './components/TourSeturForm';
import { useParams } from 'react-router';
import { useLoading } from 'hooks/useLoading';
import TouristOffersBusinessService from 'services/TouristOffersBusinessService';

const TourActivityForm = (): JSX.Element => {
  const { id } = useParams<{ id?: string }>();
  const { openLoading, closeLoading } = useLoading();
  const [selectedTour, setSelectedTour] = useState<any | null>();

  useEffect(() => {
    openLoading();
    const fetchBusinessTourData = [fetchTouristOffersActivity()];
    Promise.all(fetchBusinessTourData).finally(() => closeLoading());
  }, []);

  const service = new TouristOffersBusinessService();

  const fetchTouristOffersActivity = async () => {
    if (id) {
      try {
        const data = await service.findOne(Number(id));
        const images = data.images.map(image => image.image);
        const newActivity = { ...data, images, image: [data.image] };
        setSelectedTour(newActivity);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <MainTitle>Configurações</MainTitle>
      <Container>
        <Heading>{id ? 'Editar Atividade' : 'Nova Atividade'}</Heading>
        <TourSeturForm selectedId={id} item={selectedTour} />
      </Container>
    </>
  );
};

export default TourActivityForm;
