import IBookmark, { ICreateBookmark } from 'interfaces/IBookmark';
import RestService from './RestService';

export default class BookmarkService extends RestService<IBookmark> {
  constructor() {
    super('bookmarks');
  }

  public async getBookmarks(include = true): Promise<IBookmark[]> {
    const params = { include: include ? 'true' : 'false' };

    const query = new URLSearchParams(params).toString();
    const response = await this.httpClient.get<IBookmark[]>(`/?${query}`);
    return response.data;
  }

  public async saveBookmarks(bookmark: ICreateBookmark): Promise<void> {
    await this.httpClient.post('', bookmark);
  }

  public async deleteBookmarks(data: ICreateBookmark): Promise<void> {
    await this.httpClient.delete('', { data });
  }
}
