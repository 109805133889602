import IJourney from 'interfaces/IJourney';
import RestService from './RestService';

export default class JourneyService extends RestService<IJourney> {
  constructor() {
    super('journeys');
  }

  public async getJourneysProgress(): Promise<IJourney[]> {
    const response = await this.httpClient.get<IJourney[]>(`/progress`);
    return response.data;
  }
}
