import styled from 'styled-components';

export const VideoPlayer = styled.div`
  display: block;
  margin-left: 30px;
  width: 100%;
  height: auto;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  iframe {
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &:hover {
    outline: 5px solid #ffcb29;
  }

  @media (max-width: 960px) {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
`;
