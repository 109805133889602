import React from 'react';
import Button from 'components/Button';
import { ModalMessage, ModalMessageTitle, ModalMessageText, ModalOverlay, ModalActions } from './styles';

interface ModalProps {
  isOpen: boolean;
  title: string;
  message: string;
  onClose: () => void;
  ButtonText?: string;
}

const MessageModal: React.FC<ModalProps> = ({ isOpen, message, onClose, title, ButtonText }) => {
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  const buttonText = ButtonText ? ButtonText : 'Ok';

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalMessage>
        <div className="modal-content">
          <ModalMessageTitle>{title}</ModalMessageTitle>
          <ModalMessageText dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        <ModalActions>
          <Button type="submit" onClick={onClose} disabled={false}>
            {buttonText}
          </Button>
        </ModalActions>
      </ModalMessage>
    </ModalOverlay>
  );
};

export default MessageModal;
