import styled from 'styled-components';
import sunAndBeachHover from 'assets/images/sunAndBeachHover.svg';
import historyAndCultureHover from 'assets/images/historyAndCultureHover.svg';
import kitchenHover from 'assets/images/kitchenHover.svg';
import cowHover from 'assets/images/cowHover.svg';
import villageHover from 'assets/images/villageHover.svg';
import boatHover from 'assets/images/boatHover.svg';
import eventsHover from 'assets/images/eventsHover.svg';
import sportsHover from 'assets/images/sportsHover.svg';
import hoverboardHover from 'assets/images/hoverboardHover.svg';
import religiousHover from 'assets/images/religiousHover.svg';
import firstPageArrowHover from 'assets/images/firstPageHoverArrow.svg';
import nextPageArrowHover from 'assets/images/forwardArrowHover.svg';
import backPageArrowHover from 'assets/images/backArrowHover.svg';
import lastPageArrowHover from 'assets/images/lastPageHoverArrow.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ActivitiesFilter = styled.ul`
  width: 100%;
  display: flex;
  padding: 0 50px;
  margin: 70px 0;
  justify-content: space-between;
  gap: 30px;

  @media all and (max-width: 1024px) {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 20px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 15px;
      height: 15px;
      border: 1px solid black;
    }
  }
`;

export const ActivityListItem = styled.li`
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  box-shadow: 0px 4px 4px 0px #0000003f;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 130px;
  padding: 15px 15px;
  background-color: #ffffff;
  border: 5px solid #fff;

  img {
    width: 40px;
    height: 36px;
    margin-bottom: 20px;
  }
  span {
    text-align: center;
    color: #9a9a9a;
    font-size: 16px;
    font-weight: 500;
  }

  &:hover {
    border: 5px solid #ffcb29;
    span {
      color: #020c90;
    }
  }
  &:hover {
    img.sol-e-praia {
      content: url(${sunAndBeachHover});
    }
  }

  &:hover {
    img.religioso {
      content: url(${religiousHover});
    }
  }

  &:hover {
    img.aventura-e-natureza {
      content: url(${hoverboardHover});
    }
  }

  &:hover {
    img.esportivo {
      content: url(${sportsHover});
    }
  }

  &:hover {
    img.eventos {
      content: url(${eventsHover});
    }
  }

  &:hover {
    img.náutico-e-aquático {
      content: url(${boatHover});
    }
  }

  &:hover {
    img.aldeias-indígenas {
      content: url(${villageHover});
    }
  }

  &:hover {
    img.rural {
      content: url(${cowHover});
    }
  }

  &:hover {
    img.gastronomia {
      content: url(${kitchenHover});
    }
  }

  &:hover {
    img.história-e-cultura {
      content: url(${historyAndCultureHover});
    }
  }
`;

export const ActivitiesBoard = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  justify-content: space-between;
  gap: 30px;
  row-gap: 50px;
  margin-bottom: 70px;
  padding: 0 50px;

  @media all and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 20px;
    gap: 32px;
  }
`;

export const PaginateComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 50px 0 70px 0;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  button {
    color: #9a9a9a;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 0.5px solid #9a9a9a;
  }

  .button-active {
    color: #020c90;
    border: 0.5px solid #020c90;
  }
  .first-page-arrow {
    &:hover {
      content: url(${firstPageArrowHover});
    }
  }
  .back-arrow {
    &:hover {
      content: url(${backPageArrowHover});
    }
  }
  .next-page-arrow {
    &:hover {
      content: url(${nextPageArrowHover});
    }
  }
  .last-page-arrow {
    &:hover {
      content: url(${lastPageArrowHover});
    }
  }
`;
