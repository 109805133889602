import { useState } from 'react';

export default function useModal(): any {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (payload?: boolean) => {
    if (typeof payload == 'boolean') setIsOpen(payload);
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    toggle,
  };
}
