import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto 50px auto;
  width: 100%;
  padding: 0 50px;

  @media (max-width: 980px) {
    padding: 0 16px;
    section .MuiGrid-grid-xl-auto.buttons-grid {
      margin: 0;
      width: 100%;
      flex-wrap: nowrap;
      overflow: scroll;
      display: -webkit-box;
    }

    section .MuiGrid-item {
      padding: 0 !important;
      margin-right: 10px;
    }

    section .MuiGrid-item:last-child {
      margin-right: 0;
    }

    section .MuiGrid-item .basic-service-button {
      margin-bottom: 0;
    }
  }
`;
