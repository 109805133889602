import React from 'react';
import { Container } from './styles';
import facebook from '../../assets/images/facebook.svg';
import tiktok from '../../assets/images/tiktok.svg';
import youtube from '../../assets/images/youtube.svg';
import instagram from '../../assets/images/instagram.svg';

const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <div className="left-footer">
        <div className="terms-and-policy">
          <a href="">Termos de Uso</a>
          <a href="">Política de Privacidade</a>
        </div>
        <div className="end-span">
          © {currentYear} Prefeitura de Porto Seguro. Todos os direitos reservados. Criado por&nbsp;
          <strong>IPNET Growth Partner</strong>
        </div>
      </div>
      <div className="social-media">
        <a href="https://www.facebook.com/PortoSeguroTur" target="_blank" rel="noreferrer">
          <img src={facebook} alt="facebook logo" />
        </a>
        <a href="https://www.tiktok.com/@psturismo" target="_blank" rel="noreferrer">
          <img src={tiktok} alt="tiktok logo" />
        </a>
        <a href="https://www.youtube.com/@portoseguroturismo3631" target="_blank" rel="noreferrer">
          <img src={youtube} alt="youtube logo" />
        </a>
        <a href="https://www.instagram.com/portoseguroturismo/" target="_blank" rel="noreferrer">
          <img src={instagram} alt="instagram logo" />
        </a>
      </div>
    </Container>
  );
};

export default Footer;
