import styled from 'styled-components';
import ships from 'assets/images/PortoSeguroShip.svg';

export const Carousel = styled.div`
  div {
    width: 100%;
    height: 100%;
    max-height: 650px;
    --slider-height-percentage: 50%;
    --slider-transition-duration: 800ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 3px;
    --organic-arrow-height: 15px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 6%;
    --control-button-height: 25%;
    --control-button-background: #ffcb29;
    --control-bullet-color: #ffffff;
    --control-bullet-active-color: #ffffff;
    --loader-bar-color: #c33737;
    --loader-bar-height: 3px;
    box-shadow: rgba(0, 0, 0, 0.247) 0px 4px 4px 0px;

    .awssld__prev,
    .awssld__next {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-top: 80px;

      @media all and (max-width: 768px) {
        display: none;
      }

      &:hover {
        background-color: #020c90;
      }
    }
    .awssld__prev {
      margin-left: 50px;
    }
    .awssld__next {
      margin-right: 50px;
    }

    .awssld__content {
      background-color: transparent;
      position: relative;
    }

    .awssld__bullets {
      position: absolute;
      margin-bottom: 6%;
      z-index: 2;

      @media (max-width: 768px) {
        bottom: -20px;
      }

      button {
        background: none;
        border-style: solid;
        border-color: white;
        border-width: 2.5px;
        height: 13px;
        width: 13px;
        transform: scale(1);
        &:hover {
          transform: scale(1.1);
        }

        @media (max-width: 768px) {
          height: 8px;
          width: 8px;
          border-width: 1px;
        }
      }

      .awssld__bullets--active {
        background-color: white;
      }
    }

    .destination-carousel {
      display: flex;
      align-items: center;
      margin-top: 30px;
      background-size: cover;
      background-position: center;

      span {
        position: absolute;
        width: 25%;
        margin-left: 140px;
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        z-index: 999;

        @media all and (max-width: 1024px) {
          width: 35%;
          font-size: 16px;
        }

        @media all and (max-width: 768px) {
          margin-left: 20px;
          width: 50%;
          font-size: 14px;
        }

        @media all and (max-width: 600px) {
          margin-top: -20px;
          font-size: 10px;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
