import React from 'react';
import { Title } from './styles';

interface Props {
  title: string;
}

export const TitleCarousel = (props: Props): JSX.Element => {
  const { title } = props;
  return <Title>{title}</Title>;
};
