import React from 'react';
import { ButtonsDiv, Container, FormRow, InputField } from './styles';
import Button from 'components/Buttom/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router';

interface Props {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPopUpPage: React.Dispatch<React.SetStateAction<number>>;
}

const PopUpTourData = (props: Props): JSX.Element => {
  const { setPopUpPage, setShowPopup } = props;
  const history = useHistory();

  const handleSubmit = (data: any) => {
    console.log(data);
  };
  return (
    <Container>
      <header>
        <h1>Dados da viagem:</h1>
        <span>2/7</span>
      </header>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Estado de Origem<span>*</span>
                </label>
                <Field name="name" as="select">
                  <option>DF</option>
                </Field>
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Cidade de Origem<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Data de Chegada<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Hora de Chegada<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Data de Saída<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Hora de Saída<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
        </Form>
      </Formik>
      <ButtonsDiv>
        <Button text="EDITAR" onClick={() => history.push('my-account')} />
        <Button
          text="CANCELAR"
          onClick={() => {
            setShowPopup(false);
            setPopUpPage(1);
          }}
        />
        <Button text="PRÓXIMO" onClick={() => setPopUpPage(3)} />
      </ButtonsDiv>
    </Container>
  );
};

export default PopUpTourData;
