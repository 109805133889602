import React from 'react';
import { Field as FormikField, FieldAttributes } from 'formik';

const Field = <T,>(props: FieldAttributes<T>): JSX.Element => {
  const style = {
    border: '1px solid #9a9a9a',
    borderRadius: '18px',
    height: '40px',
    padding: '10px',
    width: '100%',
  };

  return <FormikField {...props} style={style}></FormikField>;
};

export default Field;
