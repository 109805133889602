import React, { useEffect, useState } from 'react';
import { BGIntro, Container } from './styles';
import MainTitle from 'components/MainTitle';
import RatingData from './Components/RatingsData';

const ReviewsPage = (): JSX.Element => {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showIntro && (
        <BGIntro>
          <div className="stars">
            <span className="star-1"></span>
            <span className="star-2"></span>
            <span className="star-3"></span>
            <span className="star-4"></span>
            <span className="star-5"></span>
          </div>
          <p className="loading-text">Carregando</p>
          <div className="bg-to"></div>
        </BGIntro>
      )}
      {!showIntro && (
        <>
          <MainTitle>Avaliações</MainTitle>
          <Container>
            <RatingData />
          </Container>
        </>
      )}
    </>
  );
};

export default ReviewsPage;
