import styled from 'styled-components';
import securityIcon from 'assets/images/securityIcon.svg';
import securityIconBlue from 'assets/images/securityIconBlue.svg';

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #272727;
  background-color: transparent;
  img {
    margin-right: 10px;
    content: url(${securityIcon});
  }
  span {
    font-size: 16px;
    font-weight: 700;
    text-decoration-line: underline;
    color: initial;
  }
  &:hover {
    border: 1px solid #020c90;
    img {
      content: url(${securityIconBlue});
    }
    span {
      color: #020c90;
    }
  }
`;
