import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider, IAppState, initialAppState } from './common/AppContext';
import { useRecoilState } from 'recoil';
import { logged } from 'features/Login/store/LoginAtom';
import Routes from './Routes';
import { getAccessToken } from './services/localStorage';
import Loading from 'components/Loading';
import { startAuthMonitoring } from 'utils/authUtils';
import { PortalProvider } from 'common/PortalContext';

declare global {
  interface Window {
    _env_: {
      API_URL: string;
      FIREBASE_DOMAIN: string;
      FIREBASE_API_KEY: string;
      REACT_APP_INSTAGRAM_ACCESS_TOKEN: string;
      [key: string]: string | undefined;
    };
    firebaseInstance?: firebase.app.App;
  }
}

const App = (): JSX.Element => {
  const [checkingLogin, setCheckingLogin] = useState(true);
  const [appState, setAppState] = useState<IAppState>(initialAppState);
  const [, setLoggedState] = useRecoilState(logged);

  useEffect(() => {
    const checkLogin = () => {
      setCheckingLogin(true);
      const accessToken = getAccessToken();
      if (accessToken) {
        try {
          setAppState({
            ...initialAppState,
            authState: {
              ...initialAppState.authState,
              isAuth: true,
              accessToken,
            },
          });
          setLoggedState(true);
        } catch (error) {
          console.error(error);
        }
      }

      setCheckingLogin(false);
    };

    checkLogin();
    startAuthMonitoring();
  }, []);

  if (checkingLogin) {
    return <></>;
  }

  return (
    <AppProvider initialState={appState}>
      <PortalProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <Loading />
      </PortalProvider>
    </AppProvider>
  );
};

export default App;
