import React, { useRef, useState, useEffect } from 'react';
import yup from 'utils/validation';
import {
  AskDeleteAccountButton,
  Container,
  DeleteAccountButton,
  Divider,
  FormRow,
  LabelData,
} from '../TouristAccount/styles';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import Button from 'components/Buttom/Button';
import ToggleField from 'components/ToggleField';
import ProfileImage from 'components/ProfileImage';
import ChangePasswordButton from 'features/Account/components/ChangePasswordButton';
import { useHistory } from 'react-router';
import UserService from 'services/UserService';
import ConfirmationModal from 'components/ConfirmationModal';
import MessageModal from 'common/MessageModal';

interface Props {
  setExcludeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setAskForExcludeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  saveUserData?: (userData: any) => Promise<void>;
  userData: any;
}

const NewsAccount = (props: Props): JSX.Element => {
  const form = useRef<FormikProps<FormikValues>>(null);
  const [editing, setEditing] = useState(false);
  const [seturVisibility, setSeturVisibility] = useState(false);
  const { setShowModal, setExcludeModal, setAskForExcludeModal, saveUserData } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });
  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
  const userService = new UserService();
  const history = useHistory();

  useEffect(() => {
    if (props.userData.type === 'news') {
      setSeturVisibility(true);
    } else if (props.userData.type === 'news-external') {
      setSeturVisibility(false);
    }
  }, [props.userData.type]);

  const validationSchema = yup.object({
    document: yup.string(),
    email: yup
      .string()
      .email()
      .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números'),
    name: yup.string(),
    extraInfo: yup.object({
      ...(seturVisibility && { registration: yup.string() }),
      ...(!seturVisibility && { tradeName: yup.string() }),
    }),
  });

  const handleCancelButtonClick = () => {
    form?.current?.setValues(props.userData);
    setEditing(false);
  };

  const handleSaveButtonClick = async () => {
    await form?.current?.submitForm();

    if (!form?.current?.isValid) {
      return;
    }

    setEditing(false);
  };

  const onlyViewForm = (): JSX.Element => {
    return (
      <Formik
        innerRef={form}
        initialValues={props.userData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize>
        <Form>
          <FormRow id="only-view-first-row">
            <ProfileImage name="profileImage" />
            <div className="profileInfo">
              {editing && <label>Nome Completo</label>}
              <ToggleField showField={editing} name="name" />
              {!editing && <span>Imprensa</span>}
            </div>
            {!editing && <Button text={'EDITAR'} onClick={() => setEditing(true)} />}
          </FormRow>
          <FormRow id="news-account">
            <LabelData>
              <label>CPF</label>
              <ToggleField disabled={true} showField={editing} name="document" />
            </LabelData>
            {seturVisibility ? (
              <LabelData>
                <label>Matrícula</label>
                <ToggleField showField={editing} name="extraInfo.registration" />
              </LabelData>
            ) : (
              <LabelData>
                <label>Nome Fantasia da Empresa</label>
                <ToggleField showField={editing} name="extraInfo.tradeName" />
              </LabelData>
            )}
            <LabelData>
              <label>E-mail</label>
              <ToggleField showField={editing} name="email" />
            </LabelData>
          </FormRow>
        </Form>
      </Formik>
    );
  };

  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (saveUserData) {
      await saveUserData(values);
    }
  };

  const handleDeleteUser = async () => {
    if (userIdToDelete) {
      try {
        await userService.remove(userIdToDelete);
        const messageWithSpan = 'Usuário <span>excluído</span> com sucesso!';
        setMessageInfo({
          title: 'Sucesso',
          message: messageWithSpan,
        });
        setShowMessageModal(true);
      } catch (error) {
        const messageWithSpan = 'Erro ao <span>excluir</span> usuário!';
        setMessageInfo({
          title: 'Erro',
          message: messageWithSpan,
        });
        setShowMessageModal(true);
      }
    }
    setShowConfirmationModal(false);
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);

    if (messageInfo.title === 'Sucesso') {
      history.push('/');
    }
  };

  return (
    <Container>
      {onlyViewForm()}
      {editing && (
        <div className="editButtons">
          <Button text={'Cancelar'} onClick={handleCancelButtonClick} />
          <Button text={'Salvar'} type="submit" onClick={handleSaveButtonClick} />
        </div>
      )}
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', gap: '10px' }}>
          {props.userData.type === 'news' ? (
            <>
              {/* <DeleteAccountButton
                onClick={() => {
                  setUserIdToDelete(props.userData.id);
                  setShowConfirmationModal(true);
                }}>
                <img src="" alt="" />
                <span>Excluir a Conta</span>
              </DeleteAccountButton> */}
              <ChangePasswordButton />
            </>
          ) : (
            <>
              {/* <AskDeleteAccountButton onClick={() => setAskForExcludeModal?.(true)}>
                <img src="" alt="" />
                <span>Solicitar exclusão de conta</span>
              </AskDeleteAccountButton> */}
              <ChangePasswordButton />
            </>
          )}
        </div>
      </div>
      {props.userData.type === 'news' && (
        <ConfirmationModal
          open={showConfirmationModal}
          onCancel={() => setShowConfirmationModal(false)}
          onConfirm={handleDeleteUser}
          message="Tem certeza de que deseja excluir sua conta?"
        />
      )}
      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          title={messageInfo.title}
          message={messageInfo.message}
          onClose={handleCloseMessageModal}
        />
      )}
    </Container>
  );
};

export default NewsAccount;
