import styled from 'styled-components';

export const Container = styled.div`
  .my-experiences {
    padding: 0px 50px;

    .my-experiences-section {
      margin-bottom: 50px;
      margin-left: 0;
      width: 100%;
    }

    @media (max-width: 960px) {
      padding: 0 16px;
    }
  }

  .selected {
    color: #020c90;
    border-color: #020c90;

    &:hover {
      background: #020c90;
      color: #fff;
      border: 1px solid #020c90;
    }
  }

  .experience-card .activity-card {
    height: 450px;

    .activity-card-image-content {
      height: 50%;
    }
  }

  .experience-logo {
    overflow-x: scroll;
  }

  .experience-logo::-webkit-scrollbar {
    height: 0;
  }

  @media (max-width: 940px) {
    .experience-logo {
      overflow-x: scroll;
      white-space: nowrap;
      flex-wrap: nowrap;
      gap: 20px;
      display: -webkit-box;
      -webkit-box-align: end;
    }
  }
`;

export const Journey = styled.div`
  text-align: center;
`;

export const JourneyInfo = styled.div`
  .img-undone {
    opacity: 0.25;
  }

  .img-done {
    opacity: 1;
  }
`;

const Progress = styled.div`
  border-radius: 20px;
  height: 5px;
  margin: auto;
  margin-bottom: 6px;
  margin-top: 8px;
  position: relative;
  width: 50%;
`;

export const Done = styled(Progress)`
  background-color: #01a759;
`;

export const Undone = styled(Progress)`
  border: solid 0.5px #9a9a9a;
`;

export const EmptyIcon = styled.div`
  height: 140px;

  img {
    height: 100%;
    width: 100%;
  }
`;

export const EmptyTitle = styled.h1`
  color: #9a9a9a;
`;

export const EmptyDescription = styled.p`
  color: #9a9a9a;
  font-size: 20px;
  text-align: center;
`;

export const Input = styled.input``;

export const InputLabel = styled.label`
  border-radius: 18px;
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  text-align: center;
  color: #fff;
  margin: 1rem 1rem 0 0;
  padding: 12px 13px;
  background: #ffcb29;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #020c90;
    border: 1px solid #020c90;
  }
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;
