import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import tourIcon from '../../assets/images/tour_menu_item.svg';
import { Link } from 'react-router-dom';

const TourOrganizerMenu: React.FC = () => {
  return (
    <Menu>
      <ul>
        <ListItem>
          <Link to="/tour">
            <img src={tourIcon} alt="icon for list item" />
            <span>Excursões</span>
          </Link>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default TourOrganizerMenu;
