import React from 'react';
import { Container } from './styles';

interface Props {
  text: string;
}

const SubTitle: React.FC<Props> = (props: Props) => {
  const { text } = props;
  return (
    <Container>
      <div>
        <div />
        <span className={text.length > 32 ? 'long' : ''}>{text}</span>
        <div />
      </div>
    </Container>
  );
};

export default SubTitle;
