import styled from 'styled-components';

export const ListItem = styled.li`
  display: flex;
  width: 49%;
  height: 250px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  background-color: #ffffff;
  outline: 5px solid transparent;

  a.news-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    text-decoration: none;

    div {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%;
      justify-content: space-evenly;
    }

    div.news-card-information {
      padding: 15px;

      time {
        margin-bottom: 10px;
        color: #ffcb29;
        font-weight: 400;
        font-size: 16px;
      }

      h1 {
        margin-bottom: 10px;
        color: #272727;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 20px;
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #272727;
        height: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      hr {
        margin-bottom: 10px;
        border-top: none;
        border-bottom: 3px dotted #9a9a9a;
      }

      a {
        color: #ffcb29;
        font-weight: 700;
        text-decoration-line: underline;
      }
    }
  }

  &:hover {
    outline: 5px solid #ffcb29;
  }
`;
