import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import { NavLink } from 'react-router-dom';
import busIcon from 'assets/images/busIcon.svg';

const MenuSeturExcursion: React.FC = () => {
  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/excursions">
            <img src={busIcon} alt="icon for list item" />
            <span>Excursões</span>
          </NavLink>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default MenuSeturExcursion;
