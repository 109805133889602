import sunAndBeachIcon from 'assets/images/sunAndBechIcon.svg';
import historyAndCultureIcon from 'assets/images/history_and_culture.svg';
import kitchenIconIcon from 'assets/images/kitchen_icon.svg';
import cowIconIcon from 'assets/images/cow_icon.svg';
import villageIcon from 'assets/images/Indian.svg';
import boatIcon from 'assets/images/boat.svg';
import eventsIcon from 'assets/images/events_menu_item.svg';
import sportsIcon from 'assets/images/sports.svg';
import hoverboardIcon from 'assets/images/motorcycle.svg';
import religiousIcon from 'assets/images/religious.svg';
import firstPageArrowIcon from 'assets/images/firstPageArrow.svg';
import backArrowIcon from 'assets/images/backArrow.svg';
import forwardArrowIcon from 'assets/images/forwardArrow.svg';
import lastPageArrowIcon from 'assets/images/lastPageArrow.svg';

class ActivitiesIcons {
  sunAndBeach = sunAndBeachIcon;
  historyAndCulture = historyAndCultureIcon;
  kitchenIcon = kitchenIconIcon;
  cowIcon = cowIconIcon;
  village = villageIcon;
  boat = boatIcon;
  events = eventsIcon;
  sports = sportsIcon;
  hoverboard = hoverboardIcon;
  religious = religiousIcon;
  firstPageArrow = firstPageArrowIcon;
  backArrow = backArrowIcon;
  forwardArrow = forwardArrowIcon;
  lastPageArrow = lastPageArrowIcon;
}

export default ActivitiesIcons;
