import MainTitle from 'components/MainTitle';
import React, { useEffect, useState } from 'react';
import { Container, StrategicMapHeader, StrategicMapList, StrategicMapListItem } from './styles';
import Button from 'components/Buttom/Button';
import downloadIcon from 'assets/images/downloadIcon.svg';
import NewStrategicMapModal from './components/NewStrategicMapModal';
import useModal from 'hooks/useModal';
import StrategicMapService from 'services/StrategicMapService';
import ImageService from 'services/ImageService';
import IStrategicMap from 'interfaces/IStrategicMap';
import { useLoading } from 'hooks/useLoading';

const StrategicMap = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const { toggle } = useModal();
  const [newStrategicMapModal, setNewStrategicMapModal] = useState(false);
  const [strategicMapItems, setStrategicMapItems] = useState<IStrategicMap[] | undefined>([]);
  const [strategicMap, setStrategicMap] = useState<IStrategicMap | undefined>();

  useEffect(() => {
    openLoading();
    const fetchData = [fetchStrategicMap()];
    Promise.all(fetchData).finally(() => closeLoading());
  }, []);

  const service = {
    strategicMap: new StrategicMapService(),
    file: new ImageService(),
  };

  const fetchStrategicMap = async () => {
    const data = await service.strategicMap.findAll();

    data.sort((a, b) => b.id - a.id);
    const [current, ...history] = data;

    setStrategicMapItems(history);
    setStrategicMap(current);
  };

  const handleNewStrategicMap = () => {
    setNewStrategicMapModal(!newStrategicMapModal);
    toggle();
  };

  const handleMapImport = async (file: File) => {
    openLoading();
    const url = await service.file.upload(file);
    await service.strategicMap.create({ description: file.name, url });
    await fetchStrategicMap();
    setNewStrategicMapModal(false);
    closeLoading();
  };

  return (
    <>
      <MainTitle>Mapa Estratégico</MainTitle>
      <Container>
        <StrategicMapHeader>
          <div className="new-map-box">
            <h1>Mapa Estratégico Atual</h1>
            <a href={strategicMap?.url} target="_blank" rel="noopener noreferrer">
              {strategicMap?.description} <img src={downloadIcon} alt="download icon" />
            </a>
          </div>
          <Button onClick={() => setNewStrategicMapModal(true)}>Novo Mapa</Button>
        </StrategicMapHeader>
        {Boolean(strategicMapItems?.length) && (
          <StrategicMapList>
            <h1>Histórico</h1>
            {strategicMapItems?.map(item => (
              <StrategicMapListItem key={item.id}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.description} <img src={downloadIcon} alt="download icon" />
                </a>
              </StrategicMapListItem>
            ))}
          </StrategicMapList>
        )}
        {newStrategicMapModal && (
          <NewStrategicMapModal
            isOpen={newStrategicMapModal}
            toggle={handleNewStrategicMap}
            onMapImport={handleMapImport}
          />
        )}
      </Container>
    </>
  );
};

export default StrategicMap;
