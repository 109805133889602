import React from 'react';
import EmbeddedMap from 'components/Map/EmbeddedMap';

export interface IPlaceMapProps {
  q: string;
  center?: string;
  zoom?: string;
  maptype?: string;
  language?: string;
  region?: string;
}

const PlaceMap = (props: IPlaceMapProps): JSX.Element => {
  return <EmbeddedMap mode="place" {...props} />;
};

export default PlaceMap;
