import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 60px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
  ul {
    margin: 0 auto 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;

    @media (max-width: 768px) {
      width: 100%;
      display: grid;
      overflow-x: scroll;
      padding: 16px;
      margin: 0px;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, 90%);
      grid-auto-flow: column;
      grid-auto-columns: 294px;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
      }
      img {
        @media (max-width: 768px) {
          width: 50%;
          object-fit: cover;
        }
      }
      hr,
      a {
        @media (max-width: 768px) {
          margin: 5px;
        }
      }
    }
  }
  > button {
    width: 270px;
    display: block;
    margin: auto;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
