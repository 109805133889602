import React from 'react';
import { Grid } from '@mui/material';
import { Container } from './styles';
import { NavLink } from 'react-router-dom';

interface IProps {
  options: { [key: string]: string };
  onChange: (key: string) => void;
  urlLocation: string;
}

const ButtonMenu = (props: IProps): JSX.Element => {
  const { options, onChange, urlLocation } = props;
  const menus = Object.entries(options);

  return (
    <Container>
      <Grid container spacing={3} className="buttons-grid" xl="auto" md="auto" sm="auto">
        {menus.map(([key, value]: [string, string], index) => (
          <Grid item xl={1.5} md={3} sm={6} key={index}>
            <NavLink
              exact
              to={`${urlLocation}/${key}`}
              onClick={() => {
                onChange(key);
              }}
              className="basic-service-button"
              activeClassName="selected">
              {value}
            </NavLink>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ButtonMenu;
