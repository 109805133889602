import React from 'react';
import { Modal } from 'components/Modals';
import {
  ModalContainer,
  ModalContainerActionBox,
  ModalContainerHeader,
  ModalContainerHeaderTitle,
  ModalContainerText,
} from './styles';
import Button from 'components/Button';
import { useLoading } from 'hooks/useLoading';
import { IBusiness } from 'interfaces/IBusinesses';
import TouristOffersBusinessService from 'services/TouristOffersBusinessService';
import IEvent from 'interfaces/IEvent';

interface DeleteSolicitationModalProps {
  isOpen: boolean;
  toggle: () => void;
  item: IBusiness | IEvent;
  reloadBusinessData?: () => void;
}

const DeleteSolicitationModal = ({
  isOpen,
  toggle,
  item,
  reloadBusinessData,
}: DeleteSolicitationModalProps): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();

  const service = new TouristOffersBusinessService();

  const sendDeleteSolicitation = (item: IBusiness | IEvent) => async () => {
    openLoading();
    await service.remove(item.id);
    closeLoading();
    toggle();
    reloadBusinessData && reloadBusinessData();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalContainer>
        <ModalContainerHeader>
          <ModalContainerHeaderTitle>Exclusão de Oferta Turística</ModalContainerHeaderTitle>
        </ModalContainerHeader>
        <ModalContainerText>
          Você está <b>excluindo</b> uma oferta turística. Se continuar com a ação, {''}
          <b>não será possível recuperá-lá depois</b>. Deseja continuar?
        </ModalContainerText>
        <ModalContainerActionBox>
          <Button buttonType="outline" className="cancel-button" onClick={toggle}>
            Cancelar
          </Button>
          <Button type="submit" disabled={false} onClick={sendDeleteSolicitation(item)}>
            Excluir
          </Button>
        </ModalContainerActionBox>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteSolicitationModal;
