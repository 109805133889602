import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  height: fit-content;
  width: 50%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  header,
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  p {
    margin-top: 20px;
  }

  h4 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  span {
    font-size: 16px;
    font-weight: 300;
  }

  div {
    button {
      &:nth-child(2) {
        background-color: transparent;
        color: #ffcb29;
        border: 1px solid #ffcb29;
        margin: 0 30px 0 auto;
        &:hover {
          background-color: #020c90;
          color: #fff;
          border: none;
        }
      }
    }
    &:last-child {
      margin-top: 20px;
    }
  }
`;
