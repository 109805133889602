import React from 'react';
import googleIcon from 'assets/images/google_icon.svg';
import facebookIcon from 'assets/images/facebook_icon.svg';
import appleIcon from 'assets/images/apple_icon.svg';
import { Container } from './styles';
import { auth, AuthProvider, GoogleAuthProvider, FacebookAuthProvider, AppleAuthProvider } from 'utils/firebase';
import { useLoading } from 'hooks/useLoading';

interface Props {
  touristLoginAction: (token: string) => Promise<void>;
}

const LoginTourist = (props: Props): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const signIn = (Provider: AuthProvider) => {
    return async () => {
      try {
        openLoading();
        const provider = new Provider();
        provider.addScope('email');
        const userCredential = await auth.signInWithPopup(provider);
        const token = (await userCredential.user?.getIdToken()) || '';
        props.touristLoginAction(token);
        closeLoading();
      } catch (error) {
        closeLoading();
      }
    };
  };

  return (
    <Container>
      <h2>Acesse o portal com sua conta Google!</h2>
      <button onClick={signIn(GoogleAuthProvider)}>
        <img src={googleIcon} alt="" />
        <span>ENTRAR COM O GOOGLE</span>
      </button>
      <p>
        Ao iniciar uma sessão em uma conta, você automaticamente aceita nossos
        <a href="">
          <strong> Termos de Uso </strong>
        </a>
        e
        <a href="">
          <strong> Políticas de Privacidade</strong>
        </a>
      </p>
    </Container>
  );
};

export default LoginTourist;
