import { IBusiness } from 'interfaces/IBusinesses';

export interface TradeMyBusinessNewPageProps {
  selectedId?: string;
  item?: IBusiness | null;
  type?: string;
  categories?: { label: string; value: string }[];
}

export const businessTypes = [
  { label: 'Hospedagem', value: 'Hospedagem' },
  { label: 'Passeio e Lazer', value: 'Passeio e Lazer' },
  { label: 'Restaurante (Alimento e Bebida)', value: 'Restaurante (Alimento e Bebida)' },
  { label: 'Transporte', value: 'Transporte' },
];

export const states = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AP', value: 'AP' },
  { label: 'AM', value: 'AM' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RS', value: 'RS' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SP', value: 'SP' },
  { label: 'SE', value: 'SE' },
  { label: 'TO', value: 'TO' },
];

export const daysOfWeek = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];

export const touristicExperiencesTypes = [
  { label: 'Aldeias Indígenas', value: 'Aldeias Indígenas' },
  { label: 'Aventura e Natureza', value: 'Aventura e Natureza' },
  { label: 'Esportivo', value: 'Esportivo' },
  { label: 'Eventos', value: 'Eventos' },
  { label: 'Gastronomia', value: 'Gastronomia' },
  { label: 'História e Cultura', value: 'História e Cultura' },
  { label: 'Náutico e Aquático', value: 'Náutico e Aquático' },
  { label: 'Religioso', value: 'Religioso' },
  { label: 'Rural', value: 'Rural' },
  { label: 'Sol e Praia', value: 'Sol e Praia' },
];

export const businessFormInitialValues = {
  id: '',
  businessOwner: [''],
  businessRepresentative: '',
  businessType: '',
  description: '',
  phone: '',
  document: '',
  touristicExperiences: '',
  obs: '',
  facebookLink: '',
  experiences: [],
  googleMapsLink: '',
  instagramLink: '',
  status: '',
  tradeEmail: '',
  tradeName: '',
  youtubeLink: '',
  title: '',
  site: '',
  whatsapp: '',
  image: [],
  userId: 0,
  address_id: 0,
  createdAt: '',
  updatedAt: '',
  deletedAt: '',
  address: {
    id: 0,
    additionalData: '',
    city: '',
    neighborhood: '',
    number: '',
    state: '',
    street: '',
    zipCode: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
  },
  images: [],
  structures: [],
  user: '',
};
