import styled from 'styled-components';
import loginTouristBg from 'assets/images/background_login_tourist.svg';

export const Container = styled.div`
  min-width: 68.8vw;
  background: url(${loginTouristBg}) no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #0d4a9b80;
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const SelectLoginDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60vw;

  @media (max-width: 960px) {
    width: 96%;
    margin: 30px auto;
  }
`;

export const SuccessModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  width: fit-content;
  padding: 30px;
  border-radius: 18px;
  gap: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  span {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ButtonSection = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 30px;

  :first-child {
    background-color: #fff;
    color: #ffcb29;
    border: 0.5px solid #ffcb29;

    &:hover {
      background-color: #020c90;
      border: 0.5px solid #020c90;
      color: #fff;
    }
  }
`;
