import styled from 'styled-components';

export const UploadPortalNameContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UploadPortalNameHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
`;

export const UploadPortalNameTitle = styled.h1`
  color: #272727;
  font-size: 23px;
  font-weight: 700;
`;

export const InputLabel = styled.label`
  padding: 10px 30px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  border-radius: 18px;
  background: #ffcb29;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #020c90;
  }
`;

export const Input = styled.input`
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #9a9a9a;
  font-size: 17px;

  &:disabled {
    border: none;
    background-color: #f9f9f9;
    color: #272727;
  }

  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active {
    transition: background-color 0s 600000s, color 0s 600000s;
    color: #272727;
  }
`;

export const UploadPortalNameLink = styled.a`
  color: #272727;
  font-size: 20px;
  font-weight: 500;
  text-decoration: underline;
`;

export const UploadPortalNameActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
`;

export const UploadPortalNameCancelButton = styled.button`
  margin-right: 20px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffcb29;
  padding: 10px 20px;
  border: 1px solid #ffcb29;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #020c90;
    color: #fff;
    border: 1px solid #020c90;
  }
`;

export const UploadPortalNameSaveButton = styled.button`
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 18px;
  background: #ffcb29;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #020c90;
  }

  &:disabled {
    background-color: #ccc;
    color: #f9f9f9;
    cursor: not-allowed;
  }
`;
