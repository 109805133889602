import React, { useEffect, useState } from 'react';
import PublicService from 'services/PublicService';
import { Container, DescriptionsInfo } from './styles';
import InfoCard from 'components/InfoCard';
import VideoField from 'components/VideoField';
import BannerDynamic from 'components/BannerDynamic';
import AwesomeSlider from 'react-awesome-slider';
import { Arrow } from 'common/BannerCarousel/styles';
import { useParams } from 'react-router';
import IItinerary from 'interfaces/IItinerary';
import MapDetailPage from 'components/Map/DetailMap';
import { useLoading } from 'hooks/useLoading';
import InfoCardPhotos from 'components/InfoCardPhotos';
import InfoCardBanners from 'components/InfoCardBanners';
import InfoCardActivities from 'components/InfoCardActivities';

const ItineratesDescription = (): JSX.Element => {
  const { slug } = useParams<{ slug: string }>();
  const [itinerary, setItinerary] = useState<IItinerary | undefined>();
  const { openLoading, closeLoading } = useLoading();

  const service = new PublicService();

  useEffect(() => {
    getItinerary();
  }, [slug]);

  const getItinerary = async () => {
    try {
      openLoading();
      const data = await service.getItinerary(slug);
      setItinerary(data);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  const settings = {
    infinite: true,
    organicArrows: false,
    fillParent: false,
    buttonContentRight: <Arrow>&gt;</Arrow>,
    buttonContentLeft: <Arrow>&lt;</Arrow>,
  };

  const fullAddress = [
    itinerary?.addressInfo?.street,
    itinerary?.addressInfo?.number,
    itinerary?.addressInfo?.neighborhood,
    itinerary?.addressInfo?.city,
    itinerary?.addressInfo?.state,
    itinerary?.addressInfo?.zipCode,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <Container>
        <AwesomeSlider {...settings}>
          {itinerary?.banners &&
            itinerary?.banners.map((banner, index) => (
              <div key={index} className="content-div">
                <BannerDynamic id={index} banners={[banner]} title={''} description={''} />
              </div>
            ))}
        </AwesomeSlider>
      </Container>

      <DescriptionsInfo>
        <InfoCard
          id={0}
          categories={itinerary?.category?.map((labels, id) => {
            return { labels, id };
          })}
          title={itinerary?.title}
          description={itinerary?.description}
          openingHours={itinerary?.openingHours || ''}
          openingHoursDetail={itinerary?.openingHoursDetail?.map((hours, id) => {
            return { hours, id };
          })}
        />
        {itinerary?.youtubeLink && <VideoField youtubeLink={itinerary?.youtubeLink} id={0} />}
      </DescriptionsInfo>
      <InfoCardBanners
        banners={itinerary?.itineraries?.map(subItinerary => ({
          title: subItinerary.title,
          image: subItinerary?.banners?.[0]?.image,
          link: `/what-to-do/itineraries/${subItinerary.slug}`,
        }))}
      />
      <InfoCardActivities activities={itinerary?.activities} />
      <InfoCardPhotos images={itinerary?.images?.map(({ image }) => image)} />
      <MapDetailPage address={fullAddress} placeId={itinerary?.placeId} />
    </>
  );
};

export default ItineratesDescription;
