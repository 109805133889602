import { IUserAPI } from 'interfaces/IUser';
import RestService from './RestService';

export default class UserService extends RestService<any> {
  constructor() {
    super('users');
  }

  public async findOne(): Promise<any> {
    const response = await this.httpClient.get('me');
    return response.data;
  }

  public async update(userData: any): Promise<any> {
    const { type, ...body } = userData;
    await this.httpClient.put(`/${type}`, body);
  }

  public async saveTourist(token: string): Promise<any> {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await this.httpClient.post('tourist', undefined, { headers });
    return response.data;
  }

  public async findOneById(id: number): Promise<IUserAPI> {
    const response = await this.httpClient.get<IUserAPI>(`/${id}`);
    return response.data;
  }

  public async approve(id: number): Promise<void> {
    await this.httpClient.put(`/${id}/approve`);
  }

  public async refuse(id: number): Promise<void> {
    await this.httpClient.put(`/${id}/refuse`);
  }

  public async block(id: number): Promise<void> {
    await this.httpClient.put(`/${id}/block`);
  }
}
