import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 30px;
  top: 50%;
  width: 500px;
  max-height: 495px;
  background: #fff;
  z-index: 99999;
  border-radius: 18px;
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const ModalContainerHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ModalContainerHeaderTitle = styled.h1`
  text-transform: uppercase;
  color: #272727;
  font-size: 20px;
  font-weight: 700;
`;

export const ModalContainerText = styled.p`
  text-align: center;
  color: #272727;
  font-size: 16px;
  font-weight: 500;
`;

export const ModalContainerActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  button {
    margin-left: 1rem;
    width: inherit;
    min-width: 110px;
    padding: 10px;
  }

  .cancel-button {
    background-color: #fff;
    font-weight: bold;
    color: #ffcb29;
    border: 0.5px solid #ffcb29;
    opacity: 1;

    &:hover {
      background-color: #020c90;
      color: #fff;
      border: none;
    }
`;
