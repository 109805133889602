import React from 'react';
import { Anchor, ListItem, ListItemInformation } from './styles';

interface Props {
  image: string;
  category: string;
  title: string;
  description: string;
  slug: string;
}

const ScheduleCard: React.FC<Props> = (props: Props) => {
  const { image, category, title, description, slug } = props;
  return (
    <ListItem>
      <Anchor className="activities-box" href={'/activities/' + slug} rel="noreferrer">
        <div
          className="list-item-image"
          style={{
            background: `url(${image}) center center / cover no-repeat`,
          }}
        />
      </Anchor>
      <ListItemInformation>
        <span>{category}</span>
        <h1>{title}</h1>
        <p>{description}</p>
      </ListItemInformation>
    </ListItem>
  );
};

export default ScheduleCard;
