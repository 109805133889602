import React, { createContext, useContext, useState } from 'react';

type LoadingHookType = {
  openLoading: () => void;
  closeLoading: () => void;
  loading: boolean;
};

const LoadingHookContext = createContext<LoadingHookType | null>(null);

const INITIAL_IS_LOADING = false;

export const LoadingHookProvider = ({ children }: { children: React.ReactElement }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_IS_LOADING);

  const openLoading = () => {
    setIsLoading(true);
  };
  const closeLoading = () => {
    setIsLoading(INITIAL_IS_LOADING);
  };

  return (
    <LoadingHookContext.Provider
      value={{
        loading: isLoading,
        closeLoading,
        openLoading,
      }}>
      {children}
    </LoadingHookContext.Provider>
  );
};

export const useLoading = (): LoadingHookType => {
  const socket = useContext(LoadingHookContext);

  if (!socket) {
    throw new Error('useLoadingHook deve ser usado dentro de um LoadingHookProvider');
  }

  return socket;
};
