import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/src/core/styles.scss';
import { Carousel, Arrow } from './styles';
import Button from '../../components/Buttom/Button';
import IBanner from 'interfaces/IBanner';
import { TitleCarousel } from 'common/TitleCarousel/TitleCarousel';
import { ExcursionOrganizatorBox } from 'common/ExcursionOrganizatorBox/ExcursionOrganizatorBox';

interface Props {
  banners?: IBanner[];
}

const BannerCarousel = (props: Props): JSX.Element => {
  const { banners } = props;

  const settings = {
    infinite: true,
    organicArrows: false,
    fillParent: false,
    buttonContentRight: <Arrow>&gt;</Arrow>,
    buttonContentLeft: <Arrow>&lt;</Arrow>,
  };

  return (
    <Carousel>
      <TitleCarousel title="Bem-vindo a Porto Seguro!" />
      <AwesomeSlider {...settings}>
        {banners?.map(banner => {
          return (
            <div key={banner.id}>
              <img src={banner?.image} alt={banner.description} />
            </div>
          );
        })}
      </AwesomeSlider>
      <ExcursionOrganizatorBox />
    </Carousel>
  );
};

export default BannerCarousel;
