import React from 'react';
import EmbeddedMap from 'components/Map/EmbeddedMap';

export interface IDirectionsMapProps {
  origin: string;
  destination: string;
  waypoints?: string;
  mode?: string;
  avoid?: string;
  units?: string;
  center?: string;
  zoom?: string;
  maptype?: string;
  language?: string;
  region?: string;
}

const DirectionsMap = (props: IDirectionsMapProps): JSX.Element => {
  return <EmbeddedMap mode="directions" {...props} />;
};

export default DirectionsMap;
