import RestService from './RestService';
import { ICreateImprovements, IImproviments } from 'interfaces/IImproviments';

export default class ImprovimentsService extends RestService<IImproviments> {
  constructor() {
    super('improvements');
  }

  public async createImprovement(body: ICreateImprovements): Promise<void> {
    await this.httpClient.post('', body);
  }
}
