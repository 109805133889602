import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  top: 50%;
  width: 690px;
  max-height: 495px;
  background: #fff;
  z-index: 99999;
  border-radius: 18px;
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .header h2,
  .header span {
    font-size: 20px;
    font-weight: 500;
  }

  input {
    width: 100%;
    border: 1px solid #9a9a9a;
    border-radius: 18px;
    height: 40px;
    padding: 0 5px;
  }

  label {
    margin-bottom: 10px;
    display: flex;
  }

  label:after {
    content: '*';
    display: block;
    margin: 0 5px;
    color: red;
  }

  @media (max-width: 960px) {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .NameField {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  .PlaceIDField {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .TitleHeader {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 960px) {
    flex-direction: column;

    .NameField {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;

  :first-child {
    background-color: #fff;
    color: #ffcb29;
    border: 0.5px solid #ffcb29;

    &:hover {
      background-color: #020c90;
      color: #fff;
      border: 0.5px solid #020c90;
    }
  }
`;

export const InputField = styled.div``;
export const SignUpForm = styled.div``;
