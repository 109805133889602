import React, { useEffect, useState } from 'react';
import { Modal } from 'components/Modals';
import useModal from 'hooks/useModal';
import Button from 'components/Button';
import voucherIcon from 'assets/images/voucher.svg';
import closeYellow from 'assets/images/closeYellow.svg';
import downloadIcon from 'assets/images/downloadIcon.svg';
import voucherButtonIcon from 'assets/images/voucherButtonIcon.svg';
import { ContentVoucher, EmptyVoucher, MyVoucher, VoucherHeader, VoucherModal, WithVoucher } from './styles';

interface IMyVouchersProps {
  code: string;
  validateDate: string;
}

const MyVouchers: React.FC = () => {
  const { isOpen, toggle } = useModal();
  const [vouchers, setVouchers] = useState<IMyVouchersProps[]>(mockedVouchers);
  const [shouldRenderButton, setShouldRenderButton] = useState<boolean>(true);

  const downloadVoucher = (code: string, validateDate: string) => {
    const content = `Código: ${code}, Validade: ${validateDate}`;
    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'voucher.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const handleResize = () => {
      const shouldRender = window.innerWidth >= 960;
      setShouldRenderButton(shouldRender);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <MyVoucher>
        {shouldRenderButton ? (
          <div className={vouchers.length > 0 ? 'has-vouchers' : ''}>
            <Button onClick={toggle}>Meus Vouchers</Button>
          </div>
        ) : (
          <div className={vouchers.length > 0 ? 'has-vouchers' : ''}>
            <img src={voucherButtonIcon} alt="Meus Vouchers" onClick={toggle} />
          </div>
        )}
      </MyVoucher>

      <Modal isOpen={isOpen} toggle={toggle}>
        <VoucherModal>
          <VoucherHeader>
            <h2>Meus Vouchers</h2>
            <img src={closeYellow} alt="Close" onClick={toggle} />
          </VoucherHeader>
          <ContentVoucher>
            {vouchers.length === 0 ? (
              <EmptyVoucher>
                <img src={voucherIcon} alt="Voucher" />
                <h4>Lista Vazia</h4>
                <p>Você ainda não possui vouchers</p>
              </EmptyVoucher>
            ) : (
              <WithVoucher>
                <div>
                  <span>Código</span>
                  <span>Validade</span>
                </div>

                <ul>
                  {vouchers.map((voucher, index) => (
                    <li key={index}>
                      <span>{voucher.code}</span>
                      <span>{voucher.validateDate}</span>
                      <img
                        src={downloadIcon}
                        alt="Download icon"
                        onClick={() => downloadVoucher(voucher.code, voucher.validateDate)}
                      />
                    </li>
                  ))}
                </ul>
              </WithVoucher>
            )}
          </ContentVoucher>
        </VoucherModal>
      </Modal>
    </>
  );
};

export default MyVouchers;

export const mockedVouchers: IMyVouchersProps[] = [];
