import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLoading } from 'hooks/useLoading';
import IItinerary from 'interfaces/IItinerary';
import ItineraryService from 'services/ItineraryService';
import editIcon from 'assets/images/editIcon.svg';
import trashIcon from 'assets/images/trashIcon.svg';
import { Container } from './styles';
import ItineraryCategoryService from 'services/ItineraryCategoryService';
import Heading from 'components/Heading';
import CategoryTable from 'components/CategoryTable';
import ConfirmationModal from 'components/ConfirmationModal';
import {
  SettingsList,
  SettingsListContainer,
  SettingsListItem,
  SettingsListItemButton,
  SettingsListItemButtonList,
  SettingsListItemTitle,
} from 'components/List/styles';
import Button from 'components/Button';

const Itinerary = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [itineraries, setItineraries] = useState<IItinerary[]>([]);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [itineraryId, setItineraryId] = useState(0);
  const history = useHistory();
  const service = new ItineraryService();

  useEffect(() => {
    openLoading();
    fetchItineraries().finally(() => closeLoading());
  }, []);

  const fetchItineraries = async () => {
    const data = await service.findAll();
    setItineraries(data);
  };

  const deleteItinerary = async () => {
    await service.remove(itineraryId);
    const index = itineraries.filter(item => item.id !== itineraryId);
    setItineraries(index);
  };

  return (
    <Container>
      <CategoryTable title="Categorias de Roteiros" service={new ItineraryCategoryService()} />
      <div style={{ marginTop: '2rem' }}>
        <div className="header">
          <Heading>Lista de Roteiros</Heading>
          <Button type="submit" buttonType="primary" onClick={() => history.push('/itinerary')}>
            Adicionar
          </Button>
        </div>
        <SettingsListContainer>
          <SettingsList>
            {itineraries.map((itinerary, index) => (
              <SettingsListItem key={index}>
                <SettingsListItemTitle>{itinerary.title}</SettingsListItemTitle>
                <SettingsListItemButtonList>
                  <Link to={`/itinerary/${itinerary.id}`}>
                    <SettingsListItemButton>
                      <img src={editIcon} alt="icon for edit item" />
                    </SettingsListItemButton>
                  </Link>
                  <SettingsListItemButton
                    onClick={() => {
                      setConfirmDeletion(true);
                      setItineraryId(itinerary.id);
                    }}>
                    <img src={trashIcon} alt="icon for trash item" />
                  </SettingsListItemButton>
                </SettingsListItemButtonList>
              </SettingsListItem>
            ))}
          </SettingsList>
        </SettingsListContainer>
      </div>
      <ConfirmationModal
        open={confirmDeletion}
        title="EXCLUSÃO DE ITEM"
        message="Você tem certeza de que deseja excluir esse item?"
        onCancel={() => setConfirmDeletion(false)}
        onConfirm={() => {
          setConfirmDeletion(false);
          deleteItinerary();
        }}
      />
    </Container>
  );
};

export default Itinerary;
