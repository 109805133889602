import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 75vh;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 70%);
    height: 5px;
    border-radius: 10px;
  }
`;

export const ExcursionsButtonsContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2rem;
  width: 90%;
  z-index: 1;

  button {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    justify-content: space-around;
    width: 95%;
    margin-top: -75px;
    overflow-x: auto;
  }
`;

export const WppButton = styled(Button)`
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0 20px 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  z-index: 10;

  img {
    margin-right: 8px;
  }
`;
