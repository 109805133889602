import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000066;
  z-index: 99999;
`;

export const ModalMessage = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 690px;
  max-height: 495px;
  background: #fff;
  z-index: 99999;
  border-radius: 18px;
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);

  @media (max-width: 980px) {
    width: auto;
  }
`;

export const ModalMessageTitle = styled.h1`
  text-transform: uppercase;
  color: #272727;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
`;

export const ModalMessageText = styled.p`
  text-align: center;
  color: #272727;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 1rem;

  span {
    font-weight: 800;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  align-self: center;
`;
