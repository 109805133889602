import Yup from 'utils/validation';

export const newsInitialValues = {
  id: 0,
  title: '',
  subtitle: '',
  author: '',
  description: '',
  image: [],
  banner: [],
  images: [],
};

export const newsValidationSchema = Yup.object().shape({
  id: Yup.number(),
  title: Yup.string().required(),
  subtitle: Yup.string().required(),
  author: Yup.string().required(),
  description: Yup.string().required(),
  image: Yup.array().min(1).required(),
  banner: Yup.array().min(1).required(),
  images: Yup.array(),
});
