import styled from 'styled-components';

export const ListItem = styled.li`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;

  img {
    height: fill;
    width: 100%;
    height: 222px;
    margin-top: 0;
  }

  span {
    margin-left: 15px;
  }

  &:hover {
    outline: 5px solid #ffcb29;
  }

  & .list-item-image {
    width: 100%;
    height: 222px;
  }
`;

export const ListItemImage = styled.div`
  width: 100%;
  height: 222px;
`;

export const ListItemInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  span {
    margin: 0 0 10px 0;
    padding: 5px 20px;
    background: #020c90;
    border-radius: 15px;
    color: #ffff;
    text-transform: uppercase;
  }
  h1 {
    margin: 0 0 10px 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
  display: contents;
`;
