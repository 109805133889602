export default interface IEvent {
  id: number;
  title: string;
  comments?: string;
  eventId?: number | null;
  businessOwner?: string;
  ownerName?: string;
  slug: string;
  description: string;
  image: string;
  link: string;
  date: string;
  category: string;
  createdAt?: string;
  startDate: string;
  endDate: string;
  startTime?: string;
  endTime?: string;
  place: string;
  price: string;
  status?: EventStatusAPI;
  email?: string;
  facebookLink?: string;
  instagramLink?: string;
  phone?: string;
  site?: string;
  whatsapp?: string;
  youtubeLink?: string;
  images?: Array<{ image: string }>;
  placeId?: string;
  address?: string;
  addressInfo?: {
    zipCode?: string;
    street?: string;
    number?: string;
    neighborhood?: string;
    additionalData?: string;
    city?: string;
    state?: string;
  };
  user?: {
    email: string;
    name: string;
  };
}

export enum EventStatus {
  APPROVED = 'Aprovado',
  REFUSED = 'Recusado',
  WAITING_FOR_SETUR = 'Aguardando SETUR',
  WAITING_FOR_TRADE = 'Aguardando Trade',
  PENDING_ADJUSTMENT = 'Ajuste Pendente',
}

export enum EventStatusAPI {
  APPROVED = 'approved',
  REFUSED = 'refused',
  WAITING_FOR_SETUR = 'waiting-for-setur',
  WAITING_FOR_TRADE = 'waiting-for-trade',
}
