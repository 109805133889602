import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLoading } from 'hooks/useLoading';
import { Container } from './styles';
import IEvent from 'interfaces/IEvent';
import EventService from 'services/EventService';
import EventCategoryService from 'services/EventCategoryService';
import Heading from 'components/Heading';
import eyeIcon from 'assets/images/eyeGrayIcon.svg';
import editIcon from 'assets/images/editIcon.svg';
import trashIcon from 'assets/images/trashIcon.svg';
import CategoryTable from 'components/CategoryTable';
import ConfirmationModal from 'components/ConfirmationModal';
import {
  SettingsList,
  SettingsListContainer,
  SettingsListItem,
  SettingsListItemButton,
  SettingsListItemButtonList,
  SettingsListItemTitle,
} from 'components/List/styles';
import Button from 'components/Button';

const Event = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [events, setEvents] = useState<IEvent[]>([]);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [eventId, setEventId] = useState(0);
  const history = useHistory();
  const service = new EventService();

  useEffect(() => {
    openLoading();
    fetchEvents().finally(() => closeLoading());
  }, []);

  const fetchEvents = async () => {
    const data = await service.findAll();
    setEvents(data);
  };

  const handleEdit = (eventId: number) => {
    console.log(`Editando evento com ID: ${eventId}`);
  };

  const deleteEvent = async () => {
    await service.remove(eventId);
    const index = events.filter(item => item.id !== eventId);
    setEvents(index);
  };

  return (
    <Container>
      <CategoryTable title="Categorias de Eventos" service={new EventCategoryService()} />
      <div style={{ marginTop: '2rem' }}>
        <div className="header">
          <Heading>Lista de Eventos</Heading>
          <Button type="submit" buttonType="primary" onClick={() => history.push('/event')}>
            Adicionar
          </Button>
        </div>
        <SettingsListContainer>
          <SettingsList>
            {events.map((event, index) => (
              <SettingsListItem key={index}>
                <SettingsListItemTitle>{event.title}</SettingsListItemTitle>
                <SettingsListItemButtonList>
                  <Link to={`/admin/event/${event.id}/preview`}>
                    <SettingsListItemButton>
                      <img src={eyeIcon} alt="preview" />
                    </SettingsListItemButton>
                  </Link>
                  <Link to={`/event/${event.id}`}>
                    <SettingsListItemButton onClick={() => handleEdit(event.id)}>
                      <img src={editIcon} alt="icon for edit item" />
                    </SettingsListItemButton>
                  </Link>
                  <SettingsListItemButton
                    onClick={async () => {
                      setConfirmDeletion(true);
                      setEventId(event.id);
                    }}>
                    <img src={trashIcon} alt="icon for trash item" />
                  </SettingsListItemButton>
                </SettingsListItemButtonList>
              </SettingsListItem>
            ))}
          </SettingsList>
        </SettingsListContainer>
      </div>
      <ConfirmationModal
        open={confirmDeletion}
        title="EXCLUSÃO DE ITEM"
        message="Você tem certeza de que deseja excluir esse item?"
        onCancel={() => setConfirmDeletion(false)}
        onConfirm={() => {
          setConfirmDeletion(false);
          deleteEvent();
        }}
      />
    </Container>
  );
};

export default Event;
