import React from 'react';
import { Arrow, TooltipBox } from './styles';
import questionMarkIcon from 'assets/images/questionMarkIcon.svg';

interface TooltipProps {
  text?: string;
}

const Tooltip = ({ text }: TooltipProps): JSX.Element => {
  const [tooltip, setTooltip] = React.useState(false);

  return (
    <div
      role="image"
      onFocus={() => setTooltip(true)}
      onMouseOver={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
      onBlur={() => setTooltip(false)}>
      <img src={questionMarkIcon} alt="mais informações" />
      {tooltip && (
        <TooltipBox>
          {text} <Arrow />
        </TooltipBox>
      )}
    </div>
  );
};

export default Tooltip;
