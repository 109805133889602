import styled from 'styled-components';
import trashIconBlack from 'assets/images/trashIconBlack.svg';
import blueTrashIcon from 'assets/images/blueTrashIcon.svg';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  #only-view-first-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .businessRepresentative {
      width: 100%;
    }
    .profileImage img {
      width: 110px;
      height: 110px;
    }
    span {
      &:first-child {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px;
        display: block;
      }
    }
    button {
      margin: 0 0 auto auto;
    }

    @media (max-width: 960px) {
      flex-direction: column;
      text-align: center;
      .businessRepresentative {
        margin: 10px auto;
      }
      button {
        margin: 0px auto;
      }
    }
  }
  #only-view-second-row {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  input {
    height: 40px;
    width: 100%;
    padding: 0 10px;
  }
  .editButtons {
    display: flex;
    justify-content: right;
    gap: 10px;

    :first-child {
      color: #ffcb29;
      border: 0.5px solid #ffcb29;
      background: #fff;

      &:hover {
        color: #fff;
        border: 0.5px solid #020c90;
        background: #020c90;
      }
    }
  }

  @media (max-width: 960px) {
    #only-view-second-row {
      grid-template-columns: 1fr;
    }
    .editButtons {
      justify-content: center;
    }
  }
`;

export const FormRow = styled.div`
  display: grid;
  column-gap: 30px;
  &:not(:first-child) {
    margin: 20px 0;
  }
`;

export const LabelData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  div {
    width: 100%;
  }
  label {
    font-weight: 500;
  }
  span {
    font-weight: 300;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted;
  margin: 2vh 0;
`;

export const RequestButton = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const DeleteAccountButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #272727;
  background-color: transparent;
  img {
    margin-right: 10px;
    content: url(${trashIconBlack});
  }
  span {
    font-size: 16px;
    font-weight: 700;
    text-decoration-line: underline;
  }
  &:hover {
    border: 1px solid #020c90;
    img {
      content: url(${blueTrashIcon});
    }
    span {
      color: #020c90;
    }
  }
`;

export const BusinessOwner = styled.div`
  div:has(> button) {
    display: flex;
  }
  div:has(~ button) {
    width: 100%;
  }
  button {
    height: 40px;
  }
`;
