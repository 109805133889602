import React from 'react';
import { Container } from './styles';
import CategoryTable from 'components/CategoryTable';
import TransportCategoryService from 'services/TransportCategoryService';

const Transport = (): JSX.Element => {
  return (
    <Container>
      <CategoryTable title="Categorias de Transportes" service={new TransportCategoryService()} />
    </Container>
  );
};

export default Transport;
