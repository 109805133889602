import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { formatDate, truncate } from 'utils/string';
import BookmarkService from 'services/BookmarkService';
import JourneyService from 'services/JourneyService';
import MainTitle from 'components/MainTitle';
import ActivityCard, { ICardProps } from 'components/ActivityCard';
import Button from 'components/Button';
import AddBookmark from 'components/AddBookmark';
import IBookmark from 'interfaces/IBookmark';
import IJourney from 'interfaces/IJourney';
import experiencesIcon from 'assets/images/experiences_menu_item.svg';
import SelectPortoSeguro from 'assets/images/selectDestinationPortoSeguro.svg';
import SelectArraial from 'assets/images/selectDestinationArraial.svg';
import SelectCaraiva from 'assets/images/selectDestinationCaraiva.svg';
import SelectTrancoso from 'assets/images/selectDestinationTrancoso.svg';
import SelectValeVerde from 'assets/images/selectDestinationValeVerde.svg';
import {
  ButtonBox,
  ButtonContainer,
  Container,
  Done,
  EmptyDescription,
  EmptyIcon,
  EmptyTitle,
  Input,
  InputLabel,
  Journey,
  JourneyInfo,
  Undone,
} from './styles';
import { useLoading } from 'hooks/useLoading';
import useModal from 'hooks/useModal';
import FileImportModal from '../../components/FileImportModal';
import MyVouchers from 'components/MyVoucher';
import QuizModal from 'features/QuizModal';

type JourneyProgres = { [key: string]: IJourney | undefined };

const MyExperiences = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();

  const [experienceDone, setExperienceDone] = useState(true);
  const [experiences, setExperiences] = useState<IBookmark[]>([]);
  const [journeyProgress, setJourneyProgress] = useState<JourneyProgres>({});
  const { isOpen, toggle } = useModal();
  const [selectedButton, setSelectedButton] = useState('concluidas');
  const [isFileViewerVisible, setIsFileViewerVisible] = useState(false);
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

  const getData = async () => {
    try {
      openLoading();
      await Promise.all([getExperiences(), getJourneyProgress()]);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getExperiences = async () => {
    const service = new BookmarkService();
    const response = await service.getBookmarks();
    setExperiences(response);
  };

  const getJourneyProgress = async () => {
    const service = new JourneyService();
    const response = await service.getJourneysProgress();

    setJourneyProgress(
      response.reduce((result, journey) => {
        return { ...result, [journey.destination]: journey };
      }, {})
    );
  };

  const journeys = [
    {
      destination: 'porto-seguro',
      src: SelectPortoSeguro,
    },
    {
      destination: 'arraial',
      src: SelectArraial,
    },
    {
      destination: 'caraiva',
      src: SelectCaraiva,
    },
    {
      destination: 'trancoso',
      src: SelectTrancoso,
    },
    {
      destination: 'vale-verde',
      src: SelectValeVerde,
    },
  ];

  const onBookmarkChange = () => {
    getData();
  };

  const extractDetails = (experience: IBookmark) => {
    const { activity, business, event } = experience;

    if (experience.activityId) {
      return { id: experience.activityId, type: 'activity', link: `/activities/${activity?.slug}` };
    }

    if (experience.businessId) {
      return { id: experience.businessId, type: 'business', link: `/accommodations/${business?.id}` };
    }

    return { id: experience.eventId, type: 'event', link: `/events/${event?.slug}` };
  };

  const parseToActivityCard = (experience: IBookmark): ICardProps => {
    const { activity, business, event } = experience;

    const image = activity?.image ?? business?.image ?? event?.image ?? '';
    const category = activity?.category ?? business?.type ?? event?.category ?? '';
    const date = event?.startDate && formatDate(event?.startDate);
    const title = activity?.title ?? business?.tradeName ?? event?.title ?? '';
    const description = truncate(activity?.description ?? business?.description ?? event?.description ?? '', 100);

    const { id, link, type } = extractDetails(experience);
    const action = <AddBookmark type={type} id={id} onChange={onBookmarkChange} />;

    return { image, category, date, title, description, action, link };
  };

  const onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.preventDefault();
    if (event.target?.files?.length) {
      const img = event.target.files[0];
      setFileToUpload(img);
    }
  };

  const filterExperieces = experiences.filter(experience => {
    return experience.done === experienceDone;
  });

  return (
    <>
      <Container>
        <QuizModal />
        <MainTitle>Minhas Experiências</MainTitle>
        <Box sx={{ flexGrow: 1 }} className="my-experiences">
          <ButtonContainer>
            <ButtonBox>
              <Button
                className={selectedButton === 'concluidas' ? 'selected' : ''}
                buttonType="outline"
                onClick={() => {
                  setExperienceDone(true);
                  setSelectedButton('concluidas');
                }}>
                Concluídas
              </Button>
              <Button
                className={selectedButton === 'desejos' ? 'selected' : ''}
                buttonType="outline"
                onClick={() => {
                  setExperienceDone(false);
                  setSelectedButton('desejos');
                }}>
                Lista de Desejos
              </Button>
            </ButtonBox>

            {experienceDone && <MyVouchers />}
          </ButtonContainer>

          {experienceDone && (
            <>
              <Grid className="my-experiences-section" container rowSpacing={2} alignItems="center" width={1}>
                <Grid item xs={12}>
                  <h1>Jornada Turística</h1>
                </Grid>
                <Grid container item xs={12} justifyContent="space-between" spacing={3}>
                  <Grid item xs={12} sm={12} md={8}>
                    <p>
                      Complete a jornada do turista visitando nossos 5 distritos e ganhe vouchers de desconto em nossas
                      atividades. Envie um comprovante de consumo (PDF, PNG, JPG ou JPEG) em cada destino para registrar
                      a visita, . Após completar a jornada, você pode iniciá-la novamente cadastrando novos
                      comprovantes.
                    </p>
                  </Grid>
                  <Grid item>
                    <InputLabel
                      htmlFor="arquivo"
                      onClick={() => {
                        setIsFileViewerVisible(true);
                        toggle();
                      }}>
                      Importar Comprovante
                    </InputLabel>
                    {isFileViewerVisible && (
                      <Input
                        hidden
                        onChange={onImport}
                        accept="image/png,image/jpg,image/jpeg,application/pdf"
                        id="arquivo"
                        name="arquivo"
                        type="file"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                className="my-experiences-section experience-logo"
                container
                rowSpacing={4}
                alignItems="flex-end"
                justifyContent="space-around">
                {journeys.map((journey, index) => (
                  <Grid item key={index} xs={6} sm={6} md={2} xl={2.4}>
                    <Journey>
                      {journeyProgress[journey.destination]?.progress ? (
                        <JourneyInfo>
                          <img src={journey.src} className="img-done" />
                        </JourneyInfo>
                      ) : (
                        <JourneyInfo>
                          <img src={journey.src} className="img-undone" />
                        </JourneyInfo>
                      )}

                      {journeyProgress[journey.destination]?.progress ? (
                        <JourneyInfo>
                          <Done />
                          Concluído
                        </JourneyInfo>
                      ) : (
                        <JourneyInfo>
                          <Undone />
                          Não Realizado
                        </JourneyInfo>
                      )}
                    </Journey>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Grid className="my-experiences-section" container spacing={4}>
            {filterExperieces?.map((experience, index) => (
              <Grid item key={experience.id} className="experience-card" xs={12} sm={6} md={4} lg={3} xl={3}>
                <ActivityCard {...parseToActivityCard(experience)} />
              </Grid>
            ))}
          </Grid>
          {!filterExperieces.length && (
            <Grid
              className="my-experiences-section"
              container
              rowSpacing={4}
              direction="column"
              justifyContent="center"
              alignItems="center">
              <Grid item>
                <EmptyIcon>
                  <img src={experiencesIcon} />
                </EmptyIcon>
              </Grid>
              <Grid item>
                <EmptyTitle>LISTA VAZIA</EmptyTitle>
              </Grid>
              <Grid item>
                <EmptyDescription>Você ainda não possui experiências salvas nessa lista.</EmptyDescription>
                <EmptyDescription>Explore a plataforma e comece a criar sua lista de desejo!</EmptyDescription>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
      <FileImportModal file={fileToUpload} isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export default MyExperiences;
