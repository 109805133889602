import React from 'react';
import Button from 'components/Buttom/Button';
import { Link } from 'react-router-dom';
import { Container } from './styles';

interface IBannerProps {
  id: number;
  title: string;
  description: string;
  slug?: string;
  banners: {
    image: string;
  }[];
}

const BannerDynamic = (props: IBannerProps): JSX.Element => {
  const isMultipleBanners = props.banners.length > 1;

  return (
    <Container key={props.id}>
      {isMultipleBanners ? (
        <div className="slider-container">
          {props.banners.map((banner, index) => (
            <div key={index} style={{ background: `url(${banner.image}) center top / cover no-repeat` }}></div>
          ))}
        </div>
      ) : (
        <div style={{ background: `url(${props.banners[0].image}) center top / cover no-repeat` }}>
          <article
            style={{
              background: 'linear-gradient(90deg, rgba(13,74,155,0.9) 0%, rgba(13,74,155,0) 100%)',
              width: '100%',
              height: '100vh',
            }}
          />
          <span>{props.title}</span>
          <span>{props.description}</span>
          {props?.slug && (
            <Link to={'/what-to-do/itineraries/' + props.slug}>
              <Button text="CONHECER ROTEIRO" />
            </Link>
          )}
        </div>
      )}
    </Container>
  );
};

export default BannerDynamic;
