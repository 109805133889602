import React from 'react';
import { Button } from './styles';
import { Link } from 'react-router-dom';

const ChangePasswordButton = (): JSX.Element => {
  return (
    <Button>
      <img src="" alt="" />
      <Link to="/password-reset">
        <span>Alterar minha senha</span>
      </Link>
    </Button>
  );
};

export default ChangePasswordButton;
