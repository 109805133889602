import React, { useEffect, useState } from 'react';
import {
  CloseImageButton,
  ErrorMessage,
  Input,
  InputLabel,
  UploadLogoActionButtons,
  UploadLogoCancelButton,
  UploadLogoContainer,
  UploadLogoHeader,
  UploadLogoList,
  UploadLogoListItem,
  UploadLogoSaveButton,
  UploadLogoTitle,
} from './styles';
import VisualIdentityService from 'services/VisualIdentityService';
interface IImage {
  id: number;
  name: string;
  url: string;
}

export interface UploadLogoProps {
  descriptionText?: string;
  title: string;
  multipleFiles: boolean;
  accept: string;
  importedFiles?: IImage[];
  limitFiles?: number;
  htmlFor?: string;
  onChange?: (files: Array<IImage | File>) => void;
  onSubmit?: () => void;
  required?: boolean;
}

export const UploadLogo = ({
  required,
  title,
  descriptionText,
  multipleFiles,
  limitFiles,
  importedFiles,
  htmlFor,
  accept,
  onChange,
  onSubmit,
}: UploadLogoProps): JSX.Element => {
  //const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [newFiles, setNewFiles] = useState<IImage[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isFileViewerVisible, setIsFileViewerVisible] = useState(false);
  const newFilesNumber = newFiles?.length ?? 0;
  const uploadedFilesNumber = uploadedFiles?.length ?? 0;
  const [inputLabelDisabled, setInputLabelDisabled] = useState(false);

  useEffect(() => {
    if (limitFiles && newFilesNumber + uploadedFilesNumber >= limitFiles) {
      setInputLabelDisabled(true);
    } else {
      setInputLabelDisabled(false);
    }
  }, [newFilesNumber, uploadedFilesNumber, limitFiles]);

  useEffect(() => {
    if (importedFiles) {
      setNewFiles(importedFiles);
    }
  }, [importedFiles]);

  const errorReturn = () => {
    setError(`Você pode adicionar apenas ${limitFiles} arquivos`);
    setUploadedFiles([]);
    setIsFileViewerVisible(false);
  };

  const handleChange = (images = newFiles, files = uploadedFiles) => {
    if (!onChange) {
      return;
    }

    onChange([...(images ?? []), ...(files ?? [])]);
  };

  const onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventFilesNumber = event.target.files?.length ?? 0;
    const totalFiles = eventFilesNumber + uploadedFilesNumber + newFilesNumber;

    switch (true) {
      case eventFilesNumber > (limitFiles ?? 0):
        errorReturn();
        break;
      case totalFiles > (limitFiles ?? 0):
        errorReturn();
        break;
      default:
        setError(null);
        const current = uploadedFiles ?? [];
        const fromTarget = Array.from(event.target.files ?? []);
        const newUploadedFiles = [...current, ...fromTarget];
        setUploadedFiles(newUploadedFiles);
        handleChange(newFiles, newUploadedFiles);
        break;
    }
  };

  const removeImage = (file: File) => {
    setError(null);
    setUploadedFiles(uploadedFiles => (uploadedFiles ? uploadedFiles.filter(f => f !== file) : null));
    setIsFileViewerVisible(false);
    handleChange();
  };

  const submitLogoFiles = () => {
    onSubmit && onSubmit();
    setShowSaveButton(false);
  };

  const cancelSubmitFiles = async () => {
    const logoData = await new VisualIdentityService().getLogo();
    if (logoData) {
      setNewFiles([{ id: 0, name: 'porto-seguro-logo', url: logoData as unknown as string }]);
    }
    setUploadedFiles([]);
    setIsFileViewerVisible(false);
    setShowSaveButton(false);
  };

  console.log('importedFiles', importedFiles);

  return (
    <UploadLogoContainer>
      <UploadLogoHeader>
        <UploadLogoTitle>{title}</UploadLogoTitle>
        {inputLabelDisabled ? (
          <UploadLogoActionButtons>
            {!showSaveButton ? (
              <></>
            ) : (
              <>
                <UploadLogoCancelButton type="button" onClick={cancelSubmitFiles}>
                  Cancelar
                </UploadLogoCancelButton>
                <UploadLogoSaveButton type="submit" onClick={submitLogoFiles}>
                  Salvar
                </UploadLogoSaveButton>
              </>
            )}
          </UploadLogoActionButtons>
        ) : (
          <InputLabel
            htmlFor={htmlFor}
            onClick={() => {
              setIsFileViewerVisible(true);
              setShowSaveButton(true);
            }}
            className={inputLabelDisabled ? 'disabledButton' : ''}>
            Editar
          </InputLabel>
        )}
      </UploadLogoHeader>
      {descriptionText} {required && <span>*</span>}
      {isFileViewerVisible && (
        <Input
          hidden
          onChange={onImport}
          accept={accept}
          id={htmlFor}
          type="file"
          multiple={multipleFiles}
          disabled={inputLabelDisabled}
        />
      )}
      <UploadLogoList>
        {newFiles?.map((file: IImage) => (
          <UploadLogoListItem
            style={{
              background: `url(${file.url}) center top / cover no-repeat`,
            }}
            key={file.id}>
            <CloseImageButton type="button" onClick={() => setNewFiles([])}>
              X
            </CloseImageButton>
          </UploadLogoListItem>
        ))}
        {uploadedFiles?.map((file: File) => (
          <UploadLogoListItem
            style={{
              background: `url(${URL.createObjectURL(file)}) center top / cover no-repeat`,
            }}
            key={file.name}>
            <CloseImageButton type="button" onClick={() => removeImage(file)}>
              X
            </CloseImageButton>
          </UploadLogoListItem>
        ))}
        {(newFiles?.length ?? 0) === 0 && (uploadedFiles?.length ?? 0) === 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0',
              width: '100%',
              height: '50px',
              color: '#aaa',
              background: '#f1f1f1',
              borderRadius: '18px',
            }}>
            Nenhum arquivo importado
          </div>
        )}
      </UploadLogoList>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </UploadLogoContainer>
  );
};

export default UploadLogo;
