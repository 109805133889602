import { atom, selector } from 'recoil';
import { logged } from 'features/Login/store/LoginAtom';
import IBookmark, { ICreateBookmark } from 'interfaces/IBookmark';
import BookmarkService from 'services/BookmarkService';

type BookmarkIndex = {
  [key: string]: ICreateBookmark | undefined;
};

const getColumnType = (bookmark: IBookmark): { id: number; type: string } => {
  if (bookmark.activityId) {
    return { id: bookmark.activityId, type: 'activity' };
  }

  if (bookmark.businessId) {
    return { id: bookmark.businessId, type: 'business' };
  }

  return { id: bookmark.eventId, type: 'event' };
};

const transformToCreateBookmark = (bookmark: IBookmark): ICreateBookmark & { key: string } => {
  const { id, type } = getColumnType(bookmark);
  const done = bookmark.done;

  const key = `${type}-${id}`;

  return { id, type, done, key };
};

const fetchBookmarkIndex = async (): Promise<BookmarkIndex> => {
  const service = new BookmarkService();
  const bookmarks = await service.getBookmarks(false);

  return bookmarks.reduce((result: BookmarkIndex, bookmark) => {
    const { key, ...item } = transformToCreateBookmark(bookmark);
    return { ...result, [key]: item };
  }, {});
};

const defaultIndex = {};

const bookmarkIndex = atom<BookmarkIndex>({
  key: 'BookmarkIndex',
  default: defaultIndex,
});

export default selector({
  key: 'BookmarkIndexSelector',
  get: async ({ get }) => {
    const index = get(bookmarkIndex);

    if (index !== defaultIndex || !get(logged)) {
      return index;
    }

    return fetchBookmarkIndex();
  },
  set: ({ set }, newIndex) => {
    return set(bookmarkIndex, newIndex);
  },
});
