import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
  color: #9a9a9a;

  @media all and (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Image = styled.img`
  width: 5rem;
  filter: brightness(0) saturate(100%) invert(75%) sepia(0%) saturate(521%) hue-rotate(144deg) brightness(83%)
    contrast(84%);
`;
export const Message = styled.p`
  text-align: center;
  margin-top: 1rem;
  font-size: 1.2rem;
`;
export const Phone = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
`;
export const Email = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
`;
