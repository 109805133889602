import React from 'react';
import { InfoCardHours, InfoCardOpeningHours } from './styles';
import { Modal } from 'components/Modals';
import useModal from 'hooks/useModal';
import upArrowYellow from '../../assets/images/upArrowYellow.svg';
import downArrowYellow from '../../assets/images/downArrowYellow.svg';

interface ICardHoursProps {
  id: number;
  openingHoursDetail?: {
    id: number;
    hours: string;
  }[];
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
}

const CardHours: React.FC<ICardHoursProps> = props => {
  const { isOpen, toggle } = useModal();

  const isEmptyHour = (openingHoursDetail?: { id: number; hours: string }[]) => {
    return !openingHoursDetail || openingHoursDetail.every(day => !day.hours || day.hours.trim() === '');
  };

  const isHoursEmpty = isEmptyHour(props.openingHoursDetail);

  return (
    <InfoCardHours>
      {isHoursEmpty ? (
        <small className="emptyHour">Horário de Visitação Indisponível</small>
      ) : (
        <small onClick={toggle}>
          Horário de Visitação
          {isOpen ? <img src={upArrowYellow} alt="open" /> : <img src={downArrowYellow} alt="close" />}
        </small>
      )}
      {props.startDate && props.endDate && (
        <small>
          {props.startDate} - {props.startTime} a {props.endDate} - {props.endTime}
        </small>
      )}
      <Modal isOpen={isOpen} toggle={toggle}>
        <InfoCardOpeningHours>
          {props.openingHoursDetail?.slice(0, 7).map(day => (
            <li key={day.id}>
              <span>{getDayOfWeek(day.id)}</span>
              <span>{day.hours}</span>
            </li>
          ))}
        </InfoCardOpeningHours>
      </Modal>
    </InfoCardHours>
  );
};

const getDayOfWeek = (id: number): string => {
  const daysOfWeek = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  return daysOfWeek[id] || '';
};

export default CardHours;
