import styled from 'styled-components';

export const MainContentInformationTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const MainContentListTitle = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  grid-column-start: span 1;
  margin-bottom: 20px;
`;

export const MainContentForm = styled.form`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MainContentFormItem = styled.div`
  display: grid;
  text-decoration: none;
  grid-column-start: span 1;
  margin: 20px 0;

  label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  input {
    height: 40px;
    padding: 0 10px 0 10px;
    font-size: 16px;
    border-radius: 18px;
    border: 1px solid #d3d3d3;
    gap: 10px;

    &:disabled {
      background-color: transparent;
      padding: 0;
      border: none;

      &::placeholder {
        color: #272727;
      }
    }
  }

  textarea {
    height: 76px;
    padding: 8px 10px 8px 10px;
    border-radius: 18px;
    border: 1px solid #d3d3d3;
    gap: 10px;
    font-size: 16px;
    resize: none;

    &:disabled {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      border: none;

      &::placeholder {
        color: #272727;
      }
    }
  }

  @media (max-width: 980px) {
    margin: 0 0 20px;
  }
`;

export const MainContentFormImageList = styled.ul`
  display: flex;
  list-style-type: none;
  gap: 10px;
`;

export const MainContentFormImageListItem = styled.li`
  width: 118px;
  height: 118px;
  border-radius: 18px;
`;
