import React from 'react';
import { Field } from 'formik';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import ErrorMessage from 'components/ErrorMessage';
import Button from 'components/Buttom/Button';
import FieldMask from 'components/FieldMask';
import InputPassword from 'common/InputPassword/InputPassword';

const TourOrganizerLogin = (): JSX.Element => {
  return (
    <Container>
      <h2>Preencha as informações abaixo:</h2>
      <label htmlFor="">
        CNPJ ou CPF <span>*</span>
      </label>
      <FieldMask type="cnpj-or-cpf">
        <Field type="text" name="document" />
      </FieldMask>
      <ErrorMessage name="document" />
      <label htmlFor="">
        Senha <span>*</span>
      </label>
      <InputPassword name={'password'} />
      <ErrorMessage name="password" />
      <div className="checkboxLogin">
        <input type="checkbox" />
        <label htmlFor="">Lembrar de mim</label>
        <Link to="/forgot-password">Esqueci minha senha</Link>
      </div>
      <Button text="ENTRAR" type="submit" />
      <div>
        <span>Ainda não é cadastrado?</span>
        <a href="/signup">Criar Conta</a>
      </div>
    </Container>
  );
};

export default TourOrganizerLogin;
