import styled from 'styled-components';
import closeBlue from 'assets/images/closeBlue.svg';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const FormControl = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: fixed;
  z-index: 99999;
  top: 10vh;
`;

export const Title = styled.p`
  margin-bottom: 1rem;
`;

export const TitleContainer = styled.div`
  font-size: 1.2rem;
  margin: 2rem 0 0 1rem;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 960px) {
    width: 90%;
    justify-content: space-between;
  }
`;

export const Input = styled.input``;

export const InputLabel = styled.label`
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  text-align: center;
  color: #fff;
  width: 12.5rem;
  margin: 1rem 1rem 0 0;
  padding: 0.5rem;
  background: #ffcb29;
  &:hover {
    background-color: #020c90;
  }
`;

export const CountLabel = styled.p`
  margin-left: calc(30rem - 250px);

  @media (max-width: 960px) {
    margin-left: 0;
  }
`;

export const AlignRight = styled.div`
  display: flex;
  align-items: center;
  place-self: flex-end;
`;

export const Label = styled.label`
  margin-left: 1rem;
  font-family: 'Signika';
`;

export const FileViewer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  border: 2px solid #9a9a9a;
  width: calc(100% - 2rem);
  height: 40px;
  text-align: left;
  margin: 1rem 1rem 0rem 1rem;
  padding: 0 10px;
  p:nth-child(1) {
    width: 80%;
    overflow: hidden;
    line-height: 36px;
  }

  img {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    cursor: pointer;

    &:hover {
      content: url(${closeBlue});
    }
  }

  :active {
    opacity: 1;
    border-color: rgba(0, 0, 0, 0.25);
  }
`;

export const ErrorContainer = styled.div`
  color: red;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
`;

export const Content = styled.div`
  margin-top: 2rem;
  height: 100%;
  width: 100%;

  label {
    margin-left: 1rem;
    font-family: 'Signika';
    color: #272727;
  }

  label:first-child {
    margin-bottom: 10px;
    display: block;
  }

  .Mui-checked span svg path {
    fill: #ffcb29;
  }

  span {
    font-family: 'Signika';
    margin: 0 5px 0 0;
    padding: 2px 0 0;
    font-size: 1rem;

    svg path {
      fill: #272727;
    }
  }

  @media (max-width: 960px) {
    #buttons-group-label:first-child {
      text-align: left;
      display: block;
      margin-bottom: 10px;
    }
  }
`;

export const Backgroud = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: 525px;
  align-items: flex-end;
  background: #fff;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    width: 90%;
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  width: 20rem;
  background: #fff;

  button {
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 18px;
    box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    &:first-child {
      background-color: #fff;
      font-weight: bold;
      color: #ffcb29;
      border: 0.5px solid #ffcb29;
      opacity: 1;
      &:hover {
        background-color: #020c90;
        color: #fff;
        border: none;
      }
    }
  }
`;
