import React, { useEffect } from 'react';
import { Container, Card, ImageTitle, List } from './styles';

interface IProps {
  banners?: Array<{
    link: string;
    title: string;
    image: string;
  }>;
}

const InfoCardBanners = (props: IProps): JSX.Element => {
  const { banners } = props;
  const list = document.querySelector('.list');
  const listWidth = list?.scrollWidth;

  useEffect(() => {
    document.querySelector('.prev-button')?.classList.add('disabled');

    list?.addEventListener('scroll', () => {
      if (list.scrollLeft === 0) {
        document.querySelector('.prev-button')?.classList.add('disabled');
      } else {
        document.querySelector('.prev-button')?.classList.remove('disabled');
      }
      if (list.scrollLeft === list.scrollWidth - list.clientWidth) {
        document.querySelector('.next-button')?.classList.add('disabled');
      } else {
        document.querySelector('.next-button')?.classList.remove('disabled');
      }
    });
  }, [list]);

  if (!banners?.length) {
    return <></>;
  }

  const scrollToLeft = () => {
    if (list) {
      list.scrollLeft -= (listWidth ?? 0) / 2;
    }
  };

  const scrollToRight = () => {
    if (list) {
      list.scrollLeft += (listWidth ?? 0) / 2;
    }
  };

  return (
    <Container className="list-container">
      <button aria-label="prev" className="prev-button" onClick={scrollToLeft}>
        <span>&lt;</span>
      </button>
      <List className="list">
        {banners.map((banner, index) => (
          <Card
            key={index}
            onClick={() => window.open(banner.link)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                window.open(banner.link);
              }
            }}
            style={{ background: `url(${banner.image}) center center / cover no-repeat` }}
            className="list-card">
            <ImageTitle>{banner.title}</ImageTitle>
          </Card>
        ))}
      </List>
      <button aria-label="next" className="next-button" onClick={scrollToRight}>
        <span>&gt;</span>
      </button>
    </Container>
  );
};

export default InfoCardBanners;
