import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  height: fit-content;

  input {
    display: none;
  }
`;

export const Hover = styled.div`
  display: none !important;
  width: 108px;
  height: 108px;
  background-color: #000000ad;
  position: absolute;
  margin-left: 0px;
  border-radius: 50%;
  cursor: pointer;
  top: 3px;
  left: 1px;

  label {
    color: white;
    text-align: center;
    height: 110px;
    line-height: 110px;
    width: 100%;
  }
`;

export const Selector = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 18px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px #0000003f;
  height: fit-content;
  z-index: 999;
  cursor: pointer;
  margin-top: 0px;
`;

export const Option = styled.div`
  cursor: pointer;
  width: 140px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  border-bottom: 2px dotted #9a9a9a;
  &:last-child {
    border: none;
  }
  &:hover {
    color: #020c90;
  }
  span {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ImageDiv = styled.div`
  border-radius: 50%;
  height: 110px;
  overflow: hidden;
  width: 110px;

  img {
    width: 100%;
  }
`;
