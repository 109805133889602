import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useRecoilState } from 'recoil';
import { auth } from 'utils/firebase';
import { userLogout } from 'services/localStorage';
import { logged } from 'features/Login/store/LoginAtom';

const Logout = (): JSX.Element => {
  const [loggedState, setLoggedState] = useRecoilState(logged);

  useEffect(() => {
    setLoggedState(false);
    userLogout();
    auth.signOut();
  }, []);

  return <Redirect to={{ pathname: '/' }} />;
};

export default Logout;
