import styled from 'styled-components';
import loginTouristBg from 'assets/images/background_login_tourist.svg';

export const Container = styled.div`
  min-width: 68.8vw;
  background: url(${loginTouristBg}) no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #0d4a9b80;
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const SelectLoginDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 60vw;
  margin: 5% 0;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const SuccessModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: fit-content;
  padding: 30px;
  border-radius: 18px;
  gap: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
  }
  span {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
`;

export const ButtonSection = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 30px;
`;
