import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { ButtonTab, Container, NavigationButtons } from './styles';
import WhatToDo from 'features/WhatToDo/pages/WhatToDo';
import ActivitiesList from 'features/Activities/pages/List';
import EventsList from 'features/Events/pages/List';
import MainTitle from 'components/MainTitle';
import TransportsList from 'features/Transports/pages/List';

enum Pages {
  Activities = 'activities',
  Events = 'events',
  Itineraries = 'itineraries',
  Transports = 'transports',
}

const HandleTab = (): JSX.Element => {
  const history = useHistory();
  const { slug } = useParams<{ slug?: string }>();
  const [active, setActive] = useState(slug);

  const handleComponent = () => {
    switch (active) {
      case Pages.Activities:
        return <ActivitiesList />;
      case Pages.Events:
        return <EventsList />;
      case Pages.Itineraries:
        return <WhatToDo />;
      case Pages.Transports:
        return <TransportsList />;
      default:
        return <ActivitiesList />;
    }
  };

  const handleTabClick = (page: Pages) => {
    setActive(page);
    history.replace(`/what-to-do/${page}`);
  };

  return (
    <>
      <MainTitle>O Que Fazer</MainTitle>
      <Container>
        <NavigationButtons>
          <ul>
            <li>
              <ButtonTab
                data-active={active === Pages.Activities || active === undefined}
                onClick={() => handleTabClick(Pages.Activities)}>
                ATIVIDADES
              </ButtonTab>
            </li>
            <li>
              <ButtonTab data-active={active === Pages.Events} onClick={() => handleTabClick(Pages.Events)}>
                AGENDA
              </ButtonTab>
            </li>
            <li>
              <ButtonTab data-active={active === Pages.Itineraries} onClick={() => handleTabClick(Pages.Itineraries)}>
                ROTEIROS
              </ButtonTab>
            </li>
            <li>
              <ButtonTab data-active={active === Pages.Transports} onClick={() => handleTabClick(Pages.Transports)}>
                TRANSPORTES
              </ButtonTab>
            </li>
          </ul>
        </NavigationButtons>
        {handleComponent()}
      </Container>
    </>
  );
};

export default HandleTab;
