import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 980px) {
    margin-bottom: 0px;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 30px;
    padding: 0 50px;
    list-style-type: none;
    margin: 80px 0 50px 0;

    @media (max-width: 980px) {
      width: 100%;
      display: grid;
      overflow-x: scroll;
      padding: 30px 16px 0px;
      justify-content: unset;
      margin: 0 0 30px;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, 294px);
      grid-auto-flow: column;
      grid-auto-columns: 294px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    li {
      position: relative;
      height: 100vh;
      max-height: 426px;
      z-index: 0;
      border-radius: 18px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        top: -20px;
        width: 200px;
        font-weight: 700;
        font-size: 18px;
        text-transform: none;
        z-index: 999;

        @media all and (max-width: 980px) {
          padding: 10px;
          width: inherit;
          min-width: 120px;
          font-size: 16px;
        }
      }

      button:first-child {
        &:hover {
          background-color: #ffcb29;
        }
      }
      img {
        width: 100%;
        height: 402px;
        border-radius: 18px;
      }

      &:hover {
        div {
          display: flex !important;
          align-items: center;
          padding: 20px;
          border: 5px solid #ffcb29;
        }
      }
    }
  }
  > button {
    width: 270px;
    display: block;
    margin: 0 auto 90px auto;
    font-size: 16px;
    padding: 10px 10px;

    @media (max-width: 980px) {
      margin-top: 20px;
    }
  }
`;

export const HoverDiv = styled.div`
  display: none !important;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
  background: #0d4a9bb2;
  height: 427px !important;
  z-index: 998;
  top: -40px;
  left: 0;
  border-radius: 18px;
  span {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
  button {
    position: relative !important;
    width: 100% !important;
    margin: 0 !important;
  }

  @media (max-width: 1024px) {
    span {
      font-size: 12px;
    }

    button {
      font-size: 14px !important;
    }
  }
`;
