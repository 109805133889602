import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridColumnVisibilityModel,
  GridToolbar,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import eyeIcon from 'assets/images/eyeGrayIcon.svg';
import editIcon from 'assets/images/editIcon.svg';
import trashIcon from 'assets/images/trashIcon.svg';
import comentsIcon from 'assets/images/coments.svg';
import IEvent, { EventStatusAPI } from 'interfaces/IEvent';
import { IImproviments } from 'interfaces/IImproviments';
import touristOffersEventsService from 'services/TouristOffersEventsService';
import ImprovimentsService from 'services/ImprovimentsService';
import Button from 'components/Button';
import Heading from 'components/Heading';
import MessageModal from 'common/MessageModal';
import ConfirmationModal from 'components/ConfirmationModal';
import { useLoading } from 'hooks/useLoading';
import { useHistory } from 'react-router-dom';

const EventsList = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [eventsData, setEventsData] = useState<IEvent[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [eventToDeleteId, setEventToDeleteId] = useState<string>('');
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [selectedEventsComments, setSelectedEventsComments] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    openLoading();
    const fetchData = async () => {
      await fetchEvents();
      closeLoading();
    };
    fetchData();
  }, []);

  const service = {
    touristOffersEvents: new touristOffersEventsService(),
    improvements: new ImprovimentsService(),
  };

  const fetchEvents = async () => {
    const data = await service.touristOffersEvents.findAll();
    setEventsData(data);
  };

  const stylingRowStatus = (status?: EventStatusAPI) => {
    switch (status) {
      case EventStatusAPI.APPROVED:
        return <span className="approvedBadge">Aprovado</span>;
      case EventStatusAPI.REFUSED:
        return <span className="reprovedBadge">Recusado</span>;
      case EventStatusAPI.WAITING_FOR_SETUR:
        return <span className="waitingSeturBadge">Aguardando SETUR</span>;
      case EventStatusAPI.WAITING_FOR_TRADE:
        return <span className="pendingAdjustment">Ajuste Pendente</span>;
      default:
        return '- - -';
    }
  };

  const handleRequestEdit = async (improvements?: IImproviments[]) => {
    const [latestImprovement] = improvements?.sort((a, b) => b.createdAt.localeCompare(a.createdAt)) || [];
    if (latestImprovement) {
      setSelectedEventsComments(latestImprovement.description);
    } else {
      setSelectedEventsComments('Nenhuma melhoria encontrada para o evento.');
    }
    setOpenMessageModal(true);
  };

  const deleteEvent = async () => {
    const eventId = parseInt(eventToDeleteId, 10);

    if (isNaN(eventId)) {
      return;
    }
    openLoading();
    try {
      await service.touristOffersEvents.remove(eventId);
      const updatedEventsData = eventsData.filter(event => event.id !== eventId);
      setEventsData(updatedEventsData);
    } catch (error) {
    } finally {
      closeLoading();
      setConfirmDeletion(false);
    }
  };

  const formatCreatedAtDate = (createdAt: string): JSX.Element => {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleDateString('pt-BR');
    const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

    return (
      <div className="createdDate">
        <span>{formattedDate}</span>
        <span>{formattedTime}</span>
      </div>
    );
  };

  const isValidStatusForUpdate = (status: EventStatusAPI) => {
    return status === EventStatusAPI.WAITING_FOR_TRADE || status === EventStatusAPI.APPROVED;
  };

  const isValidStatusForDelete = (status: EventStatusAPI) => {
    return status !== EventStatusAPI.REFUSED;
  };

  const eventsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Criação',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      renderCell: (params: GridRenderCellParams<IEvent, string, keyof IEvent, GridTreeNodeWithRender>) =>
        formatCreatedAtDate(params.value as string),
    },
    {
      field: 'title',
      headerName: 'Nome do Evento',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'category',
      headerName: 'Categoria',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 222,
      renderCell: (params: GridRenderCellParams<IEvent, EventStatusAPI, keyof IEvent, GridTreeNodeWithRender>) =>
        stylingRowStatus(params.value),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 285,
      getActions: params => [
        <div key={params.id}>
          <button
            className="button-icon"
            title="Preview"
            onClick={() => history.push(`/admin/event/${params.id}/preview`)}>
            <img src={eyeIcon} alt="Preview" />
          </button>
          <button
            className="button-icon"
            title="Editar"
            disabled={!isValidStatusForUpdate(params?.row?.status)}
            onClick={() => {
              history.push(`/event/${params.id}`);
            }}>
            <img src={editIcon} alt="Editar" />
          </button>
          <button
            className="button-icon"
            title="Solicitação de Edição"
            onClick={() => handleRequestEdit(params?.row?.improvements)}>
            <img src={comentsIcon} alt="Solicitação de Edição" />
          </button>
          <button
            className="button-icon"
            title="Excluir"
            disabled={!isValidStatusForDelete(params?.row?.status)}
            onClick={async () => {
              const eventId = String(params.id);
              const eventsItem: IEvent | undefined = eventsData.find(event => event.id === Number(eventId));
              if (eventsItem) {
                setEventToDeleteId(eventId);
                setConfirmDeletion(true);
              }
            }}>
            <img src={trashIcon} alt="Excluir" />
          </button>
        </div>,
      ],
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
  ];

  return (
    <Container>
      <div className="header">
        <Heading>Meus Eventos</Heading>
        <Button type="submit" buttonType="primary" onClick={() => history.push('/event')}>
          Novo Evento
        </Button>
      </div>

      {eventsData.length > 0 ? (
        <>
          <Grid sx={{ width: '100%', marginBottom: '50px', textAlign: 'center' }}>
            <DataGrid
              sx={{
                background: '#fff',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                borderRadius: '18px',
                width: '100%',
              }}
              rows={eventsData}
              columns={eventsColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              className="dataGrid"
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  densitySelector: false,
                  exportButton: false,
                },
              }}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableDensitySelector
              disableVirtualization
            />
          </Grid>
          <ConfirmationModal
            open={confirmDeletion}
            title="EXCLUSÃO DE ITEM"
            message="Você tem certeza de que deseja excluir esse item?"
            onCancel={() => setConfirmDeletion(false)}
            onConfirm={deleteEvent}
          />
          <MessageModal
            isOpen={openMessageModal}
            title="Solicitação de Edição"
            message={selectedEventsComments}
            onClose={() => {
              setSelectedEventsComments('');
              setOpenMessageModal(false);
            }}
          />
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '10px 0',
            width: '100%',
            height: '50px',
            color: '#aaa',
            background: '#f1f1f1',
            borderRadius: '18px',
          }}>
          {' '}
          Cadastre seu primeiro evento
        </div>
      )}
    </Container>
  );
};

export default EventsList;
