import styled from 'styled-components';
import ratingsBG from 'assets/images/ratingsBG.svg';
import starWhite from 'assets/images/starWhite.svg';
import starYellow from 'assets/images/starYellow.svg';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 50px;

  @media (max-width: 980px) {
    padding: 0 16px;
  }
`;

export const BGIntro = styled.div`
  display: block;
  width: 100%;
  height: 100vh;

  .stars {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    position: absolute;
    color: #fff;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
  }

  .bg-to {
    background: url(${ratingsBG}) center center / cover no-repeat;
    display: block;
    width: 100%;
    height: 100vh;
  }

  @keyframes loading {
    0% {
      background-image: url(${starWhite});
    }
    25% {
      background-image: url(${starWhite});
    }
    50% {
      background-image: url(${starYellow});
    }
    75% {
      background-image: url(${starYellow});
    }
    100% {
      background-image: url(${starWhite});
    }
  }

  .stars span {
    width: 94px;
    height: 90px;
    margin: 0 10px;
    display: inline-block;
    background-size: cover;
  }

  @keyframes loading-star-1 {
    0% {
      background-image: url(${starWhite});
    }
    20% {
      background-image: url(${starYellow});
    }
    100% {
      background-image: url(${starWhite});
    }
  }

  @keyframes loading-star-2 {
    0% {
      background-image: url(${starWhite});
    }
    40% {
      background-image: url(${starYellow});
    }
    100% {
      background-image: url(${starWhite});
    }
  }

  @keyframes loading-star-3 {
    0% {
      background-image: url(${starWhite});
    }
    60% {
      background-image: url(${starYellow});
    }
    100% {
      background-image: url(${starWhite});
    }
  }

  @keyframes loading-star-4 {
    0% {
      background-image: url(${starWhite});
    }
    80% {
      background-image: url(${starYellow});
    }
    100% {
      background-image: url(${starWhite});
    }
  }

  @keyframes loading-star-5 {
    0% {
      background-image: url(${starWhite});
    }
    100% {
      background-image: url(${starYellow});
    }
  }

  .star-1::after {
    content: '';
    background-image: url(${starWhite});
    animation: loading-star-1 5s infinite;
    display: block;
    width: 94px;
    height: 90px;
    background-size: cover;
  }

  .star-2::after {
    content: '';
    background-image: url(${starWhite});
    animation: loading-star-2 5s infinite;
    display: block;
    width: 94px;
    height: 90px;
    background-size: cover;
  }

  .star-3::after {
    content: '';
    background-image: url(${starWhite});
    animation: loading-star-3 5s infinite;
    display: block;
    width: 94px;
    height: 90px;
    background-size: cover;
  }

  .star-4::after {
    content: '';
    background-image: url(${starWhite});
    animation: loading-star-4 5s infinite;
    display: block;
    width: 94px;
    height: 90px;
    background-size: cover;
  }

  .star-5::after {
    content: '';
    background-image: url(${starWhite});
    animation: loading-star-5 5s infinite;
    display: block;
    width: 94px;
    height: 90px;
    background-size: cover;
  }

  @media (max-width: 980px) {
    .stars span {
      width: 40px;
    }
    .star-1::after,
    .star-2::after,
    .star-3::after,
    .star-4::after,
    .star-5::after {
      width: 44px;
      height: 42px;
    }
    p {
      font-size: 25px;
      bottom: 70px;
    }
  }
`;
