import React from 'react';
import { useParams } from 'react-router';
import BusinessService from 'services/TouristOffersBusinessService';
import IAccommodation from 'interfaces/IAccommodation';
import { Accommodation } from 'features/WhatToDo/components/AccommodationDetail';

const AccommodationPreview = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const getAccommodation = async () => {
    const service = new BusinessService();
    const business = await service.findOne(Number(id));
    return business as unknown as IAccommodation;
  };

  return <Accommodation getAccommodation={getAccommodation} />;
};

export default AccommodationPreview;
