import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 0 50px;

  @media (max-width: 960px) {
    padding: 0 16px;
  }
`;

export const HeaderTitle = styled.div`
  @media (max-width: 960px) {
    span {
      font-size: 22px;
      white-space: break-spaces;
      text-align: center;
    }
  }
`;

export const TouristOffersHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonTab = styled.button`
  width: 125px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px;
  border: 0.5px solid #9a9a9a;
  color: #9a9a9a;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  &:hover {
    border-color: #020c90;
    background-color: #020c90;
    color: #ffffff;
  }
  &[data-active='true'] {
    border-color: #020c90;
    color: #020c90;
    &:hover {
      color: #ffffff;
    }
    @media (max-width: 960px) {
      background-color: #fff;
      &:hover {
        color: #020c90;
      }
    }
  }
`;
