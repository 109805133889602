import styled from 'styled-components';
export const LabelCategory = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;

  li {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    padding: 5px 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    background: #020c90;
    color: #fff;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }

  li:last-child {
    margin-right: 0;
  }
`;
