import React, { useState } from 'react';
import { Container } from './styles';
import MainTitle from 'components/MainTitle';
import ButtonMenu from 'components/ButtonMenu';
import { Redirect, useHistory, useParams } from 'react-router';
import BusinessList from './components/BusinessList';
import EventsList from './components/EventsList';

const TouristOffers = (): JSX.Element => {
  const history = useHistory();
  const { slug } = useParams<{ slug?: string }>();
  const [page, setPage] = useState(slug);
  const urlLocation = '/admin/tourist-offers';

  const options = {
    'business-list': 'Negócios',
    'events-list': 'Eventos',
    //'setur-list': 'Setur',
  };

  const handleComponent = () => {
    switch (page) {
      case '':
        return <Redirect to={`${urlLocation}/business-list`} />;
      case 'business-list':
        return <BusinessList />;
      case 'events-list':
        return <EventsList />;
      // case 'setur-list':
      //   return <h1>Em breve Página SETUR</h1>;
      default:
        return '';
    }
  };

  const handleMenuChange = (key: string) => {
    setPage(key);
    history.replace(`${urlLocation}/${key}`);
  };

  return (
    <>
      <MainTitle>Ofertas Turísticas</MainTitle>
      <Container>
        <ButtonMenu options={options} onChange={handleMenuChange} urlLocation={urlLocation} />
        {handleComponent()}
      </Container>
    </>
  );
};

export default TouristOffers;
