import React from 'react';
import { StyledButton } from './styles';

interface Props {
  text?: string;
  children?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
  disabled?: boolean;
}
const Button: React.FC<Props> = (props: Props) => {
  const { children, text, type, onClick, disabled } = props;
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled}>
      {text ?? children}
    </StyledButton>
  );
};

export default Button;
