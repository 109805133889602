import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import checklist from '../../assets/images/checklist.svg';
import busIcon from '../../assets/images/busIcon.svg';
import starIcon from '../../assets/images/starIcon.svg';
import mindMap from '../../assets/images/mindMap.svg';
import configIcon from '../../assets/images/configIcon.svg';
import { NavLink } from 'react-router-dom';

const MenuSetur: React.FC = () => {
  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/tourist-offers/business-list">
            <img src={checklist} alt="icon for list item" />
            <span>Ofertas Turísticas</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/excursions">
            <img src={busIcon} alt="icon for list item" />
            <span>Excursões</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/strategic-map">
            <img src={mindMap} alt="icon for list item" />
            <span>Mapa Estratégico</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/config">
            <img src={configIcon} alt="icon for list item" />
            <span>Configurações</span>
          </NavLink>
        </ListItem>
        {/* <ListItem>
          <NavLink activeClassName="selected" to="/admin/reviews">
            <img src={starIcon} alt="icon for list item" />
            <span>Avaliações</span>
          </NavLink>
        </ListItem> */}
      </ul>
    </Menu>
  );
};

export default MenuSetur;
