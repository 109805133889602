import styled from 'styled-components';
import * as styles from 'components/SelectionField/styles';

export const Container = styled.div`
  .selection-fields {
    margin-bottom: 50px;
    padding: 0px 50px;

    @media (max-width: 960px) {
      padding: 0px 20px;
    }
  }
`;

export const SelectField = styled(styles.Container)`
  .selection-field {
    width: 100%;
    height: 40px;
    padding: 0 10px;
  }
`;

export const Map = styled.div`
  height: 500px;
`;
