import Yup from 'utils/validation';

import { IBusiness } from 'interfaces/IBusinesses';
import { validDDDs } from 'utils/basicUtils';

export interface TourSeturFormProps {
  selectedId?: string;
  item?: IBusiness | null;
  type?: string;
}

export const states = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AP', value: 'AP' },
  { label: 'AM', value: 'AM' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RS', value: 'RS' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SP', value: 'SP' },
  { label: 'SE', value: 'SE' },
  { label: 'TO', value: 'TO' },
];

export const toursTypes = [
  { label: 'Agência de Viagem', value: 'Agência de Viagem' },
  { label: 'Parque Aquático', value: 'Parque Aquático' },
  { label: 'Passeios em Geral', value: 'Passeios em Geral' },
  { label: 'Trilha', value: 'Trilha' },
  { label: 'Turismo Náutico', value: 'Turismo Náutico' },
];

export const touristicExperiencesTypes = [
  { label: 'Aldeias Indígenas', value: 'Aldeias Indígenas' },
  { label: 'Aventura e Natureza', value: 'Aventura e Natureza' },
  { label: 'Esportivo', value: 'Esportivo' },
  { label: 'Eventos', value: 'Eventos' },
  { label: 'Gastronomia', value: 'Gastronomia' },
  { label: 'História e Cultura', value: 'História e Cultura' },
  { label: 'Náutico e Aquático', value: 'Náutico e Aquático' },
  { label: 'Religioso', value: 'Religioso' },
  { label: 'Rural', value: 'Rural' },
  { label: 'Sol e Praia', value: 'Sol e Praia' },
];

export const toursSeturInitialValues = {
  id: '',
  businessRepresentative: '',
  businessOwner: [''],
  description: '',
  phone: '',
  document: '',
  touristicExperiences: '',
  obs: '',
  facebookLink: '',
  experiences: [],
  googleMapsLink: '',
  instagramLink: '',
  status: '',
  tradeEmail: '',
  tradeName: '',
  youtubeLink: '',
  title: '',
  site: '',
  whatsapp: '',
  image: [],
  userId: 0,
  address_id: 0,
  createdAt: '',
  updatedAt: '',
  deletedAt: '',
  address: {
    id: 0,
    additionalData: '',
    city: '',
    neighborhood: '',
    number: '',
    state: '',
    street: '',
    zipCode: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
  },
  images: [],
  structures: [],
  user: '',
  tour: {
    id: '',
    attractions: '',
    equipments: '',
    businessId: 0,
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
  },
};

export const toursSeturValidationSchema = Yup.object().shape({
  id: Yup.number(),
  description: Yup.string().required(),
  document: Yup.string().length(18).required(),
  experiences: Yup.array().min(1).of(Yup.string()).required(),
  googleMapsLink: Yup.string().required(),
  tradeEmail: Yup.string()
    .email()
    .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números'),
  tradeName: Yup.string().required(),
  title: Yup.string().required(),
  phone: Yup.string()
    .required('Telefone é obrigatório')
    .test('is-valid-ddd', 'DDD inválido', function (value) {
      if (!value) return false;
      const ddd = value.match(/^\((\d{2})\)/);
      if (!ddd) return false;
      return validDDDs.includes(ddd[1]);
    })
    .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
      if (!value) return false;
      const digits = value.replace(/\D/g, '');
      return !/^(\d)\1+$/.test(digits);
    }),
  image: Yup.array().min(1).required(),
  address: Yup.object().shape({
    id: Yup.number(),
    city: Yup.string().required(),
    neighborhood: Yup.string()
      .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
      .required(),
    number: Yup.string().required(),
    state: Yup.string().required(),
    street: Yup.string()
      .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
      .required(),
    zipCode: Yup.string()
      .required()
      .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
      .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
        if (!value) return false;
        const cleanedValue = value.replace('-', '');
        return !/^(.)\1+$/.test(cleanedValue);
      }),
  }),
  images: Yup.array().min(1).required(),
  tour: Yup.object().shape({
    id: Yup.number(),
    attractions: Yup.string().required(),
    equipments: Yup.string(),
  }),
});
