import React from 'react';
import { RatingDataClass } from './styles';
import IframeComponent from 'components/IFrameComponent';

const RatingData = (): JSX.Element => {
  return (
    <>
      <RatingDataClass>
        <div className="LookerRatingData">
          <IframeComponent src="https://lookerstudio.google.com/embed/reporting/012ac0da-2075-49e4-ac70-7ece638f08f4/page/xiqvD/" />
        </div>
      </RatingDataClass>
    </>
  );
};

export default RatingData;
