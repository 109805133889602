import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridColumnVisibilityModel,
  GridToolbar,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import infoIcon from '../../../../assets/images/info.svg';
import editIcon from '../../../../assets/images/editIcon.svg';
import xGrayIcon from '../../../../assets/images/xgray_Icon.svg';
import checkGrayIcon from '../../../../assets/images/checkgray_Icon.svg';
import EditSolicitationModal from '../EditSolicitationModal';
import { BusinessStatusAPI, IBusiness, SegmentationAPI } from 'interfaces/IBusinesses';
import IEvent from 'interfaces/IEvent';
import touristOffersBusinessService from 'services/TouristOffersBusinessService';
import { useLoading } from 'hooks/useLoading';
import DeleteSolicitationModal from '../DeleteSolicitationModal';
import ApproveSolicitationModal from '../ApproveSolicitationModal';
import { useHistory } from 'react-router-dom';

const BusinessList = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [businessData, setBusinessData] = useState<IBusiness[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });
  const [openModalEditSolicitationData, setOpenModalEditSolicitationData] = useState(false);
  const [openModalDeleteSolicitationData, setOpenModalDeleteSolicitationData] = useState(false);
  const [openModalApproveSolicitationData, setOpenModalApproveSolicitationData] = useState(false);
  const [openModalMoreInfo, setOpenModalMoreInfo] = useState({} as IBusiness | IEvent);
  const [openModalDeleteInfo, setOpenModalDeleteInfo] = useState({} as IBusiness | IEvent);
  const [openModalApproveInfo, setOpenModalApproveInfo] = useState({} as IBusiness | IEvent);
  const history = useHistory();

  useEffect(() => {
    openLoading();
    const fetchBusinessData = [fetchTouristOffersBusiness()];
    Promise.all(fetchBusinessData).finally(() => closeLoading());
  }, []);

  const service = {
    touristOffersBusiness: new touristOffersBusinessService(),
  };

  const fetchTouristOffersBusiness = async () => {
    const data = await service.touristOffersBusiness.findAllTrades();
    setBusinessData(data);
  };

  const stylingRowStatus = (status?: BusinessStatusAPI) => {
    switch (status) {
      case BusinessStatusAPI.APPROVED:
        return <span className="approvedBadge">Aprovado</span>;
      case BusinessStatusAPI.REFUSED:
        return <span className="reprovedBadge">Recusado</span>;
      case BusinessStatusAPI.WAITING_FOR_SETUR:
        return <span className="waitingSeturBadge">Aguardando SETUR</span>;
      case BusinessStatusAPI.WAITING_FOR_TRADE:
        return <span className="waitingTradeBadge">Aguardando Trade</span>;
      default:
        return '- - -';
    }
  };

  const stylingSegmentation = (segmentation?: SegmentationAPI) => {
    switch (segmentation) {
      case SegmentationAPI.Acommodations:
        return 'Hospedagem';
      case SegmentationAPI.Restaurants:
        return 'Restaurantes (Alimentos e Bebidas)';
      case SegmentationAPI.Transports:
        return 'Transportes';
      case SegmentationAPI.Tours:
        return 'Passeio e Lazer';
      default:
        return '- - -';
    }
  };

  const handleBusinessDisabledButton = (id: string) => {
    const business = businessData.find(business => business.id === Number(id));
    if (business?.status !== BusinessStatusAPI.WAITING_FOR_SETUR) {
      return true;
    }
    return false;
  };

  const businessEditSolicitation = (id: string) => () => {
    openLoading();
    const businessItem = businessData.find(business => business.id === Number(id));
    if (businessItem) {
      setOpenModalMoreInfo(businessItem);
    }
    setOpenModalEditSolicitationData(true);
    closeLoading();
  };

  const businessDeleteSolicitation = (id: string) => () => {
    openLoading();
    const businessItem = businessData.find(business => business.id === Number(id));
    if (businessItem) {
      setOpenModalDeleteInfo(businessItem);
    }
    setOpenModalDeleteSolicitationData(true);
    closeLoading();
  };

  const businessApproveSolicitation = (id: string) => () => {
    openLoading();
    const businessItem = businessData.find(business => business.id === Number(id));
    if (businessItem) {
      setOpenModalApproveInfo(businessItem);
    }
    setOpenModalApproveSolicitationData(true);
    closeLoading();
  };

  const formatCreatedAtDate = (createdAt: string): JSX.Element => {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleDateString('pt-BR');
    const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

    return (
      <div className="createdDate">
        <span>{formattedDate}</span>
        <span>{formattedTime}</span>
      </div>
    );
  };

  const businessColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Criação',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      renderCell: (params: GridRenderCellParams<IBusiness, string, keyof IBusiness, GridTreeNodeWithRender>) =>
        formatCreatedAtDate(params.value as string),
    },
    {
      field: 'businessOwner',
      headerName: 'Nome do Trade',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'title',
      headerName: 'Nome do Negócio',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'type',
      headerName: 'Segmento',
      flex: 1,
      minWidth: 220,
      renderCell: (params: GridRenderCellParams<IBusiness, SegmentationAPI, keyof IBusiness, GridTreeNodeWithRender>) =>
        stylingSegmentation(params.value),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 222,
      renderCell: (
        params: GridRenderCellParams<IBusiness, BusinessStatusAPI, keyof IBusiness, GridTreeNodeWithRender>
      ) => stylingRowStatus(params.value),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 285,
      getActions: params => [
        <div key={params.id}>
          <button
            className="button-icon"
            title="Mais informações"
            onClick={() => history.push(`/admin/business-list-overview/id=${params.id}`)}>
            <img src={infoIcon} alt="Mais informações" />
          </button>
          <button
            className="button-icon"
            title="Editar"
            onClick={businessEditSolicitation(params.id.toString())}
            disabled={handleBusinessDisabledButton(params.id.toString())}>
            <img src={editIcon} alt="Editar" />
          </button>
          <button
            className="button-icon"
            title="Excluir"
            onClick={businessDeleteSolicitation(params.id.toString())}
            disabled={handleBusinessDisabledButton(params.id.toString())}>
            <img src={xGrayIcon} alt="Excluir" />
          </button>
          <button
            className="button-icon"
            title="Confirmar"
            onClick={businessApproveSolicitation(params.id.toString())}
            disabled={handleBusinessDisabledButton(params.id.toString())}>
            <img src={checkGrayIcon} alt="Confirmar" />
          </button>
        </div>,
      ],
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
  ];

  return (
    <Container>
      <Grid sx={{ width: '100%', marginBottom: '50px', textAlign: 'center' }}>
        <DataGrid
          sx={{
            background: '#fff',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
            borderRadius: '18px',
            width: '100%',
          }}
          rows={businessData}
          columns={businessColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          className="dataGrid"
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              densitySelector: false,
              exportButton: false,
            },
          }}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableVirtualization
        />
      </Grid>

      {openModalEditSolicitationData && (
        <EditSolicitationModal
          isOpen={openModalEditSolicitationData}
          toggle={() => setOpenModalEditSolicitationData(false)}
          item={openModalMoreInfo}
        />
      )}

      {openModalDeleteSolicitationData && (
        <DeleteSolicitationModal
          isOpen={openModalDeleteSolicitationData}
          toggle={() => setOpenModalDeleteSolicitationData(false)}
          item={openModalDeleteInfo}
        />
      )}

      {openModalApproveSolicitationData && (
        <ApproveSolicitationModal
          isOpen={openModalApproveSolicitationData}
          toggle={() => setOpenModalApproveSolicitationData(false)}
          item={openModalApproveInfo}
        />
      )}
    </Container>
  );
};

export default BusinessList;
