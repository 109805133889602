import styled from 'styled-components';

export const HomePageDiv = styled.div``;

export const Square = styled.div`
  width: 50px;
  height: 50px;
  background-color: red;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #000;
  }
`;

export const Container = styled.div`
  background-image: url(Mapa);
  aspect-ratio: 2.34 / 1;
  height: 580;
  display: flex;
  flex-direction: column;
`;
