import styled from 'styled-components';
import loginTouristBg from 'assets/images/background_login_tourist.svg';

export const Container = styled.div`
  min-width: 68.8vw;
  min-height: 100vh;
  width: 100%;
  height: fit-content;
  background: url(${loginTouristBg}) no-repeat;
  background-size: cover;
`;

export const Content = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #0d4a9b80;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const SelectArea = styled.div`
  background-color: #f9f9f9;
  border-radius: 18px;
  padding: 30px;
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    width: 90%;

    h2 {
      text-align: center;
    }
  }
`;

export const Divider = styled.div`
  display: block;
  background-color: #9a9a9a;
  width: 1px;

  @media (max-width: 960px) {
    width: 100%;
    height: 1px;
    margin: 30px 0;
  }
`;

export const ButtonLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: break-spaces;
  button {
    width: 200px;
  }
  label {
    margin-top: 10px;
    width: 200px;
    text-align: center;
    font-size: 14px;
  }

  @media (max-width: 960px) {
    button,
    label {
      width: 100%;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
