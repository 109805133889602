import styled from 'styled-components';
import check from 'assets/images/check.svg';

export const Container = styled.div`
  width: fit-content;
  height: auto;
  border-radius: 18px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 30px;
  white-space: nowrap;
  h2 {
    width: 100%;
    color: #272727;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  label {
    display: flex;
    width: 100%;
    margin-top: 20px;
    span {
      color: #ec3237;
      margin-left: 0.2vw;
    }
  }
  input[type='text'],
  input[type='password'] {
    width: 100%;
    height: 40px;
    border: 0.5px solid #9a9a9a;
    border-radius: 18px;
    padding: 1vw;
    margin-right: 0;
  }
  div {
    display: flex;
    align-items: center;
    width: 100%;
    label {
      flex-direction: row;
      align-items: center;
      margin: 0 10px 0 0;
      font-size: 14px;
    }
    input {
      width: 36px;
      height: 16px;
      margin-right: 10px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid black;
      border-radius: 3px;
      cursor: pointer;
    }
    input[type='checkbox']:checked {
      background: url(${check}) center no-repeat;
      border: none;
    }
    a {
      font-size: 14px;
      color: #272727;
      font-weight: 700;
      &:hover {
        color: #020c90;
      }
    }
    span {
      margin-right: 4vw;
    }
    button {
      right: 10px;
      margin-right: 0;
      margin-bottom: 0;
      width: auto;
      top: 15px;
    }
  }
  div:last-child {
    margin: 0;
  }
  button {
    width: 100%;
    margin-bottom: 50px;
  }

  .checkboxLogin {
    margin: 20px 0;
  }

  @media (max-width: 940px) {
    width: 90%;
    padding: 10px;

    h2 {
      font-size: 18px;
    }

    button {
      margin-bottom: 32px;
    }
  }
`;
