import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;

  .prev-button,
  .next-button {
    position: relative;
    padding: 0px 12px;
    font-size: 35px;
    color: #fff;
    background-color: #ffc629;
    border: none;
    border-radius: 50%;
    z-index: 1;

    &:hover {
      background-color: #020c90;
    }

    @media all and (max-width: 980px) {
      display: none;
    }
  }

  .prev-button {
    left: 20px;
  }

  .next-button {
    right: 20px;
  }

  .disabled {
    visibility: hidden;
  }

  .enabled {
    visibility: visible;
  }
`;

export const List = styled.ul`
  width: 100%;
  display: grid;
  overflow-x: scroll;
  padding: 16px 5px;
  justify-content: unset;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, 23%);
  grid-auto-flow: column;
  grid-auto-columns: 23%;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  @media all and (max-width: 980px) {
    width: 100%;
    display: grid;
    overflow-x: scroll;
    padding: 16px;
    justify-content: unset;
    margin: 50px 0 0 0;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, 294px);
    grid-auto-flow: column;
    grid-auto-columns: 294px;
  }
`;

export const Card = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 400px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &:hover {
    outline: 5px solid #ffcb29;
  }
`;

export const ImageTitle = styled.h2`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 0 0 20px 20px;
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  background: linear-gradient(0deg, rgba(13, 74, 155, 0.5) 0%, rgba(13, 74, 155, 0) 50%);
  border-radius: 18px;
`;
