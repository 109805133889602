import React from 'react';
import { Redirect } from 'react-router';

const AccountManagement = (): JSX.Element => {
  const searchParams = new URLSearchParams(window.location.search);
  const mode = searchParams.get('mode');
  const code = searchParams.get('oobCode');

  switch (mode) {
    case 'resetPassword':
      return <Redirect to={{ pathname: `/password-reset/${code}` }} />;
    case 'verifyEmail':
      return <Redirect to={{ pathname: `/email-confirmation/${code}` }} />;
    default:
      return <Redirect to={{ pathname: '/' }} />;
  }
};

export default AccountManagement;
