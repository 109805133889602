import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import Button from '../../components/Buttom/Button';
import IExperience from 'interfaces/IExperience';
import SubTitle from 'common/SubTitle/SubTitle';

interface Props {
  experiences?: IExperience[];
}

const Tourism = (props: Props): JSX.Element => {
  const { experiences } = props;

  return (
    <Container>
      <SubTitle text="Experiências Turísticas" />
      <ul>
        {experiences?.slice(0, 10).map(experience => {
          return (
            <li key={experience.id}>
              <img src={experience.image} alt={experience.title} />
              <span>{experience.title}</span>
            </li>
          );
        })}
      </ul>
      <Link to="/what-to-do/activities">
        <Button text="VER TODAS AS EXPERIÊNCIAS" />
      </Link>
    </Container>
  );
};

export default Tourism;
