import styled from 'styled-components';
import check from 'assets/images/check.svg';

export const SelectArea = styled.div`
  label {
    margin-bottom: 5px;
    display: block;
  }
  .CustomSelect {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .SelectedOption {
    height: 40px;
    padding: 10px 30px 10px 10px;
    cursor: pointer;
    background: #fff;
    border: 1px solid #9a9a9a;
    border-radius: 18px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;

    &.disabled {
      cursor: not-allowed;
      background: #f2f2f2;
      color: #f2f2f2;
      border: 1px solid #9a9a9a;
    }

    p {
      display: block;
      height: 20px;
      overflow: hidden;
    }

    span {
      overflow: hidden;
      display: block;
      height: 100%;
    }
    img {
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }
  .OptionList {
    display: none;
    position: absolute;
    max-height: 200px;
    overflow: auto;
    list-style: none;
    padding: 0 10px;
    margin: 0;
    top: 40px;
    width: 100%;
    height: fit-content;
    overflow-y: scroll;
    background: #fff;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    &::-webkit-scrollbar {
      margin-right: 500px;
      width: 5px;
      height: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #9a9a9a;
      border-radius: 5px;
    }

    @media (max-width: 980px) {
      overflow: auto;
    }
  }
  .OptionList li {
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px dashed #9a9a9a;
    display: flex;
    flex-direction: row;

    input[type='checkbox'] {
      width: auto;
      height: 17px;
      margin-right: 10px;
      margin-top: 1px;
      border-radius: 5px;
      padding: 0 8px;
    }

    input[type='checkbox']:checked {
      background: url(${check}) center no-repeat;
      border: none;
      width: 17px;
    }

    &:last-child {
      border: none;
    }
    &:hover {
      color: #020c90;
    }
  }
  .CustomSelect.open .OptionList {
    display: block;
  }
`;
