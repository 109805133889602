import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import eyeIcon from 'assets/images/eyeGrayIcon.svg';
import editIcon from '../../../../assets/images/editIcon.svg';
import trashIcon from '../../../../assets/images/trashIcon.svg';
import comentsIcon from '../../../../assets/images/coments.svg';
import IEvent from 'interfaces/IEvent';
import { BusinessStatusAPI, IBusiness, SegmentationAPI } from 'interfaces/IBusinesses';
import { IImproviments } from 'interfaces/IImproviments';
import touristOffersBusinessService from 'services/TouristOffersBusinessService';
import { useLoading } from 'hooks/useLoading';
import DeleteSolicitationModal from '../DeleteSolicitationModal';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import Heading from 'components/Heading';
import MessageModal from 'common/MessageModal';

const BusinessList = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [businessData, setBusinessData] = useState<IBusiness[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });
  const [openModalDeleteSolicitationData, setOpenModalDeleteSolicitationData] = useState(false);
  const [openModalDeleteInfo, setOpenModalDeleteInfo] = useState({} as IBusiness | IEvent);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [selectedBusinessComments, setSelectedBusinessComments] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    openLoading();
    const fetchBusinessData = [fetchTouristOffersBusiness()];
    Promise.all(fetchBusinessData).finally(() => closeLoading());
  }, []);

  const service = {
    touristOffersBusiness: new touristOffersBusinessService(),
  };

  const fetchTouristOffersBusiness = async () => {
    const data = await service.touristOffersBusiness.findAll();
    setBusinessData(data);
  };

  const stylingRowStatus = (status?: BusinessStatusAPI) => {
    switch (status) {
      case BusinessStatusAPI.APPROVED:
        return <span className="approvedBadge">Aprovado</span>;
      case BusinessStatusAPI.REFUSED:
        return <span className="reprovedBadge">Recusado</span>;
      case BusinessStatusAPI.WAITING_FOR_SETUR:
        return <span className="waitingSeturBadge">Aguardando SETUR</span>;
      case BusinessStatusAPI.WAITING_FOR_TRADE:
        return <span className="pendingAdjustment">Ajuste Pendente</span>;
      default:
        return '- - -';
    }
  };

  const stylingSegmentation = (segmentation?: SegmentationAPI) => {
    switch (segmentation) {
      case SegmentationAPI.Acommodations:
        return 'Hospedagem';
      case SegmentationAPI.Restaurants:
        return 'Restaurantes (Alimentos e Bebidas)';
      case SegmentationAPI.Transports:
        return 'Transportes';
      case SegmentationAPI.Tours:
        return 'Passeios e Lazer';
      default:
        return '- - -';
    }
  };

  const businessDeleteSolicitation = (id: string) => () => {
    openLoading();
    const businessItem = businessData.find(business => business.id === Number(id));
    if (businessItem) {
      setOpenModalDeleteInfo(businessItem);
    }
    setOpenModalDeleteSolicitationData(true);
    closeLoading();
  };

  const handleBusinessDisabledButton = (id: string) => {
    const business = businessData.find(business => business.id === Number(id));
    if (business?.status !== BusinessStatusAPI.WAITING_FOR_TRADE && business?.status !== BusinessStatusAPI.APPROVED) {
      return true;
    }
    return false;
  };

  const handleBusinessDisabledDeleteButton = (id: string) => {
    const business = businessData.find(business => business.id === Number(id));
    if (business?.status === BusinessStatusAPI.REFUSED) {
      return true;
    }
    return false;
  };

  const businessColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Criação',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      valueFormatter: ({ value }) => {
        const date = new Date(value as string);
        const formattedDate = date.toLocaleDateString('pt-BR');
        const formattedTime = date.toLocaleTimeString('pt-BR').substring(0, 5);
        return `${formattedDate} - ${formattedTime}`.replace(',', '');
      },
    },
    {
      field: 'tradeName',
      headerName: 'Nome do Negócio',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'title',
      headerName: 'Título da Atividade',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'type',
      headerName: 'Segmento',
      flex: 1,
      minWidth: 220,
      renderCell: (params: GridRenderCellParams<IBusiness, SegmentationAPI, keyof IBusiness, GridTreeNodeWithRender>) =>
        stylingSegmentation(params.value),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 222,
      renderCell: (
        params: GridRenderCellParams<IBusiness, BusinessStatusAPI, keyof IBusiness, GridTreeNodeWithRender>
      ) => stylingRowStatus(params.value),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 285,
      getActions: params => [
        <div key={params.id}>
          <button
            className="button-icon"
            title="Preview"
            onClick={() => {
              const page = params?.row?.type === SegmentationAPI.Acommodations ? 'accommodation' : 'activity';
              history.push(`/admin/${page}/${params.id}/preview`);
            }}>
            <img src={eyeIcon} alt="Preview" />
          </button>
          <button
            className="button-icon"
            title="Editar"
            onClick={() => {
              history.push(`/admin/trade/my-business-edit/${params.id}`);
            }}
            disabled={handleBusinessDisabledButton(params.id.toString())}>
            <img src={editIcon} alt="Editar" />
          </button>
          <button
            className="button-icon"
            title="Solicitação de Edição"
            onClick={() => {
              const improvements: IImproviments[] = params.row.improvements;
              const [latestImprovement] = improvements?.sort((a, b) => b.createdAt.localeCompare(a.createdAt)) || [];
              if (latestImprovement) {
                setSelectedBusinessComments(latestImprovement.description);
              } else {
                setSelectedBusinessComments('Nenhuma melhoria encontrada para o negócio.');
              }
              setOpenMessageModal(true);
            }}>
            <img src={comentsIcon} alt="Solicitação de Edição" />
          </button>
          <button
            className="button-icon"
            title="Excluir"
            disabled={handleBusinessDisabledDeleteButton(params.id.toString())}
            onClick={businessDeleteSolicitation(params.id.toString())}>
            <img src={trashIcon} alt="Excluir" />
          </button>
        </div>,
      ],
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
  ];

  return (
    <Container>
      <div className="header">
        <Heading>Meus Negócios</Heading>
        <Button type="submit" buttonType="primary" onClick={() => history.push('/admin/trade/my-business-new')}>
          Novo Negócio
        </Button>
      </div>

      <Grid sx={{ width: '100%', marginBottom: '50px', textAlign: 'center' }}>
        {businessData.length === 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0',
              width: '100%',
              height: '50px',
              color: '#aaa',
              background: '#f1f1f1',
              borderRadius: '18px',
            }}>
            {' '}
            Cadastre seu primeiro negócio
          </div>
        ) : (
          <DataGrid
            sx={{
              background: '#fff',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
              borderRadius: '18px',
              width: '100%',
            }}
            rows={businessData}
            columns={businessColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            className="dataGrid"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                densitySelector: false,
                exportButton: false,
              },
            }}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableDensitySelector
            disableVirtualization
          />
        )}
      </Grid>

      {openModalDeleteSolicitationData && (
        <DeleteSolicitationModal
          isOpen={openModalDeleteSolicitationData}
          toggle={() => setOpenModalDeleteSolicitationData(false)}
          item={openModalDeleteInfo}
          reloadBusinessData={fetchTouristOffersBusiness}
        />
      )}

      <MessageModal
        isOpen={openMessageModal}
        title="Solicitação de Edição"
        message={selectedBusinessComments}
        onClose={() => {
          setSelectedBusinessComments('');
          setOpenMessageModal(false);
        }}
      />
    </Container>
  );
};

export default BusinessList;
