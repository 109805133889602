import React from 'react';
import { OverviewClass } from './styles';
import IframeComponent from 'components/IFrameComponent';

const Overview = (): JSX.Element => {
  return (
    <>
      <OverviewClass>
        <div className="LookerOverview">
          <IframeComponent src="https://lookerstudio.google.com/embed/reporting/ae414286-be09-4cbb-8fa0-d100fd4f1beb/page/yLEsD/" />
        </div>
        <div className="LongTimeView">
          <IframeComponent src="/TrendsGoogleScripts/LongTime.html" />
        </div>
        <div className="DetailingView">
          <IframeComponent src="/TrendsGoogleScripts/Detailing.html" />
        </div>
      </OverviewClass>
    </>
  );
};

export default Overview;
