import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: 'Valor inválido',
    required: 'Campo obrigatório',
  },
  string: {
    email: 'E-mail inválido',
    length: 'Preencher exatamente ${length} caracteres',
    min: 'Preencher ao menos ${min} caracteres',
  },
  array: {
    min: 'Adicionar ao menos ${min} item',
  },
});

export default yup;
