import styled from 'styled-components';

export const Container = styled.div`
  width: 50vw;
  height: fit-content;
  border: 1px solid #9a9a9a;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  color: #272727;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vh;
    h2,
    span {
      font-size: 20px;
      font-weight: 500;
    }
  }
  label {
    font-size: 16px;
    font-weight: 500;
    span {
      color: #ec3237;
    }
  }
  .dropdown {
    margin-top: 1vh;
    width: 100%;
    height: 40px;
    margin-bottom: 2.5vh;
    padding: 0 0.5vw;
    border-radius: 18px;
    div {
      margin-top: 5vh;
    }
  }
  button {
    margin: 0 0 0 auto;
  }

  @media (max-width: 960px) {
    width: 90%;
    padding: 16px;
    margin: 0 auto;
  }
`;

export const InputField = styled.div`
  div {
    display: flex;
    flex-direction: column;
    label {
      white-space: nowrap;

      span {
        color: #ec3237;
        margin-left: 0.2vw;
      }
    }
    input {
      padding: 0 0.5vw;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  gap: 30px;

  > div:first-child {
    display: block;
    width: 100%;

    .NameField input:not([type='checkbox']) {
      width: 100%;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const SignUpForm = styled.div`
  width: auto;
  border: 1px solid #9a9a9a;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  height: fit-content;
  h2 {
    margin-bottom: 1vw;
  }
  h2,
  span {
    font-size: 20px;
    font-weight: 500;
  }
  input {
    &:not([type='checkbox']) {
      width: 23vw;
      border: 1px solid #9a9a9a;
      border-radius: 18px;
      height: 40px;
      margin-top: 1vh;

      @media (max-width: 960px) {
        width: 100%;
      }
    }
  }
  header {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 960px) {
    width: 90%;
    padding: 16px;
  }
`;
export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted #9a9a9a;
  margin: 20px 0;
`;

export const LabelCheck = styled.div`
  display: flex;
  white-space: nowrap;
  margin: 0;
  label {
    margin-left: 1vw;
    white-space: break-spaces;
    a {
      color: black;
      margin-left: 5px;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  button {
    &:nth-child(2) {
      background-color: #fff;
      color: var(--ps-amarelo-mdio-ffcb-29, #ffcb29);
      border: 1px solid #ffcb29;
      box-shadow: 0px 0px 10px 0px #00000026;
      margin-right: 1.5vw;
      margin-left: auto;
      &:hover {
        background-color: #020c90;
        border: 1px solid #020c90;
        color: white;
      }
    }
  }
`;
