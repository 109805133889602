import styled from 'styled-components';

export const Activity = styled.div``;

export const ImageContent = styled.div`
  width: 100%;
  height: 222px;
  border-radius: 18px 18px 0 0;
`;

export const Image = styled.img`
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  height: 100%;
  width: 100%;
`;

export const InfoContent = styled.div`
  padding: 6px 12px;
`;

export const ActionDiv = styled.div`
  float: right;
`;

export const Info = styled.div`
  padding: 6px 0px;
`;

export const Category = styled.span`
  background: #020c90;
  border-radius: 15px;
  text-transform: uppercase;
  color: #ffff;
  display: inline-block;
  padding: 5px 20px;
`;

export const Date = styled.p`
  color: #ffcb29;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
