import styled from 'styled-components';

export const SideMenu = styled.div`
  width: 70%;
  height: 100%;
  position: fixed;
  background-color: #ffffff;
  max-width: 0;
  transition: max-width 0.5s ease-out;
  overflow: hidden;
  padding: 70px 0 0 0;
  z-index: 999999;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  nav {
    display: block;
    box-shadow: none;
    padding: 0;
    ul {
      display: block;
      a {
        padding: 0px 30px;
        &:hover {
          background-color: var(--gray);
        }
        img {
          vertical-align: text-bottom;
        }
      }
    }
  }
`;

export const HambLine = styled.label`
  cursor: pointer;
  position: absolute;
  padding: 40px 10px;
  z-index: 9999999;

  span {
    background: #9a9a9a;
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
    top: 10px;

    &::before,
    &::after {
      background: #9a9a9a;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;
    }
    &::before {
      top: 5px;
    }
    &::after {
      top: -5px;
    }
  }
`;

export const Back = styled.div`
  transition: background-color 0.5s ease-in;
`;

export const Container = styled.div`
  input {
    display: none;
    &:checked ~ ${SideMenu} {
      max-width: 100%;
    }
    &:not(:checked) ~ ${Back} {
      background-color: rgba(0, 0, 0, 0);
    }
    &:checked ~ ${Back} {
      background-color: rgba(0, 0, 0, 0.2);
      position: fixed;
      width: 100%;
      height: 100%;
    }
    &:checked ~ ${HambLine} span {
      background: transparent;
    }
    &:checked ~ ${HambLine} span::before {
      transform: rotate(-45deg);
      top: 0;
    }
    &:checked ~ ${HambLine} span::after {
      transform: rotate(45deg);
      top: 0;
    }
  }
  /* Responsiveness */
  @media (min-width: 980px) {
    ${HambLine} {
      display: none;
    }
  }
`;
