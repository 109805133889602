import {
  MainContentForm,
  MainContentFormCardImage,
  MainContentFormImageList,
  MainContentFormImageListItem,
  MainContentFormItem,
} from '../../styles';
import { TouristOffersEventsOverviewListProps } from '../..';
import React from 'react';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const EventsForm = ({ disabled, item }: TouristOffersEventsOverviewListProps): JSX.Element => {
  return (
    <MainContentForm>
      <MainContentFormItem style={{ gridColumn: 'span 2' }}>
        <label htmlFor="owner-name">Nome Completo do Responsável Comercial</label>
        <input id="owner-name" type="text" placeholder={item?.user?.name} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem style={{ gridColumn: 'span 2' }}>
        <label htmlFor="establishment-name">Nome do Estabelecimento</label>
        <input id="establishment-name" type="text" placeholder={item?.place} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem style={{ gridColumn: 'span 2' }}>
        <label htmlFor="event-name">Nome do Evento</label>
        <input id="event-name" type="text" placeholder={item?.title} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="category">Categoria</label>
        <input id="category" type="text" placeholder={item?.category} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="event-value">Valor do Evento</label>
        <input id="event-value" type="text" placeholder={item?.price} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem style={{ gridColumn: 'span 4' }}>
        <label htmlFor="description">Descrição</label>
        <input id="description" type="text" placeholder={item?.description} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="initial-date">Data de início</label>
        <input
          id="initial-date"
          type="text"
          placeholder={item?.startDate ? formatDate(item.startDate) : ''}
          disabled={disabled}
        />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="final-date">Data de fim</label>
        <input
          id="final-date"
          type="text"
          placeholder={item?.endDate ? formatDate(item.endDate) : ''}
          disabled={disabled}
        />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="start-time">Horário de início</label>
        <input id="start-time" type="text" placeholder={item?.startTime} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="end-time">Horário de fim</label>
        <input id="end-time" type="text" placeholder={item?.endTime} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="cep">CEP</label>
        <input id="cep" type="text" placeholder={item?.addressInfo?.zipCode} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem style={{ gridColumn: 'span 2' }}>
        <label htmlFor="address">Endereço</label>
        <input id="address" type="text" placeholder={item?.address} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="number">Número</label>
        <input id="number" type="text" placeholder={item?.addressInfo?.number} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="complement">Complemento</label>
        <input id="complement" type="text" placeholder={item?.addressInfo?.additionalData} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="neighborhood">Bairro</label>
        <input id="neighborhood" type="text" placeholder={item?.addressInfo?.neighborhood} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="city">Cidade</label>
        <input id="city" type="text" placeholder={item?.addressInfo?.city} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="state">Estado</label>
        <input id="state" type="text" placeholder={item?.addressInfo?.state} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="google-maps-id">Place ID do Google Maps</label>
        <input id="google-maps-id" type="text" placeholder={item?.placeId} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="telephone">Telefone</label>
        <input id="telephone" type="text" placeholder={item?.phone} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="whatsapp">WhatsApp</label>
        <input id="whatsapp" type="text" placeholder={item?.whatsapp} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="business-email">E-mail Comercial</label>
        <input id="business-email" type="text" placeholder={item?.email} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="youtube-video-link">Link de Vídeo no YouTube</label>
        <input id="youtube-video-link" type="text" placeholder={item?.youtubeLink} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="website-link">Link do Site da Organização</label>
        <input id="website-link" type="text" placeholder={item?.site} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="instagram-link">Link do Instagram</label>
        <input id="instagram-link" type="text" placeholder={item?.instagramLink} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem>
        <label htmlFor="facebook-link">Link do Facebook</label>
        <input id="facebook-link" type="text" placeholder={item?.facebookLink} disabled={disabled} />
      </MainContentFormItem>
      <MainContentFormItem style={{ gridColumn: 'span 4' }}>
        <label htmlFor="card-image">Imagem do Card</label>
        <MainContentFormCardImage
          style={{
            background: `url(${item?.image}) center center / cover no-repeat`,
          }}
          id="card-image"
        />
      </MainContentFormItem>
      <MainContentFormItem style={{ gridColumn: 'span 4' }}>
        <label htmlFor="images">Imagens</label>
        <MainContentFormImageList>
          {item?.images &&
            item.images.map((image, index) => (
              <MainContentFormImageListItem
                key={index}
                style={{
                  background: `url(${image.image}) center center / cover no-repeat`,
                }}
              />
            ))}
        </MainContentFormImageList>
      </MainContentFormItem>
    </MainContentForm>
  );
};

export default EventsForm;
