import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useLoading } from 'hooks/useLoading';
import yup from 'utils/validation';
import IDestination from 'interfaces/IDestination';
import DestinationService from 'services/DestinationService';
import MainTitle from 'components/MainTitle';
import Heading from 'components/Heading';
import ErrorMessage from 'components/ErrorMessage';
import Button from 'components/Button';
import UploadImage from 'components/UploadImage/field';
import { Container } from './styles';
import ImageService from 'services/ImageService';
import MessageModal from 'common/MessageModal';
import TextArea from 'components/TextArea';

const Destination = (): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { openLoading, closeLoading } = useLoading();
  const [destination, setDestination] = useState<IDestination | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });
  const [formValues, setFormValues] = useState({
    title: '',
    description: '',
    image: [''],
    characteristics: '',
    banners: [''],
  });
  const service = new DestinationService();
  const imageService = new ImageService();

  useEffect(() => {
    openLoading();
    fetchDestination().finally(() => closeLoading());
  }, []);

  const fetchDestination = async () => {
    const destinationId = Number(id);

    if (Number.isNaN(destinationId)) {
      return;
    }

    const data = await service.findOne(destinationId);
    setDestination(data);

    setFormValues({
      description: data?.banners?.[0]?.description ?? '',
      title: data.title,
      image: [data.image],
      characteristics: data?.characteristics.join('\n'),
      banners: data.banners?.map(banner => banner.image) ?? [],
    });
  };

  const validationSchema = yup.object({
    title: yup.string().required(),
    description: yup.string().required(),
    characteristics: yup.string().required(),
    image: yup.array().min(1).required(),
    banners: yup.array().min(1).required(),
  });

  const onSubmit = async (values: any) => {
    if (!destination?.id) return;
    openLoading();

    try {
      const [banners, images] = await Promise.all([
        imageService.uploadMultiple(values.banners),
        imageService.uploadMultiple(values.image),
      ]);

      await service.update(destination?.id, {
        title: values.title,
        image: images[0],
        link: '',
        characteristics: values.characteristics.split('\n'),
        banners: banners?.map((banner: string) => ({
          id: 0,
          description: values.description,
          image: banner,
        })),
      });

      setShowModal(true);
      const messageWithSpan = 'O destino foi <span>editado</span> com sucesso.';
      setMessageInfo({
        title: 'Sucesso',
        message: messageWithSpan,
      });
    } catch (error) {
      setShowModal(true);
      const messageWithSpan = 'Erro ao <span>editar</span> destino.';
      setMessageInfo({
        title: 'Erro',
        message: messageWithSpan,
      });
    }

    closeLoading();
  };

  const closeModal = () => {
    setShowModal(false);

    if (messageInfo.title === 'Sucesso') {
      history.push('/admin/config');
    }
  };

  return (
    <Container>
      <MainTitle>Configurações</MainTitle>
      <div style={{ padding: '0 50px' }}>
        <Heading>Editar Destino</Heading>
        <Formik initialValues={formValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
          <Form>
            <Box>
              <Grid container rowSpacing={1}>
                <Grid item xs={12} mb={3} className="form-group">
                  <label>Nome do Destino</label>
                  <Field name="title" />
                  <ErrorMessage name="title" />
                </Grid>
                <Grid item xs={12} mb={3} className="form-group">
                  <TextArea name="description" maxLength={470} title="Descrição do Carrossel" />
                  <ErrorMessage name="description" />
                </Grid>
                <Grid item xs={12} mb={3} className="form-group">
                  <UploadImage
                    name="banners"
                    descriptionText="Imagens do Carrossel: Para garantir a qualidade da visualização, as imagens precisam ter a dimensão mínima de 1366 x 600 pixels (a ordem das imagens será a mesma mostrada abaixo)"
                    multipleFiles={true}
                    htmlFor="destination-banners"
                    limitFiles={8}
                  />
                  <ErrorMessage name="banners" />
                </Grid>
                <Grid item xs={12} mb={3} className="form-group">
                  <TextArea name="characteristics" maxLength={250} title="Descrição do card" />
                  <ErrorMessage name="characteristics" />
                </Grid>
                <Grid item xs={12} mb={3} className="form-group">
                  <UploadImage
                    name="image"
                    descriptionText="Imagem do Card: Adicione 1 imagem. Para garantir a qualidade da visualização, a imagem precisa ter a dimensão mínima de 1366 x 600 pixels"
                    multipleFiles={false}
                    htmlFor="destination-image"
                    limitFiles={1}
                  />
                  <ErrorMessage name="image" />
                </Grid>
                <Grid item xs={12} mb={3} className="form-group">
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center" columnSpacing={4}>
                    <Grid item>
                      <Button buttonType="secondary" type="button" onClick={() => history.goBack()}>
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button type="submit">Salvar</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Form>
        </Formik>
      </div>
      <MessageModal isOpen={showModal} title={messageInfo.title} message={messageInfo.message} onClose={closeModal} />
    </Container>
  );
};

export default Destination;
