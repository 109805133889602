import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  min-height: 56vh;
  width: 100%;
  margin: 5vh 0 10vh 0;
  header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: #020c90;
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 2vh;
    div {
      height: 5px;
      width: 100%;
      &:first-child {
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0%, #00a759),
          color-stop(80%, #00a759),
          color-stop(80%, #c63336),
          color-stop(100%, #c63336)
        );
        margin-right: 5px;
      }
      &:last-child {
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0%, #c63336),
          color-stop(20%, #c63336),
          color-stop(20%, #ffcb29),
          color-stop(100%, #ffcb29)
        );
        margin-left: 5px;
      }
    }
  }
`;

export const RenderedForm = styled.div`
  padding: 0 50px;

  @media (max-width: 960px) {
    padding: 0 10px;
  }
`;

export const DeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  margin-top: 40vh;
  margin-left: 35vw;
  width: fit-content;
  padding: 30px;
  border-radius: 18px;
  gap: 20px;
  h2 {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
  }
  span {
    font-size: 20px;
    font-weight: 400;
    width: 30vw;
    text-align: center;
  }
  div {
    display: flex;
    button {
      white-space: nowrap;
      width: fit-content;
      padding: 0 10px;
      &:first-child {
        background-color: transparent;
        border: 1px solid #ffcb29;
        color: #ffcb29;
        font-size: 16px;
        font-weight: 700;
        margin-right: 30px;
      }
    }
  }
`;

export const AskForEditDataModal = styled(DeleteModal)`
  margin-left: 30vw;
  span {
    width: 40vw;
  }
`;
