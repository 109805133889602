import styled from 'styled-components';

export const Container = styled.div`
  margin: 50px 0px;
  @media (max-width: 768px) {
    margin: 32px 0px 16px;
  }
  div {
    display: flex;
    height: 5px;
    width: 100%;
    div {
      &:nth-child(1) {
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0%, #00a759),
          color-stop(90%, #00a759),
          color-stop(90%, #c63336),
          color-stop(100%, #c63336)
        );
      }
      &:nth-child(3) {
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0%, #c63336),
          color-stop(10%, #c63336),
          color-stop(10%, #ffcb29),
          color-stop(100%, #ffcb29)
        );
      }
    }
    span {
      color: #020c90;
      font-weight: 700;
      font-size: 33px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin: 0 10px;
      @media (max-width: 768px) {
        font-size: 20px;
        &.long {
          @media (max-width: 768px) {
            white-space: inherit;
            text-align: center;
            margin: 16px 0px 16px;
          }
        }
      }
    }
  }
`;
