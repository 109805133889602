import React from 'react';
import { Container, HeaderTitle } from './styles';
import MainTitle from 'components/MainTitle';
import EventsList from './EventsList';

const EventsSchedule = (): JSX.Element => {
  return (
    <>
      <HeaderTitle>
        <MainTitle>Agenda</MainTitle>
      </HeaderTitle>
      <Container>
        <EventsList />
      </Container>
    </>
  );
};

export default EventsSchedule;
