import React, { useEffect, useState } from 'react';
import {
  CloseImageButton,
  ErrorMessage,
  Input,
  InputLabel,
  UploadImageContainer,
  UploadImageHeader,
  UploadImageList,
  UploadImageListItem,
  UploadImageTitle,
} from './styles';

interface IImage {
  id: number;
  name: string;
  url: string;
}

export interface UploadImageProps {
  descriptionText?: string;
  multipleFiles: boolean;
  importedFiles?: IImage[];
  limitFiles?: number;
  htmlFor?: string;
  onChange?: (files: Array<IImage | File>) => void;
  required?: boolean;
}

export const UploadImage = ({
  required,
  descriptionText,
  multipleFiles,
  limitFiles,
  importedFiles,
  htmlFor,
  onChange,
}: UploadImageProps): JSX.Element => {
  const [uploadedFiles, setUploadedFiles] = useState<File[] | null>(null);
  const newFiles = importedFiles;
  const [error, setError] = useState<string | null>(null);
  const [isFileViewerVisible, setIsFileViewerVisible] = useState(false);
  const newFilesNumber = newFiles?.length ?? 0;
  const uploadedFilesNumber = uploadedFiles?.length ?? 0;
  const [inputLabelDisabled, setInputLabelDisabled] = useState(false);

  useEffect(() => {
    if (limitFiles && newFilesNumber + uploadedFilesNumber >= limitFiles) {
      setInputLabelDisabled(true);
    } else {
      setInputLabelDisabled(false);
    }
  }, [newFilesNumber, uploadedFilesNumber, limitFiles]);

  const errorReturn = () => {
    setError(`Você pode adicionar apenas ${limitFiles} arquivos`);
    setUploadedFiles([]);
    setIsFileViewerVisible(false);
  };

  const handleChange = (images = newFiles, files = uploadedFiles) => {
    if (!onChange) {
      return;
    }

    onChange([...(images ?? []), ...(files ?? [])]);
  };

  const onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventFilesNumber = event.target.files?.length ?? 0;
    const totalFiles = eventFilesNumber + uploadedFilesNumber + newFilesNumber;

    switch (true) {
      case eventFilesNumber > (limitFiles ?? 0):
        errorReturn();
        break;
      case totalFiles > (limitFiles ?? 0):
        errorReturn();
        break;
      default:
        setError(null);
        const current = uploadedFiles ?? [];
        const fromTarget = Array.from(event.target.files ?? []);
        const newUploadedFiles = [...current, ...fromTarget];
        setUploadedFiles(newUploadedFiles);
        handleChange(newFiles, newUploadedFiles);
        break;
    }
  };

  const removeImage = (file: File) => {
    setError(null);
    setUploadedFiles(uploadedFiles => (uploadedFiles ? uploadedFiles.filter(f => f !== file) : null));
    setIsFileViewerVisible(false);
    handleChange();
  };

  const removeImportedImage = (id: number) => {
    setError(null);
    handleChange(newFiles?.filter(file => file.id !== id));
    setIsFileViewerVisible(false);
  };

  return (
    <UploadImageContainer>
      <UploadImageHeader>
        <UploadImageTitle>
          {descriptionText} {required && <span>*</span>}
        </UploadImageTitle>
        <InputLabel
          htmlFor={htmlFor}
          onClick={() => setIsFileViewerVisible(true)}
          className={inputLabelDisabled ? 'disabledButton' : ''}>
          IMPORTAR IMAGEM
        </InputLabel>
        {isFileViewerVisible && (
          <Input
            hidden
            onChange={onImport}
            accept="image/png,image/jpg,image/jpeg"
            id={htmlFor}
            type="file"
            multiple={multipleFiles}
            disabled={inputLabelDisabled}
          />
        )}
      </UploadImageHeader>

      <UploadImageList>
        {newFiles?.map((file: IImage) => (
          <UploadImageListItem
            style={{
              background: `url(${file.url}) center top / cover no-repeat`,
            }}
            key={file.id}>
            <CloseImageButton type="button" onClick={() => removeImportedImage(file.id)}>
              X
            </CloseImageButton>
          </UploadImageListItem>
        ))}
        {uploadedFiles?.map((file: File) => (
          <UploadImageListItem
            style={{
              background: `url(${URL.createObjectURL(file)}) center top / cover no-repeat`,
            }}
            key={file.name}>
            <CloseImageButton type="button" onClick={() => removeImage(file)}>
              X
            </CloseImageButton>
          </UploadImageListItem>
        ))}
        {(newFiles?.length ?? 0) === 0 && (uploadedFiles?.length ?? 0) === 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0',
              width: '100%',
              height: '50px',
              color: '#aaa',
              background: '#f1f1f1',
              borderRadius: '18px',
            }}>
            Nenhum arquivo importado
          </div>
        )}
      </UploadImageList>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </UploadImageContainer>
  );
};

export default UploadImage;
