import DefaultMenu from 'common/DefaultMenu';
import MediaMenuSetur from 'common/MediaMenuSetur';
import SuperAdminMenu from 'common/SuperAdminMenu/temp';
import TourOrganizerMenu from 'common/TourOrganizerMenu';
import TouristMenu from 'common/TouristMenu';
import React from 'react';
import { getLocalUser } from 'services/localStorage';
import { Container } from './styles';
import MenuSetur from 'common/MenuSetur';
import TradeMenu from 'common/TradeMenu';
import MediaMenu from 'common/MediaMenu';
import MenuSeturMarket from 'common/MenuSeturMarket';
import MenuSeturMarketing from 'common/MenuSeturMarketing';
import MenuSeturFundetur from 'common/MenuSeturFundetur';
import MenuSeturAttorney from 'common/MenuSeturAttorney';
import MenuSeturExcursion from 'common/MenuSeturExcursion';

const HandleMenu: React.FC = () => {
  const localUser = getLocalUser();

  const profileName = localUser?.profile?.name;
  const currentPath = window.location.pathname;

  const pathsWithoutMenu = ['signup', 'login'];

  const isPathWithoutMenu = pathsWithoutMenu.some(path => currentPath.includes(path));

  if (isPathWithoutMenu || (localUser.email && !localUser.emailVerified)) {
    return <></>;
  }

  const handleMenu = () => {
    switch (profileName) {
      case 'tour':
        return <TourOrganizerMenu />;
      case 'tourist':
        return <TouristMenu />;
      case 'news':
        return <MediaMenuSetur />;
      case 'news-external':
        return <MediaMenu />;
      case 'admin':
        return <SuperAdminMenu />;
      case 'setur':
        return <MenuSetur />;
      case 'setur-market':
        return <MenuSeturMarket />;
      case 'setur-marketing':
        return <MenuSeturMarketing />;
      case 'setur-fundetur':
        return <MenuSeturFundetur />;
      case 'setur-attorney':
        return <MenuSeturAttorney />;
      case 'tour-pf':
        return <MenuSeturExcursion />;
      case 'tour-pj':
        return <MenuSeturExcursion />;
      case 'trade':
        return <TradeMenu />;
      default:
        return <DefaultMenu />;
    }
  };
  return <Container>{handleMenu()}</Container>;
};

export default HandleMenu;
