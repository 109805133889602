import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ButtonMenu from 'components/ButtonMenu';
import MainTitle from 'components/MainTitle';
import Home from 'features/Config/components/Home';
import Accommodations from 'features/Config/components/Accommodations';
import Activity from 'features/Config/components/Activity';
import Event from 'features/Config/components/Event';
import Itinerary from 'features/Config/components/Itinerary';
import Transport from 'features/Config/components/Transport';
import Service from 'features/Config/components/Service';
import Kit from 'features/Config/components/Kit';
import { Container } from './styles';

const Config = (): JSX.Element => {
  const history = useHistory();
  const { slug } = useParams<{ slug?: string }>();
  const [page, setPage] = useState(slug);
  const urlLocation = '/admin/config';

  const options = {
    '': 'Início',
    accommodations: 'Onde Ficar',
    activities: 'Atividades',
    events: 'Agenda',
    itineraries: 'Roteiros',
    transports: 'Transportes',
    services: 'Serviços Básicos',
    kit: 'Mídia Kit',
  };

  const handleComponent = () => {
    switch (page) {
      case 'accommodations':
        return <Accommodations />;
      case 'activities':
        return <Activity />;
      case 'events':
        return <Event />;
      case 'itineraries':
        return <Itinerary />;
      case 'transports':
        return <Transport />;
      case 'services':
        return <Service />;
      case 'kit':
        return <Kit />;
      default:
        return <Home />;
    }
  };

  const handleMenuChange = (key: string) => {
    setPage(key);
    history.replace(`${urlLocation}/${key}`);
  };

  return (
    <>
      <MainTitle>Configurações</MainTitle>
      <Container>
        <ButtonMenu options={options} onChange={handleMenuChange} urlLocation={urlLocation} />
        {handleComponent()}
      </Container>
    </>
  );
};

export default Config;
