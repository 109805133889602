import React from 'react';
import { env } from 'utils/env';
import { IPlaceMapProps } from 'components/Map/PlaceMap';
import { ISearchMapProps } from 'components/Map/SearchMap';
import { IDirectionsMapProps } from 'components/Map/DirectionsMap';
import { Container } from './styles';

type MapsMode = IPlaceMapProps | ISearchMapProps | IDirectionsMapProps;

type IProps = {
  mode: string;
};

const EmbeddedMap = (props: IProps & MapsMode): JSX.Element => {
  const { mode, ...query } = props;
  const key = env('REACT_APP_GOOGLE_MAPS_API_KEY');
  const params = new URLSearchParams({ key, ...query });

  return (
    <Container>
      <iframe
        src={`https://www.google.com/maps/embed/v1/${mode}?${params.toString()}`}
        referrerPolicy="no-referrer-when-downgrade"
        allowFullScreen
      />
    </Container>
  );
};

export default EmbeddedMap;
