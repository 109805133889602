import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  height: fit-content;
  width: 40%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;

  form {
    margin-top: 20px;
  }
  input,
  select {
    &:not([type='checkbox']) {
      background-color: transparent;
      width: 13vw;
      border: 1px solid #9a9a9a;
      border-radius: 18px;
      height: 4vh;
      margin-top: 1vh;
    }
  }
  header,
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 20px;
      font-weight: 500;
    }
  }
  p {
    margin-top: 20px;
    a {
      margin-left: 2px;
      text-decoration: none;
      color: #272727;
    }
  }
`;

export const ButtonsDiv = styled.div`
  button {
    &:nth-child(2) {
      background-color: transparent;
      color: #ffcb29;
      border: 1px solid #ffcb29;
      margin: 0 30px 0 auto;
      &:hover {
        background-color: #020c90;
        color: #fff;
        border: none;
      }
    }
  }
`;

export const InputField = styled.div`
  div {
    display: flex;
    flex-direction: column;
    align-items: start;
    label {
      white-space: nowrap;

      span {
        color: #ec3237;
        margin-left: 0.2vw;
      }
    }
    input {
      padding: 0 0.5vw;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:first-child) {
    margin: 20px 0;
  }
`;
