import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import newsIcon from '../../assets/images/news_menu_item.svg';
import cameraIcon from '../../assets/images/camera_menu_item.svg';
import myNews from '../../assets/images/newsReport.svg';
import { NavLink } from 'react-router-dom';

const MediaMenuSetur: React.FC = () => {
  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink exact activeClassName="selected" to="/news">
            <img src={newsIcon} alt="icon for list item" />
            <span>Notícias e Mídias Sociais</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink exact activeClassName="selected" to="/admin/news">
            <img src={myNews} alt="icon for list item" />
            <span>Minhas Notícias</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <a
            href="https://drive.google.com/drive/folders/1-8DutrRYUHw1VqR2fMj85Y49fG6ib8ts"
            target="_blank"
            rel="noopener noreferrer">
            <img src={cameraIcon} alt="icon for list item" />
            <span>Mídia Kit</span>
          </a>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default MediaMenuSetur;
