import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  min-height: fit-content;
  max-height: 100vh;
  width: 60%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  p {
    margin-top: 20px;
    a {
      color: #272727;
      font-weight: 700;
    }
  }

  form {
    margin-top: 20px;
  }
  input,
  select {
    &:not([type='checkbox']) {
      background-color: transparent;
      width: 13vw;
      border: 1px solid #9a9a9a;
      border-radius: 18px;
      height: 4vh;
      margin-top: 1vh;
    }
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  #cellphone-input {
    width: 40%;
  }
  #email-input {
    width: 60%;
  }
  .cep-input {
    width: 15%;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  button {
    &:nth-child(2) {
      background-color: transparent;
      color: #ffcb29;
      border: 1px solid #ffcb29;
      margin: 0 30px 0 auto;
      &:hover {
        background-color: #020c90;
        color: #fff;
        border: none;
      }
    }
  }
`;

export const InputField = styled.div`
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    align-items: start;
    label {
      white-space: nowrap;

      span {
        color: #ec3237;
        margin-left: 0.2vw;
      }
    }
    input,
    select {
      width: 100% !important;
      padding: 0 0.5vw;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  &:not(:first-child) {
    margin: 20px 0;
  }
`;

export const AccomodationType = styled.div`
  margin-top: 20px;
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const RadioGroup = styled.div``;

export const RadioItem = styled.div`
  label {
    display: flex;
    align-items: center;
    input[type='radio'] {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;
    }
    &:nth-child(2) {
      margin: 10px 0;
    }
  }
`;

export const NoReservationComponent = styled.div`
  header {
    display: flex;
    justify-content: left;
    span {
      color: #ec3237;
      margin-left: 3px;
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
  textarea {
    padding: 10px;
    margin-top: 5px;
    width: 100%;
    height: 10vh;
    border-radius: 18px;
  }
`;
