import styled from 'styled-components';

export const Container = styled.div`
  @media all and (max-width: 600px) {
    padding: 0 16px;
  }
`;

export const Panel = styled.div`
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;
`;

export const Div = styled.div`
  margin: 15px 0px;
  text-align: center;
`;
