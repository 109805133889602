import styled from 'styled-components';

export const Panel = styled.section`
  display: flex;
  width: 100%;
  padding: 0 60px;

  @media all and (max-width: 960px) {
    flex-direction: column;
    width: 100%;
    margin: 20px auto 32px;
    padding: 0 20px;
  }
`;

export const PanelInformation = styled.div`
  width: 100%;
  padding: 1.875rem;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #fff;

  @media all and (max-width: 960px) {
    width: 100%;
    padding: 1rem;
    margin: 0 auto 2rem;
  }
`;

export const Title = styled.h4`
  display: block;
  font-size: 1.438rem;
  font-weight: 700;
  color: #272727;
  margin-bottom: 1.25rem;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`;

export const Item = styled.li`
  line-height: 1.5rem;
`;
