import styled from 'styled-components';

export const OverviewClass = styled.div`
  display: block;
  margin: 0 auto 30px;
  width: 100%;

  .LookerOverview iframe {
    height: 600px;
  }

  .LongTimeView iframe {
    height: 450px;
    margin-bottom: 30px;
  }

  .DetailingView iframe {
    height: 460px;
    margin-bottom: 30px;
  }

  @media (max-width: 980px) {
    .LookerOverview iframe,
    .LongTimeView iframe,
    .DetailingView iframe {
      height: auto;
    }
  }
`;
