import React from 'react';
import { Container, HeaderTitle } from './styles';
import MainTitle from 'components/MainTitle';
import BusinessList from './components/BusinessList';

const TradeOffers = (): JSX.Element => {
  return (
    <>
      <HeaderTitle>
        <MainTitle>Estabelecimentos e Atividades</MainTitle>
      </HeaderTitle>
      <Container>
        <BusinessList />
      </Container>
    </>
  );
};

export default TradeOffers;
