import styled from 'styled-components';

export const Container = styled.div`
  .find-my-account-modal {
    width: 360px;
    height: 290px;
    max-width: 400px;
  }

  @media all and (max-width: 600px) {
    padding: 0 16px;
  }
`;

export const Panel = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;
`;

export const Div = styled.div`
  width: 100%;
  margin: 15px 0px;
  text-align: center;
`;

export const LinkDiv = styled.div`
  width: 100%;
  margin: 15px 0px;
  text-align: center;
  color: #000000;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;
