import PanelLayout from 'common/PanelLayout';
import showSelectLanguageState from 'components/SelectLanguague/languageState';
import React from 'react';
import { Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';

interface IProps {
  component: React.FC | React.ElementType;
}

const PublicRoute = ({ component: Component, ...rest }: IProps): JSX.Element => {
  const [showOptions, setShowOptions] = useRecoilState(showSelectLanguageState);
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <PanelLayout>
            <div
              onClick={() => {
                setShowOptions(false);
              }}>
              <Component {...props} />
            </div>
          </PanelLayout>
        );
      }}
    />
  );
};

export default PublicRoute;
