import styled from 'styled-components';

export const InputField = styled.div`
  input {
    display: flex;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    height: 5vh;
    padding: 0 0.5vw;
    width: 100%;
  }
`;
