import React, { useRef, useEffect } from 'react';
import { IframeContainer } from './styles';

interface IframeProps {
  src: string;
  children?: React.ReactNode;
}

const IframeComponent: React.FC<IframeProps> = ({ src, children }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && iframeRef.current) {
          iframeRef.current.src = src;
          observer.unobserve(iframeRef.current);
        }
      },
      { threshold: 0.5 }
    );

    if (iframeRef.current) {
      observer.observe(iframeRef.current);
    }

    return () => {
      if (iframeRef.current) {
        observer.unobserve(iframeRef.current);
      }
    };
  }, [src]);

  return (
    <IframeContainer
      ref={iframeRef}
      frameBorder={0}
      style={{ border: 0 }}
      allowFullScreen
      sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      {children}
    </IframeContainer>
  );
};

export default IframeComponent;
