import styled from 'styled-components';

export const Container = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    row-gap: 50px;
    margin-bottom: 70px;
    padding: 0 50px;

    @media (max-width: 980px) {
      width: 100%;
      display: grid;
      overflow-x: scroll;
      padding: 16px;
      justify-content: unset;
      margin: 50px 0 0 0;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, 294px);
      grid-auto-flow: column;
      grid-auto-columns: 294px;
    }
  }

  ul::-webkit-scrollbar {
    height: 0;
  }

  Button {
    width: 270px;
    display: block;
    margin: 60px auto;
    font-size: 16px;

    @media (max-width: 980px) {
      margin-top: 20px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const Anchor = styled.a`
  text-decoration: none;
`;
