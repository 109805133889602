import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;

  button {
    margin: 0px 15px;
  }

  @media (max-width: 450px) {
    button {
      margin: 0px 5px;
      font-size: 14px;
    }
  }

  @media (max-width: 375px) {
    flex-direction: column;
    margin: 10px auto;
    gap: 10px;
  }
`;
