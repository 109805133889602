import RestService from './RestService';
import INews from 'interfaces/INews';

export default class NewsService extends RestService<INews> {
  constructor() {
    super('news');
  }

  public async postNews(id: number, news: INews): Promise<void> {
    await this.create(news);
  }
}
