import React from 'react';
import { DetailingClass } from './styles';
import IframeComponent from 'components/IFrameComponent';

const Detailing = (): JSX.Element => {
  return (
    <>
      <DetailingClass>
        <div className="LongTimeView">
          <IframeComponent src="/TrendsGoogleScripts/LongTime.html" />
        </div>
        <div className="DetailingView">
          <IframeComponent src="/TrendsGoogleScripts/Detailing.html" />
        </div>
        <div className="Trends">
          <IframeComponent src="/TrendsGoogleScripts/PortoSeguroMap.html" />
          <IframeComponent src="/TrendsGoogleScripts/PortoSeguroData.html" />
        </div>
        <div className="Trends">
          <IframeComponent src="/TrendsGoogleScripts/MaceioMap.html" />
          <IframeComponent src="/TrendsGoogleScripts/MaceioData.html" />
        </div>
        <div className="Trends">
          <IframeComponent src="/TrendsGoogleScripts/GramadoMap.html" />
          <IframeComponent src="/TrendsGoogleScripts/GramadoData.html" />
        </div>
        <div className="Trends">
          <IframeComponent src="/TrendsGoogleScripts/NatalMap.html" />
          <IframeComponent src="/TrendsGoogleScripts/NatalData.html" />
        </div>
        <div className="Trends">
          <IframeComponent src="/TrendsGoogleScripts/RJMap.html" />
          <IframeComponent src="/TrendsGoogleScripts/RJData.html" />
        </div>
      </DetailingClass>
    </>
  );
};

export default Detailing;
