import React, { useEffect, useState } from 'react';
import {
  ButtonsDiv,
  Container,
  ExcursionFirstForm,
  ExcursionFirstFormContainer,
  FormRow4Cols,
  InputField,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import { Link, useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { Formik, Field, Form, FormikValues } from 'formik';
import ErrorMessage from 'components/ErrorMessage';
import FieldMask from 'components/FieldMask';
import { states, StatesEnum, StatesSelectProps } from 'features/Signup/pages/SignupTourOrganizer/utils';
import SelectComponent from 'components/Select';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import { tourReasons, TourReasonsEnum, TourReasonsProps } from 'features/NewExcursionForm/utils';
import { useLoading } from 'hooks/useLoading';
import UserService from 'services/UserService';

const NewExcursionStep2Form = (): JSX.Element => {
  const history = useHistory();
  const [user, setUser] = useState<any>({});
  const [disabledInput, setDisabledInput] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [selectedTourReason, setSelectedTourReason] = useState('');
  const { openLoading, closeLoading } = useLoading();

  const initialValues = {
    name: '',
    document: '',
    email: '',
    extraInfo: {
      phone: '',
      address: {
        zipCode: '',
        street: '',
        number: '',
        additionalData: '',
        neighborhood: '',
        city: '',
        state: '',
      },
    },
  };

  const StatesSelect: React.FC<StatesSelectProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={states.map(state => ({ label: state.label, value: state.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as StatesEnum);
            setSelectedState(value as string);
          }
        }}
        name="state"
        selected={[selectedState]}
        disabled={disabledInput}
      />
    );
  };

  const TourReasonsSelect: React.FC<TourReasonsProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={tourReasons.map(reasons => ({ label: reasons.label, value: reasons.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as TourReasonsEnum);
            setSelectedTourReason(value as string);
          }
        }}
        name="state"
        selected={[selectedTourReason]}
        disabled={disabledInput}
      />
    );
  };

  const onsubmit = (values: FormikValues) => {
    console.log(values);
  };

  return (
    <Container>
      <MainTitle>Cadastro de Excursão</MainTitle>

      <StepperContainer>
        <StepperItem className="flex flex-col items-center justify-center">
          <article>
            <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step1')}>
              <IconOrganizador color="#ffffff" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Organizador</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <StepperBullet className="onEdit" onClick={() => history.push('/admin/new-excursion/step2')}>
              <IconViagem color="#020c90" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Viagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={() => history.push('/admin/new-excursion/step3')}>
                <IconTransporte color="#ffffff" />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <p>Transporte</p>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconHospedagem />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <p>Hospedagem</p>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconGuia />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <p>Guia</p>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconPagamento />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <p>Pagamento</p>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconResumo />
              </StepperBullet>
            </Link>
          </article>
          <p>Resumo</p>
        </StepperItem>
      </StepperContainer>

      <ExcursionFirstFormContainer>
        <p>*Campo Obrigatório</p>

        <ExcursionFirstForm>
          <h2>Dados da viagem:</h2>

          <Formik initialValues={initialValues} onSubmit={onsubmit}>
            <Form>
              <FormRow4Cols>
                <InputField id="state-input" className="responsive-grid-column-span-2">
                  <div>
                    <label htmlFor="">
                      Estado de origem<span>*</span>
                    </label>
                    <Field component={StatesSelect} name="state" disabled={disabledInput} />
                  </div>
                  <ErrorMessage name="state" />
                </InputField>
                <InputField id="city-input" className="responsive-grid-column-span-2">
                  <div>
                    <label htmlFor="">
                      Cidade de origem<span>*</span>
                    </label>
                    <Field name="extraInfo.address.city" disabled={disabledInput} />
                  </div>
                  <ErrorMessage name="extraInfo.address.city" />
                </InputField>
                <InputField>
                  <div>
                    <label htmlFor="">
                      Data de Chegada<span>*</span>
                    </label>
                    <FieldMask type="date">
                      <Field name="startDate" placeholder="__/__/____" />
                    </FieldMask>
                  </div>
                  <ErrorMessage name="startDate" />
                </InputField>
                <InputField>
                  <div>
                    <label htmlFor="">
                      Data de Saída<span>*</span>
                    </label>
                    <FieldMask type="date">
                      <Field name="endDate" placeholder="__/__/____" />
                    </FieldMask>
                  </div>
                  <ErrorMessage name="endDate" />
                </InputField>
              </FormRow4Cols>
              <FormRow4Cols>
                <InputField>
                  <div>
                    <label htmlFor="">
                      Hora de chegada<span>*</span>
                    </label>
                    <FieldMask type="time">
                      <Field name="startHour" />
                    </FieldMask>
                  </div>
                  <ErrorMessage name="startHour" />
                </InputField>
                <InputField>
                  <div>
                    <label htmlFor="">
                      Hora de saída<span>*</span>
                    </label>
                    <FieldMask type="time">
                      <Field name="endHour" />
                    </FieldMask>
                  </div>
                  <ErrorMessage name="endHour" />
                </InputField>
                <InputField id="city-input" className="responsive-grid-column-span-2">
                  <div>
                    <label htmlFor="">
                      Quantidade de pessoas<span>*</span>
                    </label>
                    <Field name="peopleNumber" disabled={disabledInput} />
                  </div>
                  <ErrorMessage name="peopleNumber" />
                </InputField>
                <InputField id="city-input" className="responsive-grid-column-span-2">
                  <div>
                    <label htmlFor="">
                      Localidade de destino<span>*</span>
                    </label>
                    <Field component={StatesSelect} name="destinyCity" disabled={disabledInput} />
                  </div>
                  <ErrorMessage name="destinyCity" />
                </InputField>
              </FormRow4Cols>
              <FormRow4Cols>
                <InputField id="state-input" className="responsive-grid-column-span-2">
                  <div>
                    <label htmlFor="">
                      Motivo da Viagem<span>*</span>
                    </label>
                    <Field component={TourReasonsSelect} name="state" disabled={disabledInput} />
                  </div>
                  <ErrorMessage name="state" />
                </InputField>
              </FormRow4Cols>
              <ButtonsDiv>
                <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step1')}>
                  Voltar
                </Button>
                <article>
                  <Button buttonType="secondary">Cancelar</Button>
                  <Button onClick={() => history.push('/admin/new-excursion/step3')}>Próximo</Button>
                </article>
              </ButtonsDiv>
            </Form>
          </Formik>
        </ExcursionFirstForm>
      </ExcursionFirstFormContainer>
    </Container>
  );
};

export default NewExcursionStep2Form;
