import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100%;

  &:hover {
    outline: 5px solid #ffcb29;
  }
`;
