import React from 'react';
import { useParams } from 'react-router';
import EventService from 'services/EventService';
import { Event } from 'features/WhatToDo/components/EventDetail';

const EventPreview = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const getEvent = async () => {
    const service = new EventService();
    return service.findOne(Number(id));
  };

  return <Event getEvent={getEvent} />;
};

export default EventPreview;
