import styled from 'styled-components';

export const Container = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    row-gap: 50px;
    margin-bottom: 70px;
    padding: 0 50px;

    @media (max-width: 980px) {
      width: 100%;
      display: grid;
      overflow-x: scroll;
      padding: 16px;
      justify-content: unset;
      margin: 0px;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, 294px);
      grid-auto-flow: column;
      grid-auto-columns: 294px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    li {
      img {
        @media (max-width: 980px) {
          object-fit: cover;
        }
      }
    }
  }
  > a {
    text-decoration: none;
    > button {
      width: 270px;
      display: block;
      margin: auto;
      margin-top: 60px;
      margin-bottom: 60px;
      font-size: 16px;
      @media (max-width: 980px) {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 14px;
      }
    }
  }
`;
