import React, { FC, useEffect, useRef, useState } from 'react';
import { SelectArea } from './styles';
import upArrowGrey from '../../assets/images/upArrowGrey.svg';
import downArrowGrey from '../../assets/images/downArrowGrey.svg';
import Input from 'components/Input/Input';

interface SelectComponentProps {
  label: string;
  name?: string;
  options: { label: string; value: string }[];
  onChange: (selectedValue: string | string[]) => void;
  withCheckbox?: boolean;
  selected?: string[];
  required?: boolean;
  disabled?: boolean;
  placeholderText?: string;
}

const SelectComponent: FC<SelectComponentProps> = ({
  required,
  label,
  name,
  options,
  onChange,
  withCheckbox,
  selected = [],
  disabled,
  placeholderText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    if (selected !== null && selected.length > 0) {
      const selectedLabel = options.find(option => option.value === selected[0])?.label;
      setSelectedOptions(selectedLabel && !withCheckbox ? [selectedLabel] : selected);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selected]);

  const handleSelect = (value: string) => {
    if (withCheckbox) {
      const isSelected = selectedOptions.includes(value);
      const updatedSelection = isSelected
        ? selectedOptions.filter(option => option !== value)
        : [...selectedOptions, value];
      setSelectedOptions(updatedSelection);
      onChange(updatedSelection);
    } else {
      setSelectedOptions([value]);
      onChange(value);
      closeOptions();
    }
  };

  const toggleOptions = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const closeOptions = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      closeOptions();
    }
  };

  return (
    <SelectArea ref={selectRef}>
      <label>
        {label} {required && <span>*</span>}
      </label>
      <div className={`CustomSelect ${isOpen ? 'open' : ''}`}>
        <div className={`SelectedOption  ${disabled ? 'disabled' : ''}`} onClick={toggleOptions}>
          <p>{selectedOptions.length > 0 ? selectedOptions.join(', ') : placeholderText}</p>
          {isOpen ? <img src={upArrowGrey} alt="open" /> : <img src={downArrowGrey} alt="close" />}
        </div>
        <ul className="OptionList">
          {options.map((option, index) => (
            <li className="Option" key={index} onClick={() => handleSelect(option.value)}>
              {withCheckbox && <Input type="checkbox" checked={selectedOptions.includes(option.value)} />}
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </SelectArea>
  );
};

export default SelectComponent;
