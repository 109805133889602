import { AxiosInstance, RawAxiosRequestHeaders } from 'axios';
import getHTTPClient from './httpClient';
import { getAccessToken } from './localStorage';

export default abstract class RestService<T> {
  protected httpClient: AxiosInstance;

  constructor(path: string) {
    const headers = this.getHeaders();
    const httpClient = getHTTPClient({ headers });

    httpClient.defaults.baseURL += `/${path}`;

    httpClient.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status === 401 && error?.response?.config?.url !== '/login') {
          window.location.href = '/logout';
        }
        return Promise.reject(error);
      }
    );

    this.httpClient = httpClient;
  }

  public async findAll(): Promise<T[]> {
    const response = await this.httpClient.get('');
    return response.data;
  }

  public async findOne(id: number): Promise<T> {
    const response = await this.httpClient.get<T>(`/${id}`);
    return response.data;
  }

  public async create(body: Omit<T, 'id'>): Promise<void> {
    await this.httpClient.post('', body);
  }

  public async update(id: number, body: Partial<T>): Promise<void> {
    await this.httpClient.put(`/${id}`, body);
  }

  public async remove(id: number): Promise<void> {
    await this.httpClient.delete(`/${id}`);
  }

  protected getHeaders(): Partial<RawAxiosRequestHeaders> {
    const token = this.getAuthorizationToken();
    return { Authorization: `Bearer ${token}` };
  }

  protected getAuthorizationToken(): string {
    return getAccessToken();
  }
}
