import React, { useEffect, useState } from 'react';
import { useLoading } from 'hooks/useLoading';
import { SettingsBox } from 'components/SettingsBox';
import RestService from 'services/RestService';

interface ICategory {
  id: number;
  description: string;
}

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  service: RestService<ICategory>;
}

const CategoryTable = (props: IButtonProps): JSX.Element => {
  const { title, service } = props;
  const { openLoading, closeLoading } = useLoading();
  const [items, setItems] = useState<ICategory[]>([]);

  useEffect(() => {
    openLoading();
    fetchItems().finally(() => closeLoading());
  }, []);

  const fetchItems = async () => {
    const data = await service.findAll();
    setItems(data);
  };

  const addItem = async (description: string) => {
    openLoading();
    await service.create({ description });
    await fetchItems();
    closeLoading();
  };

  const editItem = async (id: number, description: string) => {
    openLoading();
    await service.update(id, { description });
    const index = items.findIndex(item => item.id === id);
    items[index].description = description;
    setItems([...items]);
    closeLoading();
  };

  const deleteItem = async (id: number) => {
    openLoading();
    await service.remove(id);
    setItems(items.filter(item => item.id !== id));
    closeLoading();
  };

  return (
    <SettingsBox
      settingsName={title}
      items={items.map(item => ({ id: item.id, name: item.description }))}
      onAddItem={name => addItem(name)}
      onEditItem={item => editItem(item.id, item.name)}
      onDeleteItem={item => deleteItem(item.id)}
    />
  );
};

export default CategoryTable;
