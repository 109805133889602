import styled from 'styled-components';

export const Menu = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px #0000003f;
`;

export const ListItem = styled.li`
  width: fit-content;
  height: 40px;
  padding: 10px;
  font-weight: 500;

  .selected {
    span {
      color: #020c90;
    }
    img {
      filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
        contrast(126%);
    }
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #9a9a9a;
    white-space: nowrap;

    img {
      margin-right: 10px;
    }
  }
  &:hover {
    span {
      color: #020c90;
    }
    img {
      filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
        contrast(126%);
    }
  }
`;

export const Stroke = styled.li`
  border-bottom: 1px dashed #9a9a9a;
  margin: 10px 20px;
  display: block;
`;

export const LogoutModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  margin: 30px auto 0 auto;
  width: fit-content;
  padding: 30px;
  border-radius: 18px;
  gap: 20px;
  z-index: 99999;

  h2 {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
  }

  span {
    font-size: 20px;
    font-weight: 400;
    width: 30vw;
    text-align: center;
  }

  div {
    display: flex;

    button {
      white-space: nowrap;
      min-width: 100px;
      width: fit-content;
      padding: 0 10px;

      &:first-child {
        background-color: transparent;
        border: 1px solid #ffcb29;
        color: #ffcb29;
        font-size: 16px;
        font-weight: 700;
        margin-right: 30px;
      }
    }
  }
`;
