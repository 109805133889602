import React from 'react';
import { UploadImage as Upload, UploadImageProps as UploadProps } from 'components/UploadImage';
import { useField } from 'formik';

interface UploadImageProps extends UploadProps {
  name: string;
}

const UploadImage = (params: UploadImageProps): JSX.Element => {
  const { name, ...props } = params;

  const [field, meta, helpers] = useField(name);

  const files = meta.value
    ?.filter((file: any) => {
      return typeof file === 'string';
    })
    ?.map((url: string, id: number) => {
      return { id, url, description: '' };
    });

  return (
    <Upload
      {...props}
      onChange={files => {
        const newFiles = files.map(file => {
          return 'url' in file ? file.url : file;
        });

        helpers.setValue(newFiles);
      }}
      importedFiles={files}
    />
  );
};

export default UploadImage;
