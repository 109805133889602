import React from 'react';
import { ButtonLabel, ButtonsDiv, Container, Content, Divider, SelectArea } from './styles';
import Button from 'components/Buttom/Button';
import { useHistory } from 'react-router';

const HandleArea = (): JSX.Element => {
  const history = useHistory();
  const path = window.location.pathname;
  return (
    <Container>
      <Content>
        <SelectArea>
          <h2>Selecione a sua área de acordo com o seu tipo de perfil:</h2>
          <ButtonsDiv>
            <ButtonLabel>
              <Button
                text={'ÁREA DO TURISTA'}
                onClick={() => {
                  path.includes('signup') ? history.push('/signup/tourist') : history.push('/login/tourist');
                }}
              />
              <label htmlFor="">Para perfil Turista</label>
            </ButtonLabel>
            <Divider />
            <ButtonLabel>
              <Button
                text={'ÁREA ADMINISTRATIVA'}
                onClick={() => {
                  path.includes('signup') ? history.push('/signup/admin') : history.push('/login/admin');
                }}
              />
              <label htmlFor="">
                Para perfis Imprensa, Organizador de Excursão, Secretaria do Turismo - SETUR ou Trade
              </label>
            </ButtonLabel>
          </ButtonsDiv>
        </SelectArea>
      </Content>
    </Container>
  );
};

export default HandleArea;
