import React from 'react';

const Private = (): JSX.Element => {
  return (
    <section className="page-section">
      <header className="page-header">
        <h1>Página privada</h1>
      </header>
      <div className="page-content">
        <img src="" className="App-logo" alt="logo" />
        <p>Este é o exemplo de uma página privada</p>
      </div>
      <footer className="page-footer">
        <a className="App-link" href="https://www.ipnet.cloud/" target="_blank" rel="noopener noreferrer">
          IPNET - Growth Partner
        </a>
      </footer>
    </section>
  );
};

export default Private;
