import React from 'react';
import Button from 'components/Buttom/Button';
import { Container } from './styles';
import { Anchor } from 'common/SocialMedia/styles';

export const ExcursionOrganizatorBox = (): JSX.Element => {
  return (
    <Container>
      <h1>Organizando uma Excursão?</h1>
      <Anchor href="https://marcuscosta12.wixsite.com/portosegurotur/blank" target="_blank" rel="noreferrer">
        <Button text="Cadastrar Excursão" />
      </Anchor>
    </Container>
  );
};
