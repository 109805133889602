import React, { useState } from 'react';
import { Modal } from 'components/Modals';
import Button from 'components/Button';
import {
  ModalContainer,
  ModalContainerHeader,
  ModalContainerHeaderTitle,
  ModalContainerActionBox,
  ModalContainerText,
  InputLabel,
  Input,
} from './styles';

interface NewStrategicMapModalProps {
  isOpen: boolean;
  toggle: () => void;
  onMapImport: (file: File) => void;
}

const NewStrategicMapModal = ({ isOpen, toggle, onMapImport }: NewStrategicMapModalProps): JSX.Element => {
  const [openInputFile, setOpenInputFile] = useState(false);

  const onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      onMapImport(event.target.files[0]);
    }
    return setOpenInputFile(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalContainer>
        <ModalContainerHeader>
          <ModalContainerHeaderTitle>Novo Mapa Estratégico</ModalContainerHeaderTitle>
        </ModalContainerHeader>

        <ModalContainerText>
          Você está adicionando um novo arquivo em Mapa Estratégico Atual. Se continuar com a ação, o arquivo atual será
          transferido para a lista <b>Histórico</b> e o novo arquivo irá substituí-lo em <b>Mapa Estratégico Atual</b>.
          Deseja continuar?
        </ModalContainerText>

        <ModalContainerActionBox>
          <Button buttonType="outline" className="cancel-button" onClick={toggle}>
            Cancelar
          </Button>

          <InputLabel htmlFor="openFile" onClick={() => setOpenInputFile(true)}>
            Importar Novo Mapa
          </InputLabel>
        </ModalContainerActionBox>
      </ModalContainer>

      {openInputFile && (
        <Input hidden onChange={onImport} id="openFile" name="openFile" type="file" accept="application/pdf" />
      )}
    </Modal>
  );
};

export default NewStrategicMapModal;
