import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import { NavLink } from 'react-router-dom';
import coinIcon from 'assets/images/coinIcon.svg';
import tourismCouncil from 'assets/images/tourismCouncil.svg';

const MenuSeturAttorney: React.FC = () => {
  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink activeClassName="selected" to="/">
            <img src={tourismCouncil} alt="icon for list item" />
            <span>Conselho do Turismo</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/">
            <img src={coinIcon} alt="icon for list item" />
            <span>Fundo do Turismo</span>
          </NavLink>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default MenuSeturAttorney;
