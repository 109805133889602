import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  .tooltip {
    margin-right: 5px;
  }
`;

export const MainContent = styled.article`
  display: flex;
  flex-direction: column;
  padding: 0 50px;

  @media all and (max-width: 980px) {
    padding: 0 20px;
  }

  label {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #272727;
  }

  select {
    width: fit-content;
    height: 40px;
    padding: 0px 10px;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    gap: 10px;
    cursor: pointer;

    &::before,
    &::after {
      --size: 0.3rem;
      position: absolute;
      content: '';
      right: 1rem;
      pointer-events: none;
    }

    &::before {
      border-left: var(--size) solid transparent;
      border-right: var(--size) solid transparent;
      border-bottom: var(--size) solid black;
      top: 40%;
    }

    &::after {
      border-left: var(--size) solid transparent;
      border-right: var(--size) solid transparent;
      border-top: var(--size) solid black;
      top: 55%;
    }

    option {
      position: absolute;
      top: 280px;
      left: 600px;
      padding: 25px 0px;
      border-radius: 18px;
      border-bottom: 1px solid #9a9a9a;

      &:hover {
        background-color: #f2f2f2;
        cursor: pointer;
      }
    }
  }
`;

export const MainContentTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #272727;

  @media all and (max-width: 980px) {
    font-size: 20px;
  }
`;

export const MainContentSelectBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 20px;
  border-bottom: 1px dotted #9a9a9a;

  @media all and (max-width: 980px) {
    grid-template-columns: 1fr;
  }

  &div select {
    display: none;
  }

  span {
    color: #f00;
  }
`;

export const MainContentFormsBox = styled.article`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const PlusButton = styled.button`
  width: 40px;
  height: 40px;
  padding-top: 5px;
  border-radius: 20px;
  border: none;
  text-transform: uppercase;
  color: #fff;
  background: #ffcb29;
  cursor: pointer;

  &:hover {
    background: #020c90;
  }
`;

export const FormList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
  list-style-type: none;

  @media (max-width: 980px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FormListRow = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  margin-bottom: 30px;

  &.col-2 {
    grid-column: span 2;

    @media (max-width: 980px) {
      grid-column: span 1;
    }
  }

  &.col-3 {
    grid-column: span 3;

    @media (max-width: 980px) {
      grid-column: span 1;
    }
  }

  &.col-4 {
    grid-column: span 4;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    color: #272727;
    gap: 8px;

    @media (max-width: 980px) {
      font-size: 13px;
    }

    &.with-tooltip {
      display: flex;
      align-items: center;
    }

    span {
      margin-left: 5px;
      color: #f00;
    }

    img {
      margin-left: 5px;
    }
  }

  input {
    height: 40px;
    padding: 0px 10px;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    gap: 10px;
  }

  textarea {
    height: 76px;
    padding: 8px 10px 8px 10px;
    border-radius: 18px;
    border: 1px solid #ccc;
    gap: 10px;
    resize: none;
  }

  .structure label span {
    display: none;
  }
`;

export const FormListButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  img {
    border-radius: 18px;
  }

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }
`;

export const InputField = styled.div`
  div {
    display: flex;
    flex-direction: column;
    label {
      white-space: break-spaces;
      span {
        color: #ec3237;
        margin-left: 0.2vw;
      }
    }
    input {
      padding: 0 0.5vw;
    }
  }
`;

export const BusinessOwner = styled.div`
  div:has(> button) {
    display: flex;
  }
  div:has(~ button) {
    width: 100%;
  }
  button {
    height: 40px;
  }
`;

export const ErrorMessageBox = styled.div`
  position: absolute;
  margin-top: 70px;

  &.for-textarea {
    margin-top: 110px;
  }

  &.for-uploadimages {
    margin-top: 130px;

    @media all and (max-width: 800px) {
      margin-top: 160px;
    }

    @media all and (max-width: 700px) {
      margin-top: 180px;
    }

    @media all and (max-width: 470px) {
      margin-top: 210px;
  }
`;
