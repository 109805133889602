import { Modal } from 'components/Modals';
import React, { useState } from 'react';
import {
  ModalContainer,
  ModalContainerActionBox,
  ModalContainerAsterisk,
  ModalContainerHeader,
  ModalContainerHeaderSteps,
  ModalContainerHeaderTitle,
  ModalContainerInput,
  ModalContainerInputLabel,
} from './styles';
import Button from 'components/Button';
import { Item } from 'components/SettingsBox';
import MessageModal from 'common/MessageModal';

interface SettingsBoxEditCategoryModalProps {
  isOpen: boolean;
  toggle: () => void;
  item: Item;
  onSubmit?: (item: Item) => boolean | void;
}

const SettingsBoxEditCategoryModal = ({
  isOpen,
  toggle,
  item,
  onSubmit,
}: SettingsBoxEditCategoryModalProps): JSX.Element => {
  const [newName, setNewName] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });

  const editCategoryName = () => {
    if (onSubmit) {
      const success = onSubmit({ ...item, name: newName });
      if (success !== undefined) {
        const messageWithSpan = success
          ? 'Categoria <span>editada</span> com sucesso!'
          : 'Erro ao <span>editar</span> categoria.';

        setMessageInfo({
          title: success ? 'Sucesso' : 'Erro',
          message: messageWithSpan,
        });
        setShowMessageModal(true);

        if (success) {
          toggle();
        }
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalContainer>
          <ModalContainerHeader>
            <ModalContainerHeaderTitle>Dados da categoria:</ModalContainerHeaderTitle>
            <ModalContainerHeaderSteps>1/1</ModalContainerHeaderSteps>
          </ModalContainerHeader>
          <ModalContainerInputLabel>
            Nome da Categoria <ModalContainerAsterisk>*</ModalContainerAsterisk>
          </ModalContainerInputLabel>
          <ModalContainerInput
            placeholder={item.name}
            name="category-name"
            id="Nome da Categoria"
            onChange={e => setNewName(e.target.value)}
          />
          <ModalContainerActionBox>
            <Button buttonType="outline" className="cancel-button" onClick={toggle}>
              Cancelar
            </Button>
            <Button type="submit" onClick={editCategoryName} disabled={false}>
              Salvar
            </Button>
          </ModalContainerActionBox>
        </ModalContainer>
      </Modal>

      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          title={messageInfo.title}
          message={messageInfo.message}
          onClose={() => setShowMessageModal(false)}
        />
      )}
    </>
  );
};

export default SettingsBoxEditCategoryModal;
