import styled from 'styled-components';
import trashIconBlack from 'assets/images/trashIconBlack.svg';
import blueTrashIcon from 'assets/images/blueTrashIcon.svg';
import editIcon from 'assets/images/editIcon.svg';
import blueEditIcon from 'assets/images/blueEditIcon.svg';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  #only-view-first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-bottom: 20px;

    article {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 50%;

      div {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      input {
        width: 100%;
      }
    }

    img {
      width: 100%;
      height: fit-content;
    }

    div {
      display: flex;
      flex-direction: column;
      span {
        &:first-child {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
  #only-view-second-row {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  #only-view-third-row {
    grid-template-columns: 16vw 47vw 28vw;
  }
  #only-view-fourth-row {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  #only-view-fifth-row {
    grid-template-columns: 50vw 50vw;
  }
`;

export const FormRow = styled.div`
  display: grid;
  column-gap: 30px;
  width: 100%;

  &:not(:first-child) {
    margin: 20px 0;
    justify-content: space-between;
  }
`;

export const LabelData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  label {
    font-weight: 500;
  }

  span {
    font-weight: 300;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted;
  margin: 2vh 0;
`;

export const DeleteAccountButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #272727;
  background-color: transparent;
  img {
    margin-right: 10px;
    content: url(${trashIconBlack});
  }
  span {
    font-size: 16px;
    font-weight: 700;
    text-decoration-line: underline;
  }
  &:hover {
    border: 1px solid #020c90;
    img {
      content: url(${blueTrashIcon});
    }
    span {
      color: #020c90;
    }
  }
`;

export const AskForEditData = styled(DeleteAccountButton)`
  img {
    content: url(${editIcon});
  }
  &:hover {
    img {
      content: url(${blueEditIcon});
    }
  }
`;
export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: fit-content;
`;
