import styled from 'styled-components';

export const Container = styled.div`
  div {
    width: 100%;
    --slider-height-percentage: 50%;
    --slider-transition-duration: 1000ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 3px;
    --organic-arrow-height: 20px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 6%;
    --control-button-height: 25%;
    --control-button-background: #ffcb29;
    --control-bullet-color: #ffffff;
    --control-bullet-active-color: #ffffff;
    --loader-bar-color: #c33737;
    --loader-bar-height: 3px;

    .awssld__prev,
    .awssld__next {
      border-radius: 50%;
      width: 40px;
      height: 40px;

      &:hover {
        background-color: #020c90;
      }
    }
    .awssld__prev {
      margin-left: 50px;
    }
    .awssld__next {
      margin-right: 50px;
    }

    .awssld__content {
      background-color: transparent;
      position: relative;
    }

    .awssld__bullets {
      position: absolute;
      margin-bottom: 8%;
      z-index: 2;
      @media (max-width: 480px) {
        margin-bottom: 22%;
      }

      button {
        background: none;
        border-style: solid;
        border-color: white;
        border-width: 2.5px;
        height: 2vh;
        width: 1vw;
        @media (max-width: 480px) {
          height: 1vh;
          width: 2vw;
        }
        transform: scale(1);

        &:hover {
          transform: scale(1.1);
        }
      }

      .awssld__bullets--active {
        background-color: white;
      }
    }

    .content-div {
      img {
        width: 100%;
        height: 100%;
      }
      button,
      span {
        color: #fff;
        font-size: 1.5vw;
        line-height: normal;
        position: absolute;
        left: 10%;
        z-index: 3;
      }
      span {
        &:first-of-type {
          top: 25%;
          font-size: 2vw;
          font-weight: 700;
          @media (max-width: 480px) {
            font-size: 3vw;
          }
        }
        &:last-of-type {
          top: 30%;
          max-width: 15vw;
          font-size: 1.1vw;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 11;
          overflow: hidden;
          -webkit-box-orient: vertical;
          @media (max-width: 480px) {
            font-size: 2.4vw;
            max-width: 40vw;
            -webkit-line-clamp: 3;
          }
        }
      }
      button {
        top: 63%;
        width: fit-content;
        padding: 0 15px;
        @media (max-width: 480px) {
          top: 44%;
          left: 60%;
          height: fit-content;
          padding: 10px;
          font-size: 3vw;
        }
      }
    }
  }
`;

export const DescriptionsInfo = styled.section`
  display: flex;
  width: 100%;
  margin: 50px auto;
  padding: 0 60px;

  @media all and (max-width: 960px) {
    flex-direction: column;
    width: 100%;
    margin: 20px auto 32px;
    padding: 0 20px;
  }
`;

export const MapField = styled.div`
  height: 250px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
`;
