export const truncate = (value: string, limit: number): string => {
  if (value.length <= limit) {
    return value;
  }

  const substring = value.substring(0, limit);
  const index = substring.lastIndexOf(' ');
  return value.substring(0, index) + '...';
};

export const formatDate = (isoDateTime: string): string => {
  const [date] = isoDateTime.split('T');
  return date.split('-').reverse().join('/');
};

export const slugify = (param: string): string => {
  return param.replace(/\s+/g, '-').toLowerCase();
};
