import styled from 'styled-components';
import observatoryTouristBG from '../../assets/images/ObservatoryTourist_Bg.svg';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 0 50px;

  .basic-service-button {
    padding: 10px 0;
  }

  @media (max-width: 980px) {
    padding: 0 16px;

    .MuiGrid-container {
      display: -webkit-box;
      width: 100%;
      margin: 0 0 10px;
      flex-wrap: unset;
      gap: 20px;
      overflow: scroll;
    }

    .MuiGrid-container .MuiGrid-item {
      padding: 0;
    }

    .basic-service-button {
      padding: 10px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;

export const BGIntro = styled.div`
  display: block;
  width: 100%;
  height: 100vh;

  p {
    position: absolute;
    color: #fff;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
  }

  .bg-to {
    background: url(${observatoryTouristBG}) center center / cover no-repeat;
    display: block;
    width: 100%;
    height: 100vh;
  }

  @keyframes loading {
    0% {
      content: 'Carregando.';
    }
    50% {
      content: 'Carregando..';
    }
    100% {
      content: 'Carregando...';
    }
  }

  .loading-text::after {
    content: 'Carregando';
    animation: loading 3s infinite;
  }

  @media (max-width: 980px) {
    p {
      font-size: 25px;
    }
  }
`;
