import React from 'react';
import Card from 'components/Card';
import { Media } from './styles';

interface IProps {
  src?: string;
  link?: string;
  alt: string;
}

const ImageCard = (props: IProps): JSX.Element | null => {
  const { src, link, alt } = props;

  return (
    <Card>
      <Media style={{ background: `url(${src}) center center / cover no-repeat` }}>
        {link ? (
          <a href={link}>
            <span className="sr-only">{alt}</span>
          </a>
        ) : (
          <span className="sr-only">{alt}</span>
        )}
      </Media>
    </Card>
  );
};

export default ImageCard;
