import styled from 'styled-components';

export const InfoCardHours = styled.div`
  display: block;
  position: relative;

  small {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    color: #ffcb29;
    cursor: pointer;

    img {
      margin-left: 5px;
    }
  }

  .emptyHour {
    pointer-events: none;
    cursor: default;
  }

  section {
    position: unset;
  }

  section::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000066;
    z-index: 9999;
  }

  article {
    display: block;
    position: unset;
  }
`;

export const InfoCardOpeningHours = styled.ul`
  display: block;
  flex-direction: column;
  list-style: none;
  width: 271px;
  height: auto;
  padding: 5px 10px;
  position: absolute;
  top: 100%;
  background: #fff;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 99999;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
`;
