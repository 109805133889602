import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 70px;
  padding: 0 5%;
`;

export const AccomodationLink = styled.div`
  a {
    text-decoration: none;
    font-size: 1rem;
    color: #272727;
  }
`;
