import React from 'react';
import PublicService from 'services/PublicService';
import { ActivitiesListComponent } from 'features/Activities/pages/List';

const TransportsList = (): JSX.Element => {
  const getActivities = async () => {
    const service = new PublicService();
    return service.getAllTransports();
  };

  return <ActivitiesListComponent getActivities={getActivities} />;
};

export default TransportsList;
