import React, { useRef, useState } from 'react';
import yup from 'utils/validation';
import { Container, Divider, FormRow, LabelData } from '../TouristAccount/styles';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import Button from 'components/Buttom/Button';
import ToggleField from 'components/ToggleField';
import ProfileImage from 'components/ProfileImage';
import ChangePasswordButton from 'features/Account/components/ChangePasswordButton';

interface Props {
  setExcludeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  saveUserData?: (userData: any) => Promise<void>;
  userData: any;
}

const AdminAccount = (props: Props): JSX.Element => {
  const form = useRef<FormikProps<FormikValues>>(null);
  const [editing, setEditing] = useState(false);

  const validationSchema = yup.object({
    document: yup.string().length(14).required(),
    email: yup
      .string()
      .email()
      .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
      .required(),
    name: yup.string().required(),
    extraInfo: yup.object({
      registration: yup.string().required(),
      role: yup.string().required(),
    }),
  });

  const handleCancelButtonClick = () => {
    form?.current?.setValues(props.userData);
    setEditing(false);
  };

  const handleSaveButtonClick = async () => {
    await form?.current?.submitForm();

    if (!form?.current?.isValid) {
      return;
    }

    setEditing(false);
  };

  const handleSubmit = async (values: any) => {
    if (props.saveUserData) {
      await props.saveUserData(values);
    }
  };

  const onlyViewForm = (): JSX.Element => {
    return (
      <Formik
        innerRef={form}
        initialValues={props.userData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize>
        <Form>
          <FormRow id="only-view-first-row">
            <ProfileImage name="profileImage" />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {editing ? <label>Nome Completo</label> : <span>Administrador</span>}
              <ToggleField showField={editing} name="name" />
            </div>
            {!editing && <Button text={'EDITAR'} onClick={() => setEditing(true)} />}
          </FormRow>
          <FormRow id="superAdminField">
            <LabelData>
              <label>CPF</label>
              <ToggleField disabled={true} showField={editing} name="document" />
            </LabelData>
            <LabelData>
              <label>Matrícula</label>
              <ToggleField disabled={true} showField={editing} name="extraInfo.registration" />
            </LabelData>
          </FormRow>
          <FormRow id="only-view-fifth-row">
            <LabelData>
              <label>Setor</label>
              <ToggleField disabled={true} showField={editing} name="extraInfo.role" />
            </LabelData>
            <LabelData>
              <label>E-mail</label>
              <ToggleField showField={editing} name="email" />
            </LabelData>
          </FormRow>
        </Form>
      </Formik>
    );
  };
  return (
    <Container>
      {onlyViewForm()}
      <Divider />
      <div className="formButton">
        <div className="buttonsRequest">
          <ChangePasswordButton />
        </div>
        {editing && (
          <div className="editButtons">
            <Button text={'Cancelar'} onClick={handleCancelButtonClick} />
            <Button text={'Salvar'} type="submit" onClick={handleSaveButtonClick} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default AdminAccount;
