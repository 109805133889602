import styled from 'styled-components';

export const SocialMediaCard = styled.section`
  display: flex;
  width: 100%;
  margin-bottom: 50px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;

  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    padding: 0 20px;
  }
`;

export const DetailCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.875rem;
  background: #fff;
  border-radius: 18px;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);

  h4 {
    font-size: 1.438rem;
    margin-bottom: 1.25rem;
  }

  a {
    color: #272727;
    text-decoration: none;
    display: flex;
    margin-bottom: 0.625rem;
    display: flex;
    width: fit-content;
  }

  p {
    font-size: 1rem;
    color: #272727;
    font-weight: 400;
  }

  a img {
    margin-right: 0.281rem;
  }

  @media (max-width: 960px) {
    margin: 0 0 5% 0;
    width: 100%;
    padding: 1rem;

    h4 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.925rem;
    }

    a {
      font-size: 0.875rem;
      word-break: break-all;
    }
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65%;

  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const VideoArea = styled.div`
  margin-bottom: 3.125rem;
`;

export const DescriptionArea = styled.div`
  margin-bottom: 3.125rem;
`;

export const ContactArea = styled.div`
  @media (max-width: 960px) {
    margin-bottom: 3.125rem;
  }
`;

export const SocialMediaArea = styled.div`
  grid-area: socialMedia;
`;
