import React from 'react';
import IEvent from 'interfaces/IEvent';
import EventsForm from './components/EventsForm';

export interface TouristOffersEventsOverviewListProps {
  disabled: boolean;
  item: IEvent | undefined;
}

const TouristOffersEventsOverviewList = ({ disabled, item }: TouristOffersEventsOverviewListProps): JSX.Element => {
  return <EventsForm disabled={disabled} item={item} />;
};

export default TouristOffersEventsOverviewList;
