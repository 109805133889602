import React, { useEffect, useState } from 'react';
import DynamicForm from 'components/DynamicForm';
import IItinerary from 'interfaces/IItinerary';
import { useLoading } from 'hooks/useLoading';
import { useHistory, useParams } from 'react-router';
import ItineraryService from 'services/ItineraryService';
import ItineraryCategoryService from 'services/ItineraryCategoryService';
import ImageService from 'services/ImageService';
import IFormSetur from 'interfaces/IFormSetur';
import { daysOfWeek } from 'components/DynamicForm/utils';
import MainTitle from 'components/MainTitle';
import MessageModal from 'common/MessageModal';

const ItineraryForm = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [itinerary, setItinerary] = useState<IItinerary | undefined>(undefined);
  const [itineraryList, setItineraryList] = useState<{ value: string; label: string }[]>([]);
  const [itineraryCategories, setItineraryCategories] = useState<string[]>([]);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const itineraryService = new ItineraryService();
  const imageService = new ImageService();
  const itineraryCategoryService = new ItineraryCategoryService();
  const [editingMode, setEditingMode] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });
  const [formValuesHour, setFormValuesHour] = useState<IFormSetur>({});

  useEffect(() => {
    openLoading();
    Promise.all([fetchItineraryCategories(), fetchItinerary(), fetchItineraryList()])
      .then(() => closeLoading())
      .catch(error => {
        console.error(error);
        closeLoading();
      });
  }, []);

  const fetchItineraryCategories = async () => {
    const categories = await itineraryCategoryService.findAll();
    setItineraryCategories(categories.map(category => category.description));
  };

  const fetchItinerary = async () => {
    const itineraryId = Number(id);
    if (Number.isNaN(itineraryId)) {
      return;
    }

    const data = await itineraryService.findOne(itineraryId);
    setItinerary(data);
    setEditingMode(true);

    if (data.openingHoursDetail) {
      const openingHoursArray = data.openingHoursDetail.map(detail => {
        const [openTime, closingTime] = detail.split(' - ');
        const isOpen = openTime !== 'fechado';
        const isClosed = !isOpen || closingTime === 'fechado';
        const openTimeToAdd = isClosed ? '' : openTime;
        return { openTime: openTimeToAdd, closingTime, isClosed };
      });

      const updatedFormValues = {
        ...formValuesHour,
        openTime: openingHoursArray.map(item => item.openTime),
        closingTime: openingHoursArray.map(item => item.closingTime),
        isClosed: openingHoursArray.map(item => item.isClosed),
      };

      setFormValuesHour(updatedFormValues);
    }
  };

  const fetchItineraryList = async () => {
    const data = (await itineraryService.findAll())
      .filter(item => item.itineraryId === null)
      .map(item => ({ label: item.title, value: item.id.toString() }));
    setItineraryList(data);
  };

  const handleCancel = () => {
    history.push('/admin/config/itineraries');
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);

    if (messageInfo.title === 'Sucesso') {
      history.push('/admin/config/itineraries');
    }
  };

  const handleSubmit = async (values: IFormSetur) => {
    const images = await imageService.uploadMultiple(values.banners || []);
    const banners = await imageService.uploadMultiple(values.images || []);
    const openingHoursDetail: Array<string> = daysOfWeek.map((day, index) => {
      const openTime = values.openTime?.[index] || '';
      const closingTime = values.closingTime?.[index] || '';

      if (values.isClosed?.[index]) {
        return 'fechado';
      }
      if (!openTime && !closingTime) {
        return '';
      }
      return `${openTime} - ${closingTime}`;
    });

    try {
      const itineraryData: IItinerary = {
        id: 0,
        title: values.title || '',
        slug: values.slug || '',
        description: values.description || '',
        activities: values.activities?.split('\n') || [],
        itineraryId: Number(values.mainItinerary) || null,
        date: values.startDate || '',
        category: values.category || [],
        youtubeLink: values.youtubeLink || '',
        placeId: values.placeId || '',
        address: values.address || '',
        addressInfo: {
          zipCode: values.addressInfo?.zipCode || '',
          street: values.addressInfo?.street || '',
          number: values.addressInfo?.number || '',
          neighborhood: values.addressInfo?.neighborhood || '',
          additionalData: values.addressInfo?.additionalData || '',
          city: values.addressInfo?.city || '',
          state: values.addressInfo?.state || '',
        },
        banners: banners.map(image => ({ image })),
        images: images.map(image => ({ image })),
        openingHours: values.openingHours || '',
        openingHoursDetail,
        attachmentMap: values.attachmentMap || '',
      };
      if (editingMode && itinerary?.id) {
        await itineraryService.update(itinerary.id, itineraryData);
        const messageWithSpan = 'Roteiro <span>editado</span> com sucesso!';
        setMessageInfo({
          title: 'Sucesso',
          message: messageWithSpan,
        });
        setShowMessageModal(true);
      } else {
        await itineraryService.create(itineraryData);
        const messageWithSpan = 'Roteiro criado com sucesso!';
        setMessageInfo({
          title: 'Sucesso',
          message: messageWithSpan,
        });
        setShowMessageModal(true);
      }
    } catch (error) {
      const messageWithSpan = editingMode
        ? 'Erro ao <span>editar</span> roteiro.'
        : 'Erro ao <span>criar</span> roteiro.';
      setMessageInfo({
        title: 'Erro',
        message: messageWithSpan,
      });
      setShowMessageModal(true);
    }
  };

  const fieldsToDisplay = [
    'MainItinerary',
    'MainInfo',
    'Activities',
    'AddressField',
    'MapAndVideo',
    'VistingHoursDetail',
    'UploadImageCarousel',
    'UploadImageLibrary',
  ];
  const formValues: IFormSetur = {
    ...itinerary,
    category: itinerary?.category,
    mainItinerary: itinerary?.itineraryId?.toString() ?? '',
    activities: itinerary?.activities.join('\n') || '',
    banners: itinerary?.banners?.map(({ image }) => image) || [],
    images: itinerary?.images?.map(({ image }) => image) || [],
    ...formValuesHour,
  };

  return (
    <>
      <MainTitle>Roteiros</MainTitle>
      <DynamicForm
        formName={editingMode ? 'Editar Roteiro' : 'Novo Roteiro'}
        fields={fieldsToDisplay}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        values={formValues}
        categories={itineraryCategories}
        itineraryList={itineraryList}
      />
      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          title={messageInfo.title}
          message={messageInfo.message}
          onClose={handleCloseMessageModal}
        />
      )}
    </>
  );
};

export default ItineraryForm;
