import styled from 'styled-components';

export const Section = styled.div`
  margin-top: 40px;
  border-bottom: inherit;
  width: 100%;

  .input-box {
    display: flex;
    flex-direction: column;
    width: 38%;
  }

  .input-title,
  .input-link {
    display: flex;
    width: 100%;
    height: 40px;
  }

  .carousel-list {
    max-height: fit-content;
  }

  @media (max-width: 450px) {
    .input-box {
      margin-left: 10px;
    }
  }
`;

export const VisualIdentitySection = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const CarouselHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Paragraph = styled.p`
  margin: 15px 0px;
`;

export const BannerImage = styled.div`
  display: flex;
  justify-content: end;
  width: 228px;
  height: 100px;
  border-radius: 18px;

  &:hover {
    cursor: pointer;
  }

  .add-image-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const SettingsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 10px 0 10px 10px;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    margin: 16px 0;
  }

  .add-image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9d9d9;
  }
`;

export const CloseImageButton = styled.button`
  margin: 5px 5px 0 0;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border: none;
  background: #0f0;
  border-radius: 20px;
  background: #ffcb29;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #020c90;
  }
`;
export const AddImageButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  margin: 5px 5px 0 0;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  border: none;
  background: #0f0;
  border-radius: 20px;
  background: #ffcb29;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #020c90;
  }
`;
