import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Grid } from '@mui/material';
import { formatDate } from 'utils/string';
import PublicService from 'services/PublicService';
import INews from 'interfaces/INews';
import ImageCard from 'components/ImageCard';
import MainTitle from 'components/MainTitle';
import { Author, Banner, Container, Date, SubTitle, Text, Title } from './styles';
import { useLoading } from 'hooks/useLoading';

const Detail = (): JSX.Element => {
  const { slug } = useParams<{ slug: string }>();
  const [news, setNews] = useState<INews | undefined>();
  const { openLoading, closeLoading } = useLoading();

  const service = new PublicService();

  useEffect(() => {
    getNews();
  }, []);

  const getNews = async () => {
    try {
      openLoading();
      const news = await service.getNews(slug);
      setNews(news);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  return (
    <Container>
      <MainTitle>Notícias</MainTitle>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Title>{news?.title}</Title>
          </Grid>
          {news?.subtitle && (
            <Grid item xs={12}>
              <SubTitle>{news?.subtitle}</SubTitle>
            </Grid>
          )}
          <Grid item xs={12}>
            <Banner
              style={{
                background: `url(${news?.banner}) center / cover no-repeat`,
              }}
            />
          </Grid>
        </Grid>
        <Grid container className="bottom-grids" rowSpacing={2}>
          <Grid item xs={12}>
            <Date>{formatDate(news?.date ?? '')}</Date>
          </Grid>
          <Grid item xs={12}>
            <Author>{news?.author}</Author>
          </Grid>
          <Grid item xs={12}>
            <Text>{news?.description}</Text>
          </Grid>
        </Grid>
        <Grid container className="bottom-grids" spacing={4}>
          {news?.images &&
            news.images.map(({ image }, index) => (
              <Grid item key={index} xs={12} sm={6} lg={3}>
                <ImageCard src={image} alt="" />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Detail;
