import React, { useState } from 'react';
import IAuthState from '../../interfaces/reducers/IAuthState';
import authInitialState from './initialAuthState';

export interface IAppState {
  locale: string;
  screenWidth: number;
  authState: IAuthState;
}

interface IContextProps {
  appState: IAppState;
  appLogin: (accessToken: string) => void;
  appLogout: () => void;
}

interface IProviderProps {
  children: React.ReactNode;
  initialState?: IAppState;
}

export const initialAppState: IAppState = {
  locale: 'pt-BR',
  screenWidth: window.innerWidth,
  authState: authInitialState,
};

const initialProps: IContextProps = {
  appState: initialAppState,
  appLogin: (accessToken: string) => {
    console.log(accessToken);
  },
  appLogout: () => {
    console.log('logout');
  },
};

export const AppContext = React.createContext<IContextProps>(initialProps);

export const AppProvider = ({ children, initialState = initialAppState }: IProviderProps): JSX.Element => {
  const [appState, setAppState] = useState<IAppState>(initialState);

  const appLogin = (accessToken: string) => {
    setAppState({
      ...appState,
      authState: {
        ...appState.authState,
        accessToken: accessToken,
        isAuth: true,
      },
    });
  };

  const appLogout = () => {
    setAppState({
      ...appState,
      authState: {
        ...appState.authState,
        accessToken: '',
        isAuth: false,
      },
    });
  };

  return <AppContext.Provider value={{ appState, appLogin, appLogout }}>{children}</AppContext.Provider>;
};

export default AppContext;
