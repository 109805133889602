import React from 'react';
import { Grid, Modal as MaterialModal, ModalProps } from '@mui/material';
import { Container, Panel } from './styles';

const Modal = (props: ModalProps): JSX.Element => {
  const { children, ...modalProps } = props;

  return (
    <MaterialModal {...modalProps}>
      <Container>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid xs={8} md={6} lg={4} xl={3} item>
            <Panel>{children}</Panel>
          </Grid>
        </Grid>
      </Container>
    </MaterialModal>
  );
};

export default Modal;
