import styled from 'styled-components';

export const DetailingClass = styled.div`
  display: block;
  margin: 0 auto 30px;
  width: 100%;

  .Trends {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .LongTimeView iframe {
    height: 450px;
    margin-bottom: 30px;
  }

  .DetailingView iframe {
    height: 460px;
    margin-bottom: 30px;
  }

  .Trends iframe {
    width: 50%;
    min-height: 400px;
    margin-bottom: 30px;
  }

  @media (max-width: 1366px) {
    .Trends iframe {
      min-height: 595px;
    }
  }

  @media (max-width: 980px) {
    .Trends {
      flex-direction: column;
      min-height: 400px;
    }

    .Trends iframe {
      width: 100%;
    }
  }
`;
