import { userLogout } from 'services/localStorage';
import { auth } from 'utils/firebase';

let activityTimestamp: number | null = null;
let inactivityTimeout: number | NodeJS.Timeout;

function logoutDueToInactivity() {
  auth.signOut().then(() => {
    userLogout();
    window.location.reload();
  });
}

function renewTokenIfNeeded() {
  const now = Date.now();

  if (activityTimestamp && now - activityTimestamp < 5 * 60 * 1000) {
    auth.currentUser?.getIdToken(true);
  } else {
    logoutDueToInactivity();
  }
}

function resetInactivityTimeout() {
  activityTimestamp = Date.now();
}

export function startAuthMonitoring() {
  window.addEventListener('mousemove', resetInactivityTimeout);
  window.addEventListener('keydown', resetInactivityTimeout);
  window.addEventListener('click', resetInactivityTimeout);

  setInterval(renewTokenIfNeeded, 55 * 60 * 1000);
}
