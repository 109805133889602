import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: -120px;
  margin-bottom: 120px;
  float: right;
  padding: 10px 30px;
  width: 650px;
  z-index: 999;
  background: #fff;
  border-radius: 6px 0 0 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: -71px;
    margin-bottom: 60px;
    padding: 10px 16px;
    width: 100%;
    border-radius: 0;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #272727;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  button {
    width: 250px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 150px;
      min-width: 70px;
      padding: 10px;
      font-size: 12px;
    }
  }
`;
