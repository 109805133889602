import styled from 'styled-components';
import check from 'assets/images/check.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  width: 50%;
  height: 60%;
  border-radius: 18px;
  padding: 30px;
  h2 {
    color: #272727;
    text-align: center;
    font-size: 1.5vw;
    font-weight: 500;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18vw;
    height: 5vh;
    border-radius: 18px;
    border-color: transparent;
    background: #ffcb29;
    padding: 0 1vw;
    margin-bottom: 10px;
    &:first-of-type {
      margin-top: 10px;
    }
    img {
      width: 2.5vw;
      height: 2.5vh;
      margin-right: 1vw;
    }
    span {
      color: #fff;
      font-size: 1vw;
      font-weight: 700;
      white-space: nowrap;
    }
  }

  button:hover {
    background: #020c90;
  }

  p {
    text-align: center;
    margin-bottom: 10px;
  }
  a {
    cursor: pointer;
    color: black;
  }
  label {
    display: flex;
    align-items: center;
    input {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid black;
      border-radius: 3px;
    }
    input[type='checkbox']:checked {
      background: url(${check}) center no-repeat;
      border: none;
    }
  }

  @media (max-width: 960px) {
    width: 90%;

    h2 {
      font-size: 1rem;
    }

    button {
      width: auto;

      img {
        width: 20px;
        height: 20px;
        margin-right: 1vw;
      }
      span {
        font-size: 12px;
      }
    }
  }
`;
