import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import configIcon from '../../assets/images/configIcon.svg';
import { NavLink } from 'react-router-dom';

const MenuSeturMarketing: React.FC = () => {
  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/config">
            <img src={configIcon} alt="icon for list item" />
            <span>Configurações</span>
          </NavLink>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default MenuSeturMarketing;
