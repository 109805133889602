import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { auth } from 'utils/firebase';
import { Container, Content, SelectLoginDiv, SelectDiv } from './styles';
import SelectProfile from 'common/SelectProfile';
import LoginTourist from 'features/Login/pages/LoginTourist/';
import AppContext from 'common/AppContext';
import { logged } from 'features/Login/store/LoginAtom';
import { getLocalUser, setAccessToken, setLocalUser } from 'services/localStorage';
import UserService from 'services/UserService';
import { useLoading } from 'hooks/useLoading';

const SignUpTourist = (): JSX.Element => {
  const history = useHistory();
  const { openLoading, closeLoading } = useLoading();
  const { appLogin } = useContext(AppContext);
  const [, setLoggedState] = useRecoilState(logged);
  const service = new UserService();

  const touristLoginAction = async (token: string) => {
    if (!token) {
      console.error('Empty token returned!');
      return;
    }
    try {
      openLoading();

      const user = await service.saveTourist(token);

      const localUser = getLocalUser();
      localUser.id = user.firebaseId;
      localUser.email = user.email;
      localUser.name = user.name;
      localUser.profile.name = user.type;
      localUser.profile.image = auth.currentUser?.photoURL || '';
      localUser.emailVerified = true;

      setLocalUser(localUser);
      setAccessToken(token);
      appLogin(token);
      setLoggedState(true);
      closeLoading();
      history.push('/my-experiences');
    } catch (error) {
      closeLoading();
    }
  };

  const [profile, setProfile] = useState(0);
  return (
    <Container>
      <Content>
        <SelectDiv>
          <SelectProfile setProfile={setProfile} />
        </SelectDiv>
        <SelectLoginDiv>
          <LoginTourist touristLoginAction={touristLoginAction} />
        </SelectLoginDiv>
      </Content>
    </Container>
  );
};

export default SignUpTourist;
