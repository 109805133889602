import React, { useEffect, useState } from 'react';
import {
  UploadPortalNameContainer,
  UploadPortalNameHeader,
  UploadPortalNameTitle,
  InputLabel,
  Input,
  UploadPortalNameActionButtons,
  UploadPortalNameCancelButton,
  UploadPortalNameSaveButton,
} from './styles';
import { useLoading } from 'hooks/useLoading';
import VisualIdentityService from 'services/VisualIdentityService';
import { usePortalContext } from 'common/PortalContext';

interface UploadPortalNameProps {
  name: string;
}

const UploadPortalName = ({ name }: UploadPortalNameProps): JSX.Element => {
  const [showEditInput, setShowEditInput] = useState(false);
  const [portalName, setPortalName] = useState('');
  const { openLoading, closeLoading } = useLoading();
  const portalContext = usePortalContext();

  useEffect(() => {
    setPortalName(name);
  }, [name]);

  const cancelSubmitLink = () => {
    setPortalName(name);
    setShowEditInput(false);
  };

  const submitPortalName = async (portalName: string) => {
    openLoading();
    try {
      portalContext.setPortalName(portalName);
      await new VisualIdentityService().patchPortalName(portalName);
      setShowEditInput(false);
    } catch (error: any) {
      if (error.response.status === 404) {
        await new VisualIdentityService().postLogo('', portalName);
        setShowEditInput(false);
      }
    } finally {
      closeLoading();
    }
  };

  return (
    <UploadPortalNameContainer>
      <UploadPortalNameHeader>
        <UploadPortalNameTitle>Nome do Portal</UploadPortalNameTitle>
        {showEditInput ? (
          <UploadPortalNameActionButtons>
            <UploadPortalNameCancelButton onClick={cancelSubmitLink}>Cancelar</UploadPortalNameCancelButton>
            <UploadPortalNameSaveButton
              type="submit"
              onClick={() => submitPortalName(portalName)}
              disabled={!portalName}>
              Salvar
            </UploadPortalNameSaveButton>
          </UploadPortalNameActionButtons>
        ) : (
          <InputLabel htmlFor="link" onClick={() => setShowEditInput(true)}>
            Editar
          </InputLabel>
        )}
      </UploadPortalNameHeader>
      <Input
        className="link-input"
        id="link"
        type="url"
        placeholder={portalName || 'Insira aqui o nome do Portal'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPortalName(event.target.value)}
        disabled={!showEditInput}
        value={portalName}
      />
    </UploadPortalNameContainer>
  );
};

export default UploadPortalName;
