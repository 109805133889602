import React, { useEffect, useState } from 'react';
import { Anchor, Container } from './styles';
import InstragamPost from 'common/InstagramPost/InstragamPost';
import Button from '../../components/Buttom/Button';
import fetchPosts, { Post } from 'services/InstagramService';
import SubTitle from 'common/SubTitle/SubTitle';

const SocialMedia = (): JSX.Element => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchInstagramPosts = async () => {
      const data = await fetchPosts();
      setPosts(data);
    };
    fetchInstagramPosts();
    return () => abortController.abort();
  }, []);

  return (
    <Container>
      <SubTitle text="Mídias Sociais | @portoseguroturismo" />
      <ul>
        {posts.map(post => {
          return <InstragamPost key={post.id} post={post} />;
        })}
      </ul>
      <Anchor href="https://www.instagram.com/portoseguroturismo/" target="_blank" rel="noreferrer">
        <Button text="ver todos os posts" />
      </Anchor>
    </Container>
  );
};

export default SocialMedia;
