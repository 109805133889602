import React, { useEffect, useState } from 'react';
import { Container, Title } from './styles';
import SelectDestination from '../SelectDestination';
import { IDestinationSlider } from 'interfaces/IDestinationsBannerts';
import DestinationsCarousel from '../DestinationsCarousel';
import DestinationActivities from '../Activities';
import PublicService from 'services/PublicService';
import { useLoading } from 'hooks/useLoading';
import { useHistory, useParams } from 'react-router';

const DestinationsPage = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();
  const params = useParams<{ destination?: string }>();
  const [selected, setSelected] = useState(params.destination || 'porto-seguro');
  const [destinations, setDestination] = useState<IDestinationSlider[]>([]);

  useEffect(() => {
    history.replace(`/destinations/${selected}`);
  }, [selected]);

  const getData = async () => {
    try {
      openLoading();
      const service = new PublicService();
      await service.getDestinations().then(setDestination);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const destination = destinations.find(destination => {
    return destination.slug === params.destination;
  });

  return (
    <Container>
      <Title>
        <div />
        <h2>Conheça Nossos Destinos</h2>
        <div />
      </Title>
      <SelectDestination selected={selected} setSelected={setSelected} />
      <DestinationsCarousel banners={destination?.banners || []} />
      <DestinationActivities activities={destination?.activities || []} experiences={destination?.experiences || []} />
    </Container>
  );
};

export default DestinationsPage;
