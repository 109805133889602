import React, { useState, useEffect } from 'react';
import {
  FormControl,
  Backgroud,
  Footer,
  Header,
  Input,
  Label,
  Title,
  InputLabel,
  Content,
  TitleContainer,
  AlignRight,
  CountLabel,
  FileViewer,
  ErrorContainer,
} from './styles';
import ImageService from 'services/ImageService';
import DocsService from 'services/DocsService';
import { Modal } from 'components/Modals';
import Button from 'components/Button';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useLoading } from 'hooks/useLoading';
import closeYellow from 'assets/images/closeYellow.svg';
import MessageModal from 'common/MessageModal';

const labels = ['Sem seleção', 'Arraial d’Ajuda', 'Caraíva', 'Porto Seguro', 'Trancoso', 'Vale Verde'];

const FileImportModal = ({
  isOpen,
  toggle,
  file,
}: {
  isOpen: boolean;
  file: File | null;
  toggle: (payload?: boolean) => void;
}): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [radio, setRadio] = useState<EventTarget | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isFileViewerVisible, setIsFileViewerVisible] = useState(false);
  const [isFileImported, setIsFileImported] = useState(false);

  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageText, setMessageText] = useState('');

  useEffect(() => {
    if (file?.name) {
      const img = file;
      setIsFileViewerVisible(true);
      setFileToUpload(img);
      setIsFileImported(true);
      setError(null);
    }
  }, [file]);

  const cancelUpload = () => {
    event?.preventDefault();
    toggle(false);
    setIsFileViewerVisible(false);
  };

  const onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.preventDefault();

    if (event.target.files && event.target.files.length) {
      const img = event.target.files[0];
      setFileToUpload(img);
      setIsFileImported(true);
      setError(null);
    }
  };

  const fileService = new ImageService();
  const service = new DocsService();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setError(null);
      openLoading();
      e.preventDefault();

      const value = (radio as any)?.value;

      let isError = false;
      if (!value || value === labels[0]) {
        setError('Por favor, selecione o destino de referência!');
        isError = true;
      }
      if (fileToUpload == null) {
        setError(err => `${err ?? ''}, Por favor, faça o UPLOAD do comprovante!`);
        isError = true;
      }

      if (isError) {
        closeLoading();
        return;
      }

      if (!fileToUpload) {
        return;
      }

      const link = await fileService.upload(fileToUpload);
      await service.create({ link, description: value });

      setMessageTitle('Envio de Comprovante');
      setMessageText('Comprovante enviado com sucesso!');
      setIsMessageModalOpen(true);

      toggle(false);
    } catch (error) {
      setMessageTitle('Envio de Comprovante');
      setMessageText('Ocorreu um erro durante o envio do comprovante.');
      setIsMessageModalOpen(true);

      toggle(false);
      closeLoading();
    } finally {
      closeLoading();
    }
  };

  return (
    <div>
      <MessageModal
        isOpen={isMessageModalOpen}
        title={messageTitle}
        message={messageText}
        onClose={() => setIsMessageModalOpen(false)}
        ButtonText="Ok"
      />

      <Modal isOpen={isOpen} toggle={() => null}>
        <FormControl onSubmit={handleSubmit}>
          <Backgroud>
            <Header>
              <TitleContainer>
                <Title>Comprovante de Destino</Title>
                <CountLabel>1/1</CountLabel>
              </TitleContainer>
              <Label>O arquivo deve ser PDF, PNG, JPG ou JPEG</Label>

              {isFileViewerVisible && (
                <FileViewer>
                  <p>{fileToUpload?.name ?? 'Sem Arquivo'}</p>
                  {fileToUpload?.name && <img src={closeYellow} alt="Close" onClick={() => setFileToUpload(null)} />}
                </FileViewer>
              )}

              <AlignRight>
                <InputLabel
                  htmlFor="arquivo"
                  onClick={() => {
                    setIsFileViewerVisible(true);
                  }}>
                  IMPORTAR ARQUIVO
                </InputLabel>
                <Input
                  hidden
                  onChange={onImport}
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  id="arquivo"
                  name="arquivo"
                  type="file"
                />
              </AlignRight>
            </Header>
            <Content>
              <FormLabel id="buttons-group-label">Selecione o destino de referência do comprovante:</FormLabel>
              <RadioGroup
                onChange={e => {
                  setError(null);
                  setRadio(e.target);
                }}
                onLoad={e => setRadio(e.target)}
                aria-labelledby="buttons-group-label"
                defaultValue="0"
                name="radio-buttons-group">
                <FormControlLabel
                  style={{ display: 'none' }}
                  value="0"
                  control={<Radio />}
                  label={labels[0]}
                  defaultChecked
                />
                <FormControlLabel value="1" control={<Radio />} label={labels[1]} />
                <FormControlLabel value="2" control={<Radio />} label={labels[2]} />
                <FormControlLabel value="3" control={<Radio />} label={labels[3]} />
                <FormControlLabel value="4" control={<Radio />} label={labels[4]} />
                <FormControlLabel value="5" control={<Radio />} label={labels[5]} />
              </RadioGroup>

              <ErrorContainer>{error && error.split(',').map((err, key) => <p key={key}>{err}</p>)}</ErrorContainer>
            </Content>

            <Footer>
              <Button buttonType="outline" className="cancel-button" onClick={cancelUpload}>
                Cancelar
              </Button>
              <Button type="submit" disabled={false}>
                Enviar
              </Button>
            </Footer>
          </Backgroud>
        </FormControl>
      </Modal>
    </div>
  );
};

export default FileImportModal;
