import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PublicService from 'services/PublicService';
import ActivityCard from 'components/ActivityCard';
import AddBookmark from 'components/AddBookmark';
import { Container } from './styles';
import { useLoading } from 'hooks/useLoading';
import { IBusiness } from 'interfaces/IBusinesses';

const ActivitiesList = (): JSX.Element => {
  const getActivities = async () => {
    const service = new PublicService();
    return service.getAllActivities();
  };

  return <ActivitiesListComponent getActivities={getActivities} />;
};

interface IProps {
  getActivities: () => Promise<IBusiness[]>;
}

export const ActivitiesListComponent = (props: IProps): JSX.Element => {
  const [activities, setActivities] = useState<IBusiness[]>([]);
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      openLoading();
      const data = await props.getActivities();
      setActivities(data);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          {activities?.map((activity, index) => (
            <Grid key={index} xs={12} sm={6} md={4} lg={3} xl={3} item>
              <ActivityCard
                image={activity.images?.[0].image}
                category={activity.businessType}
                title={activity.title ? activity.title : activity.tradeName}
                description={activity.description}
                link={`/activities/${activity.id}`}
                action={<AddBookmark type="business" id={activity.id} />}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ActivitiesList;
