import styled from 'styled-components';

export const SettingsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 10px 0 10px 10px;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    margin: 16px 0;
  }
`;

export const SettingsList = styled.ul`
  max-height: 200px;
  overflow: auto;
  padding: 0 10px;

  &::-webkit-scrollbar {
    margin-right: 500px;
    width: 5px;
    height: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
    border-radius: 5px;
  }

  @media (max-width: 960px) {
    height: 100%;
    max-height: 100%;
  }
`;

export const SettingsListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px dashed #9a9a9a;
`;

export const SettingsListItemTitle = styled.h3`
  color: #272727;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;

export const SettingsListItemButtonList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SettingsListItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 20px;
  background: transparent;
  border: none;

  &:hover {
    img {
      filter: opacity(1) brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg)
        brightness(63%) contrast(126%);
    }
  }
`;
