import React from 'react';
import { IBusiness, SegmentationAPI } from 'interfaces/IBusinesses';
import AccomodationsForm from './components/AccomodationsForm';
import ToursForm from './components/ToursForm';
import RestaurantsForm from './components/RestaurantsForm';
import TransportsForm from './components/TransportsForm';

export interface TouristOffersBusinessOverviewListProps {
  disabled: boolean;
  item: IBusiness | undefined;
}

const TouristOffersBusinessOverviewList = ({ disabled, item }: TouristOffersBusinessOverviewListProps): JSX.Element => {
  const typeList = item?.type;

  switch (typeList) {
    case SegmentationAPI.Acommodations:
      return <AccomodationsForm disabled={disabled} item={item} />;
    case SegmentationAPI.Restaurants:
      return <RestaurantsForm disabled={disabled} item={item} />;
    case SegmentationAPI.Transports:
      return <TransportsForm disabled={disabled} item={item} />;
    case SegmentationAPI.Tours:
      return <ToursForm disabled={disabled} item={item} />;
    default:
      return <></>;
  }
};

export default TouristOffersBusinessOverviewList;
