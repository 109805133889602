import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/src/core/styles.scss';
import { Carousel, Arrow } from './styles';
import IBanner from 'interfaces/IBanner';

interface Props {
  banners?: IBanner[];
}

const ExcursionsCarousel = (props: Props): JSX.Element => {
  const { banners } = props;

  const settings = {
    infinite: true,
    organicArrows: false,
    fillParent: false,
    buttonContentRight: <Arrow>&gt;</Arrow>,
    buttonContentLeft: <Arrow>&lt;</Arrow>,
    window: {
      width: '100%',
      height: 'auto',
    },
  };

  return (
    <Carousel>
      <AwesomeSlider {...settings}>
        {banners?.map(banner => {
          return (
            <div key={banner.id}>
              <img src={banner?.image} alt={banner.description} />
            </div>
          );
        })}
      </AwesomeSlider>
    </Carousel>
  );
};

export default ExcursionsCarousel;
