import React from 'react';
import {
  DescriptionsInfoCard,
  InfoCardTitle,
  InfoCardDescription,
  InfoCardPrice,
  InfoCardPlace,
  InfoCardRatings,
} from './style';
import LabelCategoryCard from 'components/LabelCategory';
import CardHours from 'components/InfoCardHours';
import Button from 'components/Buttom/Button';

interface IInfoCardProps {
  id: number;
  title?: string;
  description?: string;
  ratings?: string;
  categories?: {
    id: number;
    labels: string;
  }[];
  category?: string;
  businessType?: string;
  openingHours?: string;
  openingHoursDetail?: { id: number; hours: string }[];
  date?: string;
  startDate?: string;
  endDate?: string;
  place?: string;
  price?: string;
  link?: string;
  startTime?: string;
  endTime?: string;
  onClick?: () => void;
}

const InfoCard: React.FC<IInfoCardProps> = (props: IInfoCardProps) => {
  return (
    <DescriptionsInfoCard>
      {props.categories && <LabelCategoryCard categories={props.categories} id={props.id} />}
      {props.category && <LabelCategoryCard category={props.category} id={props.id} />}
      {props.businessType && <LabelCategoryCard category={props.businessType} id={props.id} />}
      {props.ratings && <InfoCardRatings>{props.ratings}</InfoCardRatings>}
      {props.startDate && (
        <CardHours
          id={props.id}
          startDate={props.startDate}
          endDate={props.endDate}
          startTime={props.startTime}
          endTime={props.endTime}
        />
      )}
      {props.openingHoursDetail && (
        <CardHours
          id={props.id}
          openingHoursDetail={props.openingHoursDetail}
          startDate={props.startDate}
          endDate={props.endDate}
        />
      )}
      {props.price && <InfoCardPrice>{props.price}</InfoCardPrice>}
      {props.title && <InfoCardTitle>{props.title}</InfoCardTitle>}
      {props.place && <InfoCardPlace>{props.place}</InfoCardPlace>}
      {props.description && <InfoCardDescription>{props.description}</InfoCardDescription>}
      {props.link && (
        <Button
          text={`COMPRAR`}
          onClick={() => {
            props.link;
          }}
        />
      )}
    </DescriptionsInfoCard>
  );
};

export default InfoCard;
