import styled from 'styled-components';

export const Container = styled.div`
  .bottom-grids {
    padding: 20px 40px;
  }

  @media (max-width: 980px) {
    .bottom-grids:nth-child(2),
    .bottom-grids:nth-child(3) {
      padding: 20px 16px;
    }

    .bottom-grids:nth-child(3) {
      width: 100%;
      margin-left: 0px;
    }

    .bottom-grids:nth-child(3) .MuiGrid-item {
      padding: 0;
      margin-bottom: 20px;
    }
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SubTitle = styled.p`
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Banner = styled.div`
  width: 100%;
  height: 600px;

  @media (max-width: 980px) {
    height: 200px;
  }
`;

export const Date = styled.p`
  color: #ffcb29;
  font-weight: 900;
`;

export const Author = styled.p`
  font-weight: 900;
`;

export const Text = styled.p`
  white-space: pre-line;
`;
