import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'components/Card';
import {
  ActionDiv,
  Activity,
  Category,
  Date,
  Description,
  Image,
  ImageContent,
  Info,
  InfoContent,
  Title,
} from './styles';

export interface ICardProps {
  category?: string;
  date?: string;
  description: string;
  image: string;
  title: string;
  link?: string;
  action?: JSX.Element;
}

const OptionalLink = (props: { children: JSX.Element; link?: string }): JSX.Element => {
  const { link, children } = props;
  return link ? <Link to={link}>{children}</Link> : children;
};

const ActivityCard = (props: ICardProps): JSX.Element => {
  const { category, date, description, image, title, link, action } = props;

  return (
    <Card>
      <Activity className="activity-card">
        <OptionalLink link={link}>
          <ImageContent
            className="activity-card-image-content"
            style={{ background: `url(${image}) center center / cover no-repeat` }}
          />
        </OptionalLink>
        <InfoContent>
          {category && (
            <Info>
              <Category>{category}</Category>
              {action && <ActionDiv>{action}</ActionDiv>}
            </Info>
          )}
          {date && (
            <Info>
              <Date>{date}</Date>
            </Info>
          )}
          <Info>
            <Title>{title}</Title>
          </Info>
          <Info>
            <Description>{description}</Description>
          </Info>
        </InfoContent>
      </Activity>
    </Card>
  );
};

export default ActivityCard;
