import styled from 'styled-components';

export const SelectMainNewsContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SelectMainNewsItem = styled.li`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const SelectMainNewsSmall = styled.small`
  font-size: 14px;
  line-height: 30px;
  color: #9a9a9a;

  @media (max-width: 1280px) {
    font-size: 12px;
  }
`;
