import React, { useEffect, useState } from 'react';
import { DetailInfo, ImageArea } from './styles';
import InfoCard from 'components/InfoCard';
import { useParams } from 'react-router';
import PublicService from 'services/PublicService';
import ContactCard from 'components/ContactCard';
import MapDetailPage from 'components/Map/DetailMap';
import { useLoading } from 'hooks/useLoading';
import MainTitle from 'components/MainTitle';
import InfoCardPhotos from 'components/InfoCardPhotos';
import { IBusiness } from 'interfaces/IBusinesses';

const ActivitiesDetailCard = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const getActivity = async () => {
    const service = new PublicService();
    return service.getActivities(Number(id));
  };

  return <Activity getActivity={getActivity} />;
};

interface IProps {
  getActivity: () => Promise<IBusiness>;
}

export const Activity = (props: IProps): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [activites, setActivites] = useState<IBusiness | undefined>();

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      openLoading();
      const activites = await props.getActivity();
      setActivites(activites);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  const fullAddress = [
    activites?.address?.street,
    activites?.address?.number,
    activites?.address?.neighborhood,
    activites?.address?.city,
    activites?.address?.state,
    activites?.address?.zipCode,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <MainTitle>Atividades</MainTitle>
      <DetailInfo>
        <InfoCard
          category={activites?.businessType}
          id={activites?.id ?? 0}
          title={activites?.tradeName}
          description={activites?.description}
        />
        <ImageArea
          style={{
            background: `url(${activites?.image ?? ''}) center center / cover no-repeat`,
          }}
        />
      </DetailInfo>
      <InfoCardPhotos images={activites?.images.map(({ image }) => image)} />
      <ContactCard
        key={0}
        email={activites?.tradeEmail}
        facebookLink={activites?.facebookLink}
        instagramLink={activites?.instagramLink}
        phone={activites?.phone}
        site={activites?.site}
        whatsapp={activites?.whatsapp}
        youtubeLink={activites?.youtubeLink}
      />

      <MapDetailPage address={fullAddress} placeId={activites?.googleMapsLink} />
    </>
  );
};

export default ActivitiesDetailCard;
