import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridColumnVisibilityModel,
  GridValueGetterParams,
  GridToolbar,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import infoIcon from '../../../../assets/images/info.svg';
import editIcon from '../../../../assets/images/editIcon.svg';
import xGrayIcon from '../../../../assets/images/xgray_Icon.svg';
import checkGrayIcon from '../../../../assets/images/checkgray_Icon.svg';
import EditSolicitationModal from '../EditSolicitationModal';
import IEvent, { EventStatusAPI } from 'interfaces/IEvent';
import touristOffersEventsService from 'services/TouristOffersEventsService';
import { useLoading } from 'hooks/useLoading';
import DeleteSolicitationModal from '../DeleteSolicitationModal';
import ApproveSolicitationModal from '../ApproveSolicitationModal';
import { useHistory } from 'react-router';

const EventsList = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [eventsData, setEventsData] = useState<IEvent[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });
  const [openModalEditSolicitationData, setOpenModalEditSolicitationData] = useState(false);
  const [openModalDeleteSolicitationData, setOpenModalDeleteSolicitationData] = useState(false);
  const [openModalApproveSolicitationData, setOpenModalApproveSolicitationData] = useState(false);
  const [openModalMoreInfo, setOpenModalMoreInfo] = useState({} as IEvent);
  const [openModalDeleteInfo, setOpenModalDeleteInfo] = useState({} as IEvent);
  const [openModalApproveInfo, setOpenModalApproveInfo] = useState({} as IEvent);
  const history = useHistory();

  useEffect(() => {
    openLoading();
    const fetchEventsData = [fetchTouristOffersEvents()];
    Promise.all(fetchEventsData).finally(() => closeLoading());
  }, []);

  const service = {
    touristOffersEvents: new touristOffersEventsService(),
  };

  const fetchTouristOffersEvents = async () => {
    const data = await service.touristOffersEvents.findAllTrades();
    setEventsData(data);
  };

  const stylingRowStatus = (status?: EventStatusAPI) => {
    switch (status) {
      case EventStatusAPI.APPROVED:
        return <span className="approvedBadge">Aprovado</span>;
      case EventStatusAPI.REFUSED:
        return <span className="reprovedBadge">Recusado</span>;
      case EventStatusAPI.WAITING_FOR_SETUR:
        return <span className="waitingSeturBadge">Aguardando SETUR</span>;
      case EventStatusAPI.WAITING_FOR_TRADE:
        return <span className="waitingTradeBadge">Aguardando Trade</span>;
      default:
        return '- - -';
    }
  };

  const handleEventsDisabledButton = (id: string) => {
    const events = eventsData.find(events => events.id === Number(id));
    if (events?.status !== EventStatusAPI.WAITING_FOR_SETUR) {
      return true;
    }
    return false;
  };

  const eventsEditSolicitation = (id: string) => () => {
    openLoading();
    const eventsItem = eventsData.find(event => event.id === Number(id));
    if (eventsItem) {
      setOpenModalMoreInfo(eventsItem);
    }
    setOpenModalEditSolicitationData(true);
    closeLoading();
  };

  const eventsDeleteSolicitation = (id: string) => () => {
    openLoading();
    const eventsItem = eventsData.find(event => event.id === Number(id));
    if (eventsItem) {
      setOpenModalDeleteInfo(eventsItem);
    }
    setOpenModalDeleteSolicitationData(true);
    closeLoading();
  };

  const eventsApproveSolicitation = (id: string) => () => {
    openLoading();
    const eventsItem = eventsData.find(event => event.id === Number(id));
    if (eventsItem) {
      setOpenModalApproveInfo(eventsItem);
    }
    setOpenModalApproveSolicitationData(true);
    closeLoading();
  };

  const formatCreatedAtDate = (createdAt: string): JSX.Element => {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleDateString('pt-BR');
    const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

    return (
      <div className="createdDate">
        <span>{formattedDate}</span>
        <span>{formattedTime}</span>
      </div>
    );
  };

  const eventsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Criação',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      renderCell: (params: GridRenderCellParams<IEvent, string, keyof IEvent, GridTreeNodeWithRender>) =>
        formatCreatedAtDate(params.value as string),
    },
    {
      field: 'tradeName',
      headerName: 'Nome do Trade',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.user?.name || '',
    },
    {
      field: 'title',
      headerName: 'Nome do Evento',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'category',
      headerName: 'Categoria',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 222,
      renderCell: (params: GridRenderCellParams<IEvent, EventStatusAPI, keyof IEvent, GridTreeNodeWithRender>) =>
        stylingRowStatus(params.value),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 285,
      type: 'actions',
      getActions: params => [
        <div key={params.id}>
          <button
            className="button-icon"
            title="Mais informações"
            onClick={() => history.push(`/admin/events-list-overview/id=${params.id}`)}>
            <img src={infoIcon} alt="Mais informações" />
          </button>
          <button
            className="button-icon"
            title="Editar"
            onClick={eventsEditSolicitation(params.id.toString())}
            disabled={handleEventsDisabledButton(params.id.toString())}>
            <img src={editIcon} alt="Editar" />
          </button>
          <button
            className="button-icon"
            title="Excluir"
            onClick={eventsDeleteSolicitation(params.id.toString())}
            disabled={handleEventsDisabledButton(params.id.toString())}>
            <img src={xGrayIcon} alt="Excluir" />
          </button>
          <button
            className="button-icon"
            title="Confirmar"
            onClick={eventsApproveSolicitation(params.id.toString())}
            disabled={handleEventsDisabledButton(params.id.toString())}>
            <img src={checkGrayIcon} alt="Confirmar" />
          </button>
        </div>,
      ],
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
  ];

  return (
    <Container>
      <Grid sx={{ width: '100%', marginBottom: '50px', textAlign: 'center' }}>
        <DataGrid
          sx={{
            background: '#fff',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
            borderRadius: '18px',
            width: '100%',
          }}
          rows={eventsData}
          columns={eventsColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          className="dataGrid"
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              densitySelector: false,
              exportButton: false,
            },
          }}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableVirtualization
        />
      </Grid>

      {openModalEditSolicitationData && (
        <EditSolicitationModal
          isOpen={openModalEditSolicitationData}
          toggle={() => setOpenModalEditSolicitationData(false)}
          item={openModalMoreInfo}
        />
      )}

      {openModalDeleteSolicitationData && (
        <DeleteSolicitationModal
          isOpen={openModalDeleteSolicitationData}
          toggle={() => setOpenModalDeleteSolicitationData(false)}
          item={openModalDeleteInfo}
        />
      )}

      {openModalApproveSolicitationData && (
        <ApproveSolicitationModal
          isOpen={openModalApproveSolicitationData}
          toggle={() => setOpenModalApproveSolicitationData(false)}
          item={openModalApproveInfo}
        />
      )}
    </Container>
  );
};

export default EventsList;
