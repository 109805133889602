import React, { useEffect, useState } from 'react';
import DynamicForm from 'components/DynamicForm';
import IEvent from 'interfaces/IEvent';
import IFormSetur from 'interfaces/IFormSetur';
import EventService from 'services/EventService';
import ImageService from 'services/ImageService';
import EventCategoryService from 'services/EventCategoryService';
import { useLoading } from 'hooks/useLoading';
import { useHistory, useParams } from 'react-router';
import MessageModal from 'common/MessageModal';
import MainTitle from 'components/MainTitle';

const EventForm = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [event, setEvent] = useState<IEvent | undefined>();
  const [eventCategories, setEventCategories] = useState<string[]>([]);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const eventService = new EventService();
  const imageService = new ImageService();
  const eventCategoryService = new EventCategoryService();
  const [editingMode, setEditingMode] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });

  useEffect(() => {
    openLoading();
    Promise.all([fetchEventCategories(), fetchEvent()])
      .then(() => closeLoading())
      .catch(error => {
        closeLoading();
      });
  }, []);

  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return '';

    const date = new Date(dateString);

    const brasiliaOffset = 3 * 60;
    const localDate = new Date(date.getTime() + (date.getTimezoneOffset() + brasiliaOffset) * 60000);

    const day = localDate.getDate().toString().padStart(2, '0');
    const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
    const year = localDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const fetchEventCategories = async () => {
    const categories = await eventCategoryService.findAll();
    setEventCategories(categories.map(category => category.description));
  };

  const fetchEvent = async () => {
    const eventId = Number(id);
    if (Number.isNaN(eventId)) {
      return;
    }

    const data = await eventService.findOne(eventId);

    const formattedData = {
      ...data,
      startDate: formatDate(data.startDate),
      endDate: formatDate(data.endDate),
    };

    setEvent(formattedData);
    setEditingMode(true);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleCloseMessageModal = () => {
    setShowMessageModal(false);

    if (messageInfo.title === 'Sucesso') {
      history.goBack();
    }
  };

  const handleSubmit = async (values: IFormSetur) => {
    const imagesLibrary = await imageService.uploadMultiple(values.banners || []);
    const image = await imageService.uploadMultiple(values.image || []);

    try {
      const eventData: IEvent = {
        id: 0,
        eventId: event?.status === 'approved' ? event.id : null,
        title: values.title || '',
        slug: values.slug || '',
        description: values.description || '',
        image: image?.[0] || '',
        link: values.link || '',
        date: values.date || '2023-01-22',
        category: values.category?.[0] || '',
        startDate: values.startDate?.split('/').reverse().join('-') || '2023-01-22',
        endDate: values.endDate?.split('/').reverse().join('-') || '2023-01-22',
        startTime: values.startTime || '',
        endTime: values.endTime || '',
        place: values.place || '',
        price: values.price || '',
        email: values.email || '',
        facebookLink: values.facebookLink || '',
        instagramLink: values.instagramLink || '',
        phone: values.phone || '',
        site: values.site || '',
        whatsapp: values.whatsapp || '',
        youtubeLink: values.youtubeLink || '',
        images: imagesLibrary.map(image => ({ image, description: values.title || '' })),
        placeId: values.placeId || '',
        address: values.address || '',
        addressInfo: {
          zipCode: values?.addressInfo?.zipCode || '',
          street: values?.addressInfo?.street || '',
          number: values?.addressInfo?.number || '',
          neighborhood: values?.addressInfo?.neighborhood || '',
          additionalData: values?.addressInfo?.additionalData || '',
          city: values?.addressInfo?.city || '',
          state: values?.addressInfo?.state || '',
        },
      };
      if (editingMode && event && event.status !== 'approved') {
        await eventService.update(event.id, eventData);
        const messageWithSpan = 'Evento <span>editado</span> com sucesso!';
        setMessageInfo({
          title: 'Sucesso',
          message: messageWithSpan,
        });
        setShowMessageModal(true);
      } else {
        await eventService.create(eventData);
        const messageWithSpan = 'Evento criado com sucesso!';
        setMessageInfo({
          title: 'Sucesso',
          message: messageWithSpan,
        });
        setShowMessageModal(true);
      }
    } catch (error) {
      const messageWithSpan = editingMode
        ? 'Erro ao <span>editar</span> evento.'
        : 'Erro ao <span>criar</span> evento.';
      setMessageInfo({
        title: 'Erro',
        message: messageWithSpan,
      });
      setShowMessageModal(true);
    }
  };
  const fieldsToDisplay = [
    'MainInfo',
    'AddressField',
    'Contact',
    'EventInfo',
    'MapAndVideo',
    'UploadImageCard',
    'UploadImageLibrary',
  ];
  const formValues: IFormSetur = {
    ...event,
    category: event?.category ? [event.category] : [],
    image: event?.image ? [event.image] : [],
    banners: event?.images?.map(({ image }) => image) || [],
    images: [],
  };
  return (
    <>
      <MainTitle>Agenda</MainTitle>
      <DynamicForm
        formName={editingMode ? 'Editar Evento' : 'Novo Evento'}
        fields={fieldsToDisplay}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        values={formValues}
        categories={eventCategories}
      />
      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          title={messageInfo.title}
          message={messageInfo.message}
          onClose={handleCloseMessageModal}
        />
      )}
    </>
  );
};

export default EventForm;
