import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0 50px;

  h1 {
    color: #272727;
    font-size: 23px;
    font-weight: 700;
    line-height: 2;
  }

  a {
    display: flex;
    align-items: baseline;
    color: #272727;
    width: fit-content;

    img {
      display: flex;
      margin-left: 10px;
    }
  }

  @media (max-width: 960px) {
    padding: 0 16px;
  }
`;

export const StrategicMapHeader = styled.header`
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
  justify-content: center;
  align-items: center;

  .new-map-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-start;
    width: 100%;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
`;

export const StrategicMapList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const StrategicMapListItem = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;
