import React, { useState } from 'react';
import { SettingsButton, SettingsContainer, SettingsHeader } from './styles';
import {
  SettingsList,
  SettingsListContainer,
  SettingsListItem,
  SettingsListItemButton,
  SettingsListItemButtonList,
  SettingsListItemTitle,
} from 'components/List/styles';
import SettingsTitle from 'components/Heading';
import useModal from 'hooks/useModal';
import editIcon from '../../assets/images/editIcon.svg';
import trashIcon from '../../assets/images/trashIcon.svg';
import SettingsBoxAddCategoryModal from './components/SettingsBoxAddCategoryModal';
import SettingsBoxEditCategoryModal from './components/SettingsBoxEditCategoryModal';
import SettingsBoxRemoveCategoryModal from './components/SettingsBoxRemoveCategoryModal';

export interface Item {
  id: number;
  name: string;
}

interface SettingsBoxProps {
  settingsName: string;
  items: Item[];
  onAddItem?: (value: string) => void;
  onEditItem?: (item: Item) => void;
  onDeleteItem?: (item: Item) => void;
}

export const SettingsBox = ({
  settingsName,
  items,
  onAddItem,
  onEditItem,
  onDeleteItem,
}: SettingsBoxProps): JSX.Element => {
  const { toggle } = useModal();
  const [modalAddCategory, setModalAddCategory] = useState(false);
  const [modalEditCategory, setModalEditCategory] = useState(false);
  const [modalRemoveCategory, setModalRemoveCategory] = useState(false);
  const [item, setItem] = useState(items[0] || { id: 0, name: '' });

  const handleAddCategory = () => {
    setModalAddCategory(!modalAddCategory);
    toggle();
  };

  const handleEditCategory = (currentItem: Item) => {
    setModalEditCategory(!modalEditCategory);
    toggle();
    setItem(currentItem);
  };

  const handleRemoveCategory = (currentItem: Item) => {
    setModalRemoveCategory(!modalRemoveCategory);
    toggle();
    setItem(currentItem);
  };

  return (
    <>
      <SettingsContainer>
        <SettingsHeader>
          <SettingsTitle>{settingsName}</SettingsTitle>
          <SettingsButton onClick={handleAddCategory}>Adicionar</SettingsButton>
        </SettingsHeader>
        <SettingsListContainer>
          <SettingsList>
            {items.map(item => (
              <SettingsListItem key={item.id}>
                <SettingsListItemTitle>{item.name}</SettingsListItemTitle>
                <SettingsListItemButtonList>
                  <SettingsListItemButton onClick={() => handleEditCategory(item)}>
                    <img src={editIcon} alt="icon for edit item" />
                  </SettingsListItemButton>
                  <SettingsListItemButton onClick={() => handleRemoveCategory(item)}>
                    <img src={trashIcon} alt="icon for trash item" />
                  </SettingsListItemButton>
                </SettingsListItemButtonList>
              </SettingsListItem>
            ))}
          </SettingsList>
        </SettingsListContainer>
      </SettingsContainer>

      <SettingsBoxAddCategoryModal
        isOpen={modalAddCategory}
        toggle={handleAddCategory}
        onSubmit={value => {
          if (onAddItem) {
            onAddItem(value);
            return true;
          }
          return false;
        }}
      />
      <SettingsBoxEditCategoryModal
        isOpen={modalEditCategory}
        toggle={() => handleEditCategory(item)}
        item={item}
        onSubmit={editedItem => {
          if (onEditItem) {
            onEditItem(editedItem);
            return true;
          }
          return false;
        }}
      />
      <SettingsBoxRemoveCategoryModal
        isOpen={modalRemoveCategory}
        toggle={() => handleRemoveCategory(item)}
        item={item}
        onSubmit={() => {
          setModalRemoveCategory(false);
          if (onDeleteItem) {
            onDeleteItem(item);
            return true;
          }
          return false;
        }}
      />
    </>
  );
};
