import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory, useParams } from 'react-router';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import { useLoading } from 'hooks/useLoading';
import { auth, EmailAuthProvider } from 'utils/firebase';
import yup from 'utils/validation';
import { logged } from 'features/Login/store/LoginAtom';
import MessageModal from 'common/MessageModal';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Field from 'components/Field';
import ErrorMessage from 'components/ErrorMessage';
import { Container, Div, Panel, Middle, PasswordDiv } from './styles';
import eyeIcon from 'assets/images/eyeIcon.svg';
import eyeDisableIcon from 'assets/images/eyeDisableIcon.svg';

interface IFormValues {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const PasswordReset = (): JSX.Element => {
  const history = useHistory();
  const [isAuth] = useRecoilState(logged);
  const { code } = useParams<{ code?: string }>();
  const [actualPasswordToText, setActualPasswordToText] = useState(false);
  const [newPasswordToText, setNewPasswordToText] = useState(false);
  const [confirmPasswordToText, setConfirmPasswordToText] = useState(false);
  const { openLoading, closeLoading } = useLoading();
  const [modalMessage, setModalMessage] = useState('');

  const redirectToLogin = () => history.push('/login');

  useEffect(() => {
    if (!isAuth && code) {
      auth.verifyPasswordResetCode(code).catch(redirectToLogin);
    } else if (!isAuth) {
      redirectToLogin();
    }
  }, []);

  const handleFormSubmit = async (formValues: IFormValues) => {
    openLoading();

    try {
      const action = code ? confirmPasswordReset : updatePassword;
      await action(formValues);
      setModalMessage('Nova senha redefinida com sucesso!');
    } catch (err) {
      setModalMessage('Erro ao redefinir nova senha!');
    } finally {
      closeLoading();
    }
  };

  const handleModalClose = () => {
    setModalMessage('');
    if (modalMessage === 'Nova senha redefinida com sucesso!') {
      redirectToLogin();
    }
  };

  const confirmPasswordReset = async (formValues: IFormValues) => {
    if (!code) return;
    await auth.confirmPasswordReset(code, formValues.password);
  };

  const updatePassword = async (formValues: IFormValues) => {
    const { currentUser } = auth;

    if (!currentUser?.email) {
      throw new Error('E-mail not found!');
    }

    const credential = EmailAuthProvider.credential(currentUser.email, formValues.currentPassword);
    await currentUser.reauthenticateWithCredential(credential);
    await currentUser.updatePassword(formValues.password);
  };

  const initialValues = {
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = yup.object({
    ...(!code && {
      currentPassword: yup.string().required(),
    }),
    password: yup.string().required(),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'As senhas devem corresponder')
      .required(),
  });

  return (
    <Container>
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ minHeight: '70vh' }}>
        <Grid item xs={12} sm={8} md={6} lg={3}>
          <Formik<IFormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}>
            <Form>
              <Panel>
                <Div>
                  <Middle>
                    <Heading>Crie sua nova senha:</Heading>
                  </Middle>
                </Div>
                {!code && (
                  <Div>
                    <label>
                      Senha atual <span>*</span>
                    </label>
                    <PasswordDiv>
                      <Field name="currentPassword" type={actualPasswordToText ? 'text' : 'password'} />
                      <button type="button" onClick={() => setActualPasswordToText(!actualPasswordToText)}>
                        <img
                          src={actualPasswordToText ? eyeDisableIcon : eyeIcon}
                          alt={actualPasswordToText ? 'An opened eye with an slash bar above it' : 'An opened eye'}
                        />
                      </button>
                    </PasswordDiv>

                    <ErrorMessage name="currentPassword" />
                  </Div>
                )}
                <Div>
                  <label>
                    Nova senha <span>*</span>
                  </label>
                  <PasswordDiv>
                    <Field name="password" type={newPasswordToText ? 'text' : 'password'} />
                    <button type="button" onClick={() => setNewPasswordToText(!newPasswordToText)}>
                      <img
                        src={newPasswordToText ? eyeDisableIcon : eyeIcon}
                        alt={newPasswordToText ? 'An opened eye with an slash bar above it' : 'An opened eye'}
                      />
                    </button>
                  </PasswordDiv>
                  <ErrorMessage name="password" />
                </Div>
                <Div>
                  <label>
                    Confirme a nova senha <span>*</span>
                  </label>
                  <PasswordDiv>
                    <Field name="passwordConfirmation" type={confirmPasswordToText ? 'text' : 'password'} />
                    <button type="button" onClick={() => setConfirmPasswordToText(!confirmPasswordToText)}>
                      <img
                        src={confirmPasswordToText ? eyeDisableIcon : eyeIcon}
                        alt={confirmPasswordToText ? 'An opened eye with an slash bar above it' : 'An opened eye'}
                      />
                    </button>
                  </PasswordDiv>
                  <ErrorMessage name="passwordConfirmation" />
                </Div>
                <Div>
                  <Middle>
                    <Button type="submit">Criar</Button>
                  </Middle>
                </Div>
              </Panel>
            </Form>
          </Formik>
        </Grid>
      </Grid>
      <MessageModal
        title="Sua nova senha foi criada com sucesso!"
        message={modalMessage}
        isOpen={Boolean(modalMessage)}
        onClose={handleModalClose}
        ButtonText="Iniciar Sessão"
      />
    </Container>
  );
};

export default PasswordReset;
