import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { logged } from 'features/Login/store/LoginAtom';
import BookmarkService from 'services/BookmarkService';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { ICreateBookmark } from 'interfaces/IBookmark';
import bookmarkerIconGray from 'assets/images/experiences_item_gray.svg';
import bookmarkerIconYellow from 'assets/images/experiences_item_yellow.svg';
import bookmarkIndex from './bookmarkIndex';
import { Container, RadioGroupContent, Title } from './styles';

enum Options {
  Done = 1,
  WishList = 2,
  Exclude = 3,
}

type IProps = {
  id: number;
  type: string;
  onChange?: () => void;
};

const AddBookmark = (props: IProps): JSX.Element => {
  const { id, type, onChange } = props;
  const bookmarkId = `${type}-${id}`;
  const service = new BookmarkService();

  const [bookmark, setBookmark] = useState<ICreateBookmark | undefined>();
  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState(0);
  const [modalVisibility, setModalVisibility] = useState(false);

  const [loggedState] = useRecoilState(logged);
  const bookmarksIndex = useRecoilValueLoadable(bookmarkIndex);
  const updateBookmarksIndex = useSetRecoilState(bookmarkIndex);

  useEffect(() => {
    bookmarksIndex.toPromise().then(index => {
      setBookmark(index[bookmarkId]);
      setLoading(false);
    });
  }, []);

  const handleWithSaveButtonClick = async () => {
    const done = option === Options.Done;
    const newBookmark = { id, done, type };

    if (option === Options.Exclude) {
      await service.deleteBookmarks(newBookmark);
      setBookmark(undefined);
      updateBookmarksIndex(index => {
        const { [bookmarkId]: excludedBookmark, ...updatedIndex } = index;
        return updatedIndex;
      });
      setModalVisibility(false);
      onChange?.();
      return;
    }

    await service.saveBookmarks(newBookmark);
    setBookmark(newBookmark);
    updateBookmarksIndex(index => {
      return { ...index, [bookmarkId]: newBookmark };
    });
    setModalVisibility(false);
    onChange?.();
  };

  const handleModalClose = () => {
    setModalVisibility(false);
    setOption(0);
  };

  if (loading || !loggedState) {
    return <></>;
  }

  return (
    <>
      <Container onClick={() => setModalVisibility(true)}>
        <img src={bookmark ? bookmarkerIconYellow : bookmarkerIconGray} />
      </Container>
      <Modal open={modalVisibility} onClose={handleModalClose}>
        <Grid container>
          <Grid xs={11} item>
            <Title>Minhas experiências</Title>
          </Grid>
          <Grid xs={1} item>
            1/1
          </Grid>
          <Grid xs={12} item>
            <div>Selecione onde deseja salvar essa experiência:</div>
            <FormControl>
              <RadioGroupContent>
                <RadioGroup
                  value={option}
                  onChange={event => {
                    setOption(Number(event.target.value));
                  }}>
                  {(!bookmark || !bookmark.done) && (
                    <FormControlLabel value={Options.Done} control={<Radio size="small" />} label="Concluídas" />
                  )}
                  {(!bookmark || bookmark.done) && (
                    <FormControlLabel
                      value={Options.WishList}
                      control={<Radio size="small" />}
                      label="Lista de Desejos"
                    />
                  )}
                  {bookmark && (
                    <FormControlLabel value={Options.Exclude} control={<Radio size="small" />} label="Excluir" />
                  )}
                </RadioGroup>
              </RadioGroupContent>
            </FormControl>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={4} direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={12} sm={4} item>
                <Button onClick={handleModalClose}>Cancelar</Button>
              </Grid>
              <Grid xs={12} sm={4} item>
                <Button buttonType={option ? 'primary' : 'disabled'} onClick={handleWithSaveButtonClick}>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AddBookmark;
