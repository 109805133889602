import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AppContext from '../common/AppContext';
import PanelLayout from '../common/PanelLayout';
import { useRecoilState } from 'recoil';
import showSelectLanguageState from 'components/SelectLanguague/languageState';

interface IProps {
  component: React.FC | React.ElementType;
}

const PrivateRoute = ({ component: Component, ...rest }: IProps): JSX.Element => {
  const {
    authState: { isAuth },
  } = useContext(AppContext).appState;
  const [showOptions, setShowOptions] = useRecoilState(showSelectLanguageState);

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuth) {
          return (
            <PanelLayout>
              <div
                onClick={() => {
                  setShowOptions(false);
                }}>
                <Component {...props} />
              </div>
            </PanelLayout>
          );
        } else {
          return <Redirect to={{ pathname: '/' }} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
