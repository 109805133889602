import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  width: 100%;
  min-height: 60vh;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .header h2 {
    margin-bottom: 0;
  }
  .header button {
    width: auto;
  }

  .approvedBadge,
  .reprovedBadge,
  .waitingSeturBadge,
  .waitingTradeBadge,
  .pendingAdjustment {
    border-radius: 18px;
    padding: 5px 10px;
    color: #fff;
  }
  .approvedBadge {
    background: #00b050;
  }
  .reprovedBadge {
    background: #c63336;
  }
  .waitingSeturBadge {
    background: #ffcb29;
  }
  .waitingTradeBadge {
    background: #d0a633;
  }
  .pendingAdjustment {
    background: #d0a633;
  }

  // Styling MUI DataGrid //

  .dataGrid {
    text-align: center !important;
  }

  .MuiDataGrid-root {
    width: 100%;
    height: 100%;
  }

  .MuiDataGrid-columnHeader {
    background: #020c90;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-align: center !important;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;

    .MuiSvgIcon-root {
      color: #fff;
      opacity: 1 !important;
    }
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    text-align: center;
  }

  .css-1rtnrqa {
    display: none;
  }

  .css-68pk0f {
    width: 40%;

    @media all and (max-width: 768px) {
      width: 100%;
    }
  }

  .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    display: none;
  }

  .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter {
    width: 40%;

    @media all and (max-width: 768px) {
      width: 100%;
    }
  }

  .button-icon {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 5px;

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      &:hover {
        filter: none;
      }
    }

    img {
      width: 20px;

      &:hover {
        filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
          contrast(126%);
      }
    }
  }

  ::-webkit-scrollbar {
    width: 25px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
    height: 5px;
    border-radius: 10px;
  }

  .modal-content p {
    text-align: left;
  }

  .modal-content p:before {
    content: 'Mensagem de solicitação';
    display: block;
    font-weight: 800;
  }
`;

export const TouristListHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const TouristListSelect = styled.select`
  width: 300px;
  height: 40px;
  border-radius: 18px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 16px;
  color: #9a9a9a;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

export const TitleTableSelect = styled.select`
  margin-bottom: 20px;
  padding: 0 10px;
  height: 40px;
  border: none;
  font-size: 20px;
  color: #272727;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;
