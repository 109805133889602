import React from 'react';
import { ActivitiesBoard, ActivitiesFilter, ActivityListItem, Container, PaginateComponent } from './styles';
import IActivities from 'interfaces/IActivities';
import IExperience from 'interfaces/IExperience';
import ActivitiesIcons from './icons';
import EventCard from '../EventCard';
import { slugify } from 'utils/string';

interface Props {
  activities?: IActivities[];
  experiences?: IExperience[];
}

const DestinationActivities = (props: Props): JSX.Element => {
  const { activities, experiences } = props;
  const icons = new ActivitiesIcons();

  return (
    <Container>
      <ActivitiesFilter>
        {experiences?.map((experience, index) => {
          return (
            <ActivityListItem key={index}>
              <img src={experience.image} className={slugify(experience.title)} />
              <span>{experience.title}</span>
            </ActivityListItem>
          );
        })}
      </ActivitiesFilter>
      <ActivitiesBoard>
        {activities?.map((activity, index) => {
          return <EventCard key={index} {...activity} slug={activity.slug} />;
        })}
      </ActivitiesBoard>
      {/* <PaginateComponent>
        <img src={icons.firstPageArrow} alt="Two left sided arrows" className="first-page-arrow" />
        <img src={icons.backArrow} alt="One left sided arrow" className="back-arrow" />
        <button className="button-active">1</button>
        <button>2</button>
        <button>3</button>
        <button>4</button>
        <button>5</button>
        <img src={icons.forwardArrow} alt="One right sided arrows" className="next-page-arrow" />
        <img src={icons.lastPageArrow} alt="Two right sided arrows" className="last-page-arrow" />
      </PaginateComponent> */}
    </Container>
  );
};

export default DestinationActivities;
