import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #9a9a9a;
  background-color: #ffffff;
`;

export const TitleDiv = styled.div`
  display: flex;
  height: 14vh;
  width: 100%;
  align-items: center;

  @media all and (max-width: 980px) {
    justify-content: center;
  }

  img {
    height: 10vh;
    cursor: pointer;
  }
  h1 {
    color: #020c90;
    font-size: 2.813rem;
    cursor: pointer;
  }
  @media (max-width: 480px) {
    img {
      width: 24vw;
      height: 24vh;
    }

    h1 span {
      display: block;
      line-height: 35px;
    }
  }
`;

export const VerticalDivider = styled.div`
  width: 2px;
  height: 80%;
  background-color: #9a9a9a;
  margin: 0 20px;
`;

export const ActionDiv = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;
  cursor: pointer;

  @media (max-width: 980px) {
    display: none;
  }
  select {
    padding: 0px 10px;

    width: 105px;
    height: 40px;

    border: 0.5px solid #9a9a9a;
    border-radius: 18px;
    background-color: white;
    text-align: right;
    margin-right: 1vw;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  gap: 10px;
`;

export const MyAccount = styled.div`
  border-radius: 18px;
  box-shadow: 0px 4px 4px 0px #0000003f;
  width: 220px;
  background-color: #ffffff;
  padding: 10px 0;
  z-index: 1000;
  position: relative;
  width: 100%;
  color: #9a9a9a;
`;

export const ImgLabel = styled.div`
  padding: 0 10px;
  display: flex;
  line-height: normal;
  img {
    margin-right: 10px;
  }
  &&:hover {
    img,
    span {
      color: #020c90;
      filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
        contrast(126%);
    }
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted;
  margin: 2vh 0;
`;

export const LoggedDiv = styled.div`
  height: fit-content;
`;

export const UserDiv = styled.div`
  display: flex;

  .userField {
    display: flex;
    flex-direction: column;
  }

  .userName {
    color: #272727;
    width: 150px;
    overflow: hidden;
    display: block;
    height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const LogoutModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  margin-top: 40vh;
  margin-left: 35vw;
  width: fit-content;
  padding: 30px;
  border-radius: 18px;
  gap: 20px;
  h2 {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
  }
  span {
    font-size: 20px;
    font-weight: 400;
    width: 30vw;
    text-align: center;
  }
  div {
    display: flex;
    button {
      white-space: nowrap;
      min-width: 100px;
      width: fit-content;
      padding: 0 10px;
      &:first-child {
        background-color: transparent;
        border: 1px solid #ffcb29;
        color: #ffcb29;
        font-size: 16px;
        font-weight: 700;
        margin-right: 30px;
      }
    }
  }
`;

export const ImageDiv = styled.div`
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  width: 40px;
  margin-right: 10px;

  img {
    width: 100%;
  }
`;
