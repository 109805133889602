import React from 'react';
import { LabelCategory } from './styles';

interface ILabelCategoryProps {
  id: number;
  category?: string;
  categories?: {
    id: number;
    labels: string;
  }[];
  onClick?: () => void;
}

const LabelCategoryCard: React.FC<ILabelCategoryProps> = ({ categories, category }) => {
  return category ? (
    <LabelCategory>
      <li>{category}</li>
    </LabelCategory>
  ) : (
    <LabelCategory>
      {categories?.map(item => (
        <li key={item.id}>{item.labels}</li>
      ))}
    </LabelCategory>
  );
};

export default LabelCategoryCard;
