import styled from 'styled-components';

export const Container = styled.div`
  min-height: 70vh;
  padding: 40px;

  button,
  hr {
    display: none;
  }
`;
