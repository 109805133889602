import React from 'react';
import { Item, List, Panel, PanelInformation, Title } from './styles';

interface IProps {
  activities?: string[];
}

const InfoCardActivities = (props: IProps): JSX.Element => {
  const { activities } = props;

  if (!activities?.length) {
    return <></>;
  }

  return (
    <Panel>
      <PanelInformation>
        <Title>Atividades e Experiências</Title>
        <List>
          {activities.map((activity, index) => (
            <Item key={index}>{activity}</Item>
          ))}
        </List>
      </PanelInformation>
    </Panel>
  );
};

export default InfoCardActivities;
