import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import giftIcon from '../../assets/images/giftIcon.svg';
import arraialIcon from '../../assets/images/arraialIcon.svg';
import caraivaIcon from '../../assets/images/caraivaIcon.svg';
import portoIcon from '../../assets/images/portoIcon.svg';
import trancosoIcon from '../../assets/images/trancosoIcon.svg';
import valeIcon from '../../assets/images/valeIcon.svg';
import ContestService from 'services/ContestService';
import {
  ModalBlock,
  ModalButton,
  ModalForm,
  ModalFormInput,
  ModalObservation,
  ModalOptions,
  ModalSuccess,
  ModalText,
  ModalTitle,
  Overlay,
  SuccessTitle,
} from './styles';
import Button from 'components/Button';
import { logged } from 'features/Login/store/LoginAtom';
import { useRecoilState } from 'recoil';

interface FormValues {
  option: string;
  answer?: string;
}

const initialValues: FormValues = {
  option: '',
  answer: '',
};

interface RadioButtonGroupProps {
  field: FieldProps['field'];
  form: {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  };
  options: { value: string; label: string; image: string }[];
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ field, form, options }) => (
  <ModalForm>
    {options.map(option => (
      <label key={option.value}>
        <img src={option.image} alt={option.label} />
        <ModalFormInput>
          <input
            type="radio"
            {...field}
            value={option.value}
            checked={field.value === option.value}
            onChange={() => form.setFieldValue(field.name, option.value)}
          />
          {option.label}
        </ModalFormInput>
      </label>
    ))}
  </ModalForm>
);

const QuizModal: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(logged);
  const [isVoteSuccessful, setIsVoteSuccessful] = useState(false);

  useEffect(() => {
    const modalClosed = localStorage.getItem('modalClosed');
    if (modalClosed) {
      setIsModalOpen(false);
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.setItem('modalClosed', 'true');
  };

  const handleOkButtonClick = () => {
    setIsVoteSuccessful(false);
    setIsModalOpen(false);
  };

  const handleSubmit = async (values: FormValues) => {
    console.log('Informações Enviadas:', values);

    const contestService = new ContestService();

    try {
      await contestService.submitVote(values);

      setIsVoteSuccessful(true);

      if (isLoggedIn) {
        setIsModalOpen(false);
        localStorage.setItem('modalClosed', 'true');
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    }
  };

  const radioOptions = [
    { image: `${arraialIcon}`, value: `Arraial d'Ajuda`, label: `Arraial d'Ajuda` },
    { image: `${caraivaIcon}`, value: 'Caraíva', label: 'Caraíva' },
    { image: `${portoIcon}`, value: 'Porto Seguro', label: 'Porto Seguro' },
    { image: `${trancosoIcon}`, value: 'Trancoso', label: 'Trancoso' },
    { image: `${valeIcon}`, value: 'Vale Verde', label: 'Vale Verde' },
  ];

  return (
    <>
      {isLoggedIn && isModalOpen && (
        <Overlay>
          <ModalBlock>
            <ModalTitle>
              <h2>VOTE NO SEU DESTINO FAVORITO E CONCORRA</h2>
              <h1>
                A PRÊMIOS incríveis <img src={giftIcon} alt="" />
              </h1>
            </ModalTitle>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form>
                <ModalOptions>
                  <label>Qual destino gostaria de visitar primeiro?</label>
                  <Field
                    name="option"
                    render={(props: FieldProps) => <RadioButtonGroup {...props} options={radioOptions} />}
                  />
                </ModalOptions>

                <ModalText>
                  <label>Conte-nos o porquê do seu voto (opcional)</label>
                  <Field type="text" name="answer" />
                </ModalText>
                <ModalObservation>
                  <p>
                    *Os prêmios podem ser vouchers de desconto em diversas atividades locais, como passeios,
                    restaurantes e hotéis.
                  </p>
                  <p>**Os prêmios podem ser resgatados e utilizados em até 1 ano a partir a partir da divulgação.</p>
                  <p>***Será aceito apenas um voto por conta cadastrada.</p>
                </ModalObservation>

                <ModalButton>
                  <Button onClick={closeModal} buttonType="outline" className="modal-cancel-outline-button">
                    Cancelar
                  </Button>

                  <Button buttonType="primary">Enviar</Button>
                </ModalButton>
              </Form>
            </Formik>
          </ModalBlock>
        </Overlay>
      )}

      {isVoteSuccessful && (
        <ModalSuccess>
          <SuccessTitle>
            <h2>Envio de Voto</h2>
            <span>Voto enviado com sucesso</span>
          </SuccessTitle>
          <ModalButton>
            <Button onClick={handleOkButtonClick} buttonType="primary">
              ok
            </Button>
          </ModalButton>
        </ModalSuccess>
      )}
    </>
  );
};

export default QuizModal;
