import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  height: fit-content;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.div`
  margin-top: 50px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  width: 100%;

  h2 {
    color: #020c90;
    font-size: 33px;
    font-weight: 700;
    padding: 0px 10px;

    @media all and (max-width: 768px) {
      font-size: 20px;
    }
  }
  div {
    height: 5px;
    width: 100%;
    &:first-child {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, #00a759),
        color-stop(95%, #00a759),
        color-stop(95%, #c63336),
        color-stop(100%, #c63336)
      );
    }
    &:nth-child(3) {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, #c63336),
        color-stop(5%, #c63336),
        color-stop(5%, #ffcb29),
        color-stop(100%, #ffcb29)
      );
    }
  }
`;
