import React from 'react';
import * as formik from 'formik';
import { Paragraph } from './styles';

interface IProps {
  name: string;
}

const ErrorMessage = (props: IProps): JSX.Element => {
  const { name } = props;

  const renderError = (message: string) => <Paragraph className="error-message">* {message}</Paragraph>;

  return <formik.ErrorMessage name={name} render={renderError} />;
};

export default ErrorMessage;
