import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto 50px auto;
  width: 100%;

  ::-webkit-scrollbar {
    margin-right: 500px;
    width: 5px;
    height: 5px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
    border-radius: 5px;
  }

  label {
    margin-bottom: 10px;
  }

  .form-group {
    > label {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }

    > input,
    textarea {
      border-radius: 18px;
      border: 1px solid #9a9a9a;
      padding: 10px;
      height: 40px;
      width: 100%;
    }

    span {
      color: #ec3237;
    }

    small {
      margin-left: 5px;
      color: #9a9a9a;
    }

    textarea {
      resize: none;
      height: 120px;
    }
  }
`;
