import styled from 'styled-components';

export const ModalContainer = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  top: 50%;
  width: 690px;
  max-height: 495px;
  background: #fff;
  z-index: 99999;
  border-radius: 18px;
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const ModalContainerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ModalContainerHeaderTitle = styled.h1`
  color: #272727;
  font-size: 20px;
  font-weight: 500;
`;

export const ModalContainerHeaderSteps = styled.small`
  color: #272727;
  font-size: 20px;
  font-weight: 500;
`;

export const ModalContainerInputLabel = styled.label`
  color: #272727;
  font-size: 16px;
  font-weight: 500;
`;

export const ModalContainerInput = styled.input`
  margin-top: 5px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 0 10px;
  margin-bottom: 1rem;
  border-radius: 18px;
  border: 1px solid var(--PS---Cinza-9A9A9A, #9a9a9a);
  background: #fff;
`;

export const ModalContainerActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;

  button {
    margin-left: 1rem;
    width: inherit;
    min-width: 110px;
    padding: 10px;
  }

  .cancel-button {
    background-color: #fff;
    font-weight: bold;
    color: #ffcb29;
    border: 0.5px solid #ffcb29;
    opacity: 1;

    &:hover {
      background-color: #020c90;
      color: #fff;
      border: none;
    }
`;

export const ModalContainerAsterisk = styled.span`
  color: #ec3237;
`;
