import React from 'react';
import { PhotoField, PhotoItem, PhotoList, PhotoTitle } from './styles';

interface IProps {
  images?: string[];
}

const InfoCardPhotos = (props: IProps): JSX.Element => {
  const { images } = props;

  if (!images?.length) {
    return <></>;
  }

  return (
    <PhotoField>
      <PhotoTitle>Fotos</PhotoTitle>
      <PhotoList>
        {images.map((image, index) => (
          <PhotoItem key={index} style={{ background: `url(${image}) center center / cover no-repeat` }} />
        ))}
      </PhotoList>
    </PhotoField>
  );
};

export default InfoCardPhotos;
