import React from 'react';
import {
  ContactArea,
  DescriptionArea,
  DetailCard,
  SocialMediaArea,
  SocialMediaCard,
  VideoArea,
  Wrapper,
  ContactInfo,
} from './styles';
import facebook from '../../assets/images/facebookFill.svg';
import youtube from '../../assets/images/youtubeIcon.svg';
import instagram from '../../assets/images/instagramIcon.svg';
import telephone from '../../assets/images/telephone.svg';
import site from '../../assets/images/site.svg';
import whatsapp from '../../assets/images/wppIconDark.svg';
import email from '../../assets/images/emailDark.svg';

interface IContactCard {
  email?: string;
  facebookLink?: string;
  instagramLink?: string;
  phone?: string;
  site?: string;
  title?: string;
  whatsapp?: string;
  youtubeLink?: string;
  attachedStructure?: string;
}

const ContactCard: React.FC<IContactCard> = props => {
  return (
    <SocialMediaCard>
      <Wrapper>
        <DetailCard>
          <VideoArea>
            {props.youtubeLink && (
              <>
                <h4>Vídeo</h4>
                <a href={props.youtubeLink} target="_blank" rel="noreferrer">
                  <img src={youtube} alt="youtube logo" />
                  {props.youtubeLink}
                </a>
              </>
            )}
          </VideoArea>

          {props.attachedStructure && (
            <DescriptionArea>
              <h4>Estruturas Anexas</h4>
              <p>{props.attachedStructure}</p>
            </DescriptionArea>
          )}
          <ContactInfo>
            <ContactArea>
              {props.whatsapp || props.email || props.phone || props.site ? <h4>Contato</h4> : ''}
              {props.phone && (
                <a href={props.phone} rel="noreferrer">
                  <img src={telephone} alt="telefone" />
                  {props.phone}
                </a>
              )}

              {props.whatsapp && (
                <a href={`https://wa.me/55${props.whatsapp}`} rel="noreferrer">
                  <img src={whatsapp} alt="whatsapp" />
                  {props.whatsapp}
                </a>
              )}

              {props.email && (
                <a href={props.email} rel="noreferrer">
                  <img src={email} alt="email" />
                  {props.email}
                </a>
              )}

              {props.site && (
                <a href={props.site} rel="noreferrer">
                  <img src={site} alt="site" />
                  {props.site}
                </a>
              )}
            </ContactArea>

            <SocialMediaArea>
              {props.instagramLink && (
                <>
                  <h4>Redes Sociais</h4>
                  <a href={props.instagramLink} target="_blank" rel="noreferrer">
                    <img src={instagram} alt="instagram logo" />
                    {props.instagramLink}
                  </a>
                </>
              )}

              {props.facebookLink && (
                <a href={props.facebookLink} target="_blank" rel="noreferrer">
                  <img src={facebook} alt="facebook logo" />
                  {props.facebookLink}
                </a>
              )}
            </SocialMediaArea>
          </ContactInfo>
        </DetailCard>
      </Wrapper>
    </SocialMediaCard>
  );
};

export default ContactCard;
