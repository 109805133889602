import React from 'react';
import { Container, InstagramPostContainer } from './styles';
import { Post } from 'services/InstagramService';

interface Props {
  post: Post;
}

const InstragamPost = (props: Props): JSX.Element => {
  const { post } = props;
  const { id, media_type, media_url, permalink, caption } = post;
  let postElement;

  switch (media_type) {
    case 'VIDEO':
      postElement = (
        <video
          src={media_url}
          loop
          muted
          onMouseOver={e => {
            e.currentTarget.play();
          }}
          onMouseLeave={e => {
            e.currentTarget.pause();
          }}
          onFocus={e => {
            e.currentTarget.pause();
          }}
        />
      );
      break;
    case 'CAROUSEL_ALBUM':
      postElement = <img id={id} src={media_url} alt={caption} />;
      break;
    default:
      postElement = <img id={id} src={media_url} alt={caption} />;
  }

  return (
    <Container>
      {media_type === 'VIDEO' ? (
        <InstagramPostContainer
          onClick={() => window.open(permalink)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              window.open(permalink);
            }
          }}
          style={{ objectFit: 'cover' }}>
          {postElement}
        </InstagramPostContainer>
      ) : (
        <InstagramPostContainer
          onClick={() => window.open(permalink)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              window.open(permalink);
            }
          }}
          style={{ background: `url(${media_url}) center center / cover no-repeat` }}
          tabIndex={0}
        />
      )}
    </Container>
  );
};

export default InstragamPost;
