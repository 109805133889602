import React from 'react';
import { Anchor, ListItem, ListItemInformation } from './styles';

interface Props {
  img: string;
  category: string;
  dateStr: string;
  eventName: string;
  slug: string;
}

const ScheduleCard: React.FC<Props> = (props: Props) => {
  const { img, category, dateStr, eventName, slug } = props;
  return (
    <Anchor className="event-box" href={'/events/' + slug} rel="noreferrer">
      <ListItem>
        <div
          className="list-item-image"
          style={{
            background: `url(${img}) no-repeat center top`,
            backgroundSize: 'cover',
          }}
        />
        <ListItemInformation>
          <span>{category}</span>
          <time>{dateStr}</time>
          <h1>{eventName}</h1>
        </ListItemInformation>
      </ListItem>
    </Anchor>
  );
};

export default ScheduleCard;
