import React, { useState } from 'react';
import { PasswordDiv } from './styles';
import eyeIcon from '../../assets/images/eyeIcon.svg';
import eyeDisableIcon from '../../assets/images/eyeDisableIcon.svg';
import { Field } from 'formik';

interface Props {
  name?: string;
}

const InputPassword = (props: Props): JSX.Element => {
  const { name } = props;
  const [passwordToText, setPasswordToText] = useState(false);

  return (
    <PasswordDiv>
      <Field name={name} type={passwordToText ? 'text' : 'password'} />
      <button type="button" onClick={() => setPasswordToText(!passwordToText)}>
        <img
          src={passwordToText ? eyeDisableIcon : eyeIcon}
          alt={passwordToText ? 'An opened eye with an slash bar above it' : 'An opened eye'}
        />
      </button>
    </PasswordDiv>
  );
};

export default InputPassword;
