import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 0px !important;
  width: 40px;
  border-radius: 20px;
  background: #ffcb29;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border: none;
`;

export const FieldGroup = styled.div`
  flex-direction: initial !important;
  margin: 5px 0px;

  input {
    margin-top: 0px !important;
    width: calc(100% - 50px) !important;
    display: flex;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    height: 4vh;
    padding: 0 0.5vw;
  }
`;
