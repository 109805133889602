import React, { useEffect, useState } from 'react';
import { SelectMainNewsContainer, SelectMainNewsItem, SelectMainNewsSmall } from './styles';
import Select from 'components/Select';
import NewsService from 'services/NewsService';
import INews from 'interfaces/INews';

const SelectMainNews = (): JSX.Element => {
  const [allNews, setAllNews] = useState<INews[]>([]);
  const [mainNews, setMainNews] = useState<INews>({} as INews);
  const [news1, setNews1] = useState<INews>({} as INews);
  const [news2, setNews2] = useState<INews>({} as INews);

  useEffect(() => {
    const service = new NewsService();
    service.findAll().then(response => {
      setAllNews(response.filter(news => news.status === 'published').sort((a, b) => a.title.localeCompare(b.title)));
    });

    service.findAll().then(response => {
      const newsMain = response.find(news => news.location === 'newsMain');
      const newsOne = response.find(news => news.location === 'newsOne');
      const newsTwo = response.find(news => news.location === 'newsTwo');
      setMainNews(newsMain || ({} as INews));
      setNews1(newsOne || ({} as INews));
      setNews2(newsTwo || ({} as INews));
    });
  }, []);

  const filterOptions = (selectedNews: INews | null, otherNews1: INews | null, otherNews2: INews | null) => {
    return allNews
      .filter(news => news.id !== selectedNews?.id && news.id !== otherNews1?.id && news.id !== otherNews2?.id)
      .map(news => ({ label: news.title, value: news.id.toString() }));
  };

  const sendMainNews = (news: INews) => {
    setMainNews(news);
    const service = new NewsService();
    service.findAll().then(response => {
      const newsMain = response.find(news => news.location === 'newsMain');
      if (newsMain) {
        service.update(newsMain.id, { ...newsMain, location: null });
      }
    });
    service.update(news.id, { ...news, location: 'newsMain' });
  };

  const sendNews1 = (news: INews) => {
    setNews1(news);
    const service = new NewsService();
    service.findAll().then(response => {
      const newsOne = response.find(news => news.location === 'newsOne');
      if (newsOne) {
        service.update(newsOne.id, { ...newsOne, location: null });
      }
    });
    service.update(news.id, { ...news, location: 'newsOne' });
  };

  const sendNews2 = (news: INews) => {
    setNews2(news);
    const service = new NewsService();
    service.findAll().then(response => {
      const newsTwo = response.find(news => news.location === 'newsTwo');
      if (newsTwo) {
        service.update(newsTwo.id, { ...newsTwo, location: null });
      }
    });
    service.update(news.id, { ...news, location: 'newsTwo' });
  };

  console.log('allNews', allNews);

  return (
    <SelectMainNewsContainer>
      <SelectMainNewsItem>
        <Select
          label="Notícia Principal"
          placeholderText="Selecione a Notícia Principal"
          options={filterOptions(mainNews, news1, news2)}
          onChange={value => sendMainNews(allNews.find(news => news.id.toString() === value) as INews)}
          selected={[mainNews.title]}
        />
        <SelectMainNewsSmall>Primeira notícia exibida em Notícias e Mídias Sociais.</SelectMainNewsSmall>
      </SelectMainNewsItem>
      <SelectMainNewsItem>
        <Select
          label="Card de Notícia 1"
          placeholderText="Selecione a Notícia 1"
          options={filterOptions(mainNews, news1, news2)}
          onChange={value => sendNews1(allNews.find(news => news.id.toString() === value) as INews)}
          selected={[news1.title]}
        />
        <SelectMainNewsSmall>Primeiro card de notícia exibido em Início. </SelectMainNewsSmall>
      </SelectMainNewsItem>
      <SelectMainNewsItem>
        <Select
          label="Card de Notícia 2"
          placeholderText="Selecione a Notícia 2"
          options={filterOptions(mainNews, news1, news2)}
          onChange={value => sendNews2(allNews.find(news => news.id.toString() === value) as INews)}
          selected={[news2.title]}
        />
        <SelectMainNewsSmall>Segundo card de notícia exibido em Início.</SelectMainNewsSmall>
      </SelectMainNewsItem>
    </SelectMainNewsContainer>
  );
};

export default SelectMainNews;
