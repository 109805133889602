import { validDDDs } from 'utils/basicUtils';
import Yup from 'utils/validation';

export const states = [
  '',
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const daysOfWeek = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];

export const getValidationSchema = (formName: string) => {
  switch (formName) {
    case 'Editar Evento':
    case 'Novo Evento':
      return Yup.object().shape({
        title: Yup.string().required(),
        category: Yup.array().min(1).required(),
        description: Yup.string().required(),
        startDate: Yup.string()
          .required()
          .test('is-not-equal', 'Data de início não pode conter todos os números iguais', function (value) {
            if (!value) return false;
            return !/^(.)\1+$/.test(value.replace(/[-/]/g, ''));
          }),
        endDate: Yup.string()
          .required()
          .test('is-not-equal', 'Data de término não pode conter todos os números iguais', function (value) {
            if (!value) return false;
            return !/^(.)\1+$/.test(value.replace(/[-/]/g, ''));
          }),
        startTime: Yup.string().required(),
        email: Yup.string()
          .email()
          .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números'),
        endTime: Yup.string(),
        address: Yup.string()
          .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
          .required(),
        placeId: Yup.string().required(),
        place: Yup.string().required(),
        phone: Yup.string()
          .required('Telefone é obrigatório')
          .test('is-valid-ddd', 'DDD inválido', function (value) {
            if (!value) return false;
            const ddd = value.match(/^\((\d{2})\)/);
            if (!ddd) return false;
            return validDDDs.includes(ddd[1]);
          })
          .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
            if (!value) return false;
            const digits = value.replace(/\D/g, '');
            return !/^(\d)\1+$/.test(digits);
          }),
        price: Yup.string().required(),
        addressInfo: Yup.object().shape({
          zipCode: Yup.string()
            .required('CEP é obrigatório')
            .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
            .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
              if (!value) return false;
              const cleanedValue = value.replace('-', '');
              return !/^(.)\1+$/.test(cleanedValue);
            }),
          neighborhood: Yup.string()
            .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
            .required(),
          number: Yup.string().matches(/^\d+$/, 'Número deve conter apenas dígitos'),
          city: Yup.string().required(),
          state: Yup.string().required(),
        }),
        banners: Yup.array().min(1).required(),
        image: Yup.array().min(1).required(),
      });
    case 'Editar Roteiro':
    case 'Novo Roteiro':
      return Yup.object().shape({
        title: Yup.string().required(),
        category: Yup.array().min(1).required(),
        description: Yup.string().required(),
        address: Yup.string().required(),
        placeId: Yup.string().required(),
        addressInfo: Yup.object().shape({
          zipCode: Yup.string()
            .required()
            .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
            .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
              if (!value) return false;
              const cleanedValue = value.replace('-', '');
              return !/^(.)\1+$/.test(cleanedValue);
            }),
          neighborhood: Yup.string()
            .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
            .required(),
          number: Yup.string().matches(/^\d+$/, 'Número deve conter apenas dígitos'),
          city: Yup.string().required(),
          state: Yup.string().required(),
        }),
        images: Yup.array().min(1).required(),
      });
    default:
      return Yup.object().shape({});
  }
};
