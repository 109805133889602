import styled from 'styled-components';

export const SignUpForm = styled.div`
  width: auto;
  border: 1px solid #9a9a9a;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  height: fit-content;
  h2 {
    margin-bottom: 1vw;
  }
  h2,
  span {
    font-size: 20px;
    font-weight: 500;
  }
  input,
  .selection-field {
    &:not([type='checkbox']) {
      width: 23vw;
      border: 1px solid #9a9a9a;
      border-radius: 18px;
      height: 40px;
      margin-top: 1vh;

      @media (max-width: 960px) {
        width: 100%;
      }
    }
  }
  header {
    display: flex;
    justify-content: space-between;
  }
  .full-name-row {
    div {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  #proper-full-name-row {
    display: flex;
    align-items: center;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-left: 10px;
      width: 43.4px;
      height: 40px;
      border-radius: 20px;
      background: #ffcb29;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      border: none;
    }
    input {
      width: 100% !important;
    }
  }

  @media (max-width: 960px) {
    width: 90%;
    padding: 16px;
    margin: 0 auto;
  }
`;

export const InputField = styled.div`
  div {
    display: flex;
    flex-direction: column;
    label {
      white-space: break-spaces;
      span {
        color: #ec3237;
        margin-left: 0.2vw;
      }
    }
    input {
      padding: 0 0.5vw;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  img {
    border-radius: 18px;
  }

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted #9a9a9a;
  margin: 20px 0;
`;

export const LabelCheck = styled.div`
  display: flex;
  white-space: nowrap;
  margin: 0;
  label {
    margin-left: 1vw;
    white-space: break-spaces;
    a {
      color: black;
      margin-left: 5px;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2vh;
  button {
    &:nth-child(2) {
      background-color: #fff;
      color: var(--ps-amarelo-mdio-ffcb-29, #ffcb29);
      border: 1px solid #ffcb29;
      box-shadow: 0px 0px 10px 0px #00000026;
      margin-right: 1.5vw;
      margin-left: auto;
    }
    &:hover {
      background-color: #020c90;
      border: 1px solid #020c90;
      color: white;
    }
  }
`;
