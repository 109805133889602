import styled from 'styled-components';

export const MainArticle = styled.div`
  li div:nth-child(2) span:nth-child(2) {
    font-size: 30px;
    text-decoration: none;
  }
`;

export const News = styled.li`
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-radius: 18px;
`;

export const ImageContent = styled.div`
  width: 49.5%;
  border-radius: 18px 0 0 18px;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  width: 49.5%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateText = styled.time`
  color: #ffcb29;
  font-size: 16px;
  font-weight: 400;
`;

export const Title = styled.h1`
  color: #272727;
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0px;
`;

export const Description = styled.p`
  color: #272727;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const Divider = styled.hr`
  margin: 10px 0px;
  border-bottom: 2px dotted #9a9a9a;
  border-top: none;
`;

export const Anchor = styled.span`
  color: #ffcb29;
  font-weight: 700;
  margin-top: 7px;
  text-decoration-line: underline;
`;
