import styled from 'styled-components';
import loginTouristBg from 'assets/images/background_login_tourist.svg';

export const Container = styled.div`
  min-width: 68.8vw;
  min-height: 100vh;
  height: fit-content;
  background: url(${loginTouristBg}) no-repeat;
  background-size: cover;
`;

export const Content = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #0d4a9b80;
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const SelectDiv = styled.div`
  height: 100%;
  min-height: 100vh;
  height: 100%;
  width: 40vw;
  background-color: #f9f9f9;

  @media (max-width: 960px) {
    width: 100%;
    min-height: auto;
  }
`;

export const SelectLoginDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  width: 60vw;
  @media (max-width: 960px) {
    width: 100%;
  }
`;
