import React, { useRef, useState } from 'react';
import yup from 'utils/validation';
import { Container, DeleteAccountButton, Divider, FormRow, LabelData } from '../TouristAccount/styles';
import { Form, Formik, FormikProps, FormikValues, FormikHelpers } from 'formik';
import Button from 'components/Buttom/Button';
import ToggleField from 'components/ToggleField';
import ProfileImage from 'components/ProfileImage';
import ChangePasswordButton from 'features/Account/components/ChangePasswordButton';
import { RequestButton } from '../TradeAccount/styles';

interface Props {
  setExcludeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  saveUserData?: (userData: any) => Promise<void>;
  userData: any;
}

const SETURAccount = (props: Props): JSX.Element => {
  const form = useRef<FormikProps<FormikValues>>(null);
  const [editing, setEditing] = useState(false);
  const { setShowModal, setExcludeModal } = props || {};

  const validationSchema = yup.object({
    document: yup.string().length(14).required(),
    email: yup
      .string()
      .email()
      .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
      .required(),
    name: yup.string().required(),
    extraInfo: yup.object({
      registration: yup.string().required(),
      role: yup.string().required(),
    }),
  });

  const handleCancelButtonClick = () => {
    form?.current?.setValues(props.userData);
    setEditing(false);
  };

  const handleSaveButtonClick = async () => {
    await form?.current?.submitForm();

    if (!form?.current?.isValid) {
      return;
    }

    setEditing(false);
  };

  const onlyViewForm = (): JSX.Element => {
    return (
      <Formik
        innerRef={form}
        initialValues={props.userData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize>
        <Form>
          <FormRow id="only-view-first-row">
            <ProfileImage name="profileImage" />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {editing ? <label>Nome Completo</label> : <span>Secretaria do Turismo - SETUR</span>}
              <ToggleField showField={editing} name="name" />
            </div>
            {!editing && <Button text={'EDITAR'} onClick={() => setEditing(true)} />}
          </FormRow>
          <FormRow id="superAdminField">
            <LabelData>
              <label>CPF</label>
              <ToggleField disabled={true} showField={editing} name="document" />
            </LabelData>
            <LabelData>
              <label>Matrícula</label>
              <ToggleField disabled={true} showField={editing} name="extraInfo.registration" />
            </LabelData>
          </FormRow>
          <FormRow id="only-view-fifth-row">
            <LabelData>
              <label>Setor</label>
              <ToggleField disabled={true} showField={editing} name="extraInfo.role" />
            </LabelData>
            <LabelData>
              <label>E-mail</label>
              <ToggleField showField={editing} name="email" />
            </LabelData>
          </FormRow>
        </Form>
      </Formik>
    );
  };

  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (props.saveUserData) {
      await props.saveUserData(values);
    }
  };

  return (
    <Container>
      {onlyViewForm()}
      {editing && (
        <div className="editButtons">
          <Button text={'Cancelar'} onClick={handleCancelButtonClick} />
          <Button text={'Salvar'} type="submit" onClick={handleSaveButtonClick} />
        </div>
      )}
      <Divider />
      <RequestButton>
        <div style={{ display: 'flex', width: 'fit-content', justifyContent: 'space-between', gap: '10px' }}>
          {/* <DeleteAccountButton onClick={() => setExcludeModal && setExcludeModal(true)}>
            <img src="" alt="" />
            <span>Solicitar exclusão de conta</span>
          </DeleteAccountButton> */}
          <ChangePasswordButton />
        </div>
      </RequestButton>
    </Container>
  );
};

export default SETURAccount;
