import React from 'react';
import { Title } from './styles';

interface IProps {
  children?: React.ReactNode;
}

const Heading = (props: IProps): JSX.Element => {
  return <Title>{props.children}</Title>;
};

export default Heading;
