import styled from 'styled-components';

export const SelectDestination = styled.ul`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 0 50px;
  margin-top: 35px;

  @media all and (max-width: 980px) {
    overflow-x: scroll;
    scroll-color: #020c90;
    padding-bottom: 20px;

    ::-webkit-scrollbar-thumb {
      height: 0px;
      width: 0px;
    }
  }

  .disabled {
    button {
      color: #9a9a9a;
      border: 0.5px solid #9a9a9a;
    }
  }
`;

export const DestinationButton = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: 768px) {
    width: inherit;
  }

  img {
    height: 137px;
    width: 70%;

    @media all and (max-width: 768px) {
      width: 100%;
    }
  }

  button {
    box-shadow: none;
    margin-top: 30px;
    width: 100%;
    min-width: 200px;
    background-color: #f9f9f9;
    color: #020c90;
    border: 0.5px solid #020c90;

    @media all and (max-width: 1366px) {
      min-width: 150px;
    }

    @media all and (max-width: 768px) {
      width: 100%;
    }

    &:hover {
      color: #fff;
    }
  }
`;
