import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'components/Card';
import { Anchor, DateText, Description, Divider, ImageContent, InfoContent, News, Title, Info } from './styles';

interface IProps {
  image: string;
  date: string;
  title: string;
  description: string;
  link: string;
}

const NewsCard = (props: IProps): JSX.Element => {
  const { image, date, title, description, link } = props;
  return (
    <Card>
      <News>
        <ImageContent style={{ background: `url(${image}) center center / cover no-repeat` }} />
        <InfoContent>
          <Info>
            <DateText>{date}</DateText>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Info>
          <Info>
            <Divider />
            <Link to={link}>
              <Anchor>Ler Mais</Anchor>
            </Link>
          </Info>
        </InfoContent>
      </News>
    </Card>
  );
};

export default NewsCard;
