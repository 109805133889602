import React, { useEffect, useState } from 'react';
import { Arrow } from 'common/BannerCarousel/styles';
import PublicService from 'services/PublicService';
import IItinerary from 'interfaces/IItinerary';
import { Container } from './styles';
import AwesomeSlider from 'react-awesome-slider';
import BannerDynamic from 'components/BannerDynamic';
import { useLoading } from 'hooks/useLoading';

const ItineratesCaroussel = (): JSX.Element => {
  const [itineraries, setItineraries] = useState<IItinerary[]>([]);
  const { openLoading, closeLoading } = useLoading();

  const service = new PublicService();

  useEffect(() => {
    getItineraries();
  }, []);

  const getItineraries = async () => {
    try {
      openLoading();
      const data = await service.getItineraries();
      setItineraries(data);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  const settings = {
    infinite: true,
    organicArrows: false,
    fillParent: false,
    buttonContentRight: <Arrow>&gt;</Arrow>,
    buttonContentLeft: <Arrow>&lt;</Arrow>,
  };

  return (
    <Container>
      <AwesomeSlider {...settings}>
        {itineraries.map((banner, index) => {
          return (
            <div key={index} className="content-div">
              <BannerDynamic
                id={index}
                banners={banner.banners.length > 0 ? [banner.banners[0]] : []}
                title={banner.title}
                slug={banner.slug}
                description={banner.description}
              />
            </div>
          );
        })}
      </AwesomeSlider>
    </Container>
  );
};

export default ItineratesCaroussel;
