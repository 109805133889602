import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 50px;
  margin: 0 auto 35px;

  a {
    text-decoration: none;
    color: #272727;
  }

  @media (max-width: 980px) {
    padding: 0 16px;
  }
`;
