import React, { useEffect, useState } from 'react';
import { Container, MainContent, MainContentTitle } from './styles';
import MainTitle from 'components/MainTitle';
import TouristOffersBusinessOverviewList from '../TouristOffersBusinessOverview/components/TouristOffersBusinessOverviewList';
import { useParams } from 'react-router';
import { IBusiness } from 'interfaces/IBusinesses';
import touristOffersBusinessService from 'services/TouristOffersBusinessService';
import { useLoading } from 'hooks/useLoading';

const TouristOffersBusinessOverview = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams<{ id?: string }>();
  const [filteredItem, setFilteredItem] = useState<IBusiness>();

  useEffect(() => {
    openLoading();
    setDisabled(true);
    const fetchBusinessData = [fetchTouristOffersBusiness()];
    Promise.all(fetchBusinessData).finally(() => closeLoading());
  }, []);

  const service = {
    touristOffersBusiness: new touristOffersBusinessService(),
  };

  const fetchTouristOffersBusiness = async () => {
    const data = await service.touristOffersBusiness.findOne(Number(id?.slice(3)));
    setFilteredItem(data);
  };

  return (
    <Container>
      <MainTitle>Ofertas Turísticas</MainTitle>
      <MainContent>
        <MainContentTitle>Detalhes do Negócio</MainContentTitle>
        <TouristOffersBusinessOverviewList disabled={disabled} item={filteredItem} />
      </MainContent>
    </Container>
  );
};

export default TouristOffersBusinessOverview;
