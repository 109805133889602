import React from 'react';
import { ListItem } from './styles';
import { Anchor } from 'common/SocialMedia/styles';

interface Props {
  img: string | string[];
  dateStr: string;
  title: string;
  description: string;
  slug: string;
}

const NewsCard: React.FC<Props> = (props: Props) => {
  const { img, dateStr, title, description, slug } = props;
  return (
    <ListItem>
      <Anchor className="news-box" href={'/news/' + slug} rel="noreferrer">
        <div style={{ background: `url(${img}) center center / cover no-repeat` }} />
        <div className="news-card-information">
          <time>{dateStr}</time>
          <h1>{title}</h1>
          <p>{description}</p>
          <hr />
          <a href={'/news/' + slug}>Ler Mais</a>
        </div>
      </Anchor>
    </ListItem>
  );
};

export default NewsCard;
