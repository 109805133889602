import styled from 'styled-components';

export const InputField = styled.div`
  div {
    display: flex;
    flex-direction: column;
    label {
      white-space: nowrap;
      span {
        color: #ec3237;
        margin-left: 0.2vw;
      }
    }
    input {
      padding: 0 10px;
    }
    .SelectedOption {
      width: 23vw;
      span {
        font-size: 16px;
      }
    }

    .OptionList {
      width: 514px;
      height: fit-content;
      right: 0;
      z-index: 1;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  @media (max-width: 960px) {
    div {
      .SelectedOption,
      .OptionList {
        width: 100%;
        left: 0;
      }
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const SignUpForm = styled.div`
  width: auto;
  border: 1px solid #9a9a9a;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  height: fit-content;
  h2 {
    margin-bottom: 1vw;
  }
  h2,
  span {
    font-size: 20px;
    font-weight: 500;
  }
  input {
    &:not([type='checkbox']) {
      width: 23vw;
      border: 1px solid #9a9a9a;
      border-radius: 18px;
      height: 40px;
      margin-top: 1vh;

      @media (max-width: 960px) {
        width: 100%;
      }
    }
  }
  header {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 960px) {
    width: 90%;
    padding: 16px;
    margin: 0 auto;
  }
`;
export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted #9a9a9a;
  margin: 20px 0;
`;

export const LabelCheck = styled.div`
  display: flex;
  white-space: nowrap;
  margin: 0;
  label {
    margin-left: 1vw;
    white-space: break-spaces;
    a {
      color: black;
      margin-left: 5px;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2vh;
`;
