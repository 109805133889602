import React from 'react';
import {
  MainContentInformationTitle,
  MainContentForm,
  MainContentFormItem,
  MainContentFormImageList,
  MainContentFormImageListItem,
} from '../styles';
import { TouristOffersBusinessOverviewListProps } from '../..';

const ToursForm = ({ disabled, item }: TouristOffersBusinessOverviewListProps): JSX.Element => {
  return (
    <>
      <MainContentInformationTitle>
        <div>
          <h2 style={{ fontSize: '20px' }}>{item?.businessOwner[0]}</h2>
          <p>{item?.tradeName}</p>
        </div>
      </MainContentInformationTitle>
      <MainContentForm>
        <MainContentFormItem style={{ gridColumn: 'span 4' }}>
          <label htmlFor="owner-name">Nome Completo do Proprietário</label>
          <input id="owner-name" type="text" placeholder={item?.businessOwner.join(', ')} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem style={{ gridColumn: 'span 2' }}>
          <label htmlFor="email">E-mail</label>
          <input id="email" type="text" placeholder={item?.tradeEmail} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem style={{ gridColumn: 'span 2' }}>
          <label htmlFor="segmentation">Seguimento</label>
          <input id="segmentation" type="text" placeholder={item?.type} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="classification">Classificação</label>
          <input id="classification" type="text" placeholder={item?.businessType} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="fantasy-name">Nome Fantasia</label>
          <input id="fantasy-name" type="text" placeholder={item?.tradeName} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="cnpj">CNPJ</label>
          <input id="cnpj" type="text" placeholder={item?.document} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="cep">CEP</label>
          <input id="cep" type="text" placeholder={item?.address?.zipCode} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem style={{ gridColumn: 'span 2' }}>
          <label htmlFor="address">Endereço</label>
          <input id="address" type="text" placeholder={item?.address?.street} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="number">Número</label>
          <input id="number" type="text" placeholder={item?.address?.number} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="complement">Complemento</label>
          <input id="complement" type="text" placeholder={item?.address?.additionalData} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="neighborhood">Bairro</label>
          <input id="neighborhood" type="text" placeholder={item?.address?.neighborhood} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="city">Cidade</label>
          <input id="city" type="text" placeholder={item?.address?.city} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="state">Estado</label>
          <input id="state" type="text" placeholder={item?.address?.state} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="google-maps-id">Place ID do Google Maps</label>
          <input id="google-maps-id" type="text" placeholder={item?.googleMapsLink} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem style={{ gridColumn: 'span 4' }}>
          <label htmlFor="description">Descrição</label>
          <textarea id="description" placeholder={item?.description} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem style={{ gridColumn: 'span 4' }}>
          <label htmlFor="comments">Observações</label>
          <textarea id="comments" placeholder={item?.tour?.attractions} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="telephone">Telefone</label>
          <input id="telephone" type="text" placeholder={item?.phone} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="whatsapp">WhatsApp</label>
          <input id="whatsapp" type="text" placeholder={item?.whatsapp} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="business-email">E-mail Comercial</label>
          <input id="business-email" type="text" placeholder={item?.tradeEmail} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="youtube-video-link">Link de Vídeo no YouTube</label>
          <input id="youtube-video-link" type="text" placeholder={item?.youtubeLink} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="website-link">Link do Site</label>
          <input id="website-link" type="text" placeholder={item?.site} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="instagram-link">Link do Instagram</label>
          <input id="instagram-link" type="text" placeholder={item?.instagramLink} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem>
          <label htmlFor="facebook-link">Link do Facebook</label>
          <input id="facebook-link" type="text" placeholder={item?.facebookLink} disabled={disabled} />
        </MainContentFormItem>
        <MainContentFormItem style={{ gridColumn: 'span 4' }}>
          <label htmlFor="images">Imagem do Card</label>
          <MainContentFormImageList>
            <MainContentFormImageListItem
              style={{ background: `url(${item?.image}) center center / cover no-repeat` }}
            />
          </MainContentFormImageList>
        </MainContentFormItem>
        <MainContentFormItem style={{ gridColumn: 'span 4' }}>
          <label htmlFor="images">Imagens da Biblioteca</label>
          <MainContentFormImageList>
            {item?.images.map((imageObject, index) => (
              <MainContentFormImageListItem
                key={index}
                style={{
                  background: `url(${imageObject.image}) center center / cover no-repeat`,
                }}
              />
            ))}
          </MainContentFormImageList>
        </MainContentFormItem>
      </MainContentForm>
    </>
  );
};

export default ToursForm;
