import React, { useRef, useState } from 'react';
import { ButtonsDiv, Divider, FormRow, InputField, SignUpForm } from './styles';
import InputPassword from 'common/InputPassword/InputPassword';
import Button from 'components/Buttom/Button';
import FieldMask from 'components/FieldMask';
import { Formik, Field, Form, FormikProps, FormikValues } from 'formik';
import SelectComponent from 'components/Select';
import { states, StatesEnum, StatesSelectProps, validateCPF } from '../../utils';
import ErrorMessage from 'components/ErrorMessage';
import yup from 'utils/validation';
import { validDDDs } from 'utils/basicUtils';

const validationSchema = yup.object({
  document: yup
    .string()
    .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
    .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || ''))
    .required(),
  name: yup
    .string()
    .matches(/^(?!^\d+$)[\d\D]*$/, 'Este campo não pode conter somente números')
    .required(),
  email: yup
    .string()
    .email()
    .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
    .required(),
  password: yup.string().min(8).required(),
  confirm_password: yup
    .string()
    .min(8)
    .oneOf([yup.ref('password'), undefined], 'As senhas não coincidem')
    .required(),
  extraInfo: yup.object({
    //   role: yup.string(),
    //   cadastur: yup.string(),
    //   legalName: yup.string().required(),
    //   responsibleName: yup.string(),
    //    responsibleCpf: yup
    //      .string()
    //      .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
    //      .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || '')),
    //   tradeName: yup.string(),
    phone: yup
      .string()
      .required('Telefone é obrigatório')
      .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
      .test('is-valid-ddd', 'DDD inválido', function (value) {
        if (!value) return false;
        const ddd = value.match(/^\((\d{2})\)/);
        if (!ddd) return false;
        return validDDDs.includes(ddd[1]);
      })
      .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
        if (!value) return false;
        const digits = value.replace(/\D/g, '');
        return !/^(\d)\1+$/.test(digits);
      }),
    address: yup.object({
      additionalData: yup.string(),
      city: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'A cidade não pode conter somente números')
        .required(),
      neighborhood: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
        .required(),
      number: yup.string().required().matches(/^\d+$/, 'Número deve conter apenas dígitos'),
      state: yup.string().required(),
      street: yup
        .string()
        .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
        .required(),
      zipCode: yup
        .string()
        .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
        .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
          if (!value) return false;
          const cleanedValue = value.replace('-', '');
          return !/^(.)\1+$/.test(cleanedValue);
        })
        .required(),
    }),
  }),
});

const initialValues = {
  name: '',
  email: '',
  document: '',
  password: '',
  type: 'tour',
  extraInfo: {
    role: 'tour-pf',
    cadastur: '',
    legalName: '',
    responsibleName: '',
    responsibleCpf: '',
    phone: '',
    tradeName: '',
    address: {
      additionalData: '',
      city: '',
      neighborhood: '',
      number: '',
      state: '',
      street: '',
      zipCode: '',
    },
  },
};

interface Props {
  onCancel: () => void;
  onSubmit: (values: FormikValues) => void;
}

const PfForm = (props: Props): JSX.Element => {
  const pfForm = useRef<FormikProps<FormikValues>>(null);
  const [selectedState, setSelectedState] = useState<string>('');

  const StatesSelect: React.FC<StatesSelectProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={states.map(state => ({ label: state.label, value: state.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as StatesEnum);
            setSelectedState(value as string);
          }
        }}
        name="state"
        selected={[selectedState]}
      />
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      pfForm.current?.submitForm();
      if (pfForm.current?.values) {
        props.onSubmit(pfForm.current.values);
      }
    }
  };

  return (
    <Formik
      innerRef={pfForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}>
      <SignUpForm>
        <header>
          <h2>Dados do responsável:</h2>
          <span>2/2</span>
        </header>
        <Form onKeyDown={handleKeyDown}>
          <FormRow>
            <InputField id="cpf-input">
              <div>
                <label htmlFor="">
                  CPF<span>*</span>
                </label>
                <FieldMask type="cpf">
                  <Field type="text" name="document" placeholder="ex: xxx.xxx.xxx-xx" />
                </FieldMask>
              </div>
              <ErrorMessage name="document" />
            </InputField>

            <InputField id="full-name-input">
              <div>
                <label htmlFor="">
                  Nome Completo<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="cellphone-input">
              <div>
                <label htmlFor="">
                  Telefone ou celular<span>*</span>
                </label>
                <FieldMask type="phone">
                  <Field name="extraInfo.phone" />
                </FieldMask>
              </div>
              <ErrorMessage name="extraInfo.phone" />
            </InputField>
            <InputField id="email-input">
              <div>
                <label htmlFor="">
                  E-mail<span>*</span>
                </label>
                <FieldMask type="email">
                  <Field type="text" name="email" />
                </FieldMask>
              </div>
              <ErrorMessage name="email" />
            </InputField>
            <InputField id="cep-input">
              <div>
                <label htmlFor="">
                  CEP<span>*</span>
                </label>
                <FieldMask type="zipcode">
                  <Field name="extraInfo.address.zipCode" />
                </FieldMask>
              </div>
              <ErrorMessage name="extraInfo.address.zipCode" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="address-input">
              <div>
                <label htmlFor="">
                  Endereço<span>*</span>
                </label>
                <Field name="extraInfo.address.street" />
              </div>
              <ErrorMessage name="extraInfo.address.street" />
            </InputField>
            <InputField id="number-input">
              <div>
                <label htmlFor="">
                  Número<span>*</span>
                </label>
                <Field name="extraInfo.address.number" />
              </div>
              <ErrorMessage name="extraInfo.address.number" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="complement-input">
              <div>
                <label htmlFor="">Complemento</label>
                <Field name="extraInfo.address.additionalData" />
              </div>
              <ErrorMessage name="extraInfo.address.additionalData" />
            </InputField>
            <InputField id="neighborhood-input">
              <div>
                <label htmlFor="">
                  Bairro<span>*</span>
                </label>
                <Field name="extraInfo.address.neighborhood" />
              </div>
              <ErrorMessage name="extraInfo.address.neighborhood" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="city-input">
              <div>
                <label htmlFor="">
                  Cidade<span>*</span>
                </label>
                <Field name="extraInfo.address.city" />
              </div>
              <ErrorMessage name="extraInfo.address.city" />
            </InputField>
            <InputField id="state-input">
              <div>
                <label htmlFor="">
                  Estado<span>*</span>
                </label>
                <Field component={StatesSelect} name="extraInfo.address.state" />
              </div>
              <ErrorMessage name="extraInfo.address.state" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="password-input">
              <div>
                <label htmlFor="">
                  Senha<span>*</span>
                </label>
                <InputPassword name={'password'} />
              </div>
              <ErrorMessage name="password" />
            </InputField>
            <InputField id="password-input">
              <div>
                <label htmlFor="">
                  Confirmação de Senha<span>*</span>
                </label>
                <InputPassword name={'confirm_password'} />
              </div>
              <ErrorMessage name="confirm_password" />
            </InputField>
          </FormRow>
          <Divider />
          <ButtonsDiv>
            <Button onClick={props.onCancel} text="VOLTAR" />
            <Button
              onClick={() => {
                props.onCancel();
                pfForm?.current?.setValues(initialValues);
              }}
              text="CANCELAR"
            />
            <Button text="CRIAR" type="submit" />
          </ButtonsDiv>
        </Form>
      </SignUpForm>
    </Formik>
  );
};

export default PfForm;
