import React from 'react';
import { useParams } from 'react-router';
import BusinessService from 'services/TouristOffersBusinessService';
import { Activity } from 'features/WhatToDo/components/ActivitiesDetail';

const ActivityPreview = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const getActivity = async () => {
    const service = new BusinessService();
    return service.findOne(Number(id));
  };

  return <Activity getActivity={getActivity} />;
};

export default ActivityPreview;
