import React from 'react';
import { Container, HoverDiv } from './styles';
import Button from '../../components/Buttom/Button';
import IDestination from 'interfaces/IDestination';
import { useHistory } from 'react-router';
import SubTitle from 'common/SubTitle/SubTitle';

interface Props {
  destinations?: IDestination[];
}

const Destinations = (props: Props): JSX.Element => {
  const { destinations } = props;
  const history = useHistory();

  const defaultCharacteristics = [
    'LINDAS PRAIAS E CLIMA TRANQUILO',
    'VARIEDADE GASTRONÔMICA',
    'PASSEIOS DE QUADRICICLO, BARCO E MERGULHO COM CILINDRO',
    'CENTRO HISTÓRICO',
  ];

  return (
    <Container>
      <SubTitle text="Conheça Nossos Destinos" />
      <ul>
        {destinations?.slice(0, 4).map(destination => {
          return (
            <li
              key={destination.id}
              style={{ background: `url(${destination.image}) center center / cover no-repeat` }}>
              <Button text={destination.title} onClick={() => history.push(`destinations/${destination.slug}`)} />
              <HoverDiv>
                {(destination.characteristics || defaultCharacteristics).slice(0, 5).map((characteristic, key) => (
                  <span key={key}>{characteristic}</span>
                ))}
                <Button
                  text={`CONHEÇA ${destination.title.toUpperCase()}`}
                  onClick={() => history.push(`destinations/${destination.slug}`)}
                />
              </HoverDiv>
            </li>
          );
        })}
      </ul>
      <Button text="conheça porto seguro" onClick={() => history.push('destinations')} />
    </Container>
  );
};

export default Destinations;
