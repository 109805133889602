import styled from 'styled-components';

export const Container = styled.div`
  width: 40vw;
  background-color: #f9f9f9;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  div {
    height: 3px;
    width: 100%;
    &:first-child {
      background: #00a759;
    }
    &:nth-child(3) {
      background: #ffcb29;
    }
  }
  h3 {
    margin: 0 10px;
    width: fit-content;
    white-space: nowrap;
    color: #020c90;
    font-size: 33px;
  }

  @media (max-width: 960px) {
    margin-top: 16px;
  }
`;

export const ContentDiv = styled.div`
  margin: 5.5vh 3.84vw 0 3.84vw;
  label {
    font-size: 20px;
    font-weight: 500;
    .Mui-checked {
      color: #ffcb29;
    }
  }
  .radioGroup {
    margin-top: 10px;
    gap: 10px;

    @media (max-width: 960px) {
      label span {
        padding: 0 0 0 0;
        margin-left: 5px;
      }
    }
  }

  @media (max-width: 960px) {
    margin-bottom: 20px;
  }
`;
