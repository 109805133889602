import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import { NavLink } from 'react-router-dom';
import checklist from 'assets/images/checklist.svg';
import mindMap from 'assets/images/mindMap.svg';
import starIcon from 'assets/images/starIcon.svg';

const MenuSeturMarket: React.FC = () => {
  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/tourist-offers/business-list">
            <img src={checklist} alt="icon for list item" />
            <span>Ofertas Turísticas</span>
          </NavLink>
        </ListItem>
        {/* <ListItem>
          <NavLink activeClassName="selected" to="/">
            <img src={starIcon} alt="icon for list item" />
            <span>Avaliações</span>
          </NavLink>
        </ListItem> */}
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/strategic-map">
            <img src={mindMap} alt="icon for list item" />
            <span>Mapa Estratégico</span>
          </NavLink>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default MenuSeturMarket;
