import React, { useEffect, useState } from 'react';
import {
  ActionButtons,
  Arrow,
  BannerCarroussel,
  Board,
  BoardLine,
  Container,
  Content,
  Divider,
  FAQ,
  PageTitle,
  Question,
  TourBoard,
  WppButton,
} from './styles';
import AwesomeSlider from 'react-awesome-slider';
import bannerTour1 from 'assets/images/bannerTourExample1.svg';
import bannerTour2 from 'assets/images/bannerTourExample2.svg';
import Button from 'components/Buttom/Button';
import upArrowBlack from 'assets/images/upArrow.svg';
import downArrowBlack from 'assets/images/downArrow.svg';
import upArrowWhite from 'assets/images/upArrowWhite.svg';
import downArrowWhite from 'assets/images/downArrowWhite.svg';
import IQuestion from 'interfaces/IQuestion';
import wppIcon from 'assets/images/wppIconWhite.svg';
import PopUpRegisterTour from '../PopUpRegisterTour';
import { Modal } from '@mui/material';
import PopUpTourData from '../PopUpTourData';
import PopUpTransport from '../PopUpTransport';
import PopUpAccomodation from '../PopUpAccomodation';

const TourOrganizerPage = (): JSX.Element => {
  const [bannerImages, setBannerImages] = useState<string[]>([]);
  const [showNextTours, setShowNextTours] = useState(true);
  const [showLastTours, setShowLastTours] = useState(true);
  const [orderNextTours, setOrderNextTours] = useState(true);
  const [orderLastTours, setOrderLastTours] = useState(true);
  const [orderDeparture, setOrderDeparture] = useState(true);
  const [orderStamp, setOrderStamp] = useState(true);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [showQuestion, setShowQuestion] = useState<boolean[]>([]);
  const [popUpPage, setPopUpPage] = useState(1);
  const [showPopUp, setShowPopUp] = useState(false);

  const mockedQuestions: IQuestion[] = [
    {
      title:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
      question:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et\
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex\
        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu\
        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt\
        mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation\
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in\
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non\
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,\
        consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
      question:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et\
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex\
        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu\
        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt\
        mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation\
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in\
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non\
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,\
        consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
      question:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et\
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex\
        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu\
        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt\
        mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation\
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in\
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non\
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,\
        consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
  ];

  useEffect(() => {
    //fetch images
    const images = [bannerTour1, bannerTour2];
    setBannerImages(images);
    //fetch questions
    const showQuestionsArray = [];
    for (let i = 0; i < mockedQuestions.length; i++) {
      showQuestionsArray[i] = false;
    }
    setShowQuestion(showQuestionsArray);
    setQuestions(mockedQuestions);
  }, []);

  const handleShowQuestion = (index: number) => {
    const cloneArray = structuredClone(showQuestion);
    cloneArray[index] = !cloneArray[index];
    setShowQuestion(cloneArray);
  };

  const settings = {
    infinite: true,
    organicArrows: false,
    fillParent: false,
    buttonContentRight: <Arrow>&gt;</Arrow>,
    buttonContentLeft: <Arrow>&lt;</Arrow>,
  };

  const handlePopUp = (): JSX.Element => {
    switch (popUpPage) {
      case 1:
        return <PopUpRegisterTour setShowPopup={setShowPopUp} setPopUpPage={setPopUpPage} />;
      case 2:
        return <PopUpTourData setShowPopup={setShowPopUp} setPopUpPage={setPopUpPage} />;
      case 3:
        return <PopUpTransport setShowPopup={setShowPopUp} setPopUpPage={setPopUpPage} />;
      case 4:
        return <PopUpAccomodation setShowPopup={setShowPopUp} setPopUpPage={setPopUpPage} />;
      default:
        return <PopUpRegisterTour setShowPopup={setShowPopUp} setPopUpPage={setPopUpPage} />;
    }
  };

  return (
    <>
      <Container>
        <PageTitle>
          <div />
          <span>Excursões</span>
          <div />
        </PageTitle>
        <BannerCarroussel data-testid="tourOrganizerBanner">
          <AwesomeSlider {...settings}>
            {bannerImages.map((img, index) => {
              return (
                <div key={index} data-testid="bannerContentDiv">
                  <img src={img} />;
                </div>
              );
            })}
          </AwesomeSlider>
        </BannerCarroussel>
        <Content>
          <ActionButtons>
            <Button text="CADASTRAR EXCURSÃO" onClick={() => setShowPopUp(true)} />
            <Button text="REGRAS BÁSICAS DE CIRCULAÇÃO" />
            <Button text="TAXAS DE TRANSPORTE E HOSPEDAGEM" />
          </ActionButtons>
          <TourBoard>
            <header>
              <h2>Próximas Excursões</h2>
              <img
                src={showNextTours ? downArrowBlack : upArrowBlack}
                alt={showNextTours ? 'An arrow pointing down' : 'An arrow pointing up'}
                onClick={() => setShowNextTours(!showNextTours)}
              />
            </header>
            {showNextTours && (
              <Board>
                <BoardLine>
                  <div>
                    <span>Chegada</span>
                    <img
                      src={orderNextTours ? downArrowWhite : upArrowWhite}
                      alt={orderNextTours ? 'An arrow pointing down' : 'An arrow pointing up'}
                      onClick={() => setOrderNextTours(!orderNextTours)}
                    />
                  </div>
                  <span>Origem</span>
                  <span>Placa</span>
                  <div>
                    <span>Saída</span>
                    <img
                      src={orderDeparture ? downArrowWhite : upArrowWhite}
                      alt={orderDeparture ? 'An arrow pointing down' : 'An arrow pointing up'}
                      onClick={() => setOrderDeparture(!orderDeparture)}
                    />
                  </div>
                  <div>
                    <span>Selo</span>
                    <img
                      src={orderStamp ? downArrowWhite : upArrowWhite}
                      alt={orderStamp ? 'An arrow pointing down' : 'An arrow pointing up'}
                      onClick={() => setOrderStamp(!orderStamp)}
                    />
                  </div>
                </BoardLine>
                <BoardLine>
                  <span>00/00/0000 00h00</span>
                  <span>Cidade Cidade Cidade Cidade - XX</span>
                  <span>XXXXXXX</span>
                  <span>00/00/0000 00h00</span>
                  <span>Processando Pagamento</span>
                  <div>
                    <button />
                    <button />
                    <button />
                  </div>
                </BoardLine>
              </Board>
            )}
          </TourBoard>
          <TourBoard>
            <header>
              <h2>Histórico de Excursões</h2>
              <img
                src={showLastTours ? downArrowBlack : upArrowBlack}
                alt={showLastTours ? 'An arrow pointing down' : 'An arrow pointing up'}
                onClick={() => setShowLastTours(!showLastTours)}
              />
            </header>
            {showLastTours && (
              <Board>
                <BoardLine>
                  <div>
                    <span>Chegada</span>
                    <img
                      src={orderLastTours ? downArrowWhite : upArrowWhite}
                      alt={orderLastTours ? 'An arrow pointing down' : 'An arrow pointing up'}
                      onClick={() => setOrderLastTours(!orderLastTours)}
                    />
                  </div>
                  <span>Origem</span>
                  <span>Placa</span>
                  <span>Saída</span>
                  <span>Selo</span>
                </BoardLine>
                <BoardLine>
                  <span>00/00/0000 00h00</span>
                  <span>Cidade Cidade Cidade Cidade - XX</span>
                  <span>XXXXXXX</span>
                  <span>00/00/0000 00h00</span>
                  <span>Processando Pagamento</span>
                  <div>
                    <button />
                    <button />
                    <button />
                  </div>
                </BoardLine>
              </Board>
            )}
          </TourBoard>
          <Divider />
          {questions.length && (
            <FAQ>
              <h2>Perguntas Frequentes</h2>
              {questions.map((question, index) => {
                return (
                  <>
                    <Question key={index}>
                      <header>
                        <h4>{question.title}</h4>
                        <img
                          src={showQuestion[index] ? upArrowBlack : downArrowBlack}
                          alt={'An arrow pointing down'}
                          onClick={() => handleShowQuestion(index)}
                        />
                      </header>
                      {showQuestion[index] && <p>{question.question}</p>}
                    </Question>
                    <Divider />
                  </>
                );
              })}
            </FAQ>
          )}
        </Content>
        <WppButton>
          <img src={wppIcon} alt="Whatsapp icon" />
          <span>FALE CONOSCO</span>
        </WppButton>
        <Modal
          open={showPopUp}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {handlePopUp()}
        </Modal>
      </Container>
    </>
  );
};

export default TourOrganizerPage;
