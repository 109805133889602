import styled from 'styled-components';

export const PortalDataClass = styled.div`
  display: block;
  margin: 0 auto 30px;
  width: 100%;

  .LookerPortalData iframe {
    height: 1060px;
    margin-bottom: 25px;
  }

  @media (max-width: 1366px) {
    .LookerPortalData iframe {
      height: 960px;
    }
  }

  @media (max-width: 980px) {
    .LookerPortalData iframe {
      min-height: 400px;
    }
  }
`;
