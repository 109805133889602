import styled from 'styled-components';

export const Container = styled.section`
  .basic-service-button {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    border: 0.5px solid #9a9a9a;
    color: #9a9a9a;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      border-color: #020c90;
      background-color: #020c90;
      color: #ffffff;
    }

    &[data-active='true'] {
      border-color: #020c90;
      color: #020c90;
      &:hover {
        color: #ffffff;
      }
      @media (max-width: 960px) {
        background-color: #fff;
        &:hover {
          color: #020c90;
        }
      }
    }
  }

  .selected {
    border-color: #020c90;
    color: #020c90;
  }
`;
