import React from 'react';
import { Field, useField } from 'formik';
import { Container } from './styles';
import Tooltip from 'components/Tooltip';

interface IProps {
  name: string;
  maxLength?: number;
  title: string;
  isRequired?: boolean;
  hasQuestionMark?: boolean;
  questionMarkText?: string;
  lg?: boolean;
}

const TextArea: React.FC<IProps> = (props: IProps) => {
  const { name, maxLength = 300, title } = props;
  const [field] = useField(props.name);

  const valueLength = field.value.length;
  const color = valueLength === maxLength ? '#ec3237' : '#9a9a9a';
  const style = { color };

  return (
    <Container>
      <label>
        <p>{title}</p>
        <small id="carousel-length" style={style}>
          {`(${valueLength}/${maxLength})`}
        </small>
        {props.isRequired && <span>*</span>}
        {props.hasQuestionMark && <Tooltip text={props.questionMarkText} />}
      </label>
      <Field name={name} as="textarea" maxLength={maxLength} className={`${props.lg ? 'max-height' : ''}`} />
    </Container>
  );
};

export default TextArea;
