import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { Container, Hover, Selector, Option, ImageDiv } from './styles';
import examplePhoto from 'assets/images/examplePhoto.svg';
import { getLocalUser } from 'services/localStorage';

interface IProps {
  name: string;
}

const ProfileImage = (props: IProps): JSX.Element => {
  const [showSelector, setShowSelector] = useState(false);
  const [userPhoto, setUserPhoto] = useState(examplePhoto);
  const [field, meta, helpers] = useField(props.name);
  const { setValue } = helpers;

  useEffect(() => {
    const localUser = getLocalUser();

    if (localUser?.profile?.image) {
      setUserPhoto(localUser?.profile?.image);
    }
  }, []);

  const fileRef = useRef<HTMLInputElement>(null);

  const handleImageClick = () => {
    setShowSelector(true);
  };

  const handleMouseLeave = () => {
    setShowSelector(false);
  };

  const handleFileChange = () => {
    const file = fileRef?.current?.files?.[0];

    if (file) {
      setValue(file);
    }
  };

  const importPhotoClick = () => {
    fileRef?.current?.click();
  };

  return (
    <Container onMouseLeave={handleMouseLeave}>
      <ImageDiv>
        <img src={userPhoto} alt="" />
      </ImageDiv>
      {/* <Hover onClick={handleImageClick}>
        <label>EDITAR</label>
      </Hover>
      <input ref={fileRef} type="file" onChange={handleFileChange} />
      {showSelector && (
        <Selector>
          <Option onClick={importPhotoClick}>Importar foto</Option>
          <Option>Remover foto</Option>
          <Option>Ver foto</Option>
        </Selector>
      )} */}
    </Container>
  );
};

export default ProfileImage;
