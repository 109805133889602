import RestService from './RestService';
import IEvent from 'interfaces/IEvent';

export default class StrategicMapService extends RestService<IEvent> {
  constructor() {
    super('events');
  }

  public async findOneTrade(id: number): Promise<IEvent> {
    const response = await this.httpClient.get<IEvent>(`/${id}`);
    return response.data;
  }

  public async findAllTrades(): Promise<IEvent[]> {
    const response = await this.httpClient.get<IEvent[]>('trades');
    return response.data;
  }

  public async deleteEvent(id: number): Promise<void> {
    await this.remove(id);
  }
}
