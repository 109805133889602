import axios from 'axios';
import { env } from 'utils/env';

export interface Post {
  id: string;
  media_type: string;
  media_url: string;
  permalink: string;
  caption: string;
}

const fetchPosts = async (limit = 4): Promise<Post[]> => {
  const token = env('REACT_APP_INSTAGRAM_ACCESS_TOKEN');
  const fields = ['id', 'media_type', 'media_url', 'permalink', 'caption'];

  const response = await axios.get<{ data: Post[] }>(
    `https://graph.instagram.com/me/media?fields=${fields.join(',')}&limit=${limit}&access_token=${token}`
  );

  return response.data.data;
};

export default fetchPosts;
