import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MainContent = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 50px;

  @media (max-width: 980px) {
    padding: 0 16px;
  }
`;

export const MainContentTitle = styled.h1`
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 30px;
`;
