import React, { useState } from 'react';

import {
  ButtonsDiv,
  Container,
  ExcursionCompanyInformation,
  ExcursionFirstForm,
  ExcursionFirstFormContainer,
  FormRow4Cols,
  InputField,
  RadioButtonsContainer,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import { Link, useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { Formik, Field, Form, FormikProps, FormikValues } from 'formik';
import ErrorMessage from 'components/ErrorMessage';
import FieldMask from 'components/FieldMask';
import { states, StatesEnum, StatesSelectProps } from 'features/Signup/pages/SignupTourOrganizer/utils';
import SelectComponent from 'components/Select';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import { TransportTypeEnum, transportTypes, TransportTypesProps } from 'features/NewExcursionForm/utils';

const NewExcursionStep3Form = (): JSX.Element => {
  const history = useHistory();
  const [disabledInput, setDisabledInput] = useState(false);
  const [selectedTransportType, setSelectedTransportType] = useState('');

  const initialValues = {
    name: '',
    document: '',
    email: '',
    extraInfo: {
      phone: '',
      address: {
        zipCode: '',
        street: '',
        number: '',
        additionalData: '',
        neighborhood: '',
        city: '',
        state: '',
      },
    },
  };

  const TransportTypesSelect: React.FC<TransportTypesProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={transportTypes.map(transportType => ({ label: transportType.label, value: transportType.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as TransportTypeEnum);
            setSelectedTransportType(value as string);
          }
        }}
        name="state"
        selected={[selectedTransportType]}
        disabled={disabledInput}
      />
    );
  };

  const onsubmit = (values: FormikValues) => {
    console.log(values);
  };

  return (
    <Container>
      <MainTitle>Cadastro de Excursão</MainTitle>

      <StepperContainer>
        <StepperItem className="flex flex-col items-center justify-center">
          <article>
            <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step1')}>
              <IconOrganizador color="#ffffff" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Organizador</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step2')}>
                <IconViagem color="#ffffff" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Viagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="onEdit" onClick={() => history.push('/admin/new-excursion/step3')}>
                <IconTransporte color="#020c90" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Transporte</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconHospedagem />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Hospedagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconGuia />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Guia</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconPagamento />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Pagamento</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconResumo />
              </StepperBullet>
            </Link>
          </article>
          <StepperName>Resumo</StepperName>
        </StepperItem>
      </StepperContainer>

      <ExcursionFirstFormContainer>
        <p>*Campo Obrigatório</p>

        <ExcursionFirstForm>
          <h2>Dados da transportadora:</h2>

          <Formik initialValues={initialValues} onSubmit={onsubmit}>
            <Form>
              <FormRow4Cols>
                <InputField id="cadastur-input" className="responsive-grid-column-span-2">
                  <div>
                    <label htmlFor="">
                      CNPJ <span>*</span>
                    </label>
                    <FieldMask type="cnpj">
                      <Field type="text" name="document" placeholder="ex: xx.xxx.xxx/0001-xx" />
                    </FieldMask>
                  </div>
                  <ErrorMessage name="document" />
                </InputField>
                <InputField id="company-name-input" className="responsive-grid-column-span-2">
                  <div>
                    <label htmlFor="">
                      Nome Fantasia<span>*</span>
                    </label>
                    <Field name="extraInfo.tradeName" />
                  </div>
                  <ErrorMessage name="extraInfo.tradeName" />
                </InputField>
                <InputField id="social-reason-input" style={{ gridColumn: 'span 2' }}>
                  <div>
                    <label htmlFor="">
                      Razão Social<span>*</span>
                    </label>
                    <Field name="extraInfo.legalName" />
                  </div>
                  <ErrorMessage name="extraInfo.legalName" />
                </InputField>
              </FormRow4Cols>
              <FormRow4Cols>
                <InputField id="cadastur-input">
                  <div>
                    <label htmlFor="">Cadastur</label>
                    <Field type="text" name="document" />
                  </div>
                  <ErrorMessage name="document" />
                </InputField>
                <InputField id="cellphone-input">
                  <div>
                    <label htmlFor="">
                      Telefone ou celular<span>*</span>
                    </label>
                    <FieldMask type="phone">
                      <Field name="extraInfo.phone" disabled={disabledInput} placeholder="ex.: (xx) x xxxx-xxxx" />
                    </FieldMask>
                  </div>
                  <ErrorMessage name="extraInfo.phone" />
                </InputField>
                <InputField id="email-input" className="responsive-grid-column-span-2">
                  <div>
                    <label htmlFor="">
                      E-mail<span>*</span>
                    </label>
                    <FieldMask type="email">
                      <Field type="text" name="email" disabled={disabledInput} />
                    </FieldMask>
                  </div>
                  <ErrorMessage name="email" />
                </InputField>
              </FormRow4Cols>
              <ExcursionCompanyInformation style={{ alignItems: 'flex-start' }}>
                <h2>Dados do veículo:</h2>
                <p>
                  <strong>Atenção!</strong> A taxa de pagamento pode variar de acordo com o tipo de veículo. Consulte os
                  valores{' '}
                  <a href="#" target="_blank">
                    aqui
                  </a>
                  . Em caso de irregularidade com a documentação do veículo cadastrado, a entrada na cidade pode ser
                  barrada durante a fiscalização.
                </p>
              </ExcursionCompanyInformation>
              <FormRow4Cols>
                <InputField id="state-input">
                  <div>
                    <label htmlFor="">
                      Tipo<span>*</span>
                    </label>
                    <Field component={TransportTypesSelect} name="state" disabled={disabledInput} />
                  </div>
                  <ErrorMessage name="state" />
                </InputField>
                <InputField id="cadastur-input">
                  <div>
                    <label htmlFor="">
                      Placa<span>*</span>
                    </label>
                    <Field type="text" name="document" />
                  </div>
                  <ErrorMessage name="document" />
                </InputField>
                <InputField id="cadastur-input" style={{ gridColumn: 'span 2' }}>
                  <div>
                    <label htmlFor="">
                      Irá conduzir passageiro PcD (Pessoa com Deficiência)?<span>*</span>
                    </label>
                    <RadioButtonsContainer>
                      <label>
                        <Field type="radio" name="isPcd" value="yes" />
                        Sim
                      </label>
                      <label>
                        <Field type="radio" name="isPcd" value="no" />
                        Não
                      </label>
                    </RadioButtonsContainer>
                  </div>
                  <ErrorMessage name="document" />
                </InputField>
              </FormRow4Cols>
              <ButtonsDiv>
                <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step2')}>
                  Voltar
                </Button>
                <article>
                  <Button buttonType="secondary">Cancelar</Button>
                  <Button onClick={() => history.push('/admin/new-excursion/step4')}>Próximo</Button>
                </article>
              </ButtonsDiv>
            </Form>
          </Formik>
        </ExcursionFirstForm>
      </ExcursionFirstFormContainer>
    </Container>
  );
};

export default NewExcursionStep3Form;
