import React from 'react';
import TopBar from 'common/TopBar/';
import Footer from 'common/Footer/Footer';
import showSelectLanguageState from 'components/SelectLanguague/languageState';
import { useRecoilState } from 'recoil';
import HandleMenu from 'common/HandleMenu';
import HandleMobileMenu from 'common/HandleMobileMenu';

interface IProps {
  children: JSX.Element;
}

const PanelLayout = (props: IProps): JSX.Element => {
  const { children } = props;
  const [showOptions, setShowOptions] = useRecoilState(showSelectLanguageState);

  return (
    <main>
      <HandleMobileMenu />
      <TopBar
        closeLanguages={() => {
          setShowOptions(false);
        }}
      />
      <HandleMenu />
      <div className="panel-content">{children}</div>
      <Footer />
    </main>
  );
};

export default PanelLayout;
