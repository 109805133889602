import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import MainTitle from 'components/MainTitle';
import DirectionsMap from 'components/Map/DirectionsMap';
import PlaceMap from 'components/Map/PlaceMap';
import { Container, Map, SelectField } from './styles';
import SelectComponent from 'components/Select';

const HowToGet = (): JSX.Element => {
  const [origin, setOrigin] = useState('Aeroporto de Porto Seguro - BA');
  const [destination, setDestination] = useState('');
  const options = [
    { label: `Arraial d'Ajuda`, value: `Arraial d'Ajuda, Porto Seguro - BA` },
    { label: 'Porto Seguro', value: 'Porto Seguro, BA' },
    { label: 'Caraíva', value: 'Caraíva, Porto Seguro - BA' },
    { label: 'Trancoso', value: 'Trancoso, Porto Seguro - BA' },
    { label: 'Vale Verde', value: 'Vale Verde, Porto Seguro - BA' },
  ];

  useEffect(() => {
    const { geolocation } = window?.navigator;

    geolocation?.getCurrentPosition(({ coords }) => {
      setOrigin(`${coords.latitude},${coords.longitude}`);
    });
  }, []);

  return (
    <Container>
      <MainTitle>Como Chegar</MainTitle>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={8} rowSpacing={4} className="selection-fields">
          <Grid item xs={12} md={6}>
            <SelectField>
              <label>Origem</label>
              <input type="text" className="selection-field" onChange={event => setOrigin(event.target.value)} />
            </SelectField>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectComponent
              label="Destino"
              options={options}
              onChange={selectedValue => {
                if (typeof selectedValue === 'string') setDestination(selectedValue);
              }}
              withCheckbox={false}
              name="options"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Map>
              {destination ? (
                <DirectionsMap origin={origin} destination={destination} />
              ) : (
                <PlaceMap q="Porto Seguro, BA" />
              )}
            </Map>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HowToGet;
