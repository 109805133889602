import styled from 'styled-components';
import check from 'assets/images/check.svg';
import radioCheck from 'assets/images/radioCheck.svg';

export const StyledInput = styled.input`
  border-radius: 18px;
  border: 1px solid #9a9a9a;
  padding: 0 0.5vw;
`;

export const StyledCheckbox = styled.input`
  width: 16px;
  height: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid black;
  border-radius: 3px;

  &:checked {
    background: url(${check}) center no-repeat;
    border: none;
  }
`;

export const StyledRadio = styled.input`
  width: 16px;
  height: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid black;
  border-radius: 3px;
  cursor: pointer;

  &:checked {
    background: url(${radioCheck}) center no-repeat;
    border: none;
  }
`;
