import { Modal } from 'components/Modals';
import React, { useState } from 'react';
import {
  ModalContainer,
  ModalContainerActionBox,
  ModalContainerHeader,
  ModalContainerHeaderTitle,
  ModalContainerText,
} from './styles';
import Button from 'components/Button';
import MessageModal from 'common/MessageModal';
import { useLoading } from 'hooks/useLoading';
import EventService from 'services/EventService';
import BusinessService from 'services/TouristOffersBusinessService';
import { BusinessStatusAPI, IBusiness } from 'interfaces/IBusinesses';
import IEvent, { EventStatusAPI } from 'interfaces/IEvent';

interface ApproveSolicitationModalProps {
  isOpen: boolean;
  toggle: () => void;
  item: IBusiness | IEvent;
}

const ApproveSolicitationModal = ({ isOpen, toggle, item }: ApproveSolicitationModalProps): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const service = {
    event: new EventService(),
    business: new BusinessService(),
  };

  const sendApproveSolicitation = async () => {
    openLoading();
    try {
      openLoading();
      if ('type' in item) {
        await service.business.approve(item.id);
        item.status = BusinessStatusAPI.APPROVED;
      } else {
        await service.event.approve(item.id);
        item.status = EventStatusAPI.APPROVED;
      }

      setShowSuccessModal(true);
    } catch (error) {
      setShowErrorModal(true);
    }
    closeLoading();
  };

  const closeModal = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    toggle();
  };

  return (
    <>
      <MessageModal
        isOpen={showSuccessModal}
        title="Sucesso"
        message="Oferta turística aprovada com sucesso!"
        onClose={closeModal}
      />
      <MessageModal
        isOpen={showErrorModal}
        title="Erro"
        message="Erro ao aprovar oferta turística!"
        onClose={closeModal}
      />
      {!(showSuccessModal || showErrorModal) && (
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalContainer>
            <ModalContainerHeader>
              <ModalContainerHeaderTitle>Aprovação de Oferta Turística</ModalContainerHeaderTitle>
            </ModalContainerHeader>
            <ModalContainerText>
              Você tem certeza de que deseja <b>aprovar</b> essa oferta turística? Se continuar com a ação, {''}
              <b>não será possível desfazê-la</b>.
            </ModalContainerText>
            <ModalContainerActionBox>
              <Button buttonType="outline" className="cancel-button" onClick={toggle}>
                Cancelar
              </Button>
              <Button type="submit" disabled={false} onClick={sendApproveSolicitation}>
                Aprovar
              </Button>
            </ModalContainerActionBox>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default ApproveSolicitationModal;
