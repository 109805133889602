import React from 'react';
import EmbeddedMap from 'components/Map/EmbeddedMap';

export interface ISearchMapProps {
  q: string;
  center?: string;
  zoom?: string;
  maptype?: string;
  language?: string;
  region?: string;
}

const SearchMap = (props: ISearchMapProps): JSX.Element => {
  return <EmbeddedMap mode="search" {...props} />;
};

export default SearchMap;
