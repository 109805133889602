import IVisualIdentity from 'interfaces/IVisualIdentity';
import RestService from './RestService';

export default class VisualIdentityService extends RestService<IVisualIdentity> {
  constructor() {
    super('logo');
  }

  public async getLogo(): Promise<IVisualIdentity> {
    const response = await this.httpClient.get(`/image`);
    return response.data;
  }

  public async getPortalName(): Promise<IVisualIdentity> {
    const response = await this.httpClient.get(`/name`);
    return response.data;
  }

  public async postLogo(image_logo_svg: string | null, logo_name: string | null): Promise<IVisualIdentity> {
    const response = await this.httpClient.post(`/create`, { image_logo_svg, logo_name });
    return response.data;
  }

  public async patchPortalName(logo_name: string): Promise<IVisualIdentity> {
    const response = await this.httpClient.patch(`/update-name`, { logo_name: logo_name });
    return response.data;
  }

  public async patchLogo(image_logo_svg: File): Promise<IVisualIdentity> {
    const formData = new FormData();
    formData.append('image_logo_svg', image_logo_svg, image_logo_svg.name);

    const response = await this.httpClient.patch(`/update-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    return response.data;
  }
}
