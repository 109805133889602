import styled from 'styled-components';

export const SettingsContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 50px auto 0 auto;
`;

export const SettingsHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SettingsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 110px;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  border-radius: 18px;
  background: #ffcb29;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

  &:hover {
    background: #020c90;
  }

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;
