import RestService from './RestService';
import IContestData from 'interfaces/IContestData';

export default class ContestService extends RestService<IContestData> {
  constructor() {
    super('contests');
  }

  public async submitVote(contestData: IContestData): Promise<void> {
    await this.httpClient.post('', contestData);
  }
}
