import React from 'react';
import { Container } from './styles';

interface IProps {
  children?: React.ReactNode;
}

const Card = (props: IProps): JSX.Element => {
  return <Container>{props.children}</Container>;
};

export default Card;
