import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background: #b3b3b3;
  }
`;

export const MainContent = styled.article`
  display: flex;
  flex-direction: column;
  padding: 0 50px;

  @media all and (max-width: 980px) {
    padding: 0 20px;
  }

  label {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #272727;
  }

  select {
    width: fit-content;
    height: 40px;
    padding: 0px 10px;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    gap: 10px;
    cursor: pointer;

    &::before,
    &::after {
      --size: 0.3rem;
      position: absolute;
      content: '';
      right: 1rem;
      pointer-events: none;
    }

    &::before {
      border-left: var(--size) solid transparent;
      border-right: var(--size) solid transparent;
      border-bottom: var(--size) solid black;
      top: 40%;
    }

    &::after {
      border-left: var(--size) solid transparent;
      border-right: var(--size) solid transparent;
      border-top: var(--size) solid black;
      top: 55%;
    }

    option {
      position: absolute;
      top: 280px;
      left: 600px;
      padding: 25px 0px;
      border-radius: 18px;
      border-bottom: 1px solid #9a9a9a;

      &:hover {
        background-color: #f2f2f2;
        cursor: pointer;
      }
    }
  }
`;

export const MainContentTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #272727;

  @media all and (max-width: 980px) {
    font-size: 20px;
  }
`;

export const MainContentSelectBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 20px;
  border-bottom: 1px dotted #9a9a9a;

  @media all and (max-width: 980px) {
    grid-template-columns: 1fr;
  }

  &div select {
    display: none;
  }

  span {
    color: #f00;
  }
`;

export const MainContentFormsBox = styled.article`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;
