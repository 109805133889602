import RestService from './RestService';
import { IBusiness } from 'interfaces/IBusinesses';

export default class StrategicMapService extends RestService<IBusiness> {
  constructor() {
    super('businesses');
  }

  public async findAllTrades(): Promise<IBusiness[]> {
    const response = await this.httpClient.get<IBusiness[]>('trades');
    return response.data;
  }

  public async findAllSeturTrades(): Promise<IBusiness[]> {
    const response = await this.httpClient.get<IBusiness[]>('');
    const filteredItems = response.data.filter(business => business.type === 'Passeio e Lazer');
    return filteredItems;
  }

  public async approve(id: number): Promise<void> {
    await this.httpClient.put(`/${id}/approve`);
  }

  public async refuse(id: number): Promise<void> {
    await this.httpClient.put(`/${id}/refuse`);
  }
}
