import styled from 'styled-components';

export const DetailInfo = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  margin: 50px auto;

  .emptyHour {
    display: none;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;

export const ImageArea = styled.div`
  width: 100%;
  height: 500px;
  margin-left: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 18px;

  div:nth-child(1) {
    height: 77.96327212020033vh;
  }

  @media (max-width: 960px) {
    width: 100%;
    margin-left: 0;

    div:nth-child(1) {
      height: auto;
    }
  }
`;
