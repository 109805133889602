import React, { useEffect, useState } from 'react';
import {
  ActionDiv,
  ButtonsDiv,
  Divider,
  Header,
  ImageDiv,
  ImgLabel,
  LoggedDiv,
  LogoutModal,
  MyAccount,
  TitleDiv,
  UserDiv,
  VerticalDivider,
} from './styles';
import Button from '../../components/Buttom/Button';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { logged } from 'features/Login/store/LoginAtom';
import photoExample from 'assets/images/examplePhoto.svg';
import myAccount from 'assets/images/myAccount.svg';
import logoutIcon from 'assets/images/logoutIcon.svg';
import { getLocalUser, userLogout } from 'services/localStorage';
import { auth } from 'utils/firebase';
import { CircularProgress, Modal } from '@mui/material';
import { navigateBasedOnProfile } from 'utils/navigateBasedOnProfile';
import { usePortalContext } from 'common/PortalContext';

const getProfileName = (profile: string) => {
  const profileNames = {
    admin: 'Administrador',
    news: 'Imprensa',
    'news-external': 'Imprensa',
    setur: 'Secretaria do Turismo',
    'setur-market': 'Secretaria do Turismo',
    'setur-marketing': 'Secretaria do Turismo',
    'setur-fundetur': 'Secretaria do Turismo',
    'setur-attorney': 'Secretaria do Turismo',
    'setur-excursion': 'Secretaria do Turismo',
    tour: 'Organizador de excursão',
    'tour-pf': 'Organizador de excursão',
    'tour-pj': 'Organizador de excursão',
    tourist: 'Turista',
    trade: 'Trade',
  } as { [key: string]: string };
  return profileNames[profile];
};

interface Props {
  closeLanguages: () => void;
}

const TopBar = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [portalName, setPortalName] = useState('');
  const [logo, setLogo] = useState('');
  const [loggedState, setLoggedState] = useRecoilState(logged);
  const [showMyAccountHover, setShowMyAccountHover] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState('Nome Sobrenome');
  const [userPhoto, setUserPhoto] = useState(photoExample);
  const [profileName, setProfileName] = useState('');
  const [navigateUser, setNavigateUser] = useState('');
  const [emailVerified, setEmailVerified] = useState(true);
  const { closeLanguages } = props;
  const path = window.location.pathname;
  const portalContext = usePortalContext();

  // const fetchPortalName = async () => {
  //   const response = await new VisualIdentityService().getPortalName();
  //   response ? setPortalName(response as unknown as string) : setPortalName('Porto Seguro');
  // };

  // const fetchLogo = async () => {
  //   const response = await new VisualIdentityService().getLogo();
  //   response ? setLogo(response as unknown as string) : setLogo(logoSVG);
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     fetchPortalName();
  //     fetchLogo();
  //   }, 500);
  //   setLoading(false);
  // }, []);

  useEffect(() => {
    const localUser = getLocalUser();

    if (localUser?.name) {
      setUserName(localUser.name);
    }
    if (localUser?.profile?.image) {
      setUserPhoto(localUser?.profile?.image);
    }
    if (localUser?.profile?.name) {
      setProfileName(getProfileName(localUser?.profile?.name));
      setNavigateUser(localUser?.profile?.name);
    }
    if (localUser?.email && typeof localUser?.emailVerified === 'boolean') {
      setEmailVerified(localUser.emailVerified);
    }
  }, []);

  const logout = () => {
    setLoggedState(false);
    userLogout();
    auth.signOut();
    history.push('/');
    setShowModal(false);
  };

  const redirectToHome = () => {
    if (!emailVerified) return;
    history.push(navigateBasedOnProfile(navigateUser));
  };

  return (
    <Header>
      {loading ? (
        <TitleDiv>
          <CircularProgress size={60} color="primary" />
        </TitleDiv>
      ) : (
        <TitleDiv>
          {Array.isArray(portalContext.portalLogo) && portalContext.portalLogo[0] ? (
            <img src={portalContext.portalLogo[0].url as string} alt="" onClick={redirectToHome} />
          ) : (
            <img src={portalContext.portalLogo as string} alt="" onClick={redirectToHome} />
          )}
          <VerticalDivider />
          <h1 onClick={redirectToHome}>
            <span>{portalContext.portalName}</span>
          </h1>
        </TitleDiv>
      )}

      <ActionDiv
        onMouseEnter={() => {
          setShowMyAccountHover(false);
        }}>
        <ButtonsDiv
          onMouseLeave={() => {
            setShowMyAccountHover(false);
          }}>
          {!loggedState && (
            <>
              <Button onClick={() => history.push('/signup/')} text={'CRIAR CONTA'} />
              <Button
                onClick={() => {
                  history.push('/login');
                }}
                text={'INICIAR SESSÃO'}
              />
            </>
          )}
          <LoggedDiv>
            {loggedState && (
              <UserDiv>
                <ImageDiv>
                  <img src={userPhoto} alt="" onMouseEnter={() => setShowMyAccountHover(true)} />
                </ImageDiv>
                <div
                  className="userField"
                  onClick={() => {
                    setShowMyAccountHover(!showMyAccountHover);
                  }}>
                  <span className="userName">
                    <b>{userName}</b>
                  </span>
                  <span>{profileName}</span>
                </div>
              </UserDiv>
            )}
            {showMyAccountHover && (
              <MyAccount
                onMouseLeave={() => {
                  setShowMyAccountHover(false);
                }}>
                {emailVerified && (
                  <>
                    <ImgLabel
                      onClick={() => {
                        history.push('/my-account');
                      }}>
                      <img src={myAccount} alt="Minha Conta" />
                      <span>Minha Conta</span>
                    </ImgLabel>
                    <Divider />
                  </>
                )}
                <ImgLabel onClick={() => setShowModal(true)}>
                  <img src={logoutIcon} alt="Sair" />
                  <span>Sair</span>
                </ImgLabel>
              </MyAccount>
            )}
          </LoggedDiv>
        </ButtonsDiv>
      </ActionDiv>
      <Modal open={showModal}>
        <LogoutModal>
          <h2>SAIR</h2>
          <span>Você está se desconectando de uma conta. Deseja continuar?</span>
          <div>
            <Button text="CANCELAR" onClick={() => setShowModal(false)} />
            <Button text="SAIR" onClick={logout} />
          </div>
        </LogoutModal>
      </Modal>
    </Header>
  );
};

export default TopBar;
