import React from 'react';
import { Field, FieldArray, useField, ArrayHelpers } from 'formik';
import { Button, FieldGroup } from './styles';
import ErrorMessage from 'components/ErrorMessage';
import sumIcon from 'assets/images/sumIcon.svg';
import trashIcon from 'assets/images/trashWhiteIcon.svg';

interface IProps {
  name: string;
}

const ArrayField: React.FC<IProps> = (props: IProps) => {
  const [field] = useField(props.name);
  const values: string[] = field.value || [''];
  const maxSize = 4;

  const showRemoveButton = (index: number, length: number) => {
    return (length > 1 && index !== length - 1) || length === maxSize;
  };

  const showInsertButton = (index: number, length: number) => {
    return index === length - 1 && length < maxSize;
  };

  return (
    <div>
      <FieldArray
        name={props.name}
        render={(arrayHelpers: ArrayHelpers) => (
          <div>
            {values.map((value, index, array) => (
              <FieldGroup key={index}>
                <div>
                  <Field name={`${props.name}.${index}`} />
                  <ErrorMessage name={`${props.name}.${index}`} />
                </div>
                {showRemoveButton(index, array.length) && (
                  <Button type="button" onClick={() => arrayHelpers.remove(index)}>
                    <img src={trashIcon} />
                  </Button>
                )}
                {showInsertButton(index, array.length) && (
                  <Button type="button" onClick={() => arrayHelpers.insert(array.length, '')}>
                    <img src={sumIcon} />
                  </Button>
                )}
              </FieldGroup>
            ))}
          </div>
        )}
      />
    </div>
  );
};

export default ArrayField;
