import React from 'react';
import { DestinationButton, SelectDestination } from './styles';
import Button from 'components/Buttom/Button';
import SelectPortoSeguro from 'assets/images/selectDestinationPortoSeguro.svg';
import SelectArraial from 'assets/images/selectDestinationArraial.svg';
import SelectCaraiva from 'assets/images/selectDestinationCaraiva.svg';
import SelectTrancoso from 'assets/images/selectDestinationTrancoso.svg';
import SelectValeVerde from 'assets/images/selectDestinationValeVerde.svg';

interface Props {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

enum DestinationTitleEnum {
  portoSeguro = 'Porto Seguro',
  arraial = "Arraial D'Ajuda",
  caraiva = 'Caraíva',
  trancoso = 'Trancoso',
  valeVerde = 'Vale Verde',
}

enum DestinationSlugEnum {
  portoSeguro = 'porto-seguro',
  arraial = 'arraial-dajuda',
  caraiva = 'caraiva',
  trancoso = 'trancoso',
  valeVerde = 'vale-verde',
}

const destinations = [
  {
    title: DestinationTitleEnum.portoSeguro,
    SelectImg: SelectPortoSeguro,
    slug: DestinationSlugEnum.portoSeguro,
  },
  {
    title: DestinationTitleEnum.arraial,
    SelectImg: SelectArraial,
    slug: DestinationSlugEnum.arraial,
  },
  {
    title: DestinationTitleEnum.caraiva,
    SelectImg: SelectCaraiva,
    slug: DestinationSlugEnum.caraiva,
  },
  {
    title: DestinationTitleEnum.trancoso,
    SelectImg: SelectTrancoso,
    slug: DestinationSlugEnum.trancoso,
  },
  {
    title: DestinationTitleEnum.valeVerde,
    SelectImg: SelectValeVerde,
    slug: DestinationSlugEnum.valeVerde,
  },
];

const DestinationsPage = (props: Props): JSX.Element => {
  const { selected, setSelected } = props;

  return (
    <SelectDestination>
      {destinations.map((destination, index) => {
        return (
          <DestinationButton key={index} className={selected == destination.slug ? 'selected' : 'disabled'}>
            <img src={destination.SelectImg} alt="" />
            <Button text={destination.title} onClick={() => setSelected(destination.slug)} />
          </DestinationButton>
        );
      })}
    </SelectDestination>
  );
};

export default DestinationsPage;
