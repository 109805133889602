import { AxiosInstance } from 'axios';
import getHTTPClient from './httpClient';
import { getAccessToken } from './localStorage';

export default class ImageService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = getHTTPClient();
  }

  public async getSignedUrl(fileName: string): Promise<any> {
    const headers = { Authorization: `Bearer ${getAccessToken()}` };
    const response = await this.instance.get(`/images/get-signed-url/${fileName}`, { headers });
    return response.data;
  }

  public async upload(file: File): Promise<string> {
    const { signedUrl, publicUrl } = await this.getSignedUrl(file.name);
    const headers = { 'Content-Type': file.type };
    const httpClient = getHTTPClient({ baseURL: signedUrl, headers });
    await httpClient.put('', file);
    return publicUrl;
  }

  public async uploadMultiple(files: Array<string | File>): Promise<string[]> {
    const promises = files.map(async file => {
      if (typeof file === 'string') {
        return file;
      }

      return this.upload(file);
    });

    return Promise.all(promises);
  }
}
