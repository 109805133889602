import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { formatDate, truncate } from 'utils/string';
import PublicService from 'services/PublicService';
import ActivityCard from 'components/ActivityCard';
import AddBookmark from 'components/AddBookmark';
import IEvent from 'interfaces/IEvent';
import { Container } from './styles';
import { useLoading } from 'hooks/useLoading';

const EventsList = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [events, setEvents] = useState<IEvent[]>([]);

  const service = new PublicService();

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      openLoading();
      const data = await service.getAllEvents();
      setEvents(data);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3.7}>
          {events?.map((event, index) => (
            <Grid key={index} xs={12} sm={6} md={4} lg={3} xl={3} item>
              <ActivityCard
                image={event.image}
                category={event.category}
                date={formatDate(event.startDate)}
                title={event.title}
                description={event.description}
                link={`/events/${event.slug}`}
                action={<AddBookmark type="event" id={event.id} />}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default EventsList;
