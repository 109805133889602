import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 70px;
  @media (max-width: 980px) {
    margin: 16px 0px 0px;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 30px;
    margin: 30px 0;
    padding: 0 50px;

    @media (max-width: 980px) {
      width: 100%;
      overflow-x: scroll;
      height: 130px;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, 194px);
      grid-auto-flow: column;
      grid-auto-columns: 194px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    li {
      height: auto;
      flex-direction: column;
      display: flex;
      align-items: center;
      padding: 15px 0px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 18px;
      background-color: #ffffff;
      border: 5px solid transparent;
      text-align: center;
      text-transform: capitalize;

      @media all and (max-width: 980px) {
        max-height: 110px;
        margin-top: 10px;
      }

      img {
        width: 40px;
        height: 36px;
      }
      span {
        color: #9a9a9a;
        margin-top: 12px;
        font-size: 20px;
      }
      &:hover {
        outline: 5px solid #ffcb29;
        span {
          color: #020c90;
        }
        img {
          filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
            contrast(126%);
        }
      }
    }
  }
  > a {
    text-decoration: none;
    > button {
      width: 270px;
      display: block;
      margin: auto;
      margin-top: 60px;
      font-size: 16px;
      @media (max-width: 980px) {
        margin-top: 16px;
        font-size: 14px;
      }
    }
  }
`;
