import React, { useEffect, useState } from 'react';
import { DetailInfo, ImageArea } from './styles';
import InfoCard from 'components/InfoCard';
import { useParams } from 'react-router';
import PublicService from 'services/PublicService';
import ContactCard from 'components/ContactCard';
import IAccommodation from 'interfaces/IAccommodation';
import MapDetailPage from 'components/Map/DetailMap';
import { useLoading } from 'hooks/useLoading';
import MainTitle from 'components/MainTitle';
import InfoCardPhotos from 'components/InfoCardPhotos';

const AccomodationDetailCard = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const getAccommodation = async () => {
    const service = new PublicService();
    return service.getAccommodation(id);
  };

  return <Accommodation getAccommodation={getAccommodation} />;
};

interface IProps {
  getAccommodation: () => Promise<IAccommodation>;
}

export const Accommodation = (props: IProps): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [accommodation, setAccommodation] = useState<IAccommodation | undefined>();

  useEffect(() => {
    getAccommodation();
  }, []);

  const getAccommodation = async () => {
    try {
      openLoading();
      const accommodation = await props.getAccommodation();
      setAccommodation(accommodation);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  const fullAddress = [
    accommodation?.address?.street,
    accommodation?.address?.number,
    accommodation?.address?.neighborhood,
    accommodation?.address?.city,
    accommodation?.address?.state,
    accommodation?.address?.zipCode,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <MainTitle>Onde Ficar</MainTitle>
      <DetailInfo>
        <InfoCard
          id={accommodation?.id ?? 0}
          category={accommodation?.businessType}
          title={accommodation?.tradeName}
          description={accommodation?.description}
        />
        <ImageArea
          style={{
            background: `url(${accommodation?.images?.[0]?.image}) center center / cover no-repeat`,
          }}
        />
      </DetailInfo>
      <InfoCardPhotos images={accommodation?.images?.map(({ image }) => image)} />
      <ContactCard
        key={0}
        attachedStructure={accommodation?.description}
        email={accommodation?.tradeEmail}
        facebookLink={accommodation?.facebookLink}
        instagramLink={accommodation?.instagramLink}
        phone={accommodation?.phone}
        site={accommodation?.site}
        whatsapp={accommodation?.whatsapp}
        youtubeLink={accommodation?.youtubeLink}
      />

      <MapDetailPage address={fullAddress} placeId={accommodation?.googleMapsLink} />
    </>
  );
};

export default AccomodationDetailCard;
