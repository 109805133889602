import styled from 'styled-components';

export const Container = styled.div`
  .selection-field {
    height: 4vh;
    border-radius: 18px;
    margin-top: 1vh;
    display: table;
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    border: 1px solid #9a9a9a;
    background-color: #ffffff;

    fieldset {
      border: none !important;
    }

    div {
      padding: 0px;
      height: 100%;
      box-sizing: inherit;
      padding-left: 0.5vw;
      display: table-cell;
      vertical-align: middle;
    }
  }
`;
