import { Modal } from 'components/Modals';
import React, { useState } from 'react';
import {
  ModalContainer,
  ModalContainerActionBox,
  ModalContainerAsterisk,
  ModalContainerHeader,
  ModalContainerHeaderSteps,
  ModalContainerHeaderTitle,
  ModalContainerInput,
  ModalContainerInputLabel,
} from './styles';
import Button from 'components/Button';
import { handleChange } from 'utils/handleChange';
import MessageModal from 'common/MessageModal';

interface SettingsBoxAddCategoryModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (value: string) => void;
}

const SettingsBoxAddCategoryModal = ({ isOpen, toggle, onSubmit }: SettingsBoxAddCategoryModalProps): JSX.Element => {
  const [value, setValue] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });

  const onButtonClick = () => {
    const success = onSubmit?.(value);
    if (success !== undefined) {
      const messageWithSpan = success
        ? 'Categoria <span>criada</span> com sucesso!'
        : 'Erro ao <span>criar</span> categoria.';
      setMessageInfo({
        title: success ? 'Sucesso' : 'Erro',
        message: messageWithSpan,
      });
      setShowMessageModal(success);
      if (success) {
        toggle();
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalContainer>
          <ModalContainerHeader>
            <ModalContainerHeaderTitle>Dados da categoria:</ModalContainerHeaderTitle>
            <ModalContainerHeaderSteps>1/1</ModalContainerHeaderSteps>
          </ModalContainerHeader>
          <ModalContainerInputLabel>
            Nome da Categoria <ModalContainerAsterisk>*</ModalContainerAsterisk>
          </ModalContainerInputLabel>

          <ModalContainerInput
            placeholder=""
            name="category-name"
            onChange={handleChange(setValue)}
            id="Nome da Categoria"
          />
          <ModalContainerActionBox>
            <Button buttonType="outline" className="cancel-button" onClick={toggle}>
              Cancelar
            </Button>
            <Button type="submit" onClick={onButtonClick} disabled={false}>
              Criar
            </Button>
          </ModalContainerActionBox>
        </ModalContainer>
      </Modal>

      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          title={messageInfo.title}
          message={messageInfo.message}
          onClose={() => setShowMessageModal(false)}
        />
      )}
    </>
  );
};

export default SettingsBoxAddCategoryModal;
