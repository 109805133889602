import styled from 'styled-components';

export const TooltipBox = styled.div`
  position: absolute;
  display: inline-block;
  top: -28px;
  width: 240px;
  height: auto;
  font-size: 14px;
  color: #fff;
  border-radius: 18px;
  margin: 0 0 0 15px;
  padding: 10px;
  gap: 10px;
  background: #020c90;
  box-shadow: 0px 0px 2px 0px #000000;
  z-index: 1;
  cursor: default;
`;

export const Arrow = styled.div`
  position: absolute;
  left: -15px;
  top: 42%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #020c90;
  rotate: 90deg;
`;
