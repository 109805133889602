import styled from 'styled-components';
import check from 'assets/images/check.svg';
import RadioCheck from 'assets/images/radioCheck.svg';

export const Container = styled.div`
  width: 95%;
  margin: 20px auto;

  .formName {
    font-size: 23px;
    font-weight: 700;
    color: #272727;
    margin-bottom: 20px;
  }

  label {
    display: block;
    color: #272727;
    font-weight: 500;
    margin-bottom: 5px;
    span {
      color: #ec3237;
    }
  }
  input,
  textarea {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-radius: 18px;
    border: 0.5px solid #9a9a9a;
    color: #272727;
    box-shadow: none;
  }

  input[type='checkbox']:checked {
    background: url(${check}) center no-repeat;
    border: none;
  }

  input[type='radio'] {
    appearance: unset;
    height: 16px;
    padding: 0 7px;
  }

  input[type='radio']:checked {
    background: url(${RadioCheck}) center no-repeat;
    border: none;
    padding: 0 8px;
  }

  .error {
    color: #ec3237;
    position: absolute;
    font-size: 12px;
  }
  .buttonsForm {
    display: flex;
    gap: 20px;
    float: right;
    margin: 20px 0;

    button {
      width: 120px;
    }
  }

  @media (max-width: 960px) {
    .buttonsForm {
      float: unset;
      width: 100%;
    }
  }
`;

export const MainItinerary = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 30px;

  grid-template-areas:
    'radioOption mainItinerary'
    'dashedLine dashedLine';

  .radio-option {
    grid-area: radioOption;
    display: flex;
    flex-direction: column;

    .radio-option-button {
      padding: 6px 0 0 5px;
    }

    .radio-option-group {
      display: flex;
      align-items: baseline;
      gap: 20px;

      .radio-option-group-item {
        display: flex;
        align-items: center;
        margin: 0;
      }
    }
  }

  .mainItinerary {
    grid-area: mainItinerary;
  }

  .dashedLine {
    grid-area: dashedLine;
    border-bottom: 1px dashed #9a9a9a;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
`;

export const MainInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 30px;

  grid-template-areas:
    'title category'
    'description description'
    'experiences experiences';

  .category {
    grid-area: category;
  }
  .category label::after {
    content: '*';
    margin-left: 5px;
    color: #ec3237;
  }
  .title {
    grid-area: title;
  }
  .description {
    grid-area: description;
    textarea {
      height: 76px;
      padding: 10px;
    }
  }
`;

export const Activities = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 30px;

  grid-template-areas: 'activities activities';

  .activities {
    grid-area: activities;
    margin-bottom: 30px;
    textarea {
      height: 76px;
      padding: 10px;
    }
  }
`;

export const AddressField = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 30px;
  grid-template-areas:
    'postalCode address address complement'
    'neighborhood number city state';
  margin-bottom: 20px;

  .OptionList {
    height: 200px;
    overflow: auto;
  }

  .OptionList li:first-child {
    border-bottom: none;
    padding: 0;
  }

  .postalCode {
    grid-area: postalCode;
  }
  .address {
    grid-area: address;
  }
  .complement {
    grid-area: complement;
  }
  .neighborhood {
    grid-area: neighborhood;
  }
  .number {
    grid-area: number;
  }
  .city {
    grid-area: city;
  }
  .state {
    grid-area: state;
  }
  .state label::after {
    content: '*';
    margin-left: 5px;
    color: #ec3237;
  }

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      'address address'
      'postalCode complement'
      'neighborhood number'
      'city state';
  }
`;

export const Contact = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin-bottom: 20px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px 30px;
    grid-template-areas:
      '. .'
      'email email'
      '. .';

    .email {
      grid-area: email;
    }
  }
`;

export const MapAndVideo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 20px;

  .placeId a {
    display: flex;
  }
`;

export const EventInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 30px;
  grid-template-areas:
    'place place place eventLink site'
    'price startDate endDate startTime endTime';
  margin-bottom: 20px;

  .place {
    grid-area: place;
  }
  .eventLink {
    grid-area: eventLink;
  }
  .site {
    grid-area: site;
  }
  .startDate {
    grid-area: startDate;
  }
  .price {
    grid-area: price;
  }
  .endDate {
    grid-area: endDate;
  }
  .startTime {
    grid-area: startTime;
  }
  .endTime {
    grid-area: endTime;
  }

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'place site'
      'eventLink price'
      'startDate endDate'
      'startTime endTime';
  }
`;

export const ItineraryVitrine = styled.div`
  display: flex;
  margin-bottom: 20px;
  input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    padding: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid black;
    border-radius: 3px;
    cursor: pointer;
  }
  input[type='checkbox']:checked {
    background: url(${check}) center no-repeat;
    border: none;
  }
`;

export const VistingHoursDetail = styled.div`
  margin-bottom: 20px;
  .daysWeek {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
    label {
      width: 100px;
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
    input {
      width: 60px;
      padding: 0 5px;
      text-align: center;
    }
    input[type='checkbox'] {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      padding: 0;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid black;
      border-radius: 3px;
      cursor: pointer;
    }
    input[type='checkbox']:checked {
      background: url(${check}) center no-repeat;
      border: none;
    }

    .closedCheck {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 380px) {
    .daysWeek {
      label {
        width: 60px;
        word-break: break-word;
      }
    }
  }

  @media (max-width: 960px) {
    .daysWeek {
      gap: 10px;
      margin-bottom: 10px;
      input {
        width: 48px;
      }
      label {
        min-width: 60px;
      }
    }
  }
`;

export const ExtraInfo = styled.div`
  .slug {
    display: none;
  }
`;
