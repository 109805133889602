import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import VisualIdentityService from 'services/VisualIdentityService';
import logoSVG from 'assets/images/logo.svg';
import { IImage } from 'interfaces/IImage';

interface PortalContextProps {
  portalName: string;
  portalLogo: string | File | IImage[] | undefined;
  setPortalName: (name: string) => void;
  setPortalLogo: (logo: string | File | IImage[] | undefined) => void;
}

const PortalContext = createContext<PortalContextProps | undefined>(undefined);

export const PortalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [portalName, setPortalName] = useState<string>('');
  const [portalLogo, setPortalLogo] = useState<string | File | IImage[] | undefined>('');

  const fetchPortalName = async () => {
    const response = await new VisualIdentityService().getPortalName();
    response ? setPortalName(response as unknown as string) : setPortalName('Porto Seguro');
  };

  const fetchLogo = async () => {
    const response = await new VisualIdentityService().getLogo();
    response ? setPortalLogo(response as unknown as string) : setPortalLogo(logoSVG);
  };

  useEffect(() => {
    fetchPortalName();
    fetchLogo();
  }, []);

  return (
    <PortalContext.Provider value={{ portalName, portalLogo, setPortalName, setPortalLogo }}>
      {children}
    </PortalContext.Provider>
  );
};

export const usePortalContext = (): PortalContextProps => {
  const context = useContext(PortalContext);
  if (!context) {
    throw new Error('usePortalContext must be used within a PortalProvider');
  }
  return context;
};
