import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background: #00000061;
  z-index: 999;
`;

export const ModalBlock = styled.div`
  width: 830px;
  height: auto;
  padding: 30px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  background: #fff;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    width: 90%;
    height: 500px;
    overflow-y: auto;
    padding: 10px;
  }
`;

export const ModalTitle = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #9a9a9a;
  text-transform: uppercase;

  h1 {
    font-size: 39px;
  }

  h2 {
    font-size: 20px;
  }

  @media (max-width: 960px) {
    h1 {
      font-size: 27px;
    }

    h2 {
      font-size: 14px;
    }
  }
`;

export const ModalOptions = styled.div`
  display: block;
  margin-bottom: 20px;

  > label {
    display: block;
    font-size: 20px;
    font-weight: 700;
    color: #272727;
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    > label {
      font-size: 17px;
    }
  }
`;

export const ModalForm = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  cursor: pointer;

  img {
    margin-bottom: 10px;
  }

  @media (max-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 10px;
    grid-template-areas:
      '. .'
      '. .'
      'last last';
    label {
      margin-bottom: 10px;
    }

    label:last-child {
      grid-area: last;
      place-self: center;
    }
  }
`;

export const ModalFormInput = styled.div`
  display: flex;
  align-items: center;

  input {
    margin-right: 10px;
    cursor: pointer;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #272727;
    border-radius: 50%;
    outline: none;
  }

  input[type='radio'] {
    &:checked {
      background: #ffcb29;
      outline: 1px solid #ffcb29;
      border: 2px solid #fff;
    }
  }
`;
export const ModalText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  label {
    font-size: 1rem;
    color: #272727;
    margin-bottom: 5px;
  }

  input[type='text'] {
    width: 100%;
    padding: 8px 10px;
    height: 50px;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
  }

  @media (max-width: 960px) {
    input[type='text'] {
      height: 202px;
    }
  }
`;

export const ModalObservation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: #272727;
  margin-bottom: 20px;

  @media (max-width: 960px) {
    align-items: flex-start;
    text-align: left;
  }
`;

export const ModalButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  .modal-cancel-outline-button {
    background-color: transparent;
    border: 0.5px solid #ffcb29;
    color: #ffcb29;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

    &:visited {
      color: #9a9a9a;
      border-color: #9a9a9a;
    }

    &:hover {
      border: none;
      background-color: #020c90;
      color: #ffffff;
    }

    .selected {
      color: #020c90;
      border-color: #020c90;
    }
  }

  button {
    width: 20%;
    margin: 0 10px;
  }

  @media (max-width: 960px) {
    button {
      width: auto;
    }
  }
`;

export const ModalSuccess = styled.div`
  width: 30%;
  height: auto;
  padding: 30px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  background: #fff;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    width: 90%;
  }
`;

export const SuccessTitle = styled.div`
  display: block;
  margin-bottom: 20px;
  h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  span {
    font-size: 1rem;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
`;
