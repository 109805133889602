import React, { useEffect, useState } from 'react';
import { DetailInfo, ImageArea } from './styles';
import InfoCard from 'components/InfoCard';
import { useParams } from 'react-router';
import PublicService from 'services/PublicService';
import ContactCard from 'components/ContactCard';
import IEvent from 'interfaces/IEvent';
import MapDetailPage from 'components/Map/DetailMap';
import { useLoading } from 'hooks/useLoading';
import { formatDate } from 'utils/string';
import MainTitle from 'components/MainTitle';
import InfoCardPhotos from 'components/InfoCardPhotos';

const EventDetailCard = (): JSX.Element => {
  const { slug } = useParams<{ slug: string }>();

  const getEvent = async () => {
    const service = new PublicService();
    return service.getEvents(slug);
  };

  return <Event getEvent={getEvent} />;
};

interface IProps {
  getEvent: () => Promise<IEvent>;
}

export const Event = (props: IProps): JSX.Element => {
  const [events, setEvents] = useState<IEvent | undefined>();
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    try {
      openLoading();
      const events = await props.getEvent();
      setEvents(events);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  const fullAddress = [
    events?.addressInfo?.street,
    events?.addressInfo?.number,
    events?.addressInfo?.neighborhood,
    events?.addressInfo?.city,
    events?.addressInfo?.state,
    events?.addressInfo?.zipCode,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <MainTitle>Agenda</MainTitle>
      <DetailInfo>
        <InfoCard
          id={events?.id ?? 0}
          category={events?.category}
          date={events?.date}
          title={events?.title}
          description={events?.description}
          startDate={formatDate(events?.startDate ?? '')}
          endDate={formatDate(events?.endDate ?? '')}
          startTime={events?.startTime}
          endTime={events?.endTime}
          place={events?.place}
          price={events?.price}
        />
        <ImageArea
          style={{
            background: `url(${events?.image ?? ''}) center center / cover no-repeat`,
          }}
        />
      </DetailInfo>
      <InfoCardPhotos images={events?.images?.map(({ image }) => image)} />
      <ContactCard
        key={0}
        email={events?.email}
        facebookLink={events?.facebookLink}
        instagramLink={events?.instagramLink}
        phone={events?.phone}
        site={events?.site}
        whatsapp={events?.whatsapp}
        youtubeLink={events?.youtubeLink}
      />

      <MapDetailPage address={fullAddress} placeId={events?.placeId} />
    </>
  );
};

export default EventDetailCard;
