import React, { useEffect, useState } from 'react';
import { Container, MainContent, MainContentTitle } from './styles';
import MainTitle from 'components/MainTitle';
import TouristOffersEventsOverviewList from '../TouristOffersEventsOverview/components/TouristOffersEventsOverviewList';
import { useParams } from 'react-router';
import IEvent from 'interfaces/IEvent';
import touristOffersEventsService from 'services/TouristOffersEventsService';
import { useLoading } from 'hooks/useLoading';

const TouristOffersEventsOverview = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams<{ id?: string }>();
  const [filteredItem, setFilteredItem] = useState<IEvent>();

  useEffect(() => {
    openLoading();
    setDisabled(true);
    const fetchEventsData = [fetchTouristOffersEvents()];
    Promise.all(fetchEventsData).finally(() => closeLoading());
  }, []);

  const service = {
    touristOffersEvents: new touristOffersEventsService(),
  };

  const fetchTouristOffersEvents = async () => {
    const data = await service.touristOffersEvents.findOneTrade(Number(id?.slice(3)));
    setFilteredItem(data);
  };

  return (
    <Container>
      <MainTitle>Ofertas Turísticas</MainTitle>
      <MainContent>
        <MainContentTitle>Detalhes do Evento</MainContentTitle>
        <TouristOffersEventsOverviewList disabled={disabled} item={filteredItem} />
      </MainContent>
    </Container>
  );
};

export default TouristOffersEventsOverview;
