import React from 'react';
import { PrimaryButton, OutlineButton, DisabledButton, SecondaryButton } from './styles';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: 'primary' | 'outline' | 'disabled' | 'secondary';
}

const Button = (props: IButtonProps): JSX.Element => {
  const { buttonType = 'primary', ...buttonProps } = props;

  if (buttonType === 'outline') {
    return <OutlineButton {...buttonProps} />;
  }

  if (buttonType === 'disabled') {
    return <DisabledButton {...buttonProps} disabled={true} />;
  }

  if (buttonType === 'secondary') {
    return <SecondaryButton {...buttonProps} />;
  }

  return <PrimaryButton {...buttonProps} />;
};

export default Button;
