import React from 'react';
import { StyledCheckbox, StyledInput, StyledRadio } from './styles';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  type: string;
}

const Input: React.FC<Props> = (props: Props) => {
  const { type } = props;

  switch (type) {
    case 'checkbox':
      return <StyledCheckbox {...props} />;
    case 'radio':
      return <StyledRadio {...props} />;
    default:
      return <StyledInput {...props} />;
  }
};

export default Input;
