import styled from 'styled-components';

export const Menu = styled.nav`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 999;

  @media all and (max-width: 980px) {
    display: none;
  }

  ul {
    display: flex;
    width: fit-content;
    justify-content: space-around;
    gap: 10px;
  }
`;

export const ListItem = styled.li`
  width: fit-content;
  height: 40px;
  padding: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;

  .selected {
    span {
      color: #020c90;
    }
    img {
      filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
        contrast(126%);
    }
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #9a9a9a;
    white-space: nowrap;

    img {
      margin-right: 10px;
    }
  }
  &:hover {
    span {
      color: #020c90;
    }
    img {
      filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
        contrast(126%);
    }
  }

  &.active {
    span {
      color: #020c90;
    }
    img {
      filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
        contrast(126%);
    }
  }

  .observatory-tourist {
    border: 3px dashed #ffcb29;
    border-radius: 18px;
    position: relative;
    padding: 15px 10px;

    @media all and (max-width: 980px) {
      border: none;
      padding-left: 30px;

      &:before {
        display: none;
      }
    }
  }

  &.my-experiences {
    border: 3px dashed #ffcb29;
    border-radius: 18px;
    position: relative;

    &:before {
      content: 'Novo';
      position: absolute;
      top: -12px;
      right: 0;
      font-size: 10px;
      background: #ffcb29;
      color: #fff;
      padding: 3px 10px;
      border-radius: 8px;
    }

    @media all and (max-width: 980px) {
      border: none;

      &:before {
        display: none;
      }
    }
  }
`;
