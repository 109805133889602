import styled from 'styled-components';

export const MapPlace = styled.section`
  display: block;
  width: 100%;
  margin: 0 auto 50px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;
  @media (max-width: 960px) {
    padding: 0 20px;
    flex-direction: column;
    padding: 0 20px;
  }
`;

export const MapInfo = styled.div`
  width: 100%;
  h2,
  p {
    margin-bottom: 10px;
  }
`;

export const MapField = styled.div`
  height: 250px;
  iframe {
    width: 100%;
    height: 250px;
    border-radius: 18px;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  }
`;
