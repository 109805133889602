import { Modal } from 'components/Modals';
import React, { useState } from 'react';
import {
  ModalContainer,
  ModalContainerActionBox,
  ModalContainerHeader,
  ModalContainerHeaderTitle,
  ModalContainerText,
} from './styles';
import Button from 'components/Button';
import { Item } from 'components/SettingsBox';
import MessageModal from 'common/MessageModal';

interface SettingsBoxRemoveCategoryModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSubmit?: (item: Item) => boolean | void;
  item: Item;
}

const SettingsBoxRemoveCategoryModal = ({
  isOpen,
  toggle,
  onSubmit,
  item,
}: SettingsBoxRemoveCategoryModalProps): JSX.Element => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });

  const deleteCategoryName = () => {
    if (onSubmit) {
      const success = onSubmit(item);

      if (success !== undefined) {
        const messageWithSpan = success
          ? 'Categoria <span>removida</span> com sucesso!'
          : 'Erro ao <span>remover</span> categoria.';

        setMessageInfo({
          title: success ? 'Sucesso' : 'Erro',
          message: messageWithSpan,
        });

        setShowMessageModal(true);

        if (success) {
          toggle();
        }
      }
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalContainer>
          <ModalContainerHeader>
            <ModalContainerHeaderTitle>Exclusão de Item</ModalContainerHeaderTitle>
          </ModalContainerHeader>
          <ModalContainerText>
            Você tem certeza de que deseja <b>excluir</b> esse item?
          </ModalContainerText>
          <ModalContainerActionBox>
            <Button buttonType="outline" className="cancel-button" onClick={toggle}>
              Cancelar
            </Button>
            <Button type="submit" onClick={deleteCategoryName} disabled={false}>
              Excluir
            </Button>
          </ModalContainerActionBox>
        </ModalContainer>
      </Modal>

      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          title={messageInfo.title}
          message={messageInfo.message}
          onClose={() => setShowMessageModal(false)}
        />
      )}
    </>
  );
};

export default SettingsBoxRemoveCategoryModal;
