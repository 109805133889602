import React, { useState } from 'react';
import {
  AccomodationType,
  ButtonsDiv,
  Container,
  FormRow,
  InputField,
  NoReservationComponent,
  RadioGroup,
  RadioItem,
} from './styles';
import Button from 'components/Buttom/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router';

interface Props {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPopUpPage: React.Dispatch<React.SetStateAction<number>>;
}

const PopUpAccomodation = (props: Props): JSX.Element => {
  const { setPopUpPage, setShowPopup } = props;
  const [renderForm, setRenderForm] = useState(-1);
  const history = useHistory();

  const handleSubmit = (data: any) => {
    console.log(data);
  };

  const handleForm = () => {
    switch (renderForm) {
      case 1:
        return <WithCNPJForm />;
      case 2:
        return <NoCNPJForm />;
      case 3:
        return <NoReservation />;
      default:
        return <></>;
    }
  };

  const WithCNPJForm = () => {
    return (
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  CNPJ<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">Cadastur</label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Nome Fantasia<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="cellphone-input">
              <div>
                <label htmlFor="">
                  Telefone ou Celular<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField id="email-input">
              <div>
                <label htmlFor="">
                  E-mail<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField className="cep-input">
              <div>
                <label htmlFor="">
                  CEP<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Endereço<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField className="cep-input">
              <div>
                <label htmlFor="">
                  Número<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Complemento<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Bairro<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Localidade<span>*</span>
                </label>
                <Field name="name" as="select">
                  <option value="0">Selecione</option>
                  <option value="1">Arraial d’Ajuda</option>
                  <option value="2">Caraíva</option>
                  <option value="3">Porto Seguro</option>
                  <option value="4">Trancoso</option>
                  <option value="5">Vale Verde</option>
                </Field>
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
        </Form>
      </Formik>
    );
  };

  const NoCNPJForm = () => {
    return (
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">CNPJ</label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">Cadastur</label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Nome Fantasia<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="cellphone-input">
              <div>
                <label htmlFor="">
                  Telefone ou Celular<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField id="email-input">
              <div>
                <label htmlFor="">
                  E-mail<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField className="cep-input">
              <div>
                <label htmlFor="">
                  CEP<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Endereço<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField className="cep-input">
              <div>
                <label htmlFor="">
                  Número<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Complemento<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Bairro<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  Localidade<span>*</span>
                </label>
                <Field name="name" as="select">
                  <option value="0">Selecione</option>
                  <option value="1">Arraial d’Ajuda</option>
                  <option value="2">Caraíva</option>
                  <option value="3">Porto Seguro</option>
                  <option value="4">Trancoso</option>
                  <option value="5">Vale Verde</option>
                </Field>
              </div>
              <ErrorMessage name="name" />
            </InputField>
          </FormRow>
        </Form>
      </Formik>
    );
  };

  const NoReservation = () => {
    return (
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        <Form>
          <NoReservationComponent>
            <header>
              <h4>Objetivo da Visita</h4> <span>*</span>
            </header>
            <Field name="name" as="textarea" />
          </NoReservationComponent>
        </Form>
      </Formik>
    );
  };
  return (
    <Container>
      <header>
        <h1>Dados do meio de hospedagem:</h1>
        <span>4/7</span>
      </header>
      <p>
        <strong>Atenção!</strong> As taxas de pagamento variam de acordo com o tipo de hospedagem e existência do
        Cadastur. Consulte os valores <a href="">aqui.</a> Caso não saiba informar os dados solicitados, entre em
        contato com o local de hospedagem.
      </p>
      <AccomodationType>
        <h4>Tipo de hospedagem</h4>
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          <Form>
            <RadioGroup>
              <RadioItem>
                <label>
                  <Field
                    name="name"
                    type="radio"
                    value="1"
                    onChange={() => {
                      setRenderForm(1);
                    }}
                    checked={renderForm == 1}
                  />
                  Hospedagem com CNPJ
                </label>
                <label>
                  <Field
                    name="name"
                    type="radio"
                    value="2"
                    onChange={() => {
                      setRenderForm(2);
                    }}
                    checked={renderForm == 2}
                  />
                  Hospedagem sem CNPJ
                </label>
                <label>
                  <Field
                    name="name"
                    type="radio"
                    value="3"
                    onChange={() => {
                      setRenderForm(3);
                    }}
                    checked={renderForm == 3}
                  />
                  Não possuo reserva em hospedagem
                </label>
              </RadioItem>
            </RadioGroup>
          </Form>
        </Formik>
      </AccomodationType>
      {handleForm()}
      <ButtonsDiv>
        <Button text="EDITAR" onClick={() => history.push('my-account')} />
        <Button
          text="CANCELAR"
          onClick={() => {
            setShowPopup(false);
            setPopUpPage(1);
          }}
        />
        <Button text="PRÓXIMO" onClick={() => setPopUpPage(3)} />
      </ButtonsDiv>
    </Container>
  );
};

export default PopUpAccomodation;
