import styled from 'styled-components';

export const Title = styled.h1`
  position: absolute;
  display: flex;
  margin-top: 30px;
  padding: 10px 50px;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #ffff;
  background-color: #ffcb29;
  border-radius: 0 18px 18px 0;
  z-index: 999;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    font-size: 23px;
    padding: 5px 16px;
    margin-top: 20px;
    border-radius: 0px 6px 6px 0px;
  }
`;
