import styled from 'styled-components';

export const StyledButton = styled.button`
  background-color: #ffcb29;
  border-radius: 18px;
  width: 118px;
  height: 40px;
  cursor: pointer;

  font-weight: 700;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  box-shadow: 0px 0px 10px 0px #00000026;
  &:hover {
    background-color: #020c90;
  }

  &:disabled {
    background-color: #d9d9d9;
    cursor: not-allowed;
  }
`;
