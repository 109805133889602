import styled from 'styled-components';

export const DescriptionsInfoCard = styled.div`
  display: block;
  width: 100%;
  padding: 1.875rem;
  padding-bottom: 0;
  background: #fff;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    width: 100%;
    padding: 1rem;
    margin: 0 auto 2rem;
  }
`;

export const InfoCardPrice = styled.span`
  display: block;
  color: #020c90;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
`;

export const InfoCardTitle = styled.h4`
  display: block;
  font-size: 1.438rem;
  font-weight: 700;
  color: #272727;
  margin-bottom: 1.25rem;
`;

export const InfoCardPlace = styled.p`
  display: block;
  color: #272727;
  line-height: 1.232rem;
  margin-bottom: 1.25rem;
`;

export const InfoCardDescription = styled.p`
  display: block;
  color: #272727;
  line-height: 1.232rem;
  margin-bottom: 1.25rem;
`;

export const InfoCardRatings = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: #272727;
  font-weight: 700;
`;
