import React, { useEffect, useState } from 'react';
import { Container, ExcursionsButtonsContainer, WppButton } from './styles';
import MainTitle from 'components/MainTitle';
import IExcursionsData from 'interfaces/IExcursionsData';
import ExcursionsCarousel from 'common/ExcursionsCarousel/ExcursionsCarousel';
import PublicService from 'services/PublicService';
import { useLoading } from 'hooks/useLoading';
import Button from 'components/Button';
import { Link, useHistory } from 'react-router-dom';
import WppIcon from 'assets/images/wppIconWhite.svg';
import { Grid } from '@mui/material';

const ExcursionsPage = (): JSX.Element => {
  const [excursionsImageData, setExcursionsImageData] = useState<IExcursionsData | undefined>();
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();

  useEffect(() => {
    getExcursionsData();
  }, []);

  const getExcursionsData = async () => {
    try {
      openLoading();
      const service = new PublicService();
      await service.getHomeData().then(setExcursionsImageData);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  return (
    <Container>
      <MainTitle>Excursões</MainTitle>
      <ExcursionsCarousel banners={excursionsImageData?.banners} />
      <WppButton onClick={() => window.open('https://api.whatsapp.com/send?phone=5573988667507')}>
        <img src={WppIcon} alt="WhatsApp Icon" />
        Fale Conosco
      </WppButton>

      <ExcursionsButtonsContainer>
        <Grid container xl="auto" md="auto" sm="auto" xs="auto" sx={{ paddingBottom: '10px' }}>
          <Link to="/admin/new-excursion/step1">
            <Button type="button">Cadastrar excursão</Button>
          </Link>

          <Button
            buttonType="secondary"
            onClick={() =>
              window.open(
                'https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/Porto%20Seguro%20-%20Regras%20Basicas%20de%20Circula%C3%A7%C3%A3o.pdf',
                '_blank'
              )
            }>
            Regras Básicas de Circulação
          </Button>

          <Button
            buttonType="secondary"
            onClick={() =>
              window.open(
                'https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/Porto%20Seguro_Taxas%20de%20transporte%20e%20hospedagem.pdf',
                '_blank'
              )
            }>
            Taxas de Transporte e Hospedagem
          </Button>
        </Grid>
      </ExcursionsButtonsContainer>
    </Container>
  );
};

export default ExcursionsPage;
