import React, { useState, useEffect } from 'react';
import { BGIntro, Container } from './styles';
import MainTitle from 'components/MainTitle';
import ButtonMenu from 'components/ButtonMenu';
import { useParams } from 'react-router';
import Overview from './components/Overview';
import Detailing from './components/Detailing';
import PortalData from './components/PortalData';

const ObservatoryTourist = (): JSX.Element => {
  const { slug } = useParams<{ slug?: string }>();
  const [page, setPage] = useState(slug);
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const urlLocation = '/admin/observatory-tourist';

  const options = {
    overview: 'Visão Geral',
    // touristBusiness: 'Negócios Turísticos',
    // pointsOfInterest: 'Pontos de Interesse',
    // accomodation: 'Hospedagem',
    // booking: 'Booking',
    // collection: 'Arrecadação',
    // flightOffers: 'Ofertas de Voo',
    detailing: 'Presença Online',
    portalData: 'Dados do Portal',
  };

  const handleComponent = () => {
    switch (page) {
      case 'overview':
        return <Overview />;
      // case 'touristBusiness':
      //   return <h1>Negócios Turísticos</h1>;
      // case 'pointsOfInterest':
      //   return <h1>Pontos de Interesse</h1>;
      // case 'accomodation':
      //   return <h1>Hospedagem</h1>;
      // case 'booking':
      //   return <h1>Booking</h1>;
      // case 'collection':
      //   return <h1>Arrecadação</h1>;
      // case 'flightOffers':
      //   return <h1>Ofertas de Voo</h1>;
      case 'detailing':
        return <Detailing />;
      case 'portalData':
        return <PortalData />;
      default:
        return <Overview />;
    }
  };

  const handleMenuChange = (key: string) => {
    setPage(key);
  };

  return (
    <>
      {showIntro && (
        <BGIntro>
          <p className="loading-text"></p>
          <div className="bg-to"></div>
        </BGIntro>
      )}
      {!showIntro && (
        <>
          <MainTitle>Observatório Turístico</MainTitle>
          <Container>
            <ButtonMenu options={options} onChange={handleMenuChange} urlLocation={urlLocation} />
            {handleComponent()}
          </Container>
        </>
      )}
    </>
  );
};

export default ObservatoryTourist;
