import React from 'react';
import { Field, useField } from 'formik';
import ErrorMessage from 'components/ErrorMessage';
import FieldMask, { MaskType } from 'components/FieldMask';
import { InputField } from './styles';

interface IProps {
  name: string;
  showField?: boolean;
  disabled?: boolean;
  mask?: MaskType;
}

const ToggleField: React.FC<IProps> = (props: IProps) => {
  const { name, showField, disabled, mask } = props;
  const [field] = useField(props.name);

  if (!showField) {
    return <span>{field.value}</span>;
  }

  return (
    <InputField>
      <div>
        {mask ? (
          <FieldMask type={mask}>
            <Field name={name} disabled={disabled} placeholder={field.value} />
          </FieldMask>
        ) : (
          <Field name={name} disabled={disabled} placeholder={field.value} />
        )}
      </div>
      <ErrorMessage name={name} />
    </InputField>
  );
};

export default ToggleField;
