export enum TourReasonsEnum {
  'Acadêmico' = 'Acadêmico',
  'Evento Esportivo' = 'Evento Esportivo',
  'Religioso' = 'Religioso',
  'Trabalho' = 'Trabalho',
  'Turismo' = 'Turismo',
}

export const tourReasons = Object.entries(TourReasonsEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export interface TourReasonsProps {
  field: {
    name: string;
    value: TourReasonsEnum;
    onChange: (value: TourReasonsEnum) => void;
    onBlur: () => void;
  };
  form: {
    setFieldValue: (field: string, value: TourReasonsEnum) => void;
  };
}
[];

export enum TransportTypeEnum {
  'Micro-ônibus' = 'Micro-ônibus',
  'Ônibus' = 'Ônibus',
  'Van ou Similar' = 'Van ou Similar',
}

export const transportTypes = Object.entries(TransportTypeEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export interface TransportTypesProps {
  field: {
    name: string;
    value: TransportTypeEnum;
    onChange: (value: TransportTypeEnum) => void;
    onBlur: () => void;
  };
  form: {
    setFieldValue: (field: string, value: TransportTypeEnum) => void;
  };
}
[];
