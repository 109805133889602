import IBanner from 'interfaces/IBanner';
import RestService from './RestService';
import { auth } from 'utils/firebase';

export default class AuthService extends RestService<IBanner> {
  constructor() {
    super('users');
  }

  protected getHeaders(): any {
    return {};
  }

  public async signUp(type: string, body: any): Promise<void> {
    const { email, password } = body;
    const { user } = await auth.createUserWithEmailAndPassword(email, password);
    await user?.sendEmailVerification();

    const token = await user?.getIdToken();
    const headers = { Authorization: `Bearer ${token}` };

    await this.httpClient.post(`/sign-up/${type}`, body, { headers });
  }

  public async login(body: any): Promise<any> {
    const { data } = await this.httpClient.post(`/login`, body);

    if (!data?.user?.email) {
      return false;
    }

    const userCredential = await auth.signInWithEmailAndPassword(data.user.email, body.password);
    const token = await userCredential.user?.getIdToken();

    const user = {
      ...data.user,
      emailVerified: userCredential.user?.emailVerified,
    };

    return token && { token, user };
  }
}
