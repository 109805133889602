export interface IBusiness {
  businessOwner: string[];
  businessId?: number | null;
  businessRepresentative: string;
  businessType: string;
  experiences: string[];
  createdAt: string;
  description: string;
  document: string;
  facebookLink: string;
  googleMapsLink: string;
  id: number;
  image: string[];
  instagramLink: string;
  phone: string;
  placeId: string;
  site: string;
  status: BusinessStatusAPI;
  tradeEmail: string;
  title?: string;
  tradeName: string;
  type: SegmentationAPI;
  whatsapp: string;
  youtubeLink: string;
  accommodation?: {
    category: string;
    description: string;
    numberOfBeds: string;
    numberOfRooms: string;
  };
  address?: {
    additionalData: string;
    city: string;
    neighborhood: string;
    number: string;
    state: string;
    street: string;
    zipCode: string;
  };
  images: Array<{
    image: string;
    description: string;
    id: number;
  }>;
  tour: {
    attractions: string;
    equipments: string;
  };
}

export enum BusinessStatus {
  APPROVED = 'Aprovado',
  REFUSED = 'Recusado',
  WAITING_FOR_SETUR = 'Aguardando SETUR',
  WAITING_FOR_TRADE = 'Aguardando Trade',
  PENDING_ADJUSTMENT = 'Ajuste Pendente',
}

export enum Segmentation {
  Acommodations = 'Acommodations',
  Restaurants = 'Restaurants',
  Transports = 'Transports',
  Tours = 'Tours',
}

export enum BusinessStatusAPI {
  APPROVED = 'approved',
  REFUSED = 'refused',
  WAITING_FOR_SETUR = 'waiting-for-setur',
  WAITING_FOR_TRADE = 'waiting-for-trade',
  PENDING_ADJUSTMENT = 'pending-adjustment',
}

export enum SegmentationAPI {
  Acommodations = 'Hospedagem',
  Restaurants = 'Restaurante (Alimento e Bebida)',
  Transports = 'Transporte',
  Tours = 'Passeio e Lazer',
}
