import styled from 'styled-components';
import trashIcon from 'assets/images/trashIconBlack.svg';
import blueTrashIcon from 'assets/images/blueTrashIcon.svg';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;

  label {
    margin-bottom: 5px !important;
  }
  input {
    height: 40px !important;
    width: 100%;
    padding: 0 10px !important;
  }
  #only-view-first-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    height: 114px;
    img {
      width: 110px;
      height: 110px;
    }
    .nameField {
      width: 63.8%;
      display: flex;
      flex-direction: column;
      justify-content: normal;
      span {
        &:first-child {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
    button {
      margin: 0 0 auto auto;
    }

    @media (max-width: 960px) {
      flex-direction: column;
      height: auto;
      div {
        margin-bottom: 10px;
      }
      div:nth-child(2) {
        width: 100%;
      }
      button {
        margin: auto auto 0 0;
      }
    }
  }
  #only-view-second-row {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 20px;

    @media (max-width: 960px) {
      div {
        margin-bottom: 10px;
      }
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
  #only-view-third-row {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 20px;

    @media (max-width: 960px) {
      div {
        margin-bottom: 10px;
      }
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
  #only-view-fourth-row {
    div {
      margin-bottom: 10px;
    }
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 20px;

    @media (max-width: 960px) {
      div {
        margin-bottom: 10px;
      }
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }
  }
  #only-view-fifth-row {
    div {
      margin-bottom: 10px;
    }
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;

    @media (max-width: 960px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }
  .profileInfo {
    display: flex;
    flex-direction: column;
  }
  #news-account {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    div {
      width: 100%;
    }
    @media (max-width: 960px) {
      flex-direction: column;
    }
  }
  .editButtons {
    display: flex;
    justify-content: right;
    gap: 10px;

    :first-child {
      color: #ffcb29;
      border: 0.5px solid #ffcb29;
      background: #fff;

      &:hover {
        color: #fff;
        border: 0.5px solid #020c90;
        background: #020c90;
      }
    }
  }
  .formButton {
    display: flex;
    justify-content: space-between;
  }
  .buttonsRequest {
    display: flex;
    width: fit-content;
    justify-content: space-between;
    gap: 10px;
  }
  #superAdminField {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;
  }

  @media (max-width: 960px) {
    .editButtons {
      justify-content: center;
    }

    #superAdminField {
      grid-template-columns: 1fr;
      margin-bottom: 10px;
      div {
        margin-bottom: 10px;
      }
    }

    .formButton {
      flex-direction: column;
    }

    .buttonsRequest {
      margin-bottom: 10px;
    }
  }
`;

export const FormRow = styled.div`
  display: grid;
  column-gap: 30px;
`;

export const LabelData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  > div {
    width: 100%;
  }
  label {
    font-weight: 500;
  }
  span {
    font-weight: 300;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted;
  margin: 2vh 0;
`;

export const AskDeleteAccountButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #272727;
  background-color: transparent;
  img {
    margin-right: 10px;
    content: url(${trashIcon});
    filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(59deg) brightness(63%);
  }
  span {
    font-size: 16px;
    font-weight: 700;
    text-decoration-line: underline;
  }
  &:hover {
    border: 1px solid #020c90;
    img {
      content: url(${blueTrashIcon});
      filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
        contrast(126%);
    }
    span {
      color: #020c90;
    }
  }
`;

export const DeleteAccountButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #272727;
  background-color: transparent;
  img {
    margin-right: 10px;
    content: url(${trashIcon});
    filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(59deg) brightness(63%);
  }
  span {
    font-size: 16px;
    font-weight: 700;
    text-decoration-line: underline;
  }
  &:hover {
    border: 1px solid #020c90;
    img {
      content: url(${blueTrashIcon});
      filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
        contrast(126%);
    }
    span {
      color: #020c90;
    }
  }
`;

export const FooterButtonsForm = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;

    div:first-child {
      margin-bottom: 20px;
    }
  }
`;
