import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

export const InstagramPostContainer = styled.div`
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 370px;

  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    outline: 5px solid #ffcb29;
  }

  video {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: 18px;
    margin: 0 !important;
    object-fit: cover;
  }
`;
