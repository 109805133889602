import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonsDiv,
  Container,
  ExcursionCompanyInformation,
  ExcursionFirstForm,
  ExcursionFirstFormContainer,
  FormRow4Cols,
  InputField,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import { Formik, Field, Form, FormikValues } from 'formik';
import ErrorMessage from 'components/ErrorMessage';
import FieldMask from 'components/FieldMask';
import SelectComponent from 'components/Select';
import { states, StatesEnum, StatesSelectProps } from 'features/Signup/pages/SignupTourOrganizer/utils';
import { useLoading } from 'hooks/useLoading';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import UserService from 'services/UserService';

const NewExcursionStep1Form = (): JSX.Element => {
  const [user, setUser] = useState<any>();
  const [selectedState, setSelectedState] = useState<string>('');
  const [disabledInput, setDisabledInput] = useState<boolean>(true);
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();

  const fetchUser = async () => {
    openLoading();
    try {
      const user = await new UserService().findOne();
      setUser(user);
      setSelectedState(user?.extraInfo?.address?.state);
    } catch (error) {
      console.error(error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const initialValues = {
    name: user?.name || '',
    email: user?.email || '',
    document: user?.document || '',
    type: user?.type || '',
    extraInfo: {
      role: user?.extraInfo?.role || '',
      legalName: user?.extraInfo?.legalName || '',
      responsibleName: user?.extraInfo?.responsibleName || '',
      responsibleCpf: user?.extraInfo?.responsibleCpf || '',
      phone: user?.extraInfo?.phone || '',
      tradeName: user?.extraInfo?.tradeName || '',
      address: {
        additionalData: user?.extraInfo?.address?.additionalData || '',
        city: user?.extraInfo?.address?.city || '',
        neighborhood: user?.extraInfo?.address?.neighborhood || '',
        number: user?.extraInfo?.address?.number || '',
        state: user?.extraInfo?.address?.state || '',
        street: user?.extraInfo?.address?.street || '',
        zipCode: user?.extraInfo?.address?.zipCode || '',
      },
    },
    status: user?.status || '',
    createdAt: user?.createdAt || '',
    updatedAt: user?.updatedAt || '',
    deletedAt: user?.deletedAt || '',
  };

  const StatesSelect: React.FC<StatesSelectProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={states.map(state => ({ label: state.label, value: state.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as StatesEnum);
            setSelectedState(value);
          }
        }}
        name="state"
        selected={[selectedState]}
        disabled={disabledInput}
      />
    );
  };

  const onsubmit = (values: FormikValues) => {
    console.log(values);
  };

  return (
    <Container>
      <MainTitle>Cadastro de Excursão</MainTitle>

      <StepperContainer>
        <StepperItem className="flex flex-col items-center justify-center">
          <article>
            <StepperBullet className="onEdit" onClick={() => history.push('/admin/new-excursion/step1')}>
              <IconOrganizador color="#020c90" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Organizador</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={() => history.push('/admin/new-excursion/step2')}>
                <IconViagem />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Viagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={() => history.push('/admin/new-excursion/step3')}>
                <IconTransporte />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Transporte</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconHospedagem />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Hospedagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconGuia />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Guia</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconPagamento />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Pagamento</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet>
                <IconResumo />
              </StepperBullet>
            </Link>
          </article>
          <StepperName>Resumo</StepperName>
        </StepperItem>
      </StepperContainer>

      <ExcursionFirstFormContainer>
        <p>{user?.type === 'tour-pj' ? '*Campo Obrigatório' : null}</p>
        <ExcursionCompanyInformation>
          <h2>{user?.type === 'tour-pj' ? 'Dados da empresa:' : 'Dados do responsável pela excursão'}</h2>
          {user?.type === 'tour-pj' ? (
            <>
              <p>
                Para cadastrar uma excursão, utilizamos os dados da empresa organizadora da excursão registrados no seu
                perfil. Para atualizar alguma informação, edite o campo em <strong>Minha Conta</strong> antes de
                continuar com o cadastro.
              </p>
              <h3>{user?.extraInfo?.legalName}</h3>
              <h4>{user?.extraInfo?.tradeName}</h4>
              <h5>{user?.document}</h5>
            </>
          ) : (
            <>
              <p>
                <p>
                  Para cadastrar uma excursão, utilizamos os dados do responsável pela excursão registrados no seu
                  perfil. Para atualizar alguma informação, edite o campo em <strong>Minha Conta</strong> antes de
                  continuar com o cadastro.
                </p>
              </p>
              <article>
                <h3>{user?.name}</h3>
                <h4>{user?.document}</h4>
                <h4>{user?.phone}</h4>
                <h4>{user?.email}</h4>
              </article>
              <article style={{ marginTop: '20px' }}>
                <h3>Endereço:</h3>
                <h4>
                  {user?.extraInfo?.address?.street}, {user?.extraInfo?.address?.number}
                </h4>
                <h4>{user?.extraInfo?.address?.additionalData}</h4>
                <h4>{user?.extraInfo?.address?.neighborhood}</h4>
                <h4>
                  {user?.extraInfo?.address?.city} - {user?.extraInfo?.address?.state},{' '}
                  {user?.extraInfo?.address?.zipCode}
                </h4>
              </article>
            </>
          )}

          {/* <Button onClick={() => history.push('/my-account')}>Editar</Button> */}
        </ExcursionCompanyInformation>

        {user?.type === 'tour-pj' ? (
          <>
            <ExcursionFirstForm>
              <h2>Dados do responsável pela excursão:</h2>
              <article>
                <input type="checkbox" onChange={() => setDisabledInput(!disabledInput)} checked={disabledInput} />
                <span>
                  Utilizar dados cadastrados em <strong>Minha Conta</strong>
                </span>
              </article>

              <Formik initialValues={initialValues} onSubmit={onsubmit} enableReinitialize>
                <Form>
                  <FormRow4Cols>
                    <InputField id="full-name-input" className="responsive-grid-column-span-2">
                      <div>
                        <label htmlFor="">
                          Nome Completo<span>*</span>
                        </label>
                        <Field name="extraInfo.responsibleName" disabled={disabledInput} />
                      </div>
                      <ErrorMessage name="name" />
                    </InputField>
                    <InputField id="cpf-input">
                      <div>
                        <label htmlFor="">
                          CPF<span>*</span>
                        </label>
                        <FieldMask type="cpf">
                          <Field
                            type="text"
                            name=".extraInfo.responsibleCpf"
                            placeholder="ex: xxx.xxx.xxx-xx"
                            disabled={disabledInput}
                          />
                        </FieldMask>
                      </div>
                      <ErrorMessage name="document" />
                    </InputField>
                    <InputField id="cellphone-input">
                      <div>
                        <label htmlFor="">
                          Telefone ou celular<span>*</span>
                        </label>
                        <FieldMask type="phone">
                          <Field name="extraInfo.phone" disabled={disabledInput} />
                        </FieldMask>
                      </div>
                      <ErrorMessage name="extraInfo.phone" />
                    </InputField>
                    <InputField id="email-input" className="responsive-grid-column-span-2">
                      <div>
                        <label htmlFor="">
                          E-mail<span>*</span>
                        </label>
                        <FieldMask type="email">
                          <Field type="text" name="email" disabled={disabledInput} />
                        </FieldMask>
                      </div>
                      <ErrorMessage name="email" />
                    </InputField>
                  </FormRow4Cols>
                  <FormRow4Cols>
                    <InputField id="cep-input" className="responsive-grid-column-span-2">
                      <div>
                        <label htmlFor="">
                          CEP<span>*</span>
                        </label>
                        <FieldMask type="zipcode">
                          <Field name="extraInfo.address.zipCode" disabled={disabledInput} />
                        </FieldMask>
                      </div>
                      <ErrorMessage name="extraInfo.address.zipCode" />
                    </InputField>
                    <InputField id="address-input" style={{ gridColumn: 'span 2' }}>
                      <div>
                        <label htmlFor="">
                          Endereço<span>*</span>
                        </label>
                        <Field name="extraInfo.address.street" disabled={disabledInput} />
                      </div>
                      <ErrorMessage name="extraInfo.address.street" />
                    </InputField>
                    <InputField id="number-input">
                      <div>
                        <label htmlFor="">
                          Número<span>*</span>
                        </label>
                        <Field name="extraInfo.address.number" disabled={disabledInput} />
                      </div>
                      <ErrorMessage name="extraInfo.address.number" />
                    </InputField>
                    <InputField id="complement-input">
                      <div>
                        <label htmlFor="">Complemento</label>
                        <Field name="extraInfo.address.additionalData" disabled={disabledInput} />
                      </div>
                      <ErrorMessage name="extraInfo.address.additionalData" />
                    </InputField>
                    <InputField id="neighborhood-input" className="responsive-grid-column-span-2">
                      <div>
                        <label htmlFor="">
                          Bairro<span>*</span>
                        </label>
                        <Field name="extraInfo.address.neighborhood" disabled={disabledInput} />
                      </div>
                      <ErrorMessage name="extraInfo.address.neighborhood" />
                    </InputField>
                    <InputField id="city-input" className="responsive-grid-column-span-2">
                      <div>
                        <label htmlFor="">
                          Cidade<span>*</span>
                        </label>
                        <Field name="extraInfo.address.city" disabled={disabledInput} />
                      </div>
                      <ErrorMessage name="extraInfo.address.city" />
                    </InputField>
                    <InputField id="state-input" className="responsive-grid-column-span-2">
                      <div>
                        <label htmlFor="">
                          Estado<span>*</span>
                        </label>
                        <Field component={StatesSelect} name="extraInfo.address.state" disabled={disabledInput} />
                      </div>
                      <ErrorMessage name="state" />
                    </InputField>
                  </FormRow4Cols>
                  <ButtonsDiv>
                    <Button buttonType="secondary">Voltar</Button>
                    <article>
                      <Button buttonType="secondary">Cancelar</Button>
                      <Button onClick={() => history.push('/admin/new-excursion/step2')}>Próximo</Button>
                    </article>
                  </ButtonsDiv>
                </Form>
              </Formik>
            </ExcursionFirstForm>
          </>
        ) : (
          <>
            <ButtonsDiv>
              <Button buttonType="secondary">Voltar</Button>
              <article>
                <Button buttonType="secondary">Cancelar</Button>
                <Button onClick={() => history.push('/admin/new-excursion/step2')}>Próximo</Button>
              </article>
            </ButtonsDiv>
          </>
        )}
      </ExcursionFirstFormContainer>
    </Container>
  );
};

export default NewExcursionStep1Form;
