import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  height: 5px;
  width: 100%;
  margin: 50px 0;
`;

export const Title = styled.span`
  color: #020c90;
  font-weight: 700;
  font-size: 33px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0 10px;

  @media (max-width: 980px) {
    font-size: 24px;
  }
`;

export const Line = styled.div`
  width: 100%;
`;

export const LineBefore = styled(Line)`
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #00a759),
    color-stop(80%, #00a759),
    color-stop(80%, #c63336),
    color-stop(100%, #c63336)
  );
`;

export const LineAfter = styled(Line)`
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #c63336),
    color-stop(20%, #c63336),
    color-stop(20%, #ffcb29),
    color-stop(100%, #ffcb29)
  );
`;
