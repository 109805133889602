import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Grid } from '@mui/material';
import firebase from 'firebase/app';
import { navigateBasedOnProfile } from 'utils/navigateBasedOnProfile';
import { auth } from 'utils/firebase';
import { getAccessToken, getLocalUser, setAccessToken, setLocalUser } from 'services/localStorage';
import { useLoading } from 'hooks/useLoading';
import Heading from 'components/Heading';
import Button from 'components/Button';
import { Container, Div, Panel } from './styles';

const EmailConfirmation = (): JSX.Element => {
  const history = useHistory();
  const { openLoading, closeLoading } = useLoading();
  const { code } = useParams<{ code?: string }>();
  const [counter, setCounter] = useState(60);
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(auth.currentUser);

  useEffect(() => {
    openLoading();
    return auth.onIdTokenChanged(user => {
      closeLoading();
      setCurrentUser(user);
      if (!user || user.emailVerified) {
        history.push('/');
        return;
      }

      if (code) {
        applyActionCode(code, user);
      }
    });
  }, []);

  useEffect(() => {
    if (counter === 0) {
      return;
    }

    setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const applyActionCode = async (actionCode: string, user: firebase.User | null) => {
    openLoading();
    try {
      await auth.applyActionCode(actionCode);
      await user?.reload();
      const token = await user?.getIdToken(true);
      const localUser = getLocalUser();
      setAccessToken(token || getAccessToken());
      setLocalUser({ ...localUser, emailVerified: true });
      history.push(navigateBasedOnProfile(localUser.profile.name));
    } catch (err) {
      history.push('/email-confirmation');
    } finally {
      closeLoading();
    }
  };

  const sendEmailVerification = async () => {
    openLoading();
    await currentUser?.sendEmailVerification();
    closeLoading();
    setCounter(60);
  };

  if (!currentUser || code) {
    return <></>;
  }

  return (
    <Container>
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ minHeight: '70vh' }}>
        <Grid item xs={12} sm={10} md={8} lg={4}>
          <Panel>
            <Div>
              <Heading>Mensagem de confirmação de e-mail enviada!</Heading>
            </Div>
            <Div>
              Enviamos uma mensagem para o endereço de e-mail cadastrado com um link para confirmar o dado informado.
            </Div>
            <Div>
              Atenção! Não se esqueça de verificar se o e-mail foi para sua caixa de spam. Caso não receba o e-mail,
              solicite o reenvio abaixo. Se o problema persistir, entre em contato pelo número (73) 8866-7507.
            </Div>
            {counter ? <Div>Solicitar o reenvio em {counter} segundos.</Div> : <Div>Solicitar o reenvio</Div>}
            <Div>
              <Button buttonType={counter ? 'disabled' : 'primary'} onClick={sendEmailVerification}>
                Reenviar
              </Button>
            </Div>
          </Panel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmailConfirmation;
