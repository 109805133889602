import React from 'react';
import { Container, LineAfter, LineBefore, Title } from './styles';

interface IProps {
  children?: React.ReactNode;
}

const MainTitle = (props: IProps): JSX.Element => {
  return (
    <Container>
      <LineBefore />
      <Title>{props.children}</Title>
      <LineAfter />
    </Container>
  );
};

export default MainTitle;
