import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import homeIcon from '../../assets/images/home_menu_item.svg';
import mapIcon from '../../assets/images/map_menu_item.svg';
import bedIcon from '../../assets/images/bed_menu_item.svg';
import umbrellaIcon from '../../assets/images/umbrella_menu_item.svg';
import hospitalIcon from '../../assets/images/hospital_menu_item.svg';
import newsIcon from '../../assets/images/news_menu_item.svg';
import cameraIcon from '../../assets/images/camera_menu_item.svg';
import experiencesIcon from '../../assets/images/experiences_menu_item.svg';
import { NavLink } from 'react-router-dom';

const TouristMenu: React.FC = () => {
  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink activeClassName="selected" exact to="/">
            <img src={homeIcon} alt="icon for list item" />
            <span>Início</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/how-to-get">
            <img src={mapIcon} alt="icon for list item" />
            <span>Como chegar</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/accommodations">
            <img src={bedIcon} alt="icon for list item" />
            <span>Onde Ficar</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/what-to-do">
            <img src={umbrellaIcon} alt="icon for list item" />
            <span>O Que Fazer</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/services">
            <img src={hospitalIcon} alt="icon for list item" />
            <span>Serviços Básicos</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/news">
            <img src={newsIcon} alt="icon for list item" />
            <span>Notícias e Mídias Sociais</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <a
            href="https://drive.google.com/drive/folders/1-8DutrRYUHw1VqR2fMj85Y49fG6ib8ts"
            target="_blank"
            rel="noopener noreferrer">
            <img src={cameraIcon} alt="icon for list item" />
            <span>Mídia Kit</span>
          </a>
        </ListItem>
        <ListItem className="my-experiences">
          <NavLink activeClassName="selected" to="/my-experiences">
            <img src={experiencesIcon} alt="icon for list item" />
            <span>Minhas Experiências</span>
          </NavLink>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default TouristMenu;
