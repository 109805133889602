import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/src/core/styles.scss';
import { Carousel } from './styles';
import { IDestinationBanner } from 'interfaces/IDestinationsBannerts';
import { Arrow } from 'common/BannerCarousel/styles';

interface Props {
  banners?: IDestinationBanner[];
}

const DestinationsCarousel = (props: Props): JSX.Element => {
  const { banners } = props;

  const settings = {
    infinite: true,
    organicArrows: false,
    fillParent: false,
    buttonContentRight: <Arrow>&gt;</Arrow>,
    buttonContentLeft: <Arrow>&lt;</Arrow>,
  };

  return (
    <Carousel>
      <AwesomeSlider {...settings}>
        {banners?.map(banner => {
          return (
            <div key={banner.id}>
              <div
                className="destination-carousel"
                style={{
                  background: `url(${banner.image}) center top / cover no-repeat`,
                }}>
                <span>{banner.description}</span>
                <div
                  style={{
                    background: 'linear-gradient(90deg, rgba(13,74,155,0.9) 0%, rgba(13,74,155,0) 100%)',
                  }}
                />
              </div>
            </div>
          );
        })}
      </AwesomeSlider>
    </Carousel>
  );
};

export default DestinationsCarousel;
