import styled from 'styled-components';
import closeBlue from 'assets/images/closeBlue.svg';

export const MyVoucher = styled.div`
  position: relative;
  .has-vouchers:before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    right: 30px;
    top: 7px;
  }

  button {
    box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 960px) {
    top: -10px;

    .has-vouchers:before {
      top: 18px;
      left: 35px;
      width: 7px;
      height: 7px;
    }
  }
`;

export const VoucherModal = styled.div`
  width: 37%;
  max-height: 495px;
  position: absolute;
  left: 30%;
  top: 30%;
  background: #fff;
  padding: 30px;
  z-index: 99999;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  -webkit-box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    width: 90%;
    padding: 1rem;
    left: 5%;
    margin: 0 auto 2rem;
  }
`;

export const VoucherHeader = styled.div`
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 20px;

  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    color: #272727;
    text-transform: uppercase;
  }

  img {
    position: absolute;
    top: -20px;
    right: 0;
    cursor: pointer;

    &:hover {
      content: url(${closeBlue});
    }
  }

  @media (max-width: 960px) {
    h2 {
      font-size: 1.125rem;
    }
  }
`;

export const ContentVoucher = styled.div`
  width: 100%;
  max-height: 390px;
  overflow: hidden;
  overflow-y: scroll;

  &&::-webkit-scrollbar {
    width: 5px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
    height: 15px;
    border-radius: 10px;
  }
`;

export const EmptyVoucher = styled.div`
  text-align: center;
  padding: 88px 0;

  img {
    width: 130px;
    margin-bottom: 30px;
  }

  h4 {
    font-size: 30px;
    font-weight: 700;
    color: #9a9a9a;
    margin-bottom: 30px;
  }

  p {
    font-size: 23px;
    color: #9a9a9a;
  }

  @media (max-width: 960px) {
    h2 {
      font-size: 1.25rem;
    }

    p {
      font-size: 1.125rem;
    }
  }
`;

export const WithVoucher = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: block;
    padding: 10px 13%;
    border-bottom: 1px dashed #9a9a9a;
  }

  div span:first-child {
    margin-right: 27%;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  ul li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 13%;
    border-bottom: 1px dashed #9a9a9a;
  }

  li img {
    cursor: pointer;
  }
`;
