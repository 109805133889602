import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

export const RadioGroupContent = styled.div`
  margin-bottom: 15px;

  label {
    .MuiFormControlLabel-label {
      font-family: 'Signika';
      font-size: 16px;
      font-weight: 500;
    }
    .Mui-checked {
      color: #ffcb29;
    }
  }
`;
