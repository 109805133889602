import React from 'react';
import { Container } from './styles';
import Button from 'components/Buttom/Button';
import { useHistory } from 'react-router';

interface Props {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPopUpPage: React.Dispatch<React.SetStateAction<number>>;
}

const PopUpRegisterTour = (props: Props): JSX.Element => {
  const { setPopUpPage, setShowPopup } = props;
  const history = useHistory();
  const mockData = {
    socialReason: 'Razão Social da empresa',
    CNPJ: 'XX.XXX.XXX/XXXX-XX',
    cadastur: 'XXXXXXX',
    accountable: 'XXX;XXX;XXX-XX',
    accountablePhone: 'XXXXXXXXXXX',
    accountableEmail: 'email@domínio.com',
    address: 'Rua Nome da Rua, XXX',
    complement: 'Complemento',
    neighborhood: 'Nome do Bairro',
    city: 'Cidade - XX, XXXXX.XXX',
  };
  return (
    <Container>
      <header>
        <h1>Dados da empresa e/ou do responsável:</h1>
        <span>1/7</span>
      </header>
      <p>
        Para o cadastro de uma excursão, serão utilizados os dados do seu perfil como os da empresa organizadora e/ou do
        responsável pela excursão. Caso precise atualizar alguma informação, edite o campo em{' '}
        <strong> Minha Conta </strong>antes de continuar com o cadastro.
      </p>
      <h4>Nome fantasia da empresa</h4>
      <span>{mockData.socialReason}</span>
      <span>{mockData.CNPJ}</span>
      <span>{mockData.cadastur}</span>
      <h4>Nome completo do responsável</h4>
      <span>{mockData.accountable}</span>
      <span>{mockData.accountablePhone}</span>
      <span>{mockData.accountableEmail}</span>
      <h4>Endereço</h4>
      <span>{mockData.address}</span>
      <span>{mockData.complement}</span>
      <span>{mockData.neighborhood}</span>
      <span>{mockData.city}</span>
      <div>
        <Button text="EDITAR" onClick={() => history.push('my-account')} />
        <Button text="CANCELAR" onClick={() => setShowPopup(false)} />
        <Button text="PRÓXIMO" onClick={() => setPopUpPage(2)} />
      </div>
    </Container>
  );
};

export default PopUpRegisterTour;
