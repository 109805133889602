import { Modal } from 'components/Modals';
import React, { useState } from 'react';
import {
  ModalContainer,
  ModalContainerActionBox,
  ModalContainerHeader,
  ModalContainerHeaderTitle,
  ModalContainerText,
  ModalContainerTextArea,
} from './styles';
import Button from 'components/Button';
import { useLoading } from 'hooks/useLoading';
import { BusinessStatusAPI, IBusiness } from 'interfaces/IBusinesses';
import IEvent, { EventStatusAPI } from 'interfaces/IEvent';
import ImprovimentsService from 'services/ImprovimentsService';
import MessageModal from 'common/MessageModal';

interface EditSolicitationModalProps {
  isOpen: boolean;
  toggle: () => void;
  item: IBusiness | IEvent;
}

const EditSolicitationModal = ({ isOpen, toggle, item }: EditSolicitationModalProps): JSX.Element => {
  const [message, setMessage] = useState('');
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });
  const [showModal, setShowModal] = useState(false);
  const { openLoading, closeLoading } = useLoading();

  const service = new ImprovimentsService();

  const sendEditSolicitation = async () => {
    try {
      openLoading();
      await service.createImprovement({
        id: item.id,
        description: message,
        type: 'type' in item ? 'business' : 'event',
      });
      item.status = 'type' in item ? BusinessStatusAPI.WAITING_FOR_TRADE : EventStatusAPI.WAITING_FOR_TRADE;
      setMessageInfo({ title: 'Sucesso', message: 'Mensagem de edição enviada com sucesso' });
      setShowModal(true);
    } catch (error) {
      setMessageInfo({ title: 'Erro', message: 'Erro ao enviar a mensagem' });
      setShowModal(true);
    }
    closeLoading();
  };

  const closeModal = () => {
    setShowModal(false);
    toggle();
  };

  return (
    <>
      <div style={{ zIndex: 999999 }}>
        <MessageModal isOpen={showModal} title={messageInfo.title} message={messageInfo.message} onClose={closeModal} />
      </div>
      {!showModal && (
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalContainer>
            <ModalContainerHeader>
              <ModalContainerHeaderTitle>Solicitação de Edição</ModalContainerHeaderTitle>
            </ModalContainerHeader>
            <ModalContainerText>
              Escreva sua solicitação <span style={{ color: '#f00' }}> *</span>
            </ModalContainerText>
            <ModalContainerTextArea onChange={e => setMessage(e.target.value)} />
            <ModalContainerActionBox>
              <Button buttonType="outline" className="cancel-button" onClick={toggle}>
                Cancelar
              </Button>
              <Button
                type="submit"
                className={`${message.length <= 0 ? 'disabled' : ''}`}
                disabled={message.length <= 0}
                onClick={sendEditSolicitation}>
                Enviar
              </Button>
            </ModalContainerActionBox>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default EditSolicitationModal;
