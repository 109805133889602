import styled from 'styled-components';

export const PhotoField = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  padding: 0 50px;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 980px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const PhotoTitle = styled.h4`
  display: block;
  font-size: 1.438rem;
  font-weight: 700;
  color: #272727;
  margin-bottom: 1.25rem;
`;

export const PhotoList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  justify-content: space-between;
  gap: 30px;
  row-gap: 50px;
  margin-bottom: 70px;

  @media all and (max-width: 980px) {
    width: 100%;
    display: grid;
    overflow-x: scroll;
    justify-content: unset;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, 294px);
    grid-auto-flow: column;
    grid-auto-columns: 294px;
  }
`;

export const PhotoItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 370px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
