import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useLoading } from 'hooks/useLoading';

const Loading: React.FC = () => {
  const { loading, closeLoading } = useLoading();

  return (
    <Backdrop
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={loading}
      onClick={closeLoading}>
      <CircularProgress size={60} color="primary" />
    </Backdrop>
  );
};

export default Loading;
