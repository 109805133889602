import styled from 'styled-components';
import facebook from 'assets/images/facebook-white.svg';
import instagram from 'assets/images/instagram-white.svg';
import tiktok from 'assets/images/tiktok-white.svg';
import youtube from 'assets/images/youtube-white.svg';

export const Container = styled.div`
  .news-group {
    display: flex;
    justify-content: space-between;
  }

  .news-section {
    margin-bottom: 70px;
    padding: 0px 60px;

    @media (max-width: 960px) {
      width: 95%;
      margin: 0 auto 35px;
      padding: 0;
    }

    .news-grid {
      height: auto;
      padding: 0;

      h1 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        height: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }

    .buttons-section {
      display: flex;
      flex-direction: column;
      padding: 30px 0 0 24px;

      @media (max-width: 960px) {
        padding: inherit;
      }
      .button-div {
        margin-bottom: 20px;
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;

        button {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-size: 26px;
          height: inherit;
          padding: 10px 0px;
          text-transform: none;
          width: 100%;
        }
      }
    }

    .video-section {
      div {
        height: 315px;
        overflow: hidden;
      }

      iframe {
        border: none;
        min-height: 100%;
        min-width: 100%;
      }

      @media (max-width: 960px) {
        padding: 0;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .news-instagram {
    display: flex;
    margin: 0 auto 30px;
    padding: 0 60px;
    justify-content: space-between;

    div {
      width: 300px;
    }

    div img {
      width: 300px;
      height: 300px;
    }

    @media (max-width: 960px) {
      display: -webkit-box;
      overflow-x: auto;
      padding: 10px 12px 8px 12px;
      gap: 30px;
    }
  }

  .news-instagram::-webkit-scrollbar {
    height: 0;
  }
`;

export const SocialMediaContainer = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    justify-content: space-between;
    gap: 30px;
    row-gap: 50px;
    margin-bottom: 70px;
    padding: 0 50px;

    @media (max-width: 980px) {
      width: 100%;
      display: grid;
      overflow-x: scroll;
      padding: 16px;
      justify-content: unset;
      margin: 50px 0 0 0;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, 294px);
      grid-auto-flow: column;
      grid-auto-columns: 294px;
    }
  }

  ul::-webkit-scrollbar {
    height: 0;
  }

  Button {
    width: 270px;
    display: block;
    margin: 60px auto;
    font-size: 16px;

    @media (max-width: 980px) {
      margin-top: 20px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const Icon = styled.div`
  display: inline-block;
  height: 39px;
  padding-right: 10px;
  vertical-align: middle;

  &.facebook-logo {
    content: url(${facebook});
  }

  &.instagram-logo {
    content: url(${instagram});
  }

  &.tiktok-logo {
    content: url(${tiktok});
  }

  &.youtube-logo {
    content: url(${youtube});
  }
`;
