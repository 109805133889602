import styled from 'styled-components';

export const RatingDataClass = styled.div`
  display: block;
  margin: 0 auto 30px;
  width: 100%;

  .LookerRatingData iframe {
    height: 1100px;
  }

  @media (max-width: 980px) {
    .LookerRatingData iframe {
      height: auto;
    }
  }
`;
