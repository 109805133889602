import React, { useEffect, useState } from 'react';
import { Container, Section, SectionHeader, SettingsListContainer, SettingsListItemButtonBox } from './styles';
import RestaurantCategoryService from 'services/RestaurantCategoryService';
import TourCategoryService from 'services/TourCategoryService';
import CategoryTable from 'components/CategoryTable';
import Heading from 'components/Heading';
import {
  SettingsList,
  SettingsListItem,
  SettingsListItemTitle,
  SettingsListItemButtonList,
  SettingsListItemButton,
} from 'components/List/styles';
import { Link, useHistory } from 'react-router-dom';
import TouristOffersBusinessService from 'services/TouristOffersBusinessService';
import { IBusiness } from 'interfaces/IBusinesses';
import editIcon from 'assets/images/editIcon.svg';
import trashIcon from 'assets/images/trashIcon.svg';
import eyeIcon from 'assets/images/eyeGrayIcon.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import Button from 'components/Button';

const Activity = (): JSX.Element => {
  const [activities, setActivities] = useState<IBusiness[]>([]);
  const [activityId, setActivityId] = useState(0);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const history = useHistory();

  const activityService = new TouristOffersBusinessService();

  useEffect(() => {
    const fetchData = async () => {
      const filteredActivities = await activityService.findAllSeturTrades();
      setActivities(filteredActivities);
    };

    fetchData();
  }, []);

  const deleteEvent = async () => {
    await activityService.remove(activityId);
    const index = activities.filter(item => item.id !== activityId);
    setActivities(index);
  };

  return (
    <Container>
      <Section>
        <SectionHeader>
          <Heading>Lista de Atividades</Heading>
          <Button type="button" buttonType="primary" onClick={() => history.push('/admin/activities/new-activity')}>
            Adicionar
          </Button>
        </SectionHeader>
        <SettingsListContainer>
          <SettingsList>
            {activities.map((activity, index) => (
              <SettingsListItem key={index}>
                <SettingsListItemTitle>{activity.title}</SettingsListItemTitle>
                <SettingsListItemButtonList>
                  <SettingsListItemButtonBox>
                    <Link to={`/admin/activity/${activity.id}/preview`}>
                      <SettingsListItemButton>
                        <img src={eyeIcon} alt="icon for edit item" />
                      </SettingsListItemButton>
                    </Link>
                    <Link to={`/admin/activities/${activity.id}`}>
                      <SettingsListItemButton>
                        <img src={editIcon} alt="icon for edit item" />
                      </SettingsListItemButton>
                    </Link>
                    <SettingsListItemButton
                      onClick={() => {
                        setConfirmDeletion(true);
                        setActivityId(activity.id);
                      }}>
                      <img src={trashIcon} alt="icon for delete item" />
                    </SettingsListItemButton>
                  </SettingsListItemButtonBox>
                </SettingsListItemButtonList>
              </SettingsListItem>
            ))}
          </SettingsList>
        </SettingsListContainer>
      </Section>
      <CategoryTable title="Categorias de Restaurantes" service={new RestaurantCategoryService()} />
      <CategoryTable title="Categorias de Passeios" service={new TourCategoryService()} />

      <ConfirmationModal
        open={confirmDeletion}
        title="EXCLUSÃO DE ITEM"
        message="Você tem certeza de que deseja excluir esse item?"
        onCancel={() => setConfirmDeletion(false)}
        onConfirm={() => {
          setConfirmDeletion(false);
          deleteEvent();
        }}
      />
    </Container>
  );
};

export default Activity;
