import React from 'react';
import { VideoPlayer } from './styles';
import ReactPlayer from 'react-player';

interface IVideoFieldProps {
  id: number;
  youtubeLink?: string;
  onClick?: () => void;
}

const VideoField: React.FC<IVideoFieldProps> = props => {
  if (!props.youtubeLink) {
    return null;
  }

  return (
    <VideoPlayer>
      <ReactPlayer url={props.youtubeLink} width="100%" />
    </VideoPlayer>
  );
};

export default VideoField;
