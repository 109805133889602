import React from 'react';
import { MapPlace, Wrapper, MapField, MapInfo } from './styles';
import PlaceMap from 'components/Map/PlaceMap';

export interface IProps {
  address?: string;
  placeId?: string;
}

const MapDetailPage: React.FC<IProps> = (props: IProps) => {
  return (
    <MapPlace>
      <Wrapper>
        <MapInfo>
          {props.address && <h2>Localização</h2>}
          <p>{props.address}</p>
          {props.placeId && (
            <MapField>
              <PlaceMap q={props.placeId} />
            </MapField>
          )}
        </MapInfo>
      </Wrapper>
    </MapPlace>
  );
};

export default MapDetailPage;
