import React from 'react';
import { PortalDataClass } from './styles';
import IframeComponent from 'components/IFrameComponent';

const PortalData = (): JSX.Element => {
  return (
    <>
      <PortalDataClass>
        <div className="LookerPortalData">
          <IframeComponent src="https://lookerstudio.google.com/embed/reporting/3e4241f9-5c3e-4534-8bdf-f513681bef4d/page/cWpuD/" />
        </div>
      </PortalDataClass>
    </>
  );
};

export default PortalData;
