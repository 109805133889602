import React from 'react';
import { Container, Message, Phone, Email, Image } from './styles';
import telephone from '../../../../assets/images/telephone.svg';

interface SACProps {
  phone?: string;
  email?: string;
}

const SAC = ({ phone, email }: SACProps): JSX.Element => {
  return (
    <Container>
      <Image src={telephone} alt="telefone" />
      <Message>Esse Serviço não possui atendimento em unidade física, mas você pode entrar em contato por:</Message>
      <Phone>{phone}</Phone>
      <Email>{email}</Email>
    </Container>
  );
};

export default SAC;
