import styled from 'styled-components';
import email from '../../assets/images/email.svg';

export const Container = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
  height: fit-content;
  background-color: #272727;
  color: #ffff;
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: block;
      margin: 20px 0 30px;
    }
    &:nth-child(1) {
      flex-direction: column;
      div {
        flex-direction: row;
        justify-content: start;
      }
    }
    div {
      &:nth-child(1) {
        div {
          flex-direction: column;
          margin-right: 10px;
          span {
            width: 290px;
            height: 22px;
            font-weight: 500;
            font-size: 18px;
          }
          input {
            align-items: center;
            padding: 10px;
            gap: 10px;

            width: 300px;
            height: 40px;

            border-radius: 18px;
            margin-top: 10px;
            background: url(${email}) no-repeat 10px 10px #ffff;
            padding-left: 40px;
          }
        }
        flex-direction: row;
      }
      button {
        margin-top: 30px;
      }
    }
  }
  .left-footer {
    a {
      color: #ffff;
    }
    div {
      &:nth-child(1) {
        span {
          margin: 2.5px 0;
        }
      }
    }
  }
  .terms-and-policy {
    width: 290px;
    display: none;
    margin-top: auto;
    margin-bottom: 0;
    justify-content: space-between !important;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .social-media {
    justify-content: space-between;
    gap: 20px;
    img {
      width: 40px;
      height: 40px;
    }
    @media (max-width: 768px) {
      justify-content: center;
      display: flex;
      margin-top: 20px;
    }
  }
  .end-span {
    @media (max-width: 768px) {
    }
  }
`;
