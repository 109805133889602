import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 70px;
`;

export const Section = styled.div`
  margin-top: 40px;
  border-bottom: inherit;

  .input-box {
    display: flex;
    flex-direction: column;
    width: 38%;
  }

  .input-title,
  .input-link {
    display: flex;
    width: 100%;
    height: 40px;
  }

  .carousel-list {
    max-height: fit-content;
  }
`;

export const SectionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SettingsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 10px 0 10px 10px;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    margin: 16px 0;
  }

  .add-image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9d9d9;
  }
`;

export const SettingsListItemButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
