import styled from 'styled-components';

export const Container = styled.div`
  .buttons-grid {
    margin: 0 auto 50px auto;
    padding: 0 50px;
    width: 100%;

    @media all and (max-width: 980px) {
      display: none;
    }

    .basic-service-button {
      width: 100%;
      font-size: 1rem;

      &:hover {
        color: #fff !important;
      }

      @media all and (max-width: 1500px) {
        font-size: 0.85rem;
      }

      @media all and (max-width: 1290px) {
        font-size: 0.8rem;
      }
    }
  }

  .select-grid-mobile {
    display: none;

    @media all and (max-width: 980px) {
      display: block;
      margin: 0 auto 50px auto;
      padding: 0 50px;
      width: 100%;
    }
  }
`;

export const Map = styled.div`
  height: 500px;
`;

export const ButtonTab = styled.button`
  width: 125px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px;
  border: 0.5px solid #9a9a9a;
  color: #9a9a9a;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  &:hover {
    border-color: #020c90;
    background-color: #020c90;
    color: #ffffff;
  }
  &[data-active='true'] {
    border-color: #020c90;
    color: #020c90;
    &:hover {
      color: #ffffff;
    }
    @media (max-width: 960px) {
      background-color: #fff;
      &:hover {
        color: #020c90;
      }
    }
  }
`;
