import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import MainTitle from 'components/MainTitle';
import PlaceMap from 'components/Map/PlaceMap';
import { ButtonTab, Container, Map } from './styles';
import SearchMap from 'components/Map/SearchMap';
import BasicServiceItem from 'features/Services/components/BasicServiceItem';
import SelectComponent from 'components/Select';
import PublicService from 'services/PublicService';
import IService from 'interfaces/IService';
import { useLoading } from 'hooks/useLoading';

const Services = (): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentService, setCurrentService] = useState<IService | undefined>();
  const [selectedButton, setSelectedButton] = useState(0);
  const { openLoading, closeLoading } = useLoading();
  const [basicService, setBasicService] = useState<IService[]>([]);

  const service = new PublicService();

  useEffect(() => {
    getBasicService();
  }, []);

  const getBasicService = async () => {
    try {
      openLoading();
      const data = await service.getAllBasicService();
      setBasicService(data);
      setSearchTerm(`${data[0].searchTerm} em Porto Seguro, BA`);
      setCurrentService(data[0]);
      setSelectedButton(0);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  const handleButtonClick = (current: IService, index: number) => {
    setSearchTerm(`${current.searchTerm} em Porto Seguro, BA`);
    setCurrentService(current);
    setSelectedButton(index);
  };

  const handleSelectChange = (strIndex: string | string[]) => {
    const index = Number(strIndex);
    const current = basicService[index];
    handleButtonClick(current, index);
  };

  return (
    <Container>
      <MainTitle>Serviços Básicos</MainTitle>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} className="buttons-grid" xl={12} md={12} sm={12}>
          {basicService.map((value, index) => (
            <Grid item xl={2} md={2} sm={4} key={`service-${index}`}>
              <ButtonTab
                className="basic-service-button"
                onClick={() => handleButtonClick(value, index)}
                data-active={selectedButton === index}>
                {value.description}
              </ButtonTab>
            </Grid>
          ))}
        </Grid>

        <Grid className="select-grid-mobile" item xs={12} md={12}>
          <SelectComponent
            label="Selecione o serviço"
            options={basicService.map((service, index) => ({ label: service.description, value: String(index) }))}
            selected={[currentService?.description || '']}
            onChange={selectedValue => handleSelectChange(selectedValue)}
          />
        </Grid>

        {currentService?.phone && currentService?.email ? (
          <BasicServiceItem email={currentService.email} phone={currentService.phone} />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Map>{searchTerm ? <SearchMap q={searchTerm} /> : <PlaceMap q="Porto Seguro, BA" />}</Map>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default Services;
