import React, { useState } from 'react';
import { ListItem, LogoutModal, Menu, Stroke } from './styles';
import logoutIcon from '../../assets/images/logoutIcon.svg';
import myAccount from '../../assets/images/myAccount.svg';
import info from '../../assets/images/info.svg';
import { Link, NavLink } from 'react-router-dom';
import { userLogout } from 'services/localStorage';
import { useRecoilState } from 'recoil';
import { logged } from 'features/Login/store/LoginAtom';
import { auth } from 'utils/firebase';
import { useHistory } from 'react-router';
import { Modal } from '@mui/material';
import Button from '../../components/Buttom/Button';

const MobileMenuItems: React.FC = () => {
  const history = useHistory();
  const [loggedState, setLoggedState] = useRecoilState(logged);
  const [showModal, setShowModal] = useState(false);

  const logout = () => {
    setLoggedState(false);
    userLogout();
    auth.signOut();
    history.push('/');
    setShowModal(false);
  };

  return (
    <>
      <Modal open={showModal} style={{ zIndex: '9999999' }}>
        <LogoutModal>
          <h2>SAIR</h2>
          <span>Você está se desconectando de uma conta. Deseja continuar?</span>
          <div>
            <Button text="CANCELAR" onClick={() => setShowModal(false)} />
            <Button text="SAIR" onClick={logout} />
          </div>
        </LogoutModal>
      </Modal>
      <Menu>
        <ul>
          <Stroke />
          {loggedState ? (
            <>
              <ListItem>
                <NavLink activeClassName="selected" to="/my-account">
                  <img src={myAccount} alt="icon for list item" />
                  <span>Minha Conta</span>
                </NavLink>
              </ListItem>
              {/* <ListItem>
                <NavLink exact activeClassName="selected" to="/">
                  <img src={info} alt="icon for list item" />
                  <span>Sobre o Portal</span>
                </NavLink>
              </ListItem> */}
              <Stroke />
              <ListItem>
                <Link onClick={() => setShowModal(true)} to={'#'}>
                  <img src={logoutIcon} alt="icon for list item" />
                  <span>Sair</span>
                </Link>
              </ListItem>
            </>
          ) : (
            <ListItem>
              <NavLink to="/login">
                <img src={myAccount} alt="icon for list item" />
                <span>Minha Conta</span>
              </NavLink>
            </ListItem>
          )}
        </ul>
      </Menu>
    </>
  );
};

export default MobileMenuItems;
