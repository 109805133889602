import React, { ReactNode } from 'react';
import { ModalOverlay, ModalContainer } from './styles';

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
}

export const Modal = (props: ModalType): JSX.Element => {
  const handleChildElementClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {props.isOpen && (
        <ModalOverlay onClick={props.toggle}>
          <ModalContainer className="modal" onClick={e => handleChildElementClick(e)}>
            {props.children}
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  );
};
