import React, { useEffect, useState } from 'react';
import { useLoading } from 'hooks/useLoading';
import { Container } from './styles';
import IService from 'interfaces/IService';
import ServiceRestService from 'services/ServiceRestService';
import Heading from 'components/Heading';
import editIcon from 'assets/images/editIcon.svg';
import trashIcon from 'assets/images/trashIcon.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import {
  SettingsList,
  SettingsListContainer,
  SettingsListItem,
  SettingsListItemButton,
  SettingsListItemButtonList,
  SettingsListItemTitle,
} from 'components/List/styles';
import Button from 'components/Button';
import { Modal } from 'components/Modals';
import BasicServiceForm from 'features/Services/components/BasicServiceForm';

const Service = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [basics, setBasic] = useState<IService[]>([]);
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [basicId, setBasicId] = useState(0);
  const [editingService, setEditingService] = useState<IService | null>(null);
  const service = new ServiceRestService();

  useEffect(() => {
    fetchBasicService();
  }, []);

  const fetchBasicService = async () => {
    openLoading();
    const data = await service.findAll();
    closeLoading();
    setBasic(data);
  };

  const handleEdit = (basicId: number) => {
    const serviceToEdit = basics.find(basic => basic.id === basicId);
    if (serviceToEdit) {
      setEditingService(serviceToEdit);
      setShowModal(true);
    } else {
      setEditingService(null);
    }
  };

  const deleteEvent = async () => {
    await service.remove(basicId);
    const index = basics.filter(item => item.id !== basicId);
    setBasic(index);
  };

  const handleCancel = (reload?: boolean) => {
    setShowModal(false);
    setEditingService(null);
    reload && fetchBasicService();
  };

  return (
    <Container>
      <div style={{ marginTop: '2rem' }}>
        <div className="header">
          <Heading>Lista de Serviços</Heading>
          <Button type="button" buttonType="primary" onClick={() => setShowModal(true)}>
            Adicionar
          </Button>
        </div>
        <SettingsListContainer>
          <SettingsList>
            {basics.map((basic, index) => (
              <SettingsListItem key={index}>
                <SettingsListItemTitle>{basic.description}</SettingsListItemTitle>
                <SettingsListItemButtonList>
                  <SettingsListItemButton onClick={() => handleEdit(basic.id)}>
                    <img src={editIcon} alt="icon for edit item" />
                  </SettingsListItemButton>
                  <SettingsListItemButton
                    onClick={async () => {
                      setConfirmDeletion(true);
                      setBasicId(basic.id);
                    }}>
                    <img src={trashIcon} alt="icon for trash item" />
                  </SettingsListItemButton>
                </SettingsListItemButtonList>
              </SettingsListItem>
            ))}
          </SettingsList>
        </SettingsListContainer>
      </div>
      <ConfirmationModal
        open={confirmDeletion}
        title="EXCLUSÃO DE ITEM"
        message="Você tem certeza de que deseja excluir esse item?"
        onCancel={() => setConfirmDeletion(false)}
        onConfirm={() => {
          setConfirmDeletion(false);
          deleteEvent();
        }}
      />
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <BasicServiceForm onCancel={handleCancel} editingService={editingService} editingMode={!!editingService} />
      </Modal>
    </Container>
  );
};

export default Service;
